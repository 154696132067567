import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';
import { Link } from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import TooltipButton from './TooltipButton';

import './UserPlayersTable.css';

Moment.locale('en');

class UserPlayersTable extends Component {
	didConfirmDelete(player) {
		this.props.onDelete(player);
	}

	handleDeleteClicked(player) {
		const { t } = this.props;

		this.dialog.show({
			title: t('Dialog.warning'),
			body: t('Dialog.sure_deletion_userplayer'),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.delete'), () => this.didConfirmDelete(player), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	render() {
		const { t, uuid } = this.props;
		let playerRows = this.props.players.map((player, index) => {
			return (
				<tr key={index}>
					<td className='td-center' width={80}>
						<OverlayTrigger
							placement='top'
							delayShow='300'
							delayHide='150'
							overlay={
								<Tooltip id='tooltip'>
									<strong>{t('UserPlayersTable.previewplayer')}</strong>
								</Tooltip>
							}
						>
							<a id='previewButton' className='btn btn-primary' target='_blank' href={`${config.playersBaseURL}/mediaserver/unit.php?playerUUID=${player.uuid}&preview=1`}>
								<em className='fa fa-eye' />
							</a>
						</OverlayTrigger>
					</td>
					<td className='hidden-xs hidden-sm'>{player.online ? <span className='label label-success'>{t('UserPlayersTable.online')}</span> : <span className='label label-danger'>{t('UserPlayersTable.offline')}</span>}</td>

					<td>
						<Link
                         id="playerButton"
                         style={{
                            alignItems: "center",
                            textAlign: "left"
                          }}
                         className="label label-primary"
                         to={`/organisations/${uuid}/players/${player.uuid}`}
                        >
                            {player.name}
                        </Link>
					</td>
					<td className='hidden-xs hidden-sm'>{player.location}</td>
					<td className='hidden-xs hidden-sm'>{player.macAddress}</td>
					<td className='hidden-xs hidden-sm'>{player.serialNumber}</td>
					<td className='hidden-xs hidden-sm hidden-md'>{Moment(player.installDate).format('YYYY-MM-DD HH:mm')}</td>
					<td className='hidden-xs'>{player.lastSeen ? Moment(player.lastSeen).fromNow() : t('UserPlayersTable.never')}</td>
					<td className='td-center'>
						{this.props.userIsAuthorized(this.props.user, ['staff_super_admin', 'staff_admin', 'organisation_admin', 'organisation_user']) ? (
							<TooltipButton className='btn-danger' tooltip={t('UserPlayersTable.deleteplayer')} onClick={() => this.handleDeleteClicked(player)}>
								<em className='fa fa-trash' /> {t('UserPlayersTable.remove')}
							</TooltipButton>
						) : null}
					</td>
				</tr>
			);
		});

		return (
			<div id='players' className='playerTable'>
				<Dialog
					ref={el => {
						this.dialog = el;
					}}
				/>
				<Table striped bordered condensed hover>
					<thead>
						<tr>
							<th>{t('UserPlayersTable.preview')}</th>
							<th className='hidden-xs hidden-sm'>{t('UserPlayersTable.status')}</th>
							<th>{t('UserPlayersTable.name')}</th>
							<th className='hidden-xs hidden-sm'>{t('UserPlayersTable.location')}</th>
							<th className='hidden-xs hidden-sm'>{t('UserPlayersTable.mac_address')}</th>
							<th className='hidden-xs hidden-sm'>{t('UserPlayersTable.serial_number')}</th>
							<th className='hidden-xs hidden-sm hidden-md'>{t('UserPlayersTable.install_date')}</th>
							<th className='hidden-xs'>{t('UserPlayersTable.last_seen')}</th>
							<th>
								<em className='fa fa-cog' />
							</th>
						</tr>
					</thead>
					<tbody>{playerRows}</tbody>
				</Table>
			</div>
		);
	}
}

export default translate('translations')(UserPlayersTable);
