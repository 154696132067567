import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateOrganisationForm from '../components/CreateOrganisationForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import './CreateOrganisation.css';

class CreateOrganisation extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreateOrganisation.title'
        )}`;

        return (
            <div className="CreateOrganisation">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreateOrganisation.title')}
                                </h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-briefcase" />{' '}
                                        <Link to="/organisations">
                                            {t('Organisations.title')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-plus" />{' '}
                                        {t('CreateOrganisation.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <CreateOrganisationForm />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateOrganisation);
