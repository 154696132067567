import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button, Modal } from 'react-bootstrap';
import './PlayerPickerModal.css';
import PlayerPickerTable from './PlayerPickerTable.js';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';

class PlayerPickerModal extends Component {
    constructor(props) {
        super(props);

        this.handleHide = this.handleHide.bind(this);

        this.state = {
            show: false,
            title: '',
            players: [],
            page: 1,
            pageCount: 0,
            limit: 20,
            search: '',
            organisationUUID: '',
            excludeAttribute: '',
            excludeGlobalPage: '',
            excludeUserPlayers: '',
            excludePlayer: '',
            itemsSelected: [],
            singleSelection: false,
            selectedPlayer: null

        };
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        this.setState({ page: selected }, () => {
            this.loadOrganisationPlayersFromServer();
        });
    };

    handleHide() {
        this.props.onHide();
        this.setState({
            itemsSelected: []
        });
    }

    handleChooseClicked() {
        if (this.state.singleSelection) {
            this.props.chosePlayer(this.state.selectedPlayer);
        } else {
            this.props.onChoose(this.state.itemsSelected);
        }

        this.setState({
            itemsSelected: []
        });
    }

    componentDidMount() {}

    loadOrganisationPlayersFromServer() {
        var self = this;

        axios
            .get(
                `${config.baseURL}/organisations/${
                    this.state.organisationUUID
                }/players`,
                {
                    params: {
                        page: self.state.page,
                        limit: self.state.limit,
                        excludePlayer: self.state.excludePlayer,
                        excludeAttribute: self.state.excludeAttribute,
                        excludePage: self.state.excludeGlobalPage,
                        excludeUserPlayers: self.state.excludeUserPlayers,
                        search: self.state.search
                    }
                }
            )
            .then(function(response) {
                self.setState({
                    players: response.data.players,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    componentWillReceiveProps(newProps) {
        this.setState(
            {
                show: newProps.show,
                title: newProps.title,
                singleSelection: newProps.singleSelection,
                organisationUUID: newProps.organisationUUID,
                excludeAttribute: newProps.excludeAttribute || '',
                excludeGlobalPage: newProps.excludeGlobalPage || '',
                excludePlayer: newProps.excludePlayer || '',
                excludeUserPlayers: newProps.excludeUserPlayers || ''
            },
            () => {
                this.loadOrganisationPlayersFromServer();
            }
        );
    }

    selectAll(players) {
        let itemsSelected = this.state.itemsSelected;
        players = players.map(player => player.uuid);
        itemsSelected = [...itemsSelected, ...players];
        let selectedSet = new Set(itemsSelected);
        this.setState({
            itemsSelected: [...selectedSet]
        });
    }

    deselectAll(players) {
        let itemsSelected = this.state.itemsSelected;
        let playerUUIDsToRemove = players.map(player => player.uuid);

        itemsSelected = itemsSelected.filter(function(uuid) {
          return !playerUUIDsToRemove.includes(uuid);
        });

        let selectedSet = new Set(itemsSelected);

        this.setState({
            itemsSelected: [...selectedSet]
        });
    }

    selectionChanged(player, checked) {
        let itemsSelected = this.state.itemsSelected;

        let selectedPlayer = null;
        if (!this.state.singleSelection) {

            if (checked) {
                itemsSelected.push(player.uuid);
            } else {
                itemsSelected = itemsSelected.filter(
                    selectedItem => selectedItem !== player.uuid
                );
            }

        } else {
                 if (checked) {
                    itemsSelected = [];
                    itemsSelected.push(player.uuid);
                    selectedPlayer = player;
                } else {
                    itemsSelected = [];
                }
        }

        var selectedSet = new Set(itemsSelected);

        this.setState({
            itemsSelected: [...selectedSet],
            selectedPlayer
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="PlayerPickerModal">
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    container={this}
                    aria-labelledby="contained-modal-title"
                    dialogClassName="PlayerPickerModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">

                        {this.state.singleSelection == false ? (t('PlayerPickerModal.title')) : (t('PlayerPickerModal.title_single'))}
                            {' '}
                            <div className="btn-toolbar pull-right" style={{ "margin-right": "10px"}}>

                            {this.state.singleSelection == false ? (
                                <Button
                                    className="btn-primary btn-sm"
                                    disabled={false}
                                    onClick={() => this.selectAll(this.state.players)}
                                >
                                    <em className="far fa-check-square" />{' '}
                                    {t(
                                        'PlayerPickerModal.select_all'
                                    )}
                                </Button>
                                ) : null
                            }
                            {this.state.singleSelection == false ? (
                                <Button
                                    className="btn-normal btn-sm"
                                    disabled={false}
                                    onClick={() => this.deselectAll(this.state.players)}
                                >
                                    <em className="far fa-square" />{' '}
                                    {t(
                                        'PlayerPickerModal.deselect_all'
                                    )}
                                </Button>  ) : null
                            }
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '0px', padding: '0px' }}>

                        <PlayerPickerTable
                            singleSelection={this.state.singleSelection}
                            itemsSelected={this.state.itemsSelected}
                            players={this.state.players}
                            onDelete={() => {}}
                            onSelectionChanged={this.selectionChanged.bind(
                                this
                            )}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center">
                            <ReactPaginate
                                previousLabel={t('Pagination.previous')}
                                nextLabel={t('Pagination.next')}
                                breakLabel={<Link to="#">...</Link>}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={10}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="btn-toolbar pull-right">
                            <Button onClick={this.handleHide}>
                                {t('PlayerPickerModal.close')}
                            </Button>
                            <Button
                                disabled={this.state.itemsSelected.length === 0}
                                className="btn-primary"
                                onClick={this.handleChooseClicked.bind(this)}
                            >
                                {this.state.singleSelection == false ? (
                                t('PlayerPickerModal.add_selected')
                                ) : (t('PlayerPickerModal.choose_selected')) }
                                {this.state.singleSelection == false ? (
                                ` (${this.state.itemsSelected.length})`
                                ) : null }
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default translate('translations')(PlayerPickerModal);
