import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';

import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

class QuestionTypeTable extends Component {
    didConfirmDelete(questiontype) {
        this.props.onDelete(questiontype);
    }

    handleDeleteClicked(questiontype) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(questiontype),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t } = this.props;

        let questiontypeRows = this.props.questiontypes.map(
            (questiontype, index) => {
                return (
                    <tr key={index}>
                        <td>{questiontype.id}</td>
                        <td>
                            {questiontype.enabled ? (
                                <span className="label label-success">
                                    {t('QuestionTypeTable.enabled')}
                                </span>
                            ) : (
                                <span className="label label-danger">
                                    {t('QuestionTypeTable.disabled')}
                                </span>
                            )}
                        </td>
                        <td>{questiontype.description}</td>
                        <td align="center">
                            <TooltipLink
                                id="editButton"
                                className="btn btn-default"
                                tooltip={t(
                                    'QuestionTypeTable.editquestiontype'
                                )}
                                to={`/qanda/questiontypes/${
                                    questiontype.id
                                }/edit`}
                            >
                                <em className="fas fa-pencil-alt" />
                            </TooltipLink>
                            <TooltipButton
                                id="deleteButton"
                                className="btn-danger pull-right"
                                tooltip={t(
                                    'QuestionTypeTable.deletequestiontype'
                                )}
                                onClick={() =>
                                    this.handleDeleteClicked(questiontype)
                                }
                            >
                                <em className="fa fa-trash" />
                            </TooltipButton>
                        </td>
                    </tr>
                );
            }
        );

        return (
            <div id="questiontypetable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{t('QuestionTypeTable.id')}</th>
                            <th>{t('QuestionTypeTable.status')}</th>
                            <th>{t('QuestionTypeTable.description')}</th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{questiontypeRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(QuestionTypeTable);
