import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form, FormGroup } from 'react-bootstrap';
import HeaderFooterPanel from '../components/HeaderFooterPanel';
import config from '../config';
import qs from 'qs';
import Dropzone from 'react-dropzone';
import MediaTable from '../components/MediaTable';
import reorder from '../helpers/reorder';
import { Link } from 'react-router-dom';
import Dialog, { displayName } from 'react-bootstrap-dialog';

import './MediaSnippetMediaLibrary.css';

class MediaSnippetMediaLibrary extends Component {
	constructor(props) {
		super(props);
		const { uuid } = this.props.match.params;

		this.state = {
			medialibrary: { name: '' },
			uuid,
			media: [],
			files: [],
			filePercentages: [],
			itemsSelected: []
		};
	}

	selectionChanged(media, checked) {
		let itemsSelected = this.state.itemsSelected;

		if (checked) {
			itemsSelected.push(media);
		} else {
			itemsSelected = itemsSelected.filter(selectedItem => selectedItem.uuid !== media.uuid);
		}

		var selectedSet = new Set(itemsSelected);

		this.setState({
			itemsSelected: [...selectedSet]
		});
	}

	enabledChanged(media, enabled) {
		try {
			var self = this;
			axios
				.put(`${config.baseURL}/media/${media.uuid}/enabled `, {
					enabled: enabled
				})
				.then(function(response) {
					console.log('Success!');
					self.loadMediaSnippetMediaLibraryMediaFromServer();
				})
				.catch(function(err) {
					console.log(err);
				});
		} catch (e) {
			console.log('exception');
		}
	}

	mutedChanged(media, muted) {
		try {
			var self = this;
			axios
				.put(`${config.baseURL}/media/${media.uuid}/muted `, {
					muted: muted
				})
				.then(function(response) {
					console.log('Success!');
					self.loadMediaSnippetMediaLibraryMediaFromServer();
				})
				.catch(function(err) {
					console.log(err);
				});
		} catch (e) {
			console.log('exception');
		}
	}

	handleDurationClicked() {
		const { t } = this.props;

		this.dialog.show({
			body: `${t('MediaTable.duration')}`,
			prompt: Dialog.TextPrompt({
				placeholder: '10.000',
				initialValue: parseFloat(10000 / 1000).toFixed(3)
			}),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.save'), dialog => this.changeDurationOfMedia(dialog.value * 1000), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	handlePublishedClicked() {
		const { t } = this.props;

		const listedMedia = this.state.itemsSelected.map(d => (
			<li key={d.uuid}>
				<strong>{d.name}</strong>
			</li>
		));

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_publish_items')}
					{listedMedia}
				</div>
			),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.publish'), () => this.changePublishedStateOfMedia(), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	deleteSelected(media) {
		const { t } = this.props;

		let listedMedia;

		if (media) {
			listedMedia = media.name;
		} else {
			listedMedia = this.state.itemsSelected.map(d => (
				<li key={d.uuid}>
					<strong>{d.name}</strong>
				</li>
			));
		}

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_deletion_items')}
					{listedMedia}
				</div>
			),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.delete'), () => this.didConfirmDelete(), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	onDeleteMedia(media) {
		const { t } = this.props;

		const listedMedia = (
			<li>
				<strong>{media.name}</strong>
			</li>
		);

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_deletion_items')}
					{listedMedia}
				</div>
			),
			actions: [Dialog.Action(t('Dialog.cancel'), () => 'btn-default'), Dialog.Action(t('Dialog.delete'), () => this.didConfirmDelete(media), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	changeDurationOfMedia(newDuration) {
		const { mediaLibraryUUID } = this.props.match.params;

		const mediaToReorder = [];
		for (var i = 0; i < this.state.itemsSelected.length; i++) {
			const media = this.state.itemsSelected[i];
			mediaToReorder[i] = media.uuid;
		}

		try {
			var self = this;
			axios
				.post(`${config.baseURL}/medialibraries/${mediaLibraryUUID}/media/duration `, {
					media: mediaToReorder,
					duration: newDuration
				})
				.then(function(response) {
					console.log('Success!');
					self.loadMediaSnippetMediaLibraryMediaFromServer();
					self.setState({ itemsSelected: [] });
				})
				.catch(function(err) {
					console.log(err);
					self.setState({ itemsSelected: [] });
				});
		} catch (e) {
			console.log('exception');
		}
	}

	changePublishedStateOfMedia() {
		const { mediaLibraryUUID } = this.props.match.params;

		const mediaToPublish = [];
		for (var i = 0; i < this.state.itemsSelected.length; i++) {
			const media = this.state.itemsSelected[i];
			mediaToPublish[i] = media.uuid;
		}

		try {
			var self = this;
			axios
				.post(`${config.baseURL}/medialibraries/${mediaLibraryUUID}/media/enabled `, {
					media: mediaToPublish,
					enabled: true
				})
				.then(function(response) {
					console.log('Success!');
					self.loadMediaSnippetMediaLibraryMediaFromServer();
					self.setState({ itemsSelected: [] });
				})
				.catch(function(err) {
					console.log(err);
					self.setState({ itemsSelected: [] });
				});
		} catch (e) {
			console.log('exception');
		}
	}

	mediaRulesClicked(media) {
		const { uuid, mediaLibraryUUID, snippetUUID, playerUUID, pageUUID } = this.props.match.params;

		const parsed = qs.parse(this.props.location.search.substring(1));
		const rel = parsed.rel;

		let link = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${media.uuid}/rules?rel=player`;
		if (rel === 'library') {
			link = `/organisations/${uuid}/medialibraries/${mediaLibraryUUID}/media/${media.uuid}/rules?rel=library`;
		} else if (rel === 'snippet') {
			link = `/organisations/${uuid}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${media.uuid}/rules?rel=snippet`;
		} else if (rel === 'page') {
			link = `/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${media.uuid}/rules?rel=page`;
		}

		this.props.history.push(link);
	}

	textsClicked(media) {
		const { uuid, mediaLibraryUUID, snippetUUID, playerUUID, pageUUID } = this.props.match.params;

		const parsed = qs.parse(this.props.location.search.substring(1));
		const rel = parsed.rel;

		let link = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${media.uuid}/texts?rel=player`;
		if (rel === 'library') {
			link = `/organisations/${uuid}/medialibraries/${mediaLibraryUUID}/media/${media.uuid}/texts?rel=library`;
		} else if (rel === 'snippet') {
			link = `/organisations/${uuid}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${media.uuid}/texts?rel=snippet`;
		} else if (rel === 'page') {
			link = `/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${media.uuid}/texts?rel=page`;
		}

		this.props.history.push(link);
	}

	reorderMedia(result) {
		const { mediaLibraryUUID } = this.props.match.params;
		const reorderedMedia = reorder(this.state.media, result.source.index, result.destination.index);

		this.setState({
			media: reorderedMedia
		});

		const ordering = {};
		for (var i = 0; i < reorderedMedia.length; i++) {
			const media = reorderedMedia[i];
			ordering[media.uuid] = i;
		}

		try {
			var self = this;
			axios
				.post(`${config.baseURL}/medialibraries/${mediaLibraryUUID}/media/reorder `, { ordering })
				.then(function(response) {
					console.log('Success!');
				})
				.catch(function(err) {
					console.log(err);
				});
		} catch (e) {
			console.log('exception');
		}
	}

	copyMedia(media) {
		const { mediaLibraryUUID } = this.props.match.params;

		var self = this;
		axios
			.post(`${config.baseURL}/medialibraries/${mediaLibraryUUID}/media?sourceUUID=${media.uuid}`, {})
			.then(function(response) {
				self.loadMediaSnippetMediaLibraryMediaFromServer();
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	saveMedia(media, newDuration) {
		var self = this;
		axios
			.put(`${config.baseURL}/media/${media.uuid}/duration`, {
				duration: newDuration
			})
			.then(function(response) {
				self.loadMediaSnippetMediaLibraryMediaFromServer();
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	selectAll() {
		this.setState({ itemsSelected: this.state.media });
	}

	selectNone() {
		this.setState({ itemsSelected: [] });
	}

	deleteMedia(media) {
		return new Promise(function(resolve, reject) {
			var self = this;
			axios
				.delete(`${config.baseURL}/media/${media.uuid}`)
				.then(function(result) {
					console.log('deleted media');
					resolve();
				})
				.catch(function(err) {
					console.log(`Error deleting file: ${media} error: ${err}`);
					reject('error');
				});
		});
	}
	componentDidMount() {
		this.loadMediaSnippetMediaLibraryFromServer();
		this.loadMediaSnippetMediaLibraryMediaFromServer();
	}

	loadMediaSnippetMediaLibraryFromServer() {
		const { uuid, mediaLibraryUUID, snippetUUID, playerUUID, pageUUID } = this.props.match.params;

		let self = this;
		axios
			.get(`${config.baseURL}/medialibraries/${mediaLibraryUUID}`, {})
			.then(function(response) {
				self.setState({ medialibrary: response.data.medialibrary });

				self.loadOrganisationInformation();
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	loadOrganisationInformation() {
		var self = this;

		const { uuid } = this.props.match.params;

		axios
			.get(`${config.baseURL}/organisations/${uuid}`, {})
			.then(function(response) {
				self.setState({
					organisation_name: response.data.organisation.name
				});

				const parsed = qs.parse(self.props.location.search.substring(1));
				const rel = parsed.rel;
				if (rel === 'player') {
					self.loadPlayerFromServer();
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	loadPlayerFromServer() {
		var self = this;
		const { playerUUID } = this.props.match.params;

		axios
			.get(`${config.baseURL}/players/${playerUUID}`, {})
			.then(function(response) {
				self.setState({
					player_name: response.data.player.name
				});
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	didConfirmDelete(media) {
		let self = this;

		const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

		const promiseSerial = files =>
			files.reduce(function(promise, media) {
				return promise.then(function(result) {
					return Promise.all([delay(250), self.deleteMedia(media)]);
				});
			}, Promise.resolve());

		if (media) {
			self.deleteMedia(media).then(function() {
				self.loadMediaSnippetMediaLibraryMediaFromServer();
			});
		} else {
			promiseSerial(this.state.itemsSelected).then(function() {
				console.log('Deleted media');
				self.setState({ itemsSelected: [] });
				self.loadMediaSnippetMediaLibraryMediaFromServer();
			});
		}
	}

	loadMediaSnippetMediaLibraryMediaFromServer() {
		const { uuid, mediaLibraryUUID } = this.props.match.params;

		let self = this;
		axios
			.get(`${config.baseURL}/medialibraries/${mediaLibraryUUID}/media`, {})
			.then(function(response) {
				let media = response.data.media;

				self.setState({ media, itemsSelected: [] });

				let setTimer = false;
				for (const mediaItem of media) {
					if (!mediaItem.uploaded) {
						setTimer = true;
						break;
					}
				}

				if (setTimer) {
					setTimeout(
						function() {
							self.loadMediaSnippetMediaLibraryMediaFromServer();
						}.bind(this),
						5000
					);
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	render() {
		const { t } = this.props;
		const { uuid, mediaLibraryUUID, playerUUID, pageUUID, snippetUUID } = this.props.match.params;

		const parsed = qs.parse(this.props.location.search.substring(1));
		const rel = parsed.rel;
		document.title = `MySign Digital Screen Solutions | ${t('MediaSnippetMediaLibrary.title')}`;

		let createMediaURL = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/create`;
		if (rel === 'player') {
			createMediaURL = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/create?rel=player`;
		} else if (rel === 'library') {
			createMediaURL = `/organisations/${uuid}/medialibraries/${mediaLibraryUUID}/media/create?rel=library`;
		} else if (rel === 'snippet') {
			createMediaURL = `/organisations/${uuid}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/create?rel=snippet`;
		} else if (rel === 'page') {
			createMediaURL = `/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/create?rel=page`;
		}

		return (
			<div className='MediaSnippetMediaLibrary'>
				<Dialog
					ref={el => {
						this.dialog = el;
					}}
				/>
				<div id='wrapper'>
					<Navigation props={this.props} />
					<div id='content-wrapper'>
						<Row>
							<Col lg={12}>
								<h1 className='page-header'>
									{this.state.medialibrary.name}{' '}
									{rel === 'player' ? (
										<small>
											{this.state.player_name}{' '}
											<small
												style={{
													whiteSpace: 'nowrap'
												}}
											>
												{this.state.organisation_name}
											</small>
										</small>
									) : null}
									{rel === 'library' ? <small>{this.state.organisation_name}</small> : null}
									<Link type='submit' className='btn btn-primary' to={createMediaURL}>
										<em className='fa fa-plus fa-fw' /> {t('MediaSnippetMediaLibrary.add_media')}
									</Link>
									{rel === 'player' ? (
										<a id='previewButton' type='submit' className='btn btn-primary' target='_blank' href={`${config.playersBaseURL}/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}>
											<em className='fa fa-eye fa-fw' /> {t('Player.preview_player')}
										</a>
									) : null}
								</h1>

								<ol className='breadcrumb'>
									{rel === 'player' ? (
										<Fragment>
											<li>
												<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
											</li>
											<li>
												<i className='fa fa-tv' /> <Link to={`/organisations/${uuid}/players`}>{t('SideNavigation.players')}</Link>
											</li>
											<li>
												<i className='fa fa-tv' /> <Link to={`/organisations/${uuid}/players/${playerUUID}`}>{t('OrganisationPlayer.title')}</Link>
											</li>
											<li>
												<i className='fa fa-file' /> <Link to={`/organisations/${uuid}/players/${playerUUID}/pages/`}>{t('SideNavigation.pages')}</Link>
											</li>
											<li>
												<i className='fa fa-puzzle-piece' />
												<Link to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}> {t('Page.title')}</Link>
											</li>
											<li>
												<i className='fas fa-images' /> <Link to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}?rel=player`}>{t('MediaSnippet.title')}</Link>
											</li>
											<li className='active'>
												<i className='fas fa-images' /> {t('MediaSnippetMediaLibrary.title')}
											</li>{' '}
										</Fragment>
									) : null}
									{rel === 'library' ? (
										<Fragment>
											<li>
												<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
											</li>
											<li>
												<i className='fas fa-images' /> <Link to={`/organisations/${uuid}/medialibraries?rel=library`}>{t('SideNavigation.globalmedialibraries')}</Link>
											</li>
											<li className='active'>
												<i className='fas fa-images' /> {t('MediaSnippetMediaLibrary.title')}
											</li>{' '}
										</Fragment>
									) : null}

									{rel === 'snippet' ? (
										<React.Fragment>
											<li>
												<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
											</li>
											<li>
												<i className='fa fa-puzzle-piece' />
												<Link to={`/organisations/${uuid}/snippets`}> {t('SideNavigation.globalsnippets')}</Link>
											</li>{' '}
											<li>
												<i className='fas fa-images' /> <Link to={`/organisations/${uuid}/mediasnippets/${snippetUUID}?rel=snippet`}>{t('MediaSnippet.title')}</Link>
											</li>
											<li className='active'>
												<i className='fas fa-images' /> {t('MediaSnippetMediaLibrary.title')}
											</li>{' '}
										</React.Fragment>
									) : null}

									{rel === 'page' ? (
										<React.Fragment>
											<li>
												<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
											</li>
											<li>
												<i className='fa fa-file' /> <Link to={`/organisations/${uuid}/pages`}>{t('SideNavigation.globalpages')}</Link>
											</li>
											<li>
												<i className='fa fa-puzzle-piece' /> <Link to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}>{t('Page.title')}</Link>
											</li>
											<li>
												<i className='fas fa-images' /> <Link to={`/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}?rel=page`}>{t('MediaSnippet.title')}</Link>
											</li>
											<li className='active'>
												<i className='fas fa-images' /> {t('MediaSnippetMediaLibrary.title')}
											</li>{' '}
										</React.Fragment>
									) : null}
								</ol>

								<HeaderFooterPanel
									title={t('MediaSnippetMediaLibrary.media')}
									headerContent={
										<div className='btn-toolbar pull-right'>
											<Button className='btn-default btn-sm' disabled={false} onClick={() => this.selectAll()}>
												<em className='far fa-check-square' /> {t('MediaSnippetMediaLibrary.select_all')}
											</Button>
											<Button className='btn-default btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.selectNone()}>
												<em className='far fa-square' /> {t('MediaSnippetMediaLibrary.select_none')}
											</Button>
											<Button className='btn-primary btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.handlePublishedClicked()}>
												<em className='far fa-check-square' /> {t('MediaSnippetMediaLibrary.mark_published')}
											</Button>
											<Button className='btn-primary btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.handleDurationClicked()}>
												<em className='fa fa-clock' /> {t('MediaSnippetMediaLibrary.modify_duration')}
											</Button>

											<Button className='btn btn-danger btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.deleteSelected()}>
												<em className='fa fa-trash' /> {t('MediaSnippetMediaLibrary.delete_selected')}
											</Button>
										</div>
									}
								>
									<MediaTable
										media={this.state.media}
										rel={rel}
										medialibraryuuid={mediaLibraryUUID}
										organisationuuid={uuid}
										onRulesClicked={this.mediaRulesClicked.bind(this)}
										onSave={this.saveMedia.bind(this)}
										onDragEnd={this.reorderMedia.bind(this)}
										onCopyClicked={this.copyMedia.bind(this)}
										onTextsClicked={this.textsClicked.bind(this)}
										onSelectionChanged={this.selectionChanged.bind(this)}
										onEnabledChanged={this.enabledChanged.bind(this)}
										onMutedChanged={this.mutedChanged.bind(this)}
										itemsSelected={this.state.itemsSelected}
										onDeleteMedia={media => this.onDeleteMedia(media)}
									/>
								</HeaderFooterPanel>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('translations')(MediaSnippetMediaLibrary);
