import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Checkbox } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';

Moment.locale('en');

class FactTypePickerTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            facttypes: this.props.facttypes
        };
    }

    getCheckedState(facttype) {
        const selected = this.state.itemsSelected.includes(facttype.id);
        return selected;
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            facttypes: newProps.facttypes,
            itemsSelected: newProps.itemsSelected
        });
    }

    handleCheckBoxChange = event => {
        const target = event.target;

        const checked = target.checked;
        const id = target.name;

        const facttype = this.state.facttypes.find(facttype => {
            return facttype.id == id;
        });

        this.props.onSelectionChanged(facttype, checked);
    };

    render() {
        const { t, uuid } = this.props;
        let facttypeRows = this.state.facttypes.map((facttype, index) => {
            return (
                <tr key={index}>
                    <td width="20">
                        <Checkbox
                            checked={this.getCheckedState(facttype)}
                            name={facttype.id}
                            onChange={this.handleCheckBoxChange}
                        />
                    </td>

                    <td>{facttype.id}</td>
                    <td>{facttype.description}</td>
                </tr>
            );
        });

        return (
            <div id="facttypes" className="facttypeTable">
                <Table
                    striped
                    bordered
                    condensed
                    hover
                    style={{ margin: '0px' }}
                >
                    <thead>
                        <tr>
                            <th width="20" />
                            <th width="20">{t('FactTypePickerTable.id')}</th>
                            <th>{t('FactTypePickerTable.description')}</th>
                        </tr>
                    </thead>
                    <tbody>{facttypeRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(FactTypePickerTable);
