import React, { Component, Fragment } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { TemplateSnippetTextMenu } from './TemplateSnippetTextMenu';
import { TemplateSnippetColorPicker } from './TemplateSnippetColorPicker';
import { TemplateSnippetPositionMenu } from './TemplateSnippetPositionMenu';

export class TemplateSnippetMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayColorPicker: false
		};
	}

	render() {
		const {
			showModal,
			zoomIn,
			zoomOut,
			addSnippet,
			removeSnippet,
			toggleBold,
			toggleItalic,
			toggleAlignLeft,
			toggleAlignRight,
			toggleAlignCenter,
			alignItemCenter,
			alignItemMiddle,
			fontSizeDecrease,
			fontSizeSet,
			fontSizeIncrease,
			setColor,
			handleSave,
			isSaved,
			isSelected,
			textColor,
			t,
			handleSnapToGrid,
			gridValue,
			handlePreview,
			previewEnabled,
			isBold,
			isItalic
		} = this.props;

		return (
			<Col xs={2}>
				<Col xs={12} id='template-snippet-menu'>
					<Row id='ts-menu-upper' className='ts-menu-group'>
						<h4>{t('TemplateSnippet.menu_canvas')}</h4>
						<Row className='ts-menu-row'>
							<Col className='ts-menu-col' xs={12}>
								<button className='btn btn-dark btn-block' onClick={showModal}>
									<i className='fas fa-image' /> <strong>{t('TemplateSnippet.button_background')}</strong>
								</button>
							</Col>
						</Row>
						<Row className='ts-menu-row'>
							<Col className='ts-menu-col p-0' xs={6}>
								<Col className='ts-menu-col' xs={12}>
									<OverlayTrigger
										placement='top'
										delayShow={300}
										delayHide={150}
										overlay={
											<Tooltip id='tooltip'>
												<strong>{t('TemplateSnippet.tooltip_zoomIn')}</strong>
											</Tooltip>
										}
									>
										<button className='btn btn-dark btn-block' onClick={zoomIn}>
											<i className='fas fa-search-plus fa-lg' />
										</button>
									</OverlayTrigger>
								</Col>
							</Col>
							<Col className='ts-menu-col p-0' xs={6}>
								<Col className='ts-menu-col' xs={12}>
									<OverlayTrigger
										placement='top'
										delayShow={300}
										delayHide={150}
										overlay={
											<Tooltip id='tooltip'>
												<strong>{t('TemplateSnippet.tooltip_zoomOut')}</strong>
											</Tooltip>
										}
									>
										<button className='btn btn-dark btn-block' onClick={zoomOut}>
											<i className='fas fa-search-minus fa-lg' />
										</button>
									</OverlayTrigger>
								</Col>
							</Col>
						</Row>
						<Row className='ts-menu-row'>
							<Col className='ts-menu-col p-0' xs={6}>
								<Col className='ts-menu-col' xs={12}>
									<OverlayTrigger
										placement='top'
										delayShow={300}
										delayHide={150}
										overlay={
											<Tooltip id='tooltip'>
												<strong>{t('TemplateSnippet.tooltip_addTextBox')}</strong>
											</Tooltip>
										}
									>
										<button className='btn btn-success btn-block' onClick={addSnippet}>
											<i className='fas fa-plus-circle fa-lg' />
										</button>
									</OverlayTrigger>
								</Col>
							</Col>
							<Col className='ts-menu-col p-0' xs={6}>
								<Col className='ts-menu-col' xs={12}>
									<OverlayTrigger
										placement='top'
										delayShow={300}
										delayHide={150}
										overlay={
											<Tooltip id='tooltip'>
												<strong>{t('TemplateSnippet.tooltip_removeTextBox')}</strong>
											</Tooltip>
										}
									>
										<button className={`btn btn-${isSelected ? 'danger' : 'disabled'} btn-block`} onClick={removeSnippet}>
											<i className='fas fa-minus-circle fa-lg' />
										</button>
									</OverlayTrigger>
								</Col>
							</Col>
						</Row>
						<Row className='ts-menu-row'>
							<Col className='ts-menu-col' xs={12}>
								<OverlayTrigger
									placement='top'
									delayShow={300}
									delayHide={150}
									overlay={
										<Tooltip id='tooltip'>
											<strong>{t('TemplateSnippet.tooltip_snapToGrid')}</strong>
										</Tooltip>
									}
								>
									<button className={`btn btn-dark btn-block ${gridValue > 1 ? 'active' : ''}`} onClick={handleSnapToGrid}>
										<span class='glyphicon glyphicon-th'></span>
									</button>
								</OverlayTrigger>
							</Col>
						</Row>
						{isSelected ? (<TemplateSnippetColorPicker setColor={setColor} textColor={textColor} t={t} />) : null}
						{isSelected ? (
							<Fragment>
								<TemplateSnippetTextMenu
									t={t}
									toggleBold={toggleBold}
									toggleItalic={toggleItalic}
									toggleAlignLeft={toggleAlignLeft}
									toggleAlignRight={toggleAlignRight}
									toggleAlignCenter={toggleAlignCenter}
									fontSizeDecrease={fontSizeDecrease}
									fontSizeSet={fontSizeSet}
									fontSizeIncrease={fontSizeIncrease}
									isBold={isBold}
									isItalic={isItalic}
								/>
								<TemplateSnippetPositionMenu t={t} alignItemCenter={alignItemCenter} alignItemMiddle={alignItemMiddle} />
							</Fragment>
						) : null}
					</Row>
					<Row id='ts-menu-lower' className='ts-menu-group'>
						<Row className='ts-menu-row'>
							<Col className='ts-menu-col' xs={6}>
								<button className={`btn btn-block btn-primary ${previewEnabled ? 'active' : ''}`} onClick={handlePreview}>
									<i className='fas fa-eye'></i> <strong>{t('TemplateSnippet.button_preview')}</strong>
								</button>
							</Col>
							<Col className='ts-menu-col' xs={6}>
								<button className={`btn btn-block ${isSaved ? 'btn-success' : 'btn-warning'}`} onClick={handleSave}>
									<i className='fas fa-save fa-lg' /> <strong>{t('TemplateSnippet.button_save')}</strong>
								</button>
							</Col>
						</Row>
					</Row>
				</Col>
			</Col>
		);
	}
}

export default translate('translations')(TemplateSnippetMenu);
