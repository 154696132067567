import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditQuestionForm from '../components/EditQuestionForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditQuestion.css';

class EditQuestion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: { description: '', answer: '', typeID: 0 },
            questionTypes: []
        };
    }

    componentDidMount() {
        this.loadQuestionFromServer();
        this.loadQuestionTypesFromServer();
    }

    loadQuestionFromServer() {
        const { questionID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/questions/${questionID}`, {})
            .then(function(response) {
                self.setState({ question: response.data.question });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadQuestionTypesFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/questiontypes`, {
                params: {
                    all: true
                }
            })
            .then(function(response) {
                self.setState({
                    questionTypes: response.data.questionTypes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'EditQuestion.title'
        )}`;

        return (
            <div className="EditQuestion">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('EditQuestion.title')}
                                </h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-question" />{' '}
                                        <Link to={`/qanda`}>
                                            {t('QAndA.title')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fas fa-pencil-alt" />{' '}
                                        {t('EditQuestion.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <EditQuestionForm
                                        question={this.state.question}
                                        questionTypes={this.state.questionTypes}
                                    />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditQuestion);
