import React, { Component, Fragment } from 'react';
import { Row, Col, Form, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-i18next';

export class TemplateSnippetTextMenu extends Component {
	render() {
		const { t, toggleBold, toggleItalic, toggleAlignLeft, toggleAlignRight, toggleAlignCenter, alignItemCenter, alignItemMiddle, fontSizeDecrease, fontSizeSet, fontSizeIncrease, isBold, isItalic } = this.props;

		return (
			<Fragment>
				<h4>{t('TemplateSnippet.menu_text')}</h4>
				<Row className='ts-menu-row'>
					<Col className='ts-menu-col p-0' xs={6}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_bold')}</strong>
									</Tooltip>
								}
							>
								<button className={`btn btn-${isBold ? 'primary' : 'dark'} btn-block`} onClick={toggleBold}>
									<i className='fas fa-bold fa-lg' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
					<Col className='ts-menu-col p-0' xs={6}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_italics')}</strong>
									</Tooltip>
								}
							>
								<button className={`btn btn-${isItalic ? 'primary' : 'dark'} btn-block`} onClick={toggleItalic}>
									<i className='fas fa-italic fa-lg' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
				</Row>
				<Row className='ts-menu-row'>
					<Col className='ts-menu-col p-0' xs={4}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_alignLeft')}</strong>
									</Tooltip>
								}
							>
								<button className='btn btn-dark btn-block' onClick={toggleAlignLeft}>
									<i className='fas fa-align-left fa-lg' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
					<Col className='ts-menu-col p-0' xs={4}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_alignCenter')}</strong>
									</Tooltip>
								}
							>
								<button className='btn btn-dark btn-block' onClick={toggleAlignCenter}>
									<i className='fas fa-align-center fa-lg' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
					<Col className='ts-menu-col p-0' xs={4}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_alignRight')}</strong>
									</Tooltip>
								}
							>
								<button className='btn btn-dark btn-block' onClick={toggleAlignRight}>
									<i className='fas fa-align-right fa-lg' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
				</Row>
				<Row className='ts-menu-row'>
					<Col className='ts-menu-col p-0' xs={3}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_fontSizeDown')}</strong>
									</Tooltip>
								}
							>
								<button className='btn btn-dark btn-block' onClick={fontSizeDecrease}>
									<i className='fas fa-font fa-lg' />
									<i className='fas fa-minus' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
					<Col className='ts-menu-col p-0' xs={6}>
						<Col className='ts-menu-col' xs={12}>
							<Form
								onSubmit={e => {
									e.preventDefault();
								}}
							>
								<FormControl type='number' min='10' max={300} placeholder='Set font size' onBlur={fontSizeSet} />
							</Form>
						</Col>
					</Col>
					<Col className='ts-menu-col p-0' xs={3}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_fontSizeUp')}</strong>
									</Tooltip>
								}
							>
								<button className='btn btn-dark btn-block' onClick={fontSizeIncrease}>
									<i className='fas fa-font fa-lg' />
									<i className='fas fa-plus' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
				</Row>
			</Fragment>
		);
	}
}
