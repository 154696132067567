import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox,
    Panel
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './CreatePageSnippetForm.css';

class CreatePageSnippetForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newSnippet: null,
            x: 0,
            y: 0,
            z: 0,
            isLoading: false,
            snippet_name: '',
            typeID: '1',
            organisationUUID: this.props.uuid,
            pageUUID: this.props.pageUUID,
            width: 1920,
            height: 1080,
            animationType: 'fade',
            fadeType: 'sequence',
            animationSpeed: 'normal',
            backgroundColour: '',
            duration: '10000',
            textColour: '#000000',
            fontSize: '',
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            },
            rss_feed: 'http://feeds.bbci.co.uk/news/rss.xml',
            dialColour: '#000000',
            secondHandColour: '#ff0000',
            minuteHandColour: '#000000',
            hourHandColour: '#000000',
            showNumerals: true,
            headingFontSize: '',
            headingFontWeight: '',
            headingTextColour: '',
            headingFontFamily: '',
            detailFontSize: '',
            detailFontWeight: '',
            detailTextColour: '',
            detailFontFamily: '',
            latitude: '',
            longitude: '',
            lowTemperatureColour: '#bababa',
            highTemperatureColour: '#878787',
            muted: false,
            gridColumns: 3,
            gridRows: 3,
            gridDuration: 10000,
            calendarId: '',
            pageSize: 10,
            eventCount: 10,
            oddRowColour: '#E4F2F8',
            evenRowColour: '#FFFFFF'
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.snippet_name.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleShowNumeralsChanged = event => {
        this.setState({
            showNumerals: event.target.checked
        });
    };

    handleMutedChanged = event => {
        this.setState({
            muted: event.target.checked
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        let snippetProperties = {
            name: this.state.snippet_name,
            width: this.state.width,
            height: this.state.height,
            typeID: this.state.typeID
        };

        if (this.state.typeID === '1') {
            snippetProperties.animationSpeed = this.state.animationSpeed;
            snippetProperties.animationType = this.state.animationType;
            snippetProperties.fadeType = this.state.fadeType;
            snippetProperties.backgroundColour = this.state.backgroundColour;
            snippetProperties.gridColumns = this.state.gridColumns;
            snippetProperties.gridRows = this.state.gridRows;
            snippetProperties.gridDuration = this.state.gridDuration;
        }

        if (
            this.state.typeID === '2' ||
            this.state.typeID === '3' ||
            this.state.typeID === '5' ||
            this.state.typeID === '6' ||
            this.state.typeID === '17'
        ) {
            snippetProperties.duration = this.state.duration;
            snippetProperties.backgroundColour = this.state.backgroundColour;
        }

        if (
            this.state.typeID === '3' ||
            this.state.typeID === '6' ||
            this.state.typeID === '7' ||
            this.state.typeID === '8' ||
            this.state.typeID === '9' ||
            this.state.typeID === '10' ||
            this.state.typeID === '12'
        ) {
            snippetProperties.textColour = this.state.textColour;
            snippetProperties.fontFamily = this.state.fontFamily;
            snippetProperties.fontSize = this.state.fontSize;
        }

        if (this.state.typeID === '4') {
            snippetProperties.html = this.state.html;
        }

        if (this.state.typeID === '5' || this.state.typeID === '17') {
            snippetProperties.headingStyle = this.state.headingStyle;
            snippetProperties.detailStyle = this.state.detailStyle;
            snippetProperties.headingFontFamily = this.state.headingFontFamily;
            snippetProperties.headingFontSize = this.state.headingFontSize;
            snippetProperties.headingFontWeight = this.state.headingFontWeight;
            snippetProperties.headingTextColour = this.state.headingTextColour;
            snippetProperties.detailFontFamily = this.state.detailFontFamily;
            snippetProperties.detailFontSize = this.state.detailFontSize;
            snippetProperties.detailFontWeight = this.state.detailFontWeight;
            snippetProperties.detailTextColour = this.state.detailTextColour;
        }

        if (this.state.typeID === '7') {
            snippetProperties.rss_feed = this.state.rss_feed;
        }

        if (this.state.typeID === '8' || this.state.typeID === '12') {
            snippetProperties.latitude = this.state.latitude;
            snippetProperties.longitude = this.state.longitude;
        }

        if (this.state.typeID === '9' || this.state.typeID === '10') {
            snippetProperties.format = this.state.format;
            snippetProperties.textAlign = this.state.textAlign;
        }

        if (this.state.typeID === '11') {
            snippetProperties.backgroundColour = this.state.backgroundColour;
            snippetProperties.dialColour = this.state.dialColour;
            snippetProperties.secondHandColour = this.state.secondHandColour;
            snippetProperties.minuteHandColour = this.state.minuteHandColour;
            snippetProperties.hourHandColour = this.state.hourHandColour;
            snippetProperties.fontFamily = this.state.fontFamily;
            snippetProperties.textColour = this.state.textColour;
            snippetProperties.showNumerals = this.state.showNumerals;
            snippetProperties.height = this.state.width;
        }

        if (this.state.typeID === '12') {
            snippetProperties.lowTemperatureColour = this.state.lowTemperatureColour;
            snippetProperties.highTemperatureColour = this.state.highTemperatureColour;
        }

        if (this.state.typeID === '13') {
            snippetProperties.muted = this.state.muted;
        }

        if (this.state.typeID === '16') {
            snippetProperties.calendarId = this.state.calendarId;
            snippetProperties.duration = this.state.duration;
            snippetProperties.pageSize = this.state.pageSize;
            snippetProperties.eventCount = this.state.eventCount;
            snippetProperties.oddRowColour = this.state.oddRowColour;
            snippetProperties.evenRowColour = this.state.evenRowColour;

        }

        try {
            let self = this;
            axios
                .post(
                    `${config.baseURL}/organisations/${
                        this.state.organisationUUID
                    }/snippets`,
                    snippetProperties
                )
                .then(function(response) {
                    console.log(
                        `Response Data: ${JSON.stringify(response.data)}`
                    );
                    console.log(`PageUUID: ${self.state.pageUUID}`);
                    // Add snippet to the page
                    return axios.post(
                        `${config.baseURL}/pages/${
                            self.state.pageUUID
                        }/snippets`,
                        {
                            snippetUUID: response.data.snippet.uuid,
                            x: self.state.x,
                            y: self.state.y,
                            z: self.state.z
                        }
                    );
                })
                .then(function(response) {
                    self.setState({
                        newSnippet: response.data.snippet,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreatePageSnippetForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreatePageSnippetForm.created_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    console.log(
                        `Error: ${JSON.stringify(err.response.data.message)}`
                    );
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreatePageSnippetForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreatePageSnippetForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreatePageSnippetForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createSnippetForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="snippet_name" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePageSnippetForm.snippet_name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.snippet_name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreatePageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="typeID" bsSize="sm">
                                <label>{t('CreatePageSnippetForm.type')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.typeID}
                                    onChange={this.handleChange}
                                >
                                    <option value="9">
                                        {t('CreatePageSnippetForm.types.date')}
                                    </option>
                                    <option value="3">
                                        {t('CreatePageSnippetForm.types.facts')}
                                    </option>
                                    <option value="12">
                                        {t(
                                            'CreatePageSnippetForm.types.forecast'
                                        )}
                                    </option>
                                    <option value="4">
                                        {t('CreatePageSnippetForm.types.html')}
                                    </option>
                                    <option value="1">
                                        {t('CreatePageSnippetForm.types.media')}
                                    </option>
                                    <option value="2">
                                        {t(
                                            'CreatePageSnippetForm.types.onthisday'
                                        )}
                                    </option>
                                    <option value="13">
                                        {t('CreatePageSnippetForm.types.pip')}
                                    </option>
                                    <option value="6">
                                        {t('CreatePageSnippetForm.types.qanda')}
                                    </option>
                                    <option value="7">
                                        {t(
                                            'CreatePageSnippetForm.types.rssfeed'
                                        )}
                                    </option>
                                    <option value="5">
                                        {t('CreatePageSnippetForm.types.text')}
                                    </option>
                                    <option value="11">
                                        {t(
                                            'CreatePageSnippetForm.types.timeanalogue'
                                        )}
                                    </option>
                                    <option value="10">
                                        {t(
                                            'CreatePageSnippetForm.types.timedigital'
                                        )}
                                    </option>
                                    <option value="8">
                                        {t(
                                            'CreatePageSnippetForm.types.weather'
                                        )}
                                    </option>
                                    <option value="15">
                                        {t(
                                            'CreatePageSnippetForm.types.template'
                                        )}
                                    </option>
                                    <option value="16">
                                        {t(
                                            'CreatePageSnippetForm.types.calendar'
                                        )}
                                    </option>
                                    <option value="17">
                                        {t(
                                            'CreatePageSnippetForm.types.textlibrary'
                                        )}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('CreatePageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={4}>
                            <FormGroup controlId="x" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePageSnippetForm.x')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-arrows-alt" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.x}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreatePageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup controlId="y" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePageSnippetForm.y')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-arrows-alt" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.y}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreatePageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup controlId="z" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePageSnippetForm.z')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-arrows-alt" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.z}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreatePageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.state.typeID != '11' ? (
                        <Row bsClass="form-row">
                            <Col sm={6}>
                                <FormGroup controlId="width" bsSize="sm">
                                    <ControlLabel>
                                        {t('CreatePageSnippetForm.width')}
                                    </ControlLabel>
                                    <InputGroup>
                                        <span className="input-group-addon">
                                            <i className="fas fa-ruler-horizontal" />
                                        </span>
                                        <FormControl
                                            type="text"
                                            value={this.state.width}
                                            onChange={this.handleChange}
                                        />
                                    </InputGroup>
                                    <small className="form-text text-muted">
                                        {t('CreatePageSnippetForm.required')}
                                    </small>
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup controlId="height" bsSize="sm">
                                    <ControlLabel>
                                        {t('CreatePageSnippetForm.height')}
                                    </ControlLabel>
                                    <InputGroup>
                                        <span className="input-group-addon">
                                            <i className="fas fa-ruler-vertical" />
                                        </span>
                                        <FormControl
                                            type="text"
                                            value={this.state.height}
                                            onChange={this.handleChange}
                                        />
                                    </InputGroup>
                                    <small className="form-text text-muted">
                                        {t('CreatePageSnippetForm.required')}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                    ) : null}

                    {this.state.typeID === '8' || this.state.typeID === '12' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="latitude" bsSize="sm">
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.latitude'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.latitude}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="longitude"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.longitude'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.longitude}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '1' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="animationType"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t(
                                                'CreatePageSnippetForm.animationType'
                                            )}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.animationType}
                                            onChange={this.handleChange}
                                        >
                                            <option value="fade">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.fade'
                                                )}
                                            </option>
                                            <option value="fadeEmpty">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.fadeEmpty'
                                                )}
                                            </option>
                                            <option value="slideOver">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.slideOver'
                                                )}
                                            </option>
                                            <option value="slideRight">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.slideRight'
                                                )}
                                            </option>
                                            <option value="slideUp">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.slideUp'
                                                )}
                                            </option>
                                            <option value="slideDown">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.slideDown'
                                                )}
                                            </option>
                                            <option value="slideEmpty">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.slideEmpty'
                                                )}
                                            </option>
                                            <option value="displayAll">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.displayAll'
                                                )}
                                            </option>
                                            <option value="displayGrid">
                                                {t(
                                                    'CreatePageSnippetForm.animationTypes.displayGrid'
                                                )}
                                            </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="fadeType"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t(
                                                'CreatePageSnippetForm.fadeType'
                                            )}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.fadeType}
                                            onChange={this.handleChange}
                                        >
                                            <option value="sequence">
                                                {t(
                                                    'CreatePageSnippetForm.fadeTypes.sequence'
                                                )}
                                            </option>
                                            <option value="random">
                                                {t(
                                                    'CreatePageSnippetForm.fadeTypes.random'
                                                )}
                                            </option>
                                           
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="animationSpeed"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t(
                                                'CreatePageSnippetForm.animationSpeed'
                                            )}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.animationSpeed}
                                            onChange={this.handleChange}
                                        >
                                            <option value="slow">
                                                {t(
                                                    'CreatePageSnippetForm.animationSpeeds.slow'
                                                )}
                                            </option>
                                            <option value="normal">
                                                {t(
                                                    'CreatePageSnippetForm.animationSpeeds.normal'
                                                )}
                                            </option>
                                            <option value="fast">
                                                {t(
                                                    'CreatePageSnippetForm.animationSpeeds.fast'
                                                )}
                                            </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="backgroundColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.backgroundColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.backgroundColour}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '2' ||
                    this.state.typeID === '3' ||
                    this.state.typeID === '5' ||
                    this.state.typeID === '17' ||
                    this.state.typeID === '6' ? (
                        <Fragment>
                             <Row bsClass="form-row">
                             {this.state.typeID !== '17' ? (
                                <Col sm={6}>
                                    <FormGroup controlId="duration" bsSize="sm">
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.duration'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.duration}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                            ) : null }
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="backgroundColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.backgroundColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.backgroundColour}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}


                    {this.state.animationType == 'displayGrid' ? (
                                <Fragment> <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="gridRows" bsSize="sm">
                                        <ControlLabel>
                                            {t('CreatePageSnippetForm.gridRows')}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.gridRows}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t('CreatePageSnippetForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="gridColumns"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.gridColumns'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state
                                                    .gridColumns
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t('CreatePageSnippetForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                </Row>
                                <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="gridDuration"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.gridDuration'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state
                                                    .gridDuration
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t('CreatePageSnippetForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                </Row>
                                </Fragment>
                                ) : null }

                    {this.state.typeID === '3' ||
                    this.state.typeID === '6' ||
                    this.state.typeID === '7' ||
                    this.state.typeID === '8' ||
                    this.state.typeID === '9' ||
                    this.state.typeID === '10' ||
                    this.state.typeID === '12' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="textColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.textColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.textColour}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="fontFamily"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.fontFamily'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.fontFamily}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup controlId="fontSize" bsSize="sm">
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.fontSize'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.fontSize}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '4' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup controlId="html" bsSize="sm">
                                        <label>
                                            {t('CreatePageSnippetForm.html')}
                                        </label>
                                        <FormControl
                                            componentClass="textarea"
                                            placeholder={t(
                                                'CreatePageSnippetForm.html'
                                            )}
                                            value={this.state.html}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '5' || this.state.typeID === '17' ? (
                        <Fragment>
                            <Col sm={12}>
                                <Panel id="heading-style">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            {t(
                                                'CreatePageSnippetForm.headingStyling'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            <Row bsClass="form-row">
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="headingFontSize"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'CreatePageSnippetForm.headingFontSize'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .headingFontSize
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />

                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="headingFontFamily"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'CreatePageSnippetForm.headingFontFamily'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .headingFontFamily
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row bsClass="form-row">
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="headingTextColour"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'CreatePageSnippetForm.headingTextColour'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .headingTextColour
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />

                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="headingFontWeight"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'CreatePageSnippetForm.headingFontWeight'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            componentClass="select"
                                                            placeholder="Choose..."
                                                            value={
                                                                this.state
                                                                    .headingFontWeight
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        >
                                                            <option value="normal">
                                                                {t(
                                                                    'CreatePageSnippetForm.fontweight.normal'
                                                                )}
                                                            </option>
                                                            <option value="bold">
                                                                {t(
                                                                    'CreatePageSnippetForm.fontweight.bold'
                                                                )}
                                                            </option>
                                                        </FormControl>
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row bsClass="form-row">
                                                <Col sm={12}>
                                                    <FormGroup
                                                        controlId="headingStyle"
                                                        bsSize="sm"
                                                    >
                                                        <label>
                                                            {t(
                                                                'CreatePageSnippetForm.headingStyle'
                                                            )}
                                                        </label>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            placeholder={t(
                                                                'CreatePageSnippetForm.css_prompt'
                                                            )}
                                                            value={
                                                                this.state
                                                                    .headingStyle
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            </Col>
                            <Col sm={12}>
                                <Panel id="detail-style">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            {t(
                                                'CreatePageSnippetForm.detailStyling'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            <Row bsClass="form-row">
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="detailFontSize"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'CreatePageSnippetForm.detailFontSize'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .detailFontSize
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />

                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="detailFontFamily"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'CreatePageSnippetForm.detailFontFamily'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .detailFontFamily
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row bsClass="form-row">
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="detailTextColour"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'CreatePageSnippetForm.detailTextColour'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .detailTextColour
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />

                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="detailFontWeight"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'CreatePageSnippetForm.detailFontWeight'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            componentClass="select"
                                                            placeholder="Choose..."
                                                            value={
                                                                this.state
                                                                    .detailFontWeight
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        >
                                                            <option value="normal">
                                                                {t(
                                                                    'CreatePageSnippetForm.fontweight.normal'
                                                                )}
                                                            </option>
                                                            <option value="bold">
                                                                {t(
                                                                    'CreatePageSnippetForm.fontweight.bold'
                                                                )}
                                                            </option>
                                                        </FormControl>
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row bsClass="form-row">
                                                <Col sm={12}>
                                                    <FormGroup
                                                        controlId="detailStyle"
                                                        bsSize="sm"
                                                    >
                                                        <label>
                                                            {t(
                                                                'CreatePageSnippetForm.detailStyle'
                                                            )}
                                                        </label>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            placeholder={t(
                                                                'CreatePageSnippetForm.css_prompt'
                                                            )}
                                                            value={
                                                                this.state
                                                                    .detailStyle
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'CreatePageSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            </Col>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '7' ? (
                        <Row bsClass="form-row">
                            <Col sm={12}>
                                <FormGroup controlId="rss_feed" bsSize="sm">
                                    <label>
                                        {t('CreatePageSnippetForm.rss_feed')}
                                    </label>
                                    <FormControl
                                        componentClass="textarea"
                                        placeholder={t(
                                            'CreatePageSnippetForm.rss_prompt'
                                        )}
                                        value={this.state.rss_feed}
                                        onChange={this.handleChange}
                                    />
                                    <small className="form-text text-muted">
                                        {t('CreatePageSnippetForm.required')}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                    ) : null}

                    {this.state.typeID === '9' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="format" bsSize="sm">
                                        <ControlLabel>
                                            {t('CreatePageSnippetForm.format')}
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.format}
                                            onChange={this.handleChange}
                                        >
                                            <option value="dddd D MMMM">
                                                Monday 1 January
                                            </option>
                                            <option value="dddd[<br/>]D[<br/>]MMMM">
                                                Monday&#9166; 1&#9166; January
                                            </option>
                                            <option value="dddd[<br/>]D MMMM">
                                                Monday&#9166; 1 January
                                            </option>
                                            <option value="ddd[<br/>]D MMM">
                                                Mon&#9166; 1 Jan
                                            </option>
                                            <option value="dddd Do MMMM">
                                                Monday 1st January
                                            </option>
                                            <option value="ddd D MMM">
                                                Mon 1 Jan
                                            </option>
                                            <option value="ddd[<br/>]D[<br/>]MMM">
                                                Mon&#9166; 1&#9166; Jan
                                            </option>
                                            <option value="D MMMM">
                                                1 January
                                            </option>
                                            <option value="Do MMMM">
                                                1st January
                                            </option>
                                            <option value="D MMM">1 Jan</option>
                                            <option value="dddd">Monday</option>
                                            <option value="ddd">Mon</option>
                                            <option value="D">1</option>
                                            <option value="MMMM">
                                                January
                                            </option>
                                            <option value="MMM">Jan</option>
                                            <option value="YYYY">2019</option>
                                            <option value="YY">19</option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="textAlign"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.textAlign'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.textAlign}
                                            onChange={this.handleChange}
                                        >
                                            <option value="left">
                                                {t(
                                                    'CreatePageSnippetForm.align.left'
                                                )}
                                            </option>
                                            <option value="center">
                                                {t(
                                                    'CreatePageSnippetForm.align.center'
                                                )}
                                            </option>
                                            <option value="right">
                                                {t(
                                                    'CreatePageSnippetForm.align.right'
                                                )}
                                            </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '10' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="format" bsSize="sm">
                                        <ControlLabel>
                                            {t('CreatePageSnippetForm.format')}
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.format}
                                            onChange={this.handleChange}
                                        >
                                            <option value="hh:mma">
                                                03:30pm
                                            </option>
                                            <option value="hh:mmA">
                                                03:30PM
                                            </option>
                                            <option value="h:mma">
                                                3:30pm
                                            </option>
                                            <option value="h:mmA">
                                                3:30PM
                                            </option>
                                            <option value="hh:mm">03:30</option>
                                            <option value="HH:mm">15:30</option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="textAlign"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.textAlign'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.textAlign}
                                            onChange={this.handleChange}
                                        >
                                            <option value="left">
                                                {t(
                                                    'CreatePageSnippetForm.align.left'
                                                )}
                                            </option>
                                            <option value="center">
                                                {t(
                                                    'CreatePageSnippetForm.align.center'
                                                )}
                                            </option>
                                            <option value="right">
                                                {t(
                                                    'CreatePageSnippetForm.align.right'
                                                )}
                                            </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '11' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="width" bsSize="sm">
                                        <ControlLabel>
                                            {t('CreatePageSnippetForm.width')}
                                        </ControlLabel>
                                        <InputGroup>
                                            <span className="input-group-addon">
                                                <i className="fas fa-ruler-horizontal" />
                                            </span>
                                            <FormControl
                                                type="text"
                                                value={this.state.width}
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup controlId="height" bsSize="sm">
                                        <ControlLabel>
                                            {t('CreatePageSnippetForm.height')}
                                        </ControlLabel>
                                        <InputGroup>
                                            <span className="input-group-addon">
                                                <i className="fas fa-ruler-vertical" />
                                            </span>
                                            <FormControl
                                                type="text"
                                                disabled
                                                value={this.state.width}
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="backgroundColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.backgroundColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.backgroundColour}
                                            onChange={this.handleChange}
                                        />

                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="dialColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.dialColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.dialColour}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="secondHandColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.secondHandColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.secondHandColour}
                                            onChange={this.handleChange}
                                        />

                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="minuteHandColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.minuteHandColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.minuteHandColour}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="hourHandColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.hourHandColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.hourHandColour}
                                            onChange={this.handleChange}
                                        />

                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="fontFamily"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.fontFamily'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.fontFamily}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="textColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.textColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.textColour}
                                            onChange={this.handleChange}
                                        />

                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="showNumerals"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.showNumerals'
                                            )}
                                        </ControlLabel>
                                        <Checkbox
                                            checked={this.state.showNumerals}
                                            onChange={
                                                this.handleShowNumeralsChanged
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '12' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="lowTemperatureColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.lowTemperatureColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.lowTemperatureColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="highTemperatureColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.highTemperatureColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.highTemperatureColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '13' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup controlId="muted" bsSize="sm">
                                        <ControlLabel>
                                            {t('CreatePageSnippetForm.muted')}
                                        </ControlLabel>
                                        <Checkbox
                                            checked={this.state.muted}
                                            onChange={this.handleMutedChanged}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID === '16' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="duration"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.duration'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.duration
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="eventCount"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.eventCount'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.eventCount
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="evenRowColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.evenRowColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.evenRowColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="oddRowColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.oddRowColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.oddRowColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="pageSize"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'CreatePageSnippetForm.pageSize'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.pageSize
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'CreatePageSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'CreatePageSnippetForm.add_snippet'
                                    )}`}
                                    loadingText={`${t(
                                        'CreatePageSnippetForm.adding_snippet'
                                    )}`}
                                />
                                {this.state.typeID === '8' ||
                                this.state.typeID === '12' ? (
                                    <small className="text-muted">
                                        <a href="https://developer.apple.com/weatherkit/">
                                            {t(
                                                'CreatePageSnippetForm.appleweather_attribution'
                                            )}
                                        </a>
                                    </small>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreatePageSnippetForm">
                {this.state.newSnippet === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreatePageSnippetForm);
