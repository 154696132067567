import React, { Component } from 'react';
import { Panel, Col, Row } from 'react-bootstrap';

import './HeaderFooterPanel.css';

export default class HeaderFooterPanel extends Component {
    render() {
        return (
            <Row className="HeaderFooterPanel">
                <Col md={12}>
                    <Panel className="panel-default panel-table ">
                        <Panel.Heading className="panel-heading clearfix">
                            {this.props.title}
                            {this.props.headerContent}
                        </Panel.Heading>
                        <Panel.Body>{this.props.children}</Panel.Body>
                    </Panel>
                </Col>
            </Row>
        );
    }
}
