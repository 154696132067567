import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import AttributePlayersTable from '../components/AttributePlayersTable';
import PaginationPanel from '../components/PaginationPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import PlayerPickerModal from '../components/PlayerPickerModal';

import axios from 'axios';
import {
    Col,
    Row,
    Button
} from 'react-bootstrap';

import { translate } from 'react-i18next';

import './AttributePlayers.css';

class AttributePlayers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            players: [],
            attribute: {},
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1,
            showingModal: false
        };
    }

    componentDidMount() {
        this.loadAttributeInformation();
        this.loadAttributePlayersFromServer();
    }

    handleAddClicked() {
        this.setState({ showingModal: true });
    }

    hideModal() {
        this.setState({ showingModal: false });
    }

    didPickPlayers(items) {
        this.setState({ showingModal: false });
        const { uuid, attributeUUID } = this.props.match.params;

        console.log(`Selected Items: ${JSON.stringify(items)}`);

        var self = this;
        axios
            .post(
                `${
                    config.baseURL
                }/organisations/${uuid}/attributes/${attributeUUID}/players`,
                {
                    playerUUIDs: items
                }
            )
            .then(function(response) {
                self.loadAttributePlayersFromServer();
            })
            .catch(function(err) {
                console.log('Error');
            });
    }

    deletePlayer(player) {
        const { uuid, attributeUUID } = this.props.match.params;

        var self = this;
        axios
            .delete(
                `${config.baseURL}/players/${
                    player.uuid
                }/attributes/${attributeUUID}`
            )
            .then(function(response) {
                self.loadAttributePlayersFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadAttributeInformation() {
        var self = this;

        const { uuid, attributeUUID } = this.props.match.params;

        axios
            .get(
                `${
                    config.baseURL
                }/organisations/${uuid}/attributes/${attributeUUID}`,
                {}
            )
            .then(function(response) {
                self.setState({
                    attribute: response.data.attribute
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadAttributePlayersFromServer() {
        var self = this;
        const { uuid, attributeUUID } = this.props.match.params;

        axios
            .get(
                `${
                    config.baseURL
                }/organisations/${uuid}/attributes/${attributeUUID}/players`,
                {
                    params: {
                        page: self.state.page,
                        limit: self.state.limit,
                        search: self.state.search
                    }
                }
            )
            .then(function(response) {
                self.setState({
                    players: response.data.players,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {

            this.loadAttributePlayersFromServer();
        });
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();
        this.loadAttributePlayersFromServer();
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'AttributePlayers.title'
        )}`;

        const user = this.state.user;
        const { uuid, attributeUUID } = this.props.match.params;
        const attributepath = this.props.emailAttribute ? "emailattributes" : "attributes";

        return (
            <div className="AttributePlayers">
                <PlayerPickerModal
                    show={this.state.showingModal}
                    onHide={this.hideModal.bind(this)}
                    organisationUUID={uuid}
                    excludeAttribute={attributeUUID}
                    onChoose={this.didPickPlayers.bind(this)}
                />
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('AttributePlayers.title')}{' '}
                                    <small>{this.state.attribute.name}</small>
                                    {this.state.userIsAuthorized(user, [
                                        'staff_super_admin',
                                        'staff_admin',
                                        'organisation_admin',
                                        'organisation_user'
                                    ]) ? (
                                        <Button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                this.handleAddClicked()
                                            }
                                        >
                                            <em className="fa fa-plus fa-fw" />{' '}
                                            {t('Players.add_player')}
                                        </Button>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>
                                            {t('Dashboard.title')}
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-tags" />{' '}
                                        <Link
                                            to={`/organisations/${uuid}/${attributepath}`}
                                        >
                                          {this.props.emailAttribute ? (

                                            t('SideNavigation.emailattributes')

                                            ) :  t('SideNavigation.attributes')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-tv" />{' '}
                                        {t('SideNavigation.players')}
                                    </li>
                                </ol>

                                <PaginationPanel
                                    heading={<Row />}
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <AttributePlayersTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        players={this.state.players}
                                        uuid={uuid}
                                        onDelete={this.deletePlayer.bind(this)}
                                    />
                                </PaginationPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(AttributePlayers);
