import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler } from '@fortawesome/free-solid-svg-icons';
import { CompactPicker } from 'react-color';

import './EditPageForm.css';

class EditPageForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            page: {
                name: '',
                fontFamily: '',
                width: 0,
                height: 0,
                textColour: '#000000',
                backgroundColour: '#ffffff'
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            },
            rel: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.state.page) {
            if (nextProps.page) {
                this.setState({ page: nextProps.page, rel: nextProps.rel });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return this.state.page.name.length > 0;
    }

    handleChange = event => {
        const page = this.state.page;
        page[event.target.id] = event.target.value;

        // update state
        this.setState({
            page
        });
    };

    handleTextColourChangeComplete = color => {
        const page = this.state.page;
        page.textColour = color.hex;

        // update state
        this.setState({
            page
        });
    };
    handleBackgroundColourChangeComplete = color => {
        const page = this.state.page;
        page.backgroundColour = color.hex;

        // update state
        this.setState({
            page
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const uuid = this.state.page.uuid;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(`${config.baseURL}/pages/${uuid}`, {
                    name: this.state.page.name,
                    width: this.state.page.width,
                    height: this.state.page.height,
                    global: this.state.page.global,
                    backgroundColour: this.state.page.backgroundColour,
                    textColour: this.state.page.textColour,
                    fontFamily: this.state.page.fontFamily
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditPageForm.success')}:`,
                            style: 'success',
                            message: `${t('EditPageForm.saved_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditPageForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditPageForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditPageForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditPageForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPageForm.name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.page.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditPageForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="width" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPageForm.width')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fas fa-ruler-horizontal" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.page.width}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditPageForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="height" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPageForm.height')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fas fa-ruler-vertical" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.page.height}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditPageForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="fontFamily" bsSize="sm">
                                <label>{t('EditPageForm.fontFamily')}</label>
                                <FormControl
                                    type="text"
                                    value={this.state.page.fontFamily}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditPageForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="backgroundColour" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPageForm.backgroundColour')}
                                </ControlLabel>
                                <InputGroup>
                                    <CompactPicker
                                        color={this.state.page.backgroundColour}
                                        onChangeComplete={
                                            this
                                                .handleBackgroundColourChangeComplete
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="textColour" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPageForm.textColour')}
                                </ControlLabel>
                                <InputGroup>
                                    <CompactPicker
                                        color={this.state.page.textColour}
                                        onChangeComplete={
                                            this.handleTextColourChangeComplete
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('EditPageForm.save_page')}`}
                                    loadingText={`${t(
                                        'EditPageForm.saving_page'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
    //                {this.state.saved ? this.renderForm() : this.renderSuccessMessage()}

    render() {
        return <div className="EditPageForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditPageForm);
