import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormGroup,
    FormControl,
    ControlLabel,
    Panel,
    Col,
    Row
} from 'react-bootstrap';
import SpinnerButton from '../components/SpinnerButton';
import DismissableAlert from '../components/DismissableAlert';
import './Login.css';
import config from '../config';
import { Link } from 'react-router-dom';

import logo from '../logo.png';
import axios from 'axios';

axios.defaults.withCredentials = true;

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: '',
            remember: false,
            password: '',
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/auth/login`, {
                    email: this.state.email,
                    password: this.state.password,
                    remember: this.state.remember
                })
                .then(function(response) {
                    const user = response.data.user;
                    user.isAuthenticated = true;

                    let roles = new Set();
                    for (var key in user.organisationRoles) {
                        const organisationRoles = user.organisationRoles[key];
                        for (const role of organisationRoles) {
                            roles.add(role);
                        }
                    }

                    if (roles.has("staff_engineer")) {
                        user.isEngineer = true;
                    } else {
                        user.isEngineer = false;
                    }


                    self.props.userHasAuthenticated(true, user);
                })
                .catch(function(error) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('Login.error')}:`,
                            message: `${t('Login.incorrect_credentials')}`,
                            style: 'danger'
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('Login.error')}:`,
                    message: `${t('Login.unknown_error')}:`,
                    style: 'danger'
                }
            });
        }
    };

    render() {
        const { t } = this.props;

        document.title = `MySign Digital Screen Solutions | ${t(
            'Login.title'
        )}`;

        return (
            <div className="Login">
                <Row>
                    <Col
                        xs={10}
                        sm={6}
                        md={6}
                        lg={4}
                        xsOffset={1}
                        smOffset={3}
                        mdOffset={3}
                        lgOffset={4}
                    >
                        <Panel>
                            <Panel.Body>
                                <form onSubmit={this.handleSubmit}>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        className="center-block img-responsive"
                                        style={{ width: 168, height: 81 }}
                                    />

                                    <DismissableAlert
                                        title={this.state.error.title}
                                        message={this.state.error.message}
                                        hidden={this.state.error.hidden}
                                        style={this.state.error.style}
                                    />

                                    <FormGroup controlId="email" bsSize="sm">
                                        <ControlLabel>
                                            {t('Login.email')}
                                        </ControlLabel>
                                        <FormControl
                                            autoFocus
                                            type="text"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="password" bsSize="sm">
                                        <ControlLabel>
                                            {t('Login.password')}
                                        </ControlLabel>
                                        <FormControl
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            type="password"
                                        />
                                        <small>
                                            {t('Login.forgot_password')}{' '}
                                            <Link to="/login/forgotten">
                                                {t('Login.reset_here')}
                                            </Link>
                                        </small>
                                    </FormGroup>

                                    <SpinnerButton
                                        block
                                        bsStyle="primary"
                                        bsSize="large"
                                        disabled={!this.validateForm()}
                                        type="submit"
                                        isLoading={this.state.isLoading}
                                        text={`${t('Login.login')}`}
                                        loadingText={`${t('Login.logging_in')}`}
                                    />
                                </form>
                            </Panel.Body>
                        </Panel>
                        <small style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <Link to="/terms" style={{ marginRight: '0.5rem' }} >
                                {t('Login.terms')}
                            </Link>
                            {' | '}
                            <Link to="/privacy" style={{ marginLeft: '0.5rem' }} >
                                {t('Login.privacy')}
                            </Link>
                        </small>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate('translations')(Login);
