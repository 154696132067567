import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { translate } from 'react-i18next';
import Moment from 'moment';
import './OrganisationUserTable.css';
import TooltipLink from './TooltipLink';

class OrganisationUserTable extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() { }

	getRoles(user, uuid) {
		if (user.organisationRoles && user.organisationRoles[uuid]) {
			return user.organisationRoles[uuid].join(', ');
		}
		return '';
	}

	render() {
		const { t, uuid, users, user } = this.props;

		let userRows = users.map((user, index) => {
			return (
				<tr key={index}>
					<td width="75" className="hidden-xs hidden-sm">
						{user.firstName}
					</td>
					<td width="75" className="hidden-xs hidden-sm">
						{user.lastName}
					</td>
					<td width="75">{user.email}</td>

					<td width="50" className="hidden-xs">
						{this.getRoles(user, uuid)}
					</td>
					<td width="5%" className="td-center">
						<TooltipLink className="btn btn-default" tooltip={t('UserPlayersTable.viewplayers')} to={`/organisations/${uuid}/users/${user.uuid}/players`}>
							<em className="fa fa-tv" /> {t('UserPlayersTable.players')}
						</TooltipLink>
					</td>
				</tr>
			);
		});

		return (
			<div id="users">
				<Table striped bordered condensed hover>
					<thead>
						<tr>
							<th className="hidden-xs hidden-sm">{t('OrganisationUsersTable.first_name')}</th>
							<th className="hidden-xs hidden-sm">{t('OrganisationUsersTable.last_name')}</th>
							<th>{t('OrganisationUsersTable.email')}</th>
							<th className="hidden-xs">{t('OrganisationUsersTable.role')}</th>
							{this.props.userIsAuthorized(user, ['staff_super_admin', 'staff_admin', 'organisation_admin']) ? (
								<th>
									<em className='fa fa-cog' />{' '}
								</th>
							) : null}
						</tr>
					</thead>
					<tbody>{userRows}</tbody>
				</Table>
			</div>
		);
	}
}

export default translate('translations')(OrganisationUserTable);
