import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import config from '../config';
import { Link } from 'react-router-dom';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';
Moment.locale('en');

class PlayerTable extends Component {
    didConfirmDelete(player) {
        this.props.onDelete(player);
    }

    handleDeleteClicked(player) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(player),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t } = this.props;
        let playerRows = this.props.players.map((player, index) => {
            return (
                <tr key={index}>
                    <td align="center" width={80}>
                        <OverlayTrigger
                            placement="top"
                            delayShow="300"
                            delayHide="150"
                            overlay={
                                <Tooltip id="tooltip">
                                    <strong>
                                        {t('PlayersTable.previewplayer')}
                                    </strong>
                                </Tooltip>
                            }
                        >
                            <a
                                id="previewButton"
                                className="btn btn-primary"
                                target="_blank"
                                href={`${
                                    config.playersBaseURL
                                }/mediaserver/unit.php?playerUUID=${
                                    player.uuid
                                }&preview=1`}
                            >
                                <em className="fa fa-eye" />
                            </a>
                        </OverlayTrigger>
                    </td>
                    <td className="hidden-xs hidden-sm">
                        {player.online ? (
                            <span className="label label-success">
                                {t('PlayersTable.online')}
                            </span>
                        ) : (
                            <span className="label label-danger">
                                {t('PlayersTable.offline')}
                            </span>
                        )}
                    </td>
                    <td>
                        <Link
                           style={{
                            alignItems: "center",
                            textAlign: "left"
                          }}
                         className="label label-primary"
                            to={`/organisations/${
                                player.organisationUUID
                            }/players/${player.uuid}`}
                        >
                            {player.name}
                        </Link>
                    </td>
                    <td className="hidden-xs hidden-sm">{player.location}</td>
                    <td className="hidden-xs hidden-sm hidden-md">
                        {player.macAddress}
                    </td>
                    <td className="hidden-xs hidden-sm">
                        {player.serialNumber}
                    </td>
                    <td className="hidden-sm hidden-xs hidden-md">
                        {Moment(player.installDate).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs">
                        {player.lastSeen
                            ? Moment(player.lastSeen).fromNow()
                            : t('PlayersTable.never')}
                    </td>
                    <td align="center">
                        {this.props.userIsAuthorized(this.props.user, [
                            'staff_super_admin'
                        ]) ? (
                            <Fragment>
                                <TooltipLink
                                    id="editButton"
                                    className="btn btn-default"
                                    tooltip={t('PlayersTable.editplayer')}
                                    to={`/organisations/${
                                        player.organisationUUID
                                    }/players/${player.uuid}/edit?rel=admin`}
                                >
                                    <em className="fas fa-pencil-alt" />
                                </TooltipLink>
                                <TooltipButton
                                    id="deleteButton"
                                    className="btn-danger pull-right"
                                    tooltip={t('PlayersTable.editplayer')}
                                    onClick={() =>
                                        this.handleDeleteClicked(player)
                                    }
                                >
                                    <em className="fa fa-trash" />
                                </TooltipButton>
                            </Fragment>
                        ) : null}
                    </td>
                </tr>
            );
        });

        return (
            <div id="players" className="playerTable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{t('PlayersTable.preview')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayersTable.status')}
                            </th>
                            <th>{t('PlayersTable.name')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayersTable.location')}
                            </th>
                            <th className="hidden-xs hidden-sm hidden-md">
                                {t('PlayersTable.mac_address')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayersTable.serial_number')}
                            </th>
                            <th className="hidden-xs hidden-sm hidden-md">
                                {t('PlayersTable.install_date')}
                            </th>
                            <th className="hidden-xs">
                                {t('PlayersTable.last_seen')}
                            </th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{playerRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(PlayerTable);
