import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    Form,
    FormGroup
} from 'react-bootstrap';
import PaginationPanel from '../components/PaginationPanel';
import config from '../config';
import qs from 'qs';
import OrganisationNotificationRulesTable from '../components/OrganisationNotificationRulesTable';
import { Link } from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import ReactPaginate from 'react-paginate';

import './OrganisationNotificationRules.css';

class OrganisationNotificationRules extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            rules: [],
            rulegroup: { uuid: '' },
            page: props.page,
            limit: 40,
            pageCount: 0,
            selected: props.selected - 1
        };
    }

    deleteRule(rule) {

        const { uuid } = this.props.match.params;

        var self = this;
        axios
            .delete(
                `${config.baseURL}/organisations/${uuid}/notifications/rulegroups/${
                    this.state.rulegroup.uuid
                }/rules/${rule.uuid}`
            )
            .then(function(result) {
                self.loadNotificationRuleGroupsFromServer();
            })
            .catch(function(err) {
                console.log(`Error deleting rule: ${rule} error: ${err}`);
            });
    }

    editRule(rule) {
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID,
            settingsID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        let link =  link = `/organisations/${uuid}/notificationsettings/${settingsID}/rules/${
            rule.uuid
        }/edit?rel=library`;

        this.props.history.push(link);
    }

    componentDidMount() {
        this.loadOrganisationInformation();
        this.loadNotificationRuleGroupsFromServer();
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadNotificationRuleGroupsFromServer() {
        const { uuid } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}/notifications/rulegroups`, {})
            .then(function(response) {
                if (
                    response.data.rulegroups &&
                    response.data.rulegroups.length > 0
                ) {
                    self.setState(
                        { rulegroup: response.data.rulegroups[0] },
                        () => {
                            self.loadRuleGroupRulesFromServer();
                        }
                    );
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleChange = event => {
        const { uuid } = this.props.match.params;

        const rulegroup = this.state.rulegroup;

        const newOperator = event.target.value;

        let self = this;

        axios
            .patch(
                `${config.baseURL}/organisations/${uuid}/notifications/rulegroups/${
                    rulegroup.uuid
                }`,
                {
                    name: rulegroup.name,
                    operator: newOperator
                }
            )
            .then(function(response) {
                rulegroup.operator = newOperator;
                self.setState({
                    rulegroup
                });
        })
            .catch(function(error) {
                console.log(error);
            });
    };

    loadRuleGroupRulesFromServer() {
        const { uuid } = this.props.match.params;

        let self = this;
        axios
            .get(
                `${config.baseURL}/organisations/${uuid}/notifications/rulegroups/${
                    this.state.rulegroup.uuid
                }/rules`,
                {
                    params: {
                        page: self.state.page,
                        limit: self.state.limit
                    }
                }
            )
            .then(function(response) {
                self.setState({
                    rules: response.data.rules,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handlePageClick = data => {

        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {
            this.loadRuleGroupRulesFromServer();
        });
    };

    render() {
        const { t } = this.props;
        const {
            uuid,
            settingsID,
            playerUUID,
            pageUUID,
            snippetUUID,
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'OrganisationNotificationRules.title'
        )}`;

        let createRuleLink = `/organisations/${uuid}/notificationsettings/${settingsID}/rules/create`;

        return (
            <div className="OrganisationNotificationRules">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('OrganisationNotificationRules.title')}
                                    {this.state.userIsAuthorized(
                                        this.state.user,
                                        [
                                            'staff_super_admin',
                                            'staff_admin',
                                            'organisation_admin',
                                            'organisation_user'
                                        ]
                                    ) ? (
                                        <Link
                                            type="submit"
                                            className="btn btn-primary"
                                            to={createRuleLink}
                                        >
                                            <em className="fa fa-plus fa-fw" />{' '}
                                            {t('OrganisationNotificationRules.add_rule')}
                                        </Link>
                                    ) : null}
                                    {rel === 'player' ? (
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-primary"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                   

                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-cog" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/settings`}
                                                >
                                                    {t(
                                                        'OrganisationSettings.title'
                                                    )}
                                                </Link>
                                            </li>
                                         
                                            <li className="active">
                                                <i className="fas fa-clipboard-list" />{' '}
                                                {t('OrganisationNotificationRules.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                   
                                </ol>

                                <PaginationPanel
                                    heading={
                                        <Form inline>
                                            <FormGroup>
                                                <h4
                                                    style={{
                                                        display: 'inline'
                                                    }}
                                                >
                                                    {t('OrganisationNotificationRules.send_if')}
                                                </h4>{' '}
                                                <FormControl
                                                    className="select input-sm"
                                                    componentClass="select"
                                                    placeholder="Choose..."
                                                    value={
                                                        this.state.rulegroup
                                                            .operator
                                                    }
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="AND">
                                                        {t('OrganisationNotificationRules.all')}
                                                    </option>
                                                    <option value="OR">
                                                        {t('OrganisationNotificationRules.any')}
                                                    </option>
                                                </FormControl>{' '}
                                                <h4
                                                    style={{
                                                        display: 'inline'
                                                    }}
                                                >
                                                    {t(
                                                        'OrganisationNotificationRules.of_the_rules_below_apply'
                                                    )}
                                                </h4>
                                            </FormGroup>
                                        </Form>
                                    }
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <OrganisationNotificationRulesTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        rules={this.state.rules}
                                        settingsID={settingsID}
                                        onDelete={this.deleteRule.bind(this)}
                                        handleEditClicked={this.editRule.bind(
                                            this
                                        )}
                                    />
                                </PaginationPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(OrganisationNotificationRules);