import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormGroup,
    FormControl,
    ControlLabel,
    Panel,
    Row,
    Col
} from 'react-bootstrap';
import SpinnerButton from '../components/SpinnerButton';
import './Forgotten.css';
import DismissableAlert from '../components/DismissableAlert';
import config from '../config';

import axios from 'axios';

import logo from '../logo.png';

class Forgotten extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: '',
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.email.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/auth/password_reset_token`, {
                    email: this.state.email
                })
                .then(function(response) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('ForgottenPassword.success')}:`,
                            message: `${t('ForgottenPassword.email_sent')}`,
                            style: 'success'
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('ForgottenPassword.error')}:`,
                            style: 'danger',
                            message: `${t('ForgottenPassword.unknown_error')}`
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('ForgottenPassword.error')}:`,
                    message: `${t('ForgottenPassword.unknown_error')}`,
                    style: 'danger'
                }
            });
        }
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'ForgottenPassword.title'
        )}`;

        return (
            <div className="Forgotten">
                <Row>
                    <Col
                        xs={10}
                        sm={6}
                        md={6}
                        lg={4}
                        xsOffset={1}
                        smOffset={3}
                        mdOffset={3}
                        lgOffset={4}
                    >
                        <Panel>
                            <Panel.Body>
                                <form onSubmit={this.handleSubmit}>
                                    <center>
                                        <img
                                            src={logo}
                                            alt="logo"
                                            style={{ width: 168, height: 81 }}
                                        />
                                    </center>
                                    <DismissableAlert
                                        title={this.state.error.title}
                                        message={this.state.error.message}
                                        hidden={this.state.error.hidden}
                                        style={this.state.error.style}
                                    />
                                    <FormGroup controlId="email" bsSize="sm">
                                        <ControlLabel>
                                            {t(
                                                'ForgottenPassword.enter_email_prompt'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            autoFocus
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>

                                    <SpinnerButton
                                        block
                                        bsStyle="primary"
                                        bsSize="large"
                                        disabled={!this.validateForm()}
                                        type="submit"
                                        isLoading={this.state.isLoading}
                                        text={`${t(
                                            'ForgottenPassword.reset_password'
                                        )}`}
                                        loadingText={`${t(
                                            'ForgottenPassword.resetting_password'
                                        )}`}
                                    />
                                </form>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate('translations')(Forgotten);
