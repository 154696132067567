import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './PageNotFound.css';

class PageNotFound extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;

        return (
            <div className="PageNotFound">
                <h3>{t('PageNotFound')}</h3>
            </div>
        );
    }
}

export default translate('translations')(PageNotFound);
