import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';

import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import DateTime from 'react-datetime';
import TimePicker from 'rc-time-picker';

import 'react-datetime/css/react-datetime.css';
import './CreateOrganisationNotificationEmailRuleForm.css';
import Moment from 'moment';
import 'rc-time-picker/assets/index.css';

class CreateOrganisationNotificationEmailRuleForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newRule: null,
            isLoading: false,
            description: '',
            typeID: 1,
            name: '',
            organisationUUID: this.props.uuid,
            ruleGroupUUID: this.props.ruleGroupUUID,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            },
            ruleTypes: this.props.ruleTypes,
            attributes: this.props.attributes,
            attributeUUID: '',
            start_time: new Moment(),
            end_time: new Moment(),
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true,
            emailId: -1,
            exclude: false
        };
    }

    componentDidMount() {}

    handleStartDateChange = date => {
        // update state
        this.setState({
            start_date: date
        });
    };

    handleEndDateChange = date => {
        // update state
        this.setState({
            end_date: date
        });
    };

    handleStartTimeChange = time => {
        // update state
        this.setState({
            start_time: time
        });
    };

    handleEndTimeChange = time => {
        // update state
        this.setState({
            end_time: time
        });
    };

    handleDayChanged = event => {
        this.setState({
            [event.target.id]: event.target.checked
        });
    };

    componentWillReceiveProps(newProps) {
        let attributeUUID = '';
        if (newProps.attributes && newProps.attributes.length > 0) {
            attributeUUID = newProps.attributes[0].uuid;
        }

        this.setState({
            organisationUUID: newProps.uuid,
            ruleTypes: newProps.ruleTypes,
            attributes: newProps.attributes,
            attributeUUID,
            ruleGroupUUID: newProps.ruleGroupUUID,
        });
    }

    validateForm() {
        return this.state.name.length > 0;
    }

    handleChange = event => {

        let updatedValue = event.target.value;

        if (updatedValue === "true" || updatedValue === "false") {
            updatedValue = JSON.parse(updatedValue);
        }

        this.setState({
            [event.target.id]: updatedValue
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        let ruleProperties = {
            name: this.state.name,
            description: '_',
            typeID: this.state.typeID
        };

        if (this.state.typeID == '1') {
            ruleProperties.start_date = this.state.start_date;
            ruleProperties.end_date = this.state.end_date;
        }

        if (this.state.typeID == '2') {
            ruleProperties.attributeUUID = this.state.attributeUUID;
            ruleProperties.exclude = this.state.exclude;
        }

        if (this.state.typeID == '3') {
            ruleProperties.start_time = this.state.start_time;
            ruleProperties.end_time = this.state.end_time;
            ruleProperties.monday = this.state.monday;
            ruleProperties.tuesday = this.state.tuesday;
            ruleProperties.wednesday = this.state.wednesday;
            ruleProperties.thursday = this.state.thursday;
            ruleProperties.friday = this.state.friday;
            ruleProperties.saturday = this.state.saturday;
            ruleProperties.sunday = this.state.sunday;
        }

        try {
            let self = this;
            axios
                .post(
                    `${config.baseURL}/organisations/${this.state.organisationUUID}/emails/${this.state.emailId}/rulegroups/${
                        this.state.ruleGroupUUID
                    }/rules`,
                    ruleProperties
                )

                .then(function(response) {
                    self.setState({
                        newRule: response.data.rule,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateOrganisationNotificationEmailRuleForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreateOrganisationNotificationEmailRuleForm.created_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    console.log(
                        `Error: ${JSON.stringify(err.response.data.message)}`
                    );
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateOrganisationNotificationEmailRuleForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateOrganisationNotificationEmailRuleForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateOrganisationNotificationEmailRuleForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createTextRuleForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateOrganisationNotificationEmailRuleForm.name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationNotificationEmailRuleForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="typeID" bsSize="sm">
                                <label>{t('CreateOrganisationNotificationEmailRuleForm.type')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.typeID}
                                    onChange={this.handleChange}
                                >
                                    {this.state.ruleTypes.map((type, key) => {
                                        return (
                                            <option key={key} value={type.id}>
                                                {type.description}
                                            </option>
                                        );
                                    })}
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationNotificationEmailRuleForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    {this.state.typeID == '1' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="start_date"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t('CreateOrganisationNotificationEmailRuleForm.start_date')}
                                        </label>
                                        <DateTime
                                            dateFormat="MMMM Do YYYY"
                                            utc={false}
                                            onChange={
                                                this.handleStartDateChange
                                            }
                                            value={this.state.start_date}
                                        />
                                        <small className="form-text text-muted">
                                            {t('CreateOrganisationNotificationEmailRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup controlId="end_date" bsSize="sm">
                                        <label>
                                            {t('CreateOrganisationNotificationEmailRuleForm.end_date')}
                                        </label>
                                        <DateTime
                                            dateFormat="MMMM Do YYYY"
                                            utc={false}
                                            onChange={this.handleEndDateChange}
                                            value={this.state.end_date}
                                        />
                                        <small className="form-text text-muted">
                                            {t('CreateOrganisationNotificationEmailRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID == '2' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="attributeUUID"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t('CreateOrganisationNotificationEmailRuleForm.attribute')}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.attributeUUID}
                                            onChange={this.handleChange}
                                        >
                                            {this.state.attributes.map(
                                                (attribute, key) => {
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={
                                                                attribute.uuid
                                                            }
                                                        >
                                                            {attribute.name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t('CreateOrganisationNotificationEmailRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="exclude"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t('EditMediaRuleForm.exclude_or_include')}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={
                                                this.state.exclude ? "true" : "false"
                                            }
                                            onChange={this.handleChange}
                                        >
                                             <option
                                                            key={1}
                                                            value="false"
                                                        >
                                                            {"Include"}
                                                        </option>
                                                        <option
                                                            key={2}
                                                            value="true"
                                                        >
                                                            {"Exclude"}
                                                        </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t('EditTextRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.typeID == '3' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="start_time"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t('CreateOrganisationNotificationEmailRuleForm.start_time')}
                                        </label>
                                        <TimePicker
                                            style={{ display: 'block' }}
                                            defaultValue={Moment()}
                                            onChange={
                                                this.handleStartTimeChange
                                            }
                                            value={this.state.start_time}
                                        />{' '}
                                        <small className="form-text text-muted">
                                            {t('CreateOrganisationNotificationEmailRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup controlId="end_time" bsSize="sm">
                                        <label>
                                            {t('CreateOrganisationNotificationEmailRuleForm.end_time')}
                                        </label>
                                        <TimePicker
                                            style={{ display: 'block' }}
                                            defaultValue={Moment()}
                                            onChange={this.handleEndTimeChange}
                                            value={this.state.end_time}
                                        />
                                        <small className="form-text text-muted">
                                            {t('CreateOrganisationNotificationEmailRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="start_time"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t(
                                                'CreateOrganisationNotificationEmailRuleForm.valid_week_days'
                                            )}
                                        </label>
                                        <FormGroup>
                                            <Checkbox
                                                id="monday"
                                                checked={this.state.monday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('CreateOrganisationNotificationEmailRuleForm.monday')}
                                            </Checkbox>
                                            <Checkbox
                                                id="tuesday"
                                                checked={this.state.tuesday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t(
                                                    'CreateOrganisationNotificationEmailRuleForm.tuesday'
                                                )}
                                            </Checkbox>
                                            <Checkbox
                                                id="wednesday"
                                                checked={this.state.wednesday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t(
                                                    'CreateOrganisationNotificationEmailRuleForm.wednesday'
                                                )}
                                            </Checkbox>
                                            <Checkbox
                                                id="thursday"
                                                checked={this.state.thursday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t(
                                                    'CreateOrganisationNotificationEmailRuleForm.thursday'
                                                )}
                                            </Checkbox>
                                            <Checkbox
                                                id="friday"
                                                checked={this.state.friday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('CreateOrganisationNotificationEmailRuleForm.friday')}
                                            </Checkbox>
                                            <Checkbox
                                                id="saturday"
                                                checked={this.state.saturday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t(
                                                    'CreateOrganisationNotificationEmailRuleForm.saturday'
                                                )}
                                            </Checkbox>
                                            <Checkbox
                                                id="sunday"
                                                checked={this.state.sunday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('CreateOrganisationNotificationEmailRuleForm.sunday')}
                                            </Checkbox>
                                        </FormGroup>
                                        <small className="form-text text-muted">
                                            {t('CreateOrganisationNotificationEmailRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('CreateOrganisationNotificationEmailRuleForm.add_rule')}`}
                                    loadingText={`${t(
                                        'CreateOrganisationNotificationEmailRuleForm.adding_rule'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreateOrganisationNotificationEmailRuleForm">
                {this.state.newRule === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreateOrganisationNotificationEmailRuleForm);