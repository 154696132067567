import React, { Component } from 'react';
import { translate } from 'react-i18next';

import './AccessDenied.css';

class AccessDenied extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;

        return (
            <div className="AccessDenied">
                <h3>{t('AccessDenied')}</h3>
            </div>
        );
    }
}

export default translate('translations')(AccessDenied);
