import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { FormControl, FormGroup, InputGroup, ControlLabel, Col, Row, Checkbox } from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

import DateTime from 'react-datetime';

import './CreateOTDForm.css';

import 'react-datetime/css/react-datetime.css';

class CreateOTDForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newOTD: null,
            isLoading: false,
            description: '',
            detailed_description: '',
            month: 1,
            image_url: '',
            day: 1,
            date: new Date(),
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.description.length > 0;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleDateChange = (date) => {
        let month = date.month() + 1;
        let day = date.date();

        this.setState({
            date,
            month,
            day
        });
    };

    handleSubmit = (event) => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/onthisday`, {
                    month: this.state.month,
                    day: this.state.day,
                    description: this.state.description,
                    detailed_description: this.state.detailed_description,
                    date: this.state.date,
                    image_url: this.state.image_url
                })
                .then(function(response) {
                    self.setState({
                        newOTD: response.data.onthisday,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateOTDForm.success')}:`,
                            style: 'success',
                            message: `${t('CreateOTDForm.created_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateOTDForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateOTDForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateOTDForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createAttributeForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="image_url" bsSize="sm">
                                <ControlLabel>{t('CreateOTDForm.image_path')}</ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    placeholder="e.g. otd_hd/fireworks.jpg"
                                    value={this.state.image_url}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">{t('CreateOTDForm.required')}</small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>{t('CreateOTDForm.date')}</ControlLabel>
                                <DateTime
                                    dateFormat="MMMM Do YYYY"
                                    timeFormat={false}
                                    utc={true}
                                    onChange={this.handleDateChange}
                                    value={this.state.date}
                                />
                                <small className="form-text text-muted">{t('CreateOTDForm.required')}</small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>{t('CreateOTDForm.description')}</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="e.g. January 1st: New Year's Day"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">{t('CreateOTDForm.required')}</small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="detailed_description" bsSize="sm">
                                <ControlLabel>{t('CreateOTDForm.detailed_description')}</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="e.g. This is the world's only global holiday, often celebrated with fireworks and a party to welcome in the New Year."
                                    value={this.state.detailed_description}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">{t('CreateOTDForm.required')}</small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('CreateOTDForm.add_otd')}`}
                                    loadingText={`${t('CreateOTDForm.adding_otd')}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreateOTDForm">
                {this.state.newOTD === null ? this.renderForm() : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreateOTDForm);
