import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import './TemplateSnippet.css';
import TemplateSnippetCanvas from './TemplateSnippetCanvas';
import MediaTextsMenu from './MediaTextsMenu';
import TemplateSnippetBackgroundPicker from './TemplateSnippetBackgroundPicker';
import axios from 'axios';
import config from '../config';

export class MediaTextsEditor extends Component {
    constructor(props) {
        super(props);

        let snippets = []
        if (this.props.snippet && this.props.snippet.subsnippets) {
            snippets = this.props.snippet.subsnippets;
        }

        this.state = {
            saveState: {
                snippets: snippets
            },
            backgroundUUID: '#fff',
            templateUUID: 0,
            currentSnippetUUID: null,
            backgroundFilename: null,
            isSelected: false,
            isSaved: true,
            zoom: 1,
            zoomOffset: 1,
            scrollPos: {
                x: 0,
                y: 0
            },
            caretPos: 0,
            modalShow: false,
            selectedBackgroundUUID: null,
            textColor: '#343a40',
            gridValue: 1,
            previewEnabled: false,
            organisationUUID: this.props.match.params.uuid,
            statistics: null,
            canvasWidth: 1280,
            canvasHeight: 720,
            isBold: false,
            isItalic: false
        };
    }

    componentWillReceiveProps(newProps) {

        const { uuid, mediaLibraryUUID } = this.props.match.params;
        let backgroundFilename = `${uuid}/${mediaLibraryUUID}/${newProps.media.uuid}.${newProps.media.extension}`;

        this.setState(
            {
                media: newProps.media,
                title: newProps.title,
                saveState: { snippets: newProps.media.subsnippets },
                canvasWidth: newProps.media.width,
                canvasHeight: newProps.media.height,
                backgroundFilename: backgroundFilename

            },
            () => {

                if (backgroundFilename != null) {
                    let backgroundURL = `https://players.cloudsignage.me/mediaserver/media/${
                    this.state.backgroundFilename
                    }`;

                    let self = this;
                    let img = new Image();
                    img.src = backgroundURL;

                    img.onload = () => {
                        console.log('On Load!');
                        self.setState({
                             backgroundURL: backgroundURL,
                             canvasWidth: img.width,
                             canvasHeight: img.height
                        });
                    };
                }
            }
        );
    }

    handleDragStop = (e, d, id) => {
        console.log('DragStopped');
        var snippetState = this.state.saveState.snippets;
        const i = this.getSnippetIndex(id);
        snippetState[i].x = Math.floor(d.x);
        snippetState[i].y = Math.floor(d.y);
        let uuid = snippetState[i].uuid;

        let self = this;

        axios
            .patch(
                `${config.baseURL}/media/${
                this.state.media.uuid
                }/subsnippets/${uuid}`,
                { x: Math.floor(d.x), y: Math.floor(d.y) }
            )
            .then(function (response) {
                self.setState(prevState => ({
                    ...prevState,
                    saveState: {
                        snippets: snippetState
                    }
                }));
                self.selectSnippet(e, id);
            })
            .catch(function (err) { });
    };

    handleMove = (e, d, id) => {
        var snippetState = this.state.saveState.snippets;
        const i = this.getSnippetIndex(id);
        snippetState[i].x = Math.floor(d.x);
        snippetState[i].y = Math.floor(d.y);
        let uuid = snippetState[i].uuid;

        let self = this;

        this.setState(prevState => ({
            ...prevState,
            saveState: {
                snippets: snippetState
            }
        }));
        this.selectSnippet(e, id);
    };

    handleResize = (e, direction, ref, delta, position, id) => {
        var snippetState = this.state.saveState.snippets;
        const i = this.getSnippetIndex(id);
        snippetState[i].width = ref.offsetWidth;
        snippetState[i].height = ref.offsetHeight;

        this.setState(prevState => ({
            ...prevState,
            saveState: {
                snippets: snippetState,
            },
            isSaved: false
        }));
        this.selectSnippet(e, id);
    };

    contentChange = (e, id) => {
        const caretPosition = e.target.selectionStart;
        const snippetState = this.state.saveState.snippets;

        const i = this.getSnippetIndex(id);
        snippetState[i].content = e.target.value;

        this.setState(prevState => ({
            ...prevState,
            saveState: {
                snippets: snippetState
            },
            caretPos: caretPosition,
            isSaved: false
        }));
    };

    // Menu Actions
    zoomIn = () => {
        let { zoom } = this.state;

        this.setState({
            zoom: Math.round((zoom + 0.1) * 10) / 10
        }, () => {
            this.dummyRender();
        });
    };

    zoomOut = () => {
        let { zoom } = this.state;

        this.setState({
            zoom: Math.round((zoom - 0.1) * 10) / 10
        }, () => {
            this.dummyRender();
        });

    };

    dummyRender = () => {
        const state = this.state;

        this.setState(state);
    }

    addSnippet = () => {
        // API Call to add new default snippet
        let snippetProperties = {
            name: 'New Snippet',
            typeID: 14,
            width: 320,
            height: 180,
            fontSize: '64',
            textColour: `${this.state.textColor}`,
            fontFamily: 'Arial',
            textStyle: 'normal',
            textAlign: 'center',
            textDecoration: 'none',
            fontWeight: '400',
            content: 'Snippet Text'
        };

        let self = this;
        let newSnippet = null;
        axios
            .post(
                `${config.baseURL}/organisations/${
                this.state.organisationUUID
                }/snippets`,
                snippetProperties
            )
            .then(function (response) {
                newSnippet = response.data.snippet;
                newSnippet.x = 0;
                newSnippet.y = 0;
                newSnippet.z = 0;
                console.log(
                    `New Snippet: ${JSON.stringify(response.data.snippet)}`
                );
                //Add snippet to the template snippet
                return axios.post(
                    `${config.baseURL}/media/${
                    self.state.media.uuid
                    }/subsnippets`,
                    {
                        snippetUUID: response.data.snippet.uuid,
                        x: 0,
                        y: 0,
                        z: 0
                    }
                );
            })
            .then(function (response) {
                console.log('created new snippet:');

                // Create pseudo state object, push to it
                const snippetsArray = self.state.saveState.snippets;
                snippetsArray.push(newSnippet);

                // Update state with psuedo object
                self.setState(prevState => ({
                    ...prevState,
                    saveState: {
                        snippets: snippetsArray
                    },
                    currentSnippetUUID: newSnippet.uuid,
                    isSaved: false
                }));
            })
            .catch(function (err) {
                console.log(`Error:`);
            });
    };

    removeSnippet = () => {
        if (this.state.isSelected && this.state.currentSnippetUUID) {
            const snippetState = this.state.saveState.snippets;

            let self = this;
            let newSnippet = null;
            axios
                .delete(
                    `${config.baseURL}/media/${
                    this.state.media.uuid
                    }/subsnippets/${this.state.currentSnippetUUID}`
                )
                .then(function (response) {
                    const i = self.getSnippetIndex();
                    snippetState.splice(i, 1);

                    self.setState(prevState => ({
                        ...prevState,
                        saveState: {
                            snippets: snippetState
                        },
                        isSaved: true
                    }));
                    self.deselectSnippet();
                })
                .catch(function (err) {
                    console.log(`Error deleting: ${err}`);
                });
        }
    };

    getSnippetIndex = id => {
        const snippetsArray = this.state.saveState.snippets;
        let target = id;
        if (typeof id === 'undefined' && this.state.currentSnippetUUID) {
            target = this.state.currentSnippetUUID;
        }

        const targetSnippet = snippetsArray.find(elem => {
            return elem.uuid === target;
        });
        return snippetsArray.indexOf(targetSnippet);
    };


    toggleBold() {

        const snippetState = this.state.saveState.snippets;

        if (snippetState) {

            const i = this.getSnippetIndex();

            let isBold = false;
            if (snippetState[i].fontWeight === '400') {
                snippetState[i].fontWeight = '700';
                isBold = true;
            } else {
                snippetState[i].fontWeight = '400';
            }

            this.setState(prevState => ({
                ...prevState,
                saveState: {
                    snippets: snippetState
                },
                isBold: isBold,
                isSaved: false
            }));
        }
    }

    toggleItalic() {
        const snippetState = this.state.saveState.snippets;
        if (snippetState) {
            const i = this.getSnippetIndex();

            let isItalic = false;
            if (snippetState[i].fontStyle === 'normal') {
                snippetState[i].fontStyle = 'italic';
                isItalic = true;
            } else {
                snippetState[i].fontStyle = 'normal';
            }

            this.setState(prevState => ({
                ...prevState,
                saveState: {
                    snippets: snippetState
                },
                isSaved: false,
                isItalic: isItalic
            }));
        }
    };

    toggleAlign = newAlign => {
        const snippetState = this.state.saveState.snippets;

        if (snippetState && this.state.currentSnippetUUID) {
            const i = this.getSnippetIndex();
            if (snippetState[i].textAlign !== newAlign) {
                snippetState[i].textAlign = newAlign;
            }

            this.setState(prevState => ({
                ...prevState,
                saveState: {
                    snippets: snippetState
                },
                isSaved: false
            }));
        }
    };

    alignItemCenter = direction => {
        const snippetState = this.state.saveState.snippets;

        if (snippetState) {
            const i = this.getSnippetIndex();

            if (direction === 'vertical') {
                const newPos = Math.floor(
                    this.state.canvasHeight / 2 - snippetState[i].height / 2
                );
                snippetState[i].y = newPos;
            } else if (direction === 'horizontal') {
                const newPos = Math.floor(
                    this.state.canvasWidth / 2 - snippetState[i].width / 2
                );
                snippetState[i].x = newPos;
            } else {
                console.error('No alignment direction provided');
            }

            const uuid = snippetState[i].uuid;
            let self = this;

            axios
                .patch(
                    `${config.baseURL}/media/${
                    this.state.media.uuid
                    }/subsnippets/${uuid}`,
                    { x: snippetState[i].x, y: snippetState[i].y }
                )
                .then(function (response) {
                    self.setState(prevState => ({
                        ...prevState,
                        saveState: {
                            snippets: snippetState
                        }
                    }));
                })
                .catch(function (err) {
                    console.error(err)
                });
        }
    };

    updateFontSize = transform => {
        const snippetState = this.state.saveState.snippets;

        if (snippetState && this.state.currentSnippetUUID) {
            const i = this.getSnippetIndex();
            var newSize = parseInt(snippetState[i].fontSize, 10);
            if (transform === 'increase') {
                newSize += 2;
            } else if (transform === 'decrease') {
                newSize -= 2;
            }
            snippetState[i].fontSize = newSize;

            this.setState(prevState => ({
                ...prevState,
                saveState: {
                    snippets: snippetState
                },
                isSaved: false
            }));
        }
    };

    setFontSize = event => {
        const snippetState = this.state.saveState.snippets;

        if (snippetState) {
            const i = this.getSnippetIndex();
            snippetState[i].fontSize = parseInt(event.target.value);

            this.setState(prevState => ({
                ...prevState,
                saveState: {
                    snippets: snippetState
                },
                isSaved: false
            }));
        }
    };

    setColor = color => {
        const snippetState = this.state.saveState.snippets;
        if (snippetState.length > 0 && this.state.currentSnippetUUID) {
            const i = this.getSnippetIndex();
            snippetState[i].textColour = color;
        }

        this.setState(prevState => ({
            ...prevState,
            saveState: {
                snippets: snippetState
            },
            textColor: color,
            isSaved: false
        }));
    };

    onSave = () => {
        this.setState({
            isSaved: true
        });
        // Send API saveState

        axios
            .put(
                `${config.baseURL}/media/${
                this.state.media.uuid
                }/subsnippets`,
                { subSnippets: this.state.saveState.snippets }
            )
            .then(function (response) {
                console.log('saved');
            })
            .catch(function (err) {
                console.error(err)
            });
    };

    selectSnippet = async (e, id) => {

        const caretPosition = e.target.selectionStart;

        if(id !== undefined) {

            const snippetState = this.state.saveState.snippets;

            let isBold = false;
            let isItalic = false;

            if (snippetState) {
                const i = this.getSnippetIndex(id);
                const currentSnippet = snippetState[i];

                if(currentSnippet) {
                    if (currentSnippet['fontStyle'] === 'italic' ) {
                        isItalic = true;
                    }

                    if (currentSnippet['fontWeight'] === '700' ) {
                        isBold = true;
                    }
                }
            }

            this.setState(prevState => ({
                ...prevState,
                currentSnippetUUID: id,
                isSelected: true,
                caretPos: caretPosition,
                isBold: isBold,
                isItalic: isItalic
            }));
        }
    };

    deselectSnippet = () => {
        this.setState({
            currentSnippetUUID: null,
            isSelected: false,
            isBold: false,
            isItalic: false
        });
    };

    getScroll = () => {
        const canvas = document.getElementById(
            'template-snippet-canvas-wrapper'
        );
        var scrollOffset = {
            x: canvas.scrollLeft,
            y: canvas.scrollTop
        };

        return scrollOffset;
    };

    handleModalOpen = () => {
        this.setState({
            modalShow: true
        });
    };

    handleModalSave = () => {
        // Sets snippet background to selected background
        this.setState(prevState => ({
            ...prevState,
            modalShow: false
        }));
    };

    handleModalClose = () => {
        this.setState({
            modalShow: false
        });
    };

    handleSnapToGrid = () => {
        var val;
        if (this.state.gridValue < 20) {
            val = 20;
        } else {
            val = 1;
        }

        this.setState({
            gridValue: val
        });
    };



    handlePreview = () => {
        this.setState({
            previewEnabled: !this.state.previewEnabled
        });
    };

    render() {

        return (
            <Row className="editor-wrapper">
                <TemplateSnippetCanvas
                    snippets={this.state.saveState.snippets}
                    getScroll={this.getScroll}
                    caretPos={this.state.caretPos}
                    zoom={this.state.zoom}
                    handleMove={this.handleMove}
                    handleDragStop={this.handleDragStop}
                    contentChange={this.contentChange}
                    isSelected={this.state.isSelected}
                    currentSnippet={this.state.currentSnippetUUID}
                    selectSnippet={this.selectSnippet}
                    deselectSnippet={this.deselectSnippet}
                    handleResize={this.handleResize}
                    gridValue={this.state.gridValue}
                    selectedBackground={this.state.backgroundURL}
                    previewEnabled={this.state.previewEnabled}
                    canvasHeight={this.state.canvasHeight}
                    canvasWidth={this.state.canvasWidth}
                />
                <MediaTextsMenu
                    changeBackground={this.changeBackground}
                    zoomIn={this.zoomIn}
                    zoomOut={this.zoomOut}
                    addSnippet={this.addSnippet}
                    removeSnippet={this.removeSnippet}
                    toggleBold={() =>
                        this.toggleBold()
                    }
                    toggleItalic={() =>
                        this.toggleItalic()
                    }
                    toggleAlignLeft={() => this.toggleAlign('left')}
                    toggleAlignCenter={() => this.toggleAlign('center')}
                    toggleAlignRight={() => this.toggleAlign('right')}
                    alignItemCenter={() => this.alignItemCenter('horizontal')}
                    alignItemMiddle={() => this.alignItemCenter('vertical')}
                    fontSizeDecrease={() => this.updateFontSize('decrease')}
                    fontSizeSet={this.setFontSize}
                    fontSizeIncrease={() => this.updateFontSize('increase')}
                    handleSnapToGrid={this.handleSnapToGrid}
                    gridValue={this.state.gridValue}
                    setColor={this.setColor}
                    handleSave={this.onSave}
                    isSaved={this.state.isSaved}
                    isSelected={this.state.isSelected}
                    textColor={this.state.textColor}
                    currentSnippetUUID={this.state.currentSnippetUUID}
                    handlePreview={this.handlePreview}
                    previewEnabled={this.state.previewEnabled}
                    isBold={this.state.isBold}
                    isItalic={this.state.isItalic}
                />

            </Row>
        );
    }
}

export default MediaTextsEditor;
