import React, { Component } from 'react';
import { translate } from 'react-i18next';
import config from '../config';

import { Table, Checkbox, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import TooltipButton from './TooltipButton';

import './TextLibraryTextTable.css';

class TextLibraryTextTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDragging: false,
			textlibraryuuid: this.props.textlibraryuuid,
			organisationuuid: this.props.organisationuuid,
			texts: this.props.texts,
			itemsSelected: this.props.itemsSelected
		};
	}

	didSaveChanges(text, newDuration) {
		newDuration = newDuration * 1000;
		this.props.onSave(text, newDuration);
	}

	handleCopyClicked(text) {
		this.props.onCopyClicked(text);
	}

	handleDurationClicked(text) {
		const { t } = this.props;

		this.dialog.show({
			body: `${t('TextLibraryTextTable.duration')}`,
			prompt: Dialog.TextPrompt({
				placeholder: '10',
				initialValue: parseFloat(text.duration / 1000).toFixed(3)
			}),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.save'), dialog => this.didSaveChanges(text, dialog.value), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	humanFileSize(bytes, si) {
		var thresh = si ? 1000 : 1024;
		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}
		var units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		var u = -1;
		do {
			bytes /= thresh;
			++u;
		} while (Math.abs(bytes) >= thresh && u < units.length - 1);
		return bytes.toFixed(1) + ' ' + units[u];
	}

	componentWillReceiveProps(newProps) {
		this.setState({
			textlibraryuuid: newProps.textlibraryuuid,
			organisationuuid: newProps.organisationuuid,
			texts: newProps.texts,
			itemsSelected: newProps.itemsSelected
		});
	}

	toggleSelected(text) {
		this.props.onSelectionChanged(text, !this.getCheckedState(text));
	}

	onBeforeDragStart = () => {
		this.setState({
			isDragging: true
		});
	};

	onDragEnd = result => {
		this.setState({
			isDragging: false
		});

		// dropped outside the list
		if (!result.destination || result.destination.index === result.source.index) {
			return;
		}

		// no movement
		if (result.destination.index === result.source.index) {
			return;
		}

		this.props.onDragEnd(result);
	};

	getCheckedState(text) {
		return this.state.itemsSelected.includes(text);
	}

	handleSwitchChange(checked, text) {
		console.log('click: ' + text.uuid);
		this.props.onEnabledChanged(text, checked);
	}

	handleCheckBoxChange = event => {
		const target = event.target;

		const checked = target.checked;
		const uuid = target.name;

		const text = this.state.texts.find(text => {
			return text.uuid === uuid;
		});

		this.props.onSelectionChanged(text, checked);
	};

	render() {
		const noPropagation = e => e.stopPropagation();

		const { t, textlibraryuuid, organisationuuid } = this.props;

		const getItemStyle = (isDragging, draggableStyle) => ({
			// some basic styles to make the items look a bit nicer
			userSelect: 'none',
			background: isDragging ? '#dddddd' : '',
			display: isDragging ? 'table' : '',
			...draggableStyle
		});

		const getListStyle = isDraggingOver => ({
			borderCollapse: isDraggingOver ? 'separate' : 'collapse'
		});

		let textRows = this.state.texts.map((text, index) => {
			return (
                
				<Draggable key={text.uuid} draggableId={text.uuid} index={index}>
					{(provided, snapshot) => (
						<tr
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							onClick={e => {
								e.stopPropagation();
								this.toggleSelected(text);
							}}
							style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
						>
							<td width='20'>
								<Checkbox checked={this.getCheckedState(text)} name={text.uuid} onChange={this.handleCheckBoxChange} />
							</td>
							<td className='hidden-xs'>
								{text.heading}
							</td>
							<td className='hidden-xs'>
                                {text.detail}
							</td>
						
                            <td>
    							<span className='label label-success'>{parseFloat(text.duration / 1000).toFixed(2)}</span> 
                            </td>
							<td className='hidden-xs hidden-sm'>
								{Moment(text.updatedAt).format('YYYY-MM-DD HH:mm')}
							</td>
							<td className='hidden-xs hidden-sm'>
								{Moment(text.createdAt).format('YYYY-MM-DD HH:mm')}
							</td>
							<td>
								<Switch
									onChange={(checked, e, id) => {
										e.stopPropagation();

										this.handleSwitchChange(checked, text);
									}}
									checked={text.enabled}
									onColor={'#5db85d'}
									id='normal-switch'
								/>
							</td>
							<td>
								<div className='btn-toolbar pull-right'>
									<TooltipButton
										className='btn-default'
										disabled={this.state.isDragging || !text.uploaded ? true : false}
										onClick={e => {
											e.stopPropagation();
											this.handleCopyClicked(text);
										}}
										tooltip={t('TextLibraryTextTable.copy')}
									>
										<em className='far fa-clone' />
									</TooltipButton>
									<TooltipButton
										disabled={this.state.isDragging || !text.uploaded ? true : false}
										onClick={e => {
											e.stopPropagation();
											this.handleDurationClicked(text);
										}}
										tooltip={t('TextLibraryTextTable.duration_tip')}
									>
										<em className='fas fa-clock' />
									</TooltipButton>
									<TooltipButton
										disabled={this.state.isDragging || !text.uploaded ? true : false}
										className={text.hasRules ? 'btn-primary' : 'btn-default'}
										onClick={e => {
											e.stopPropagation();
											this.props.onRulesClicked(text);
										}}
										tooltip={t('TextLibraryTextTable.rules')}
									>
										<em className='fas fa-clipboard-list' />
									</TooltipButton>
									
									<TooltipButton
										disabled={this.state.isDragging ? true : false}
										className='btn-danger'
										onClick={e => {
											e.stopPropagation();
											this.props.onDeleteText(text);
										}}
										tooltip={t('Dialog.delete')}
									>
										<em className='fas fa-trash' />
									</TooltipButton>
								</div>
							</td>
						</tr>
					)}
				</Draggable>
			);
		});

		return (
			<div className='TextLibraryTextTable'>
				<Dialog
					ref={el => {
						this.dialog = el;
					}}
				/>
				<DragDropContext onBeforeDragStart={this.onBeforeDragStart} onDragEnd={this.onDragEnd}>
					<Droppable droppableId='droppable'>
						{(provided, snapshot) => (
							<table style={getListStyle(snapshot.isDraggingOver)} ref={provided.innerRef} className='table table-striped'>
								<thead>
									<tr>
										<th width='10'/>
										<th width='275' className='hidden-xs'>
											{t('TextLibraryTextTable.heading')}
										</th>
										<th width='275' className='hidden-xs'>
											{t('TextLibraryTextTable.detail')}
										</th>
										<th width='100' >{t('TextLibraryTextTable.duration')}</th>

										<th width='150' className='hidden-xs hidden-sm'>
											{t('TextLibraryTextTable.updated')}
										</th>
                                        <th width='150' className='hidden-xs hidden-sm'>
											{t('TextLibraryTextTable.created')}
										</th>
										<th width='100'>{t('TextLibraryTextTable.published')}</th>
										<th>
											<em className='fa fa-cog' />
										</th>
									</tr>
								</thead>
								<tbody>{textRows}</tbody>
							</table>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		);
	}
}

export default translate('translations')(TextLibraryTextTable);
