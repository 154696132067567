import React, { Component } from 'react';
import { translate } from 'react-i18next';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';

import DismissableAlert from '../components/DismissableAlert';
import './Engineers.css';
import config from '../config';
import { Link } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import EngineerPlayerTable from '../components/EngineerPlayerTable';
import PaginationPanel from '../components/PaginationPanel';
import logo from '../logo.png';

axios.defaults.withCredentials = true;

class Engineers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            players: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleLogout = event => {
        var self = this;
        axios
            .get(`${config.baseURL}/auth/logout`)
            .then(function(response) {
                self.props.userHasAuthenticated(false, {
                    isAuthenticated: false,
                    isEngineer: false
                });
                self.props.history.push('/login');
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {
            this.loadPlayersFromServer();
        });
    };

    componentDidMount() {
        this.loadPlayersFromServer();
    }

    loadPlayersFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/engineers/players`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    players: response.data.players,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
                self.setState({
                    players: [],
                    pageCount: 0
                });
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url);

        event.preventDefault();
        this.loadPlayersFromServer();
    };

    render() {
        const { t } = this.props;

        document.title = `MySign Digital Screen Solutions | ${t(
            'Engineers.title'
        )}`;

        return (
            <div className="Engineers">
                <Row>
                    <Col
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xsOffset={1}
                        smOffset={0.5}
                        mdOffset={0.5}
                        lgOffset={0.5}
                    >
                    


                      <Panel>

                        <img
                                        src={logo}
                                        alt="logo"
                                        className="center-block img-responsive"
                                        style={{ width: 168, height: 81 }}
                                    />

                                    <h1 className="mainHeading">Player Checkin</h1>
                                    <h1>Please enter the site <br/> name or postcode</h1>

                                    <DismissableAlert
                                        title={this.state.error.title}
                                        message={this.state.error.message}
                                        hidden={this.state.error.hidden}
                                        style={this.state.error.style}
                                    />

<Row>
                            <Col lg={12}>
                                
                                <ol className="breadcrumb">
                                    <li className="active">
                                        <i className="fa fa-desktop" />{' '}
                                        {t('Players.title')}
                                    </li>
                                    
                                </ol>
                            </Col>
                        </Row>
                        <PaginationPanel
                            heading={
                                <Row>
                                     
                                    <Col xs={12} className="text-right">
                                        
                                        <form
                                            role="search"
                                            onSubmit={this.handleSubmit}
                                        >
                                            <FormGroup controlId="search">
                                                <InputGroup>
                                                    <FormControl
                                                        type="text"
                                                        value={
                                                            this.state.search
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder={t(
                                                            'Search'
                                                        )}
                                                    />
                                                    <InputGroup.Button>
                                                        <Button
                                                            onClick={
                                                                this
                                                                    .handleSubmit
                                                            }
                                                        >
                                                            <i className="glyphicon glyphicon-search" />
                                                        </Button>
                                                    </InputGroup.Button>
                                                </InputGroup>
                                            </FormGroup>
                                        </form>
                                    </Col>
                                </Row>
                            }
                            pagination={
                                <ReactPaginate
                                    previousLabel={t('Pagination.previous')}
                                    nextLabel={t('Pagination.next')}
                                    breakLabel={<Link to="#">...</Link>}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    forcePage={this.state.selected}
                                />
                            }
                        >
                            <EngineerPlayerTable
                                userIsAuthorized={this.state.userIsAuthorized}
                                user={this.state.user}
                                players={this.state.players}
                            />
                        </PaginationPanel>
                        <Button 
                          
                          className="logout"
                          bsStyle="light"
                          bsSize="medium"
                          onClick={this.handleLogout}>
                                {t('Navigation.logout')}
                        </Button>
                    
                        </Panel> 
                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate('translations')(Engineers);
