import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import './DashboardTile.css';

export default class DashboardTile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            buttonText: this.props.buttonText,
            icon: this.props.icon,
            count: this.props.count,
            colour: this.props.colour,
            link: this.props.link
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            title: nextProps.title,
            buttonText: nextProps.buttonText,
            icon: nextProps.icon,
            count: nextProps.count,
            colour: nextProps.colour,
            link: nextProps.link
        });
    }

    render() {
        return (
            <Link className="DashboardTile" to={this.state.link}>
                <div className="col-lg-3 col-md-6">
                    <div className={`panel panel-${this.state.colour}`}>
                        <div className="panel-heading">
                            <div className="row">
                                <div className="col-xs-3">
                                    <i className={`${this.state.icon}`} />
                                </div>
                                <div className="col-xs-9 text-right">
                                    <div className="huge">
                                        {this.state.count}
                                    </div>
                                    <div>{this.state.title}</div>
                                </div>
                            </div>
                        </div>
                        <Link to={this.state.link}>
                            <div className="panel-footer">
                                <span className="pull-left">
                                    {this.state.buttonText}
                                </span>
                                <span className="pull-right">
                                    <i className="fa fa-arrow-circle-right" />
                                </span>
                                <div className="clearfix" />
                            </div>
                        </Link>
                    </div>
                </div>
            </Link>
        );
    }
}
