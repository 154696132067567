import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './CreateTextForm.css';

class CreateTextForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newText: null,
            isLoading: false,
            heading: '',
            detail: '',
            snippetUUID: this.props.snippetUUID,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.heading.length > 0 && this.state.detail.length;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            let self = this;
            axios
                .post(
                    `${config.baseURL}/snippets/${
                        this.state.snippetUUID
                    }/texts`,
                    {
                        heading: this.state.heading,
                        detail: this.state.detail
                    }
                )

                .then(function(response) {
                    self.setState({
                        newText: response.data.text,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateTextForm.success')}:`,
                            style: 'success',
                            message: `${t('CreateTextForm.created_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    console.log(
                        `Error: ${JSON.stringify(err.response.data.message)}`
                    );
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateTextForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateTextForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateTextForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createTextForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="heading" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateTextForm.heading')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.heading}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateTextForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="detail" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateTextForm.detail')}
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.detail}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateTextForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('CreateTextForm.add_text')}`}
                                    loadingText={`${t(
                                        'CreateTextForm.adding_text'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreateTextForm">
                {this.state.newText === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreateTextForm);
