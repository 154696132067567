import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
    Nav,
    Navbar,
    NavItem,
    NavDropdown,
    MenuItem,
    FormControl
} from 'react-bootstrap';
import './NavigationSidebar.css';
import config from '../config';
import { translate } from 'react-i18next';

import axios from 'axios';

axios.defaults.withCredentials = true;

class NavigationSidebar extends Component {
    constructor(props) {
        super(props);

        const childProps = this.props.childProps;

        this.state = {
            currentOrganisation: childProps.currentOrganisation,
            user: childProps.user,
            userIsAuthorized: childProps.userIsAuthorized,
            changeOrganisation: childProps.changeOrganisation
        };
    }

    render() {
        const { t } = this.props;

        const user = this.state.user;
        const currentOrganisationUUID = this.state.currentOrganisation.uuid;

        return (
            <div className="NavigationSidebar">
                <div className="collapse navbar-collapse navbar-ex1-collapse">
                    <ul className="nav navbar-nav side-nav ">
                        <li>
                            <Link
                                to={`/organisations/${currentOrganisationUUID}`}
                            >
                                <div>
                                    <i className="fa fa-tachometer-alt" />{' '}
                                    {t('SideNavigation.dashboard')}
                                </div>
                            </Link>
                        </li>

                        {this.state.userIsAuthorized(user, [
                            'staff_super_admin',
                            'staff_admin',
                            'organisation_admin'
                        ]) ? (
                            <li>
                                <Link
                                    to={`/organisations/${currentOrganisationUUID}/users`}
                                >
                                    <div>
                                        <i className="fas fa-fw fa-users" />{' '}
                                        {t('SideNavigation.users')}
                                    </div>
                                </Link>
                            </li>
                        ) : null}

                        <li>
                            <Link
                                to={`/organisations/${currentOrganisationUUID}/players`}
                            >
                                <div>
                                    <i className="fa fa-fw fa-desktop" />{' '}
                                    {t('SideNavigation.players')}
                                </div>
                            </Link>
                        </li>

                        {this.state.userIsAuthorized(user, [
                            'staff_super_admin',
                            'staff_admin',
                            'organisation_admin'
                        ]) ? (
                            <li>
                                <Link
                                    to={`/organisations/${currentOrganisationUUID}/attributes`}
                                >
                                    <div>
                                        <i className="fa fa-fw fa-tags" />{' '}
                                        {t('SideNavigation.attributes')}
                                    </div>
                                </Link>
                            </li>
                        ) : null}
                        <li>
                            <Link
                                to={`/organisations/${currentOrganisationUUID}/pages`}
                            >
                                <div>
                                    <i className="fa fa-fw fa-file" />{' '}
                                    {t('SideNavigation.pages')}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/organisations/${currentOrganisationUUID}/snippets`}
                            >
                                <div>
                                    <i className="fa fa-fw fa-puzzle-piece" />{' '}
                                    {t('SideNavigation.snippets')}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/organisations/${currentOrganisationUUID}/medialibraries`}
                            >
                                <div>
                                    <i className="fa fa-fw fa-images" />{' '}
                                    {t('SideNavigation.medialibraries')}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/organisations/${currentOrganisationUUID}/textlibraries`}
                            >
                                <div>
                                    <i className="fa fa-fw fa-font" />{' '}
                                    {t('SideNavigation.textlibraries')}
                                </div>
                            </Link>
                        </li>
                        <div className="spacer"/>
                    <div className="terms">
                        <Link to="/terms">
                            {t('Login.terms_short')}
                        </Link>
                        {' | '}
                        <Link to="/privacy">
                            {t('Login.privacy_short')}
                        </Link>
                    </div>
                    </ul>
                    

                    
                </div>
            </div>
        );
    }
}
export default translate('translations')(NavigationSidebar);
