import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreatePlayerForm from '../components/CreatePlayerForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import './CreatePlayer.css';

class CreatePlayer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t('CreatePlayer.title')}`;

        const { uuid } = this.props.match.params;

        return (
            <div className="CreatePlayer">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">{t('CreatePlayer.title')}</h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-tv" />{' '}
                                        <Link to={`/organisations/${uuid}/players`}>{t('SideNavigation.players')}</Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-plus" /> {t('CreatePlayer.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <CreatePlayerForm uuid={uuid} />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreatePlayer);
