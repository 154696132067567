import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Checkbox } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';
import { Link } from 'react-router-dom';

Moment.locale('en');

class PlayerPickerTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            players: this.props.players,
            singleSelection: false
        };
    }

    getCheckedState(player) {
        const selected = this.state.itemsSelected.includes(player.uuid);
        console.log(`selected: ${selected}`);
        return selected;
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            players: newProps.players,
            itemsSelected: newProps.itemsSelected,
            singleSelection: newProps.singleSelection
        });
    }

    handleCheckBoxChange = event => {
        const target = event.target;

        const checked = target.checked;
        const uuid = target.name;

        const player = this.state.players.find(player => {
            return player.uuid === uuid;
        });

        this.props.onSelectionChanged(player, checked);
    };

    render() {
        const { t, uuid } = this.props;
        let playerRows = this.state.players.map((player, index) => {
            return (
                <tr key={index}>
                    <td width="20">
                        <Checkbox
                            checked={this.getCheckedState(player)}
                            name={player.uuid}
                            onChange={this.handleCheckBoxChange}
                        />
                    </td>
                    <td className="hidden-xs hidden-sm">
                        {player.online ? (
                            <span className="label label-success">
                                {t('PlayerPickerTable.online')}
                            </span>
                        ) : (
                            <span className="label label-danger">
                                {t('PlayerPickerTable.offline')}
                            </span>
                        )}
                    </td>

                    <td>
                        <Link
                         style={{
                            alignItems: "center",
                            textAlign: "left"
                          }}
                         className="label label-primary"
                            to={`/organisations/${uuid}/players/${player.uuid}`}
                        >
                            {player.name}
                        </Link>
                    </td>
                    <td className="hidden-xs hidden-sm"  onClick={(e) => {

                        let checked = this.getCheckedState(player);
                
                        this.props.onSelectionChanged(player, !checked);

                    }}>{player.location}</td>
                    <td className="hidden-xs hidden-sm">{player.macAddress}</td>
                    <td className="hidden-xs hidden-sm">
                        {player.serialNumber}
                    </td>
                    <td className="hidden-xs hidden-sm hidden-md">
                        {Moment(player.installDate).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs">
                        {player.lastSeen
                            ? Moment(player.lastSeen).fromNow()
                            : t('PlayerPickerTable.never')}
                    </td>
                </tr>
            );
        });

        return (
            <div id="players" className="playerTable">
                <Table
                    striped
                    bordered
                    condensed
                    hover
                    style={{ margin: '0px' }}
                >
                    <thead>
                        <tr>
                            <th width="20" />
                            <th className="hidden-xs hidden-sm">
                                {t('PlayerPickerTable.status')}
                            </th>
                            <th>{t('PlayerPickerTable.name')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayerPickerTable.location')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayerPickerTable.mac_address')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayerPickerTable.serial_number')}
                            </th>
                            <th className="hidden-xs hidden-sm hidden-md">
                                {t('PlayerPickerTable.install_date')}
                            </th>
                            <th className="hidden-xs">
                                {t('PlayerPickerTable.last_seen')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{playerRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(PlayerPickerTable);
