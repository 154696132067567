import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './InviteUserForm.css';

class InviteUserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uuid: this.props.uuid,
            newUser: null,
            isLoading: false,
            email: '',
            role: '5',
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.email.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(
                    `${config.baseURL}/organisations/${this.state.uuid}/users`,
                    {
                        email: this.state.email,
                        role: this.state.role
                    }
                )
                .then(function(response) {
                    self.setState({
                        newUser: response.data.user,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('InviteUserForm.success')}:`,
                            style: 'success',
                            message: `${t('InviteUserForm.invited_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('InviteUserForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('InviteUserForm.error')}:`,
                    style: 'danger',
                    message: `${t('InviteUserForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="InviteUserForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="email" bsSize="sm">
                                <ControlLabel>
                                    {t('InviteUserForm.email')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-envelope" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('InviteUserForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="role" bsSize="sm">
                                <label>{t('InviteUserForm.role')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.role}
                                    onChange={this.handleChange}
                                >
                                    {this.props.userIsAuthorized(
                                        this.props.user,
                                        ['staff_super_admin']
                                    ) ? (
                                        <option value="1">
                                            {t(
                                                'InviteUserForm.roles.staff_super_admin'
                                            )}
                                        </option>
                                    ) : null}
                                    {this.props.userIsAuthorized(
                                        this.props.user,
                                        ['staff_super_admin', 'staff_admin']
                                    ) ? (
                                        <option value="2">
                                            {t(
                                                'InviteUserForm.roles.staff_admin'
                                            )}
                                        </option>
                                    ) : null}
                                    {this.props.userIsAuthorized(
                                        this.props.user,
                                        ['staff_super_admin', 'staff_admin']
                                    ) ? (
                                        <option value="3">
                                            {t(
                                                'InviteUserForm.roles.staff_engineer'
                                            )}
                                        </option>
                                    ) : null}
                                    {this.props.userIsAuthorized(
                                        this.props.user,
                                        [
                                            'staff_super_admin',
                                            'staff_admin',
                                            'organisation_admin'
                                        ]
                                    ) ? (
                                        <option value="4">
                                            {t(
                                                'InviteUserForm.roles.organisation_admin'
                                            )}
                                        </option>
                                    ) : null}
                                    {this.props.userIsAuthorized(
                                        this.props.user,
                                        [
                                            'staff_super_admin',
                                            'staff_admin',
                                            'organisation_admin'
                                        ]
                                    ) ? (
                                        <option value="5" defaultValue>
                                            {t(
                                                'InviteUserForm.roles.organisation_user'
                                            )}
                                        </option>
                                    ) : null}
                                    {this.props.userIsAuthorized(
                                        this.props.user,
                                        [
                                            'staff_super_admin',
                                            'staff_admin',
                                            'organisation_admin'
                                        ]
                                    ) ? (
                                        <option value="6">
                                            {t(
                                                'InviteUserForm.roles.organisation_publisher'
                                            )}
                                        </option>
                                    ) : null}
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('InviteUserForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('InviteUserForm.invite_user')}`}
                                    loadingText={`${t(
                                        'InviteUserForm.inviting_user'
                                    )}`}
                                />
                                <small className="text-muted">
                                    {t('InviteUserForm.invite_explaination')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="InviteUserForm">
                {this.state.newUser === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(InviteUserForm);
