import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import PaginationPanel from '../components/PaginationPanel';
import './UserPlayers.css';
import config from '../config';
import { Link } from 'react-router-dom';

import UserPlayersTable from '../components/UserPlayersTable';
import PlayerPickerModal from '../components/PlayerPickerModal';

import axios from 'axios';
import { Col, Row, Button } from 'react-bootstrap';

import { translate } from 'react-i18next';

import './AttributePlayers.css';

class UserPlayers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: '',
			lastName: '',
			user: props.user,
			userIsAuthorized: props.userIsAuthorized,
			players: [],
			attribute: {},
			page: props.page,
			pageCount: 0,
			limit: 15,
			search: props.search,
            selected: props.selected - 1,
			showingModal: false
		};
	}

	componentDidMount = () => {
		this.loadUserNameFromServer();
	};

	loadUserNameFromServer() {
		const { userUUID } = this.props.match.params;

		var self = this;

		axios
			.get(`${config.baseURL}/users/${userUUID}/name`)
			.then(function (response) {
				self.setState({
					firstName: response.data.firstName,
					lastName: response.data.lastName
				});

				self.loadUserPlayersFromServer();
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	handleAddClicked = () => {
		this.setState({ showingModal: true });
	};

	hideModal = () => {
		this.setState({ showingModal: false });
	};

	didPickPlayers = items => {
		this.setState({ showingModal: false });
		const { uuid, userUUID } = this.props.match.params;
		// const { user } = this.props;

		var self = this;

		axios
			// .post(`${config.baseURL}/organisations/${uuid}/users/${user.uuid}/players`, {
			.post(`${config.baseURL}/organisations/${uuid}/users/${userUUID}/players`, {
				playerUUIDs: items
			})
			.then(function (response) {
				self.loadUserPlayersFromServer();
			})
			.catch(function (err) {
				console.log('Error');
			});
	};

	loadUserPlayersFromServer() {
		const { uuid, userUUID } = this.props.match.params;

		var self = this;

		axios
			.get(`${config.baseURL}/organisations/${uuid}/users/${userUUID}/players`, {
				params: {
					page: self.state.page,
					limit: self.state.limit,
					search: self.search
				}
			})
			.then(function (response) {
				self.setState({
					players: response.data.players,
					pageCount: response.data.pageCount
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	handlePageClick = data => {
		let selected = data.selected + 1;

		const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {

			this.loadUserPlayersFromServer();
		});
	};

	handleDelete = player => {
		const { uuid, userUUID } = this.props.match.params;

		var self = this;

		axios
			.delete(`${config.baseURL}/organisations/${uuid}/users/${userUUID}/players/${player.uuid}`)
			.then(function (response) {
				self.loadUserPlayersFromServer();
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	render() {
		const { t } = this.props;
		document.title = `MySign Digital Screen Solutions | ${t('UserPlayers.title')}`;

		const user = this.state.user;
		const { uuid, userUUID } = this.props.match.params;

		return (
			<div className='UserPlayers'>
				<PlayerPickerModal show={this.state.showingModal} onHide={this.hideModal} organisationUUID={uuid} onChoose={this.didPickPlayers}  excludeUserPlayers={userUUID}/>
				<div id='wrapper'>
					<Navigation props={this.props} />
					<div id='content-wrapper'>
						<Row>
							<Col lg={12}>
								<h1 className='page-header'>
									{t('UserPlayers.title')}
									<small>
										{' '}
										{this.state.firstName} {this.state.lastName}
									</small>
									{this.state.userIsAuthorized(user, ['staff_super_admin', 'staff_admin', 'organisation_admin', 'organisation_user']) ? (
										<Button type='submit' className='btn btn-primary' onClick={() => this.handleAddClicked()}>
											<em className='fa fa-plus fa-fw' /> {t('Players.add_player')}
										</Button>
									) : null}
								</h1>


								<ol className='breadcrumb'>
									<li>
										<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
									</li>
									<li>
										<i className='fa fa-users' /> <Link to={`/organisations/${uuid}/users`}>{t('SideNavigation.users')}</Link>
									</li>
									<li className='active'>
										<i className='fa fa-tv' /> {t('SideNavigation.players')}
									</li>
								</ol>

								<PaginationPanel
									heading={<Row />}
									pagination={
										<ReactPaginate
											previousLabel={t('Pagination.previous')}
											nextLabel={t('Pagination.next')}
											breakLabel={<Link to='#'>...</Link>}
											breakClassName={'break-me'}
											pageCount={this.state.pageCount}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={this.handlePageClick}
											containerClassName={'pagination'}
											subContainerClassName={'pages pagination'}
											activeClassName={'active'}
											forcePage={this.state.selected}
										/>
									}
								>
									<UserPlayersTable userIsAuthorized={this.state.userIsAuthorized} user={user} players={this.state.players} uuid={uuid} onDelete={this.handleDelete} />
								</PaginationPanel>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('translations')(UserPlayers);
