import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Checkbox } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';

Moment.locale('en');

class TextLibraryPickerTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            textlibraries: this.props.textlibraries
        };
    }

    getCheckedState(textlibrary) {
        const selected = this.state.itemsSelected.includes(textlibrary.uuid);
        console.log(`selected: ${selected}`);
        return selected;
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            textlibraries: newProps.textlibraries,
            itemsSelected: newProps.itemsSelected
        });
    }

    handleCheckBoxChange = event => {
        const target = event.target;

        const checked = target.checked;
        const uuid = target.name;

        const textlibrary = this.state.textlibraries.find(textlibrary => {
            return textlibrary.uuid === uuid;
        });

        this.props.onSelectionChanged(textlibrary, checked);
    };

    render() {
        const { t, uuid } = this.props;
        let textlibraryRows = this.state.textlibraries.map(
            (textlibrary, index) => {
                return (
                    <tr key={index}>
                        <td width="20">
                            <Checkbox
                                checked={this.getCheckedState(textlibrary)}
                                name={textlibrary.uuid}
                                onChange={this.handleCheckBoxChange}
                            />
                        </td>

                        <td>
                            <i className={'fa fa-font'} /> {textlibrary.name}
                        </td>

                        <td className="hidden-xs hidden-sm hidden-md">
                            {Moment(textlibrary.updatedAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}
                        </td>
                        <td className="hidden-xs">
                            {Moment(textlibrary.createdAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}
                        </td>
                    </tr>
                );
            }
        );

        return (
            <div id="textlibraries" className="textlibraryTable">
                <Table
                    striped
                    bordered
                    condensed
                    hover
                    style={{ margin: '0px' }}
                >
                    <thead>
                        <tr>
                            <th width="20" />
                            <th width="500">
                                {t('TextSnippetTextLibrariesTable.name')}
                            </th>

                            <th className="hidden-xs">
                                {t('TextSnippetTextLibrariesTable.updated')}
                            </th>
                            <th className="hidden-xs">
                                {t('TextSnippetTextLibrariesTable.created')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{textlibraryRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(TextLibraryPickerTable);
