import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import PlayerAttributesTable from '../components/PlayerAttributesTable';
import PaginationPanel from '../components/PaginationPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';

import { translate } from 'react-i18next';

import './PlayerAttributes.css';

class PlayerAttributes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            attributes: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: '',
            selected: props.selected - 1
        };
    }

    componentDidMount() {
        this.loadPlayerFromServer();
        this.loadPlayerAttributesFromServer();
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    deleteAttribute(attribute) {
        const { uuid, playerUUID } = this.props.match.params;

        var self = this;
        axios
            .delete(
                `${config.baseURL}/players/${playerUUID}/attributes/${
                    attribute.uuid
                }`
            )
            .then(function(response) {
                self.loadPlayerAttributesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerAttributesFromServer() {
        var self = this;
        const { uuid, playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}/attributes`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                console.log(
                    `Attributes: ${JSON.stringify(response.data.attributes)}`
                );
                self.setState({
                    attributes: response.data.attributes,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handlePageClick = data => {
        let selected = data.selected + 1;
        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {

            this.loadPlayerAttributesFromServer();
        });
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url); 

        event.preventDefault();
        this.loadPlayerAttributesFromServer();
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'PlayerAttributes.title'
        )}`;

        const user = this.state.user;
        const { uuid, playerUUID } = this.props.match.params;

        return (
            <div className="PlayerAttributes">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('PlayerAttributes.title')}{' '}
                                    <small>
                                        {this.state.player_name}{' '}
                                        <small style={{ whiteSpace: 'nowrap' }}>
                                            {this.state.organisation_name}
                                        </small>
                                    </small>
                                </h1>

                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>
                                            {t('Dashboard.title')}
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-tv" />{' '}
                                        <Link
                                            to={`/organisations/${uuid}/players`}
                                        >
                                            {t('SideNavigation.players')}
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-tv" />{' '}
                                        <Link
                                            to={`/organisations/${uuid}/players/${playerUUID}`}
                                        >
                                            {t('OrganisationPlayer.title')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-tags" />{' '}
                                        {t('SideNavigation.attributes')}
                                    </li>
                                </ol>

                                <PaginationPanel
                                    heading={
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <form
                                                    role="search"
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    <FormGroup controlId="search">
                                                        <InputGroup>
                                                            <FormControl
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .search
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                placeholder={t(
                                                                    'Search'
                                                                )}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    onClick={
                                                                        this
                                                                            .handleSubmit
                                                                    }
                                                                >
                                                                    <i className="glyphicon glyphicon-search" />
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </form>
                                            </Col>
                                        </Row>
                                    }
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <PlayerAttributesTable
                                        attributes={this.state.attributes}
                                        uuid={uuid}
                                        onDelete={this.deleteAttribute.bind(
                                            this
                                        )}
                                    />
                                </PaginationPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(PlayerAttributes);
