import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import './FactSnippetFactTypesTable.css';
import TooltipButton from './TooltipButton';

Moment.locale('en');

class FactSnippetFactTypesTable extends Component {
    constructor(props) {
        super(props);
    }

    didConfirmDelete(facttype) {
        this.props.onDelete(facttype);
    }

    handleDeleteClicked(facttype) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(facttype),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    componentDidMount() {}

    render() {
        const {
            t,
            uuid,
            pageUUID,
            playerUUID,
            facttypes,
            snippet
        } = this.props;

        let facttypeRows = facttypes.map((facttype, index) => {
            let iconName = 'fas fa-images';
            let facttypeLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/factsnippets/${
                snippet.uuid
            }/facttypes/${facttype.uuid}?rel=player`;

            return (
                <tr>
                    <td width="20">{facttype.id}</td>
                    <td>{facttype.description}</td>
                    <td align="right">
                        {this.props.userIsAuthorized(this.props.user, [
                            'staff_super_admin',
                            'staff_admin',
                            'organisation_admin',
                            'organisation_user'
                        ]) ? (
                            <TooltipButton
                                id="deleteButton"
                                className="btn-danger pull-right"
                                tooltip={t(
                                    'FactSnippetFactTypesTable.deletefacttype'
                                )}
                                onClick={() =>
                                    this.handleDeleteClicked(facttype)
                                }
                            >
                                <em className="fa fa-trash" />
                            </TooltipButton>
                        ) : null}
                    </td>
                </tr>
            );
        });

        return (
            <div
                id="FactSnippetFactTypesTable"
                className="FactSnippetFactTypesTable"
            >
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th width="20">
                                {t('FactSnippetFactTypesTable.id')}
                            </th>
                            <th>
                                {t('FactSnippetFactTypesTable.description')}
                            </th>
                            <th width="60">
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{facttypeRows}</tbody>
                </table>
            </div>
        );
    }
}

export default translate('translations')(FactSnippetFactTypesTable);
