import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button, Modal } from 'react-bootstrap';
import './QuestionTypePickerModal.css';
import QuestionTypePickerTable from './QuestionTypePickerTable.js';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';

class QuestionTypePickerModal extends Component {
    constructor(props) {
        super(props);

        this.handleHide = this.handleHide.bind(this);

        this.state = {
            show: false,
            questiontypes: [],
            page: 1,
            pageCount: 0,
            limit: 10,
            search: '',
            organisationUUID: '',
            excludeAttribute: '',
            itemsSelected: []
        };
    }

    handlePageClick = data => {
        let selected = data.selected + 1;
        this.setState({ page: selected }, () => {
            this.loadOrganisationQuestionTypesFromServer();
        });
    };

    handleHide() {
        this.props.onHide();
        this.setState({
            itemsSelected: []
        });
    }

    handleChooseClicked() {
        this.props.onChoose(this.state.itemsSelected);
        this.setState({
            itemsSelected: []
        });
    }

    componentDidMount() {}

    loadOrganisationQuestionTypesFromServer() {
        var self = this;

        axios
            .get(`${config.baseURL}/questiontypes`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    questiontypes: response.data.questionTypes,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    componentWillReceiveProps(newProps) {
        this.setState(
            {
                show: newProps.show,
                title: newProps.title,
                organisationUUID: newProps.organisationUUID,
                excludeAttribute: newProps.excludeAttribute
            },
            () => {
                this.loadOrganisationQuestionTypesFromServer();
            }
        );
    }

    selectionChanged(questiontype, checked) {
        let itemsSelected = this.state.itemsSelected;

        if (checked) {
            itemsSelected.push(questiontype.id);
        } else {
            itemsSelected = itemsSelected.filter(
                selectedItem => selectedItem !== questiontype.id
            );
        }

        var selectedSet = new Set(itemsSelected);

        this.setState({
            itemsSelected: [...selectedSet]
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="QuestionTypePickerModal">
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    container={this}
                    aria-labelledby="contained-modal-title"
                    dialogClassName="QuestionTypePickerModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">
                            {t('QuestionTypePickerModal.title')}{' '}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '0px', padding: '0px' }}>
                        <QuestionTypePickerTable
                            itemsSelected={this.state.itemsSelected}
                            questiontypes={this.state.questiontypes}
                            onDelete={() => {}}
                            onSelectionChanged={this.selectionChanged.bind(
                                this
                            )}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center">
                            <ReactPaginate
                                previousLabel={t('Pagination.previous')}
                                nextLabel={t('Pagination.next')}
                                breakLabel={<Link to="#">...</Link>}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="btn-toolbar pull-right">
                            <Button onClick={this.handleHide}>
                                {t('QuestionTypePickerModal.close')}
                            </Button>
                            <Button
                                disabled={this.state.itemsSelected.length === 0}
                                className="btn-primary"
                                onClick={this.handleChooseClicked.bind(this)}
                            >
                                {t('QuestionTypePickerModal.add_selected')}
                                {` (${this.state.itemsSelected.length})`}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default translate('translations')(QuestionTypePickerModal);
