import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import TextSnippetTextsTable from '../components/TextSnippetTextsTable';
import PlainPanel from '../components/PlainPanel';
import DismissableAlert from '../components/DismissableAlert';
import config from '../config';
import qs from 'qs';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog';
import { Link } from 'react-router-dom';
import reorder from '../helpers/reorder';

import { translate } from 'react-i18next';

import './TextSnippet.css';

class TextSnippet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            snippet: {},
            texts: [],
            userIsAuthorized: props.userIsAuthorized,
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }

        window.history.pushState(null, null, url); 

        event.preventDefault();
        this.loadTextSnippetTextsFromServer();
    };

    componentDidMount() {
        this.loadTextSnippetFromServer();
        this.loadTextSnippetTextsFromServer();
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadTextSnippetTextsFromServer() {
        var self = this;
        const { uuid, snippetUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}/texts`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    texts: response.data.texts,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    deleteText(text) {
        const { snippetUUID } = this.props.match.params;

        var self = this;
        axios
            .delete(
                `${config.baseURL}/snippets/${snippetUUID}/texts/${text.uuid}`
            )
            .then(function(response) {
                self.loadTextSnippetTextsFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadTextSnippetFromServer() {
        var self = this;
        const { uuid, snippetUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}`, {})
            .then(function(response) {
                self.setState({
                    snippet: response.data.snippet
                });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    enabledChanged(text, enabled) {
        try {
            var self = this;
            axios
                .put(`${config.baseURL}/text/${text.uuid}/enabled `, {
                    enabled: enabled
                })
                .then(function(response) {
                    console.log('Success!');
                    self.loadTextSnippetTextsFromServer();
                })
                .catch(function(err) {
                    console.log(err);
                });
        } catch (e) {
            console.log('exception');
        }
    }

    reorderTexts(result) {
        const { uuid, snippetUUID } = this.props.match.params;

        const reorderedTexts = reorder(
            this.state.texts,
            result.source.index,
            result.destination.index
        );
        this.setState({
            texts: reorderedTexts
        });

        const ordering = {};
        for (var i = 0; i < reorderedTexts.length; i++) {
            const text = reorderedTexts[i];
            ordering[text.uuid] = i;
        }

        try {
            var self = this;
            axios
                .post(
                    `${config.baseURL}/snippets/${snippetUUID}/texts/reorder `,
                    { ordering }
                )
                .then(function(response) {
                    console.log('Success!');
                })
                .catch(function(err) {
                    console.log(err);
                });
        } catch (e) {
            console.log('exception');
        }
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'TextSnippet.title'
        )}`;

        const user = this.state.user;
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        let createLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textsnippets/${snippetUUID}/texts/create?rel=player`;

        if (rel === 'snippet') {
            createLink = `/organisations/${uuid}/textsnippets/${snippetUUID}/texts/create?rel=snippet`;
        } else if (rel === 'page') {
            createLink = `/organisations/${uuid}/pages/${pageUUID}/textsnippets/${snippetUUID}/texts/create?rel=page`;
        }

        return (
            <div className="TextSnippet">
                <div id="wrapper">
                    <Dialog
                        ref={el => {
                            this.dialog = el;
                        }}
                    />
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {this.state.snippet.name}{' '}
                                    {rel === 'player' ? (
                                        <small>
                                            {this.state.player_name}{' '}
                                            <small
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {this.state.organisation_name}
                                            </small>
                                        </small>
                                    ) : (
                                        <small>
                                            {this.state.organisation_name}
                                        </small>
                                    )}
                                    {this.state.userIsAuthorized(user, [
                                        'staff_super_admin',
                                        'staff_admin',
                                        'organisation_admin',
                                        'organisation_user'
                                    ]) ? (
                                        <Link
                                            id="editButton"
                                            className="btn btn-primary"
                                            to={createLink}
                                        >
                                            <em className="fas fa-plus" />{' '}
                                            {t('TextSnippet.add_text')}
                                        </Link>
                                    ) : null}
                                    {rel === 'player' ? (
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-primary"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fa fa-font" />{' '}
                                                {t('TextSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}

                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fa fa-font" />{' '}
                                                {t('TextSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-font" />{' '}
                                                {t('TextSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>
                                <DismissableAlert
                                    title={this.state.error.title}
                                    message={this.state.error.message}
                                    hidden={this.state.error.hidden}
                                    style={this.state.error.style}
                                />
                                <PlainPanel>
                                    <TextSnippetTextsTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        uuid={uuid}
                                        rel={rel}
                                        texts={this.state.texts}
                                        snippet={this.state.snippet}
                                        playerUUID={playerUUID}
                                        pageUUID={pageUUID}
                                        onDelete={this.deleteText.bind(this)}
                                        onDragEnd={this.reorderTexts.bind(this)}
                                        onEnabledChanged={this.enabledChanged.bind(
                                            this
                                        )}
                                    />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(TextSnippet);
