import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import MediaSnippetMediaLibrariesTable from '../components/MediaSnippetMediaLibrariesTable';
import PaginationPanel from '../components/PaginationPanel';
import DismissableAlert from '../components/DismissableAlert';
import MediaLibraryPickerModal from '../components/MediaLibraryPickerModal';
import config from '../config';
import qs from 'qs';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    DropdownButton,
    MenuItem,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog';
import { Link } from 'react-router-dom';
import reorder from '../helpers/reorder';

import { translate } from 'react-i18next';

import './MediaSnippet.css';

class MediaSnippet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            snippet: { name: '' },
            userIsAuthorized: props.userIsAuthorized,
            medialibraries: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            },
            showingModal: false
        };
    }

    saveMediaLibrary(medialibrary, newName) {
        var self = this;
        axios
            .put(`${config.baseURL}/medialibraries/${medialibrary.uuid}`, {
                name: newName
            })
            .then(function(response) {
                self.loadMediaSnippetMediaLibrariesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url); 

        event.preventDefault();
        this.loadMediaSnippetMediaLibrariesFromServer();
    };

    componentDidMount() {
        console.log('componentDidMount');
        this.loadMediaSnippetFromServer();
        this.loadMediaSnippetMediaLibrariesFromServer();
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadMediaSnippetMediaLibrariesFromServer() {
        var self = this;
        const { snippetUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}/medialibraries`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    medialibraries: response.data.mediaLibraries,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    reorderMediaLibraries(result) {
        const { snippetUUID } = this.props.match.params;

        const reorderedMediaLibraries = reorder(
            this.state.medialibraries,
            result.source.index,
            result.destination.index
        );
        this.setState({
            medialibraries: reorderedMediaLibraries
        });

        const ordering = [];
        for (var i = 0; i < reorderedMediaLibraries.length; i++) {
            const snippet = reorderedMediaLibraries[i];

            let orderingObject = {
                mediaLibraryUUID: snippet.uuid,
                id: snippet.id,
                position: i
            };

            ordering.push(orderingObject);
        }

        try {
            var self = this;
            axios
                .post(
                    `${
                        config.baseURL
                    }/snippets/${snippetUUID}/medialibraries/reorder `,
                    { ordering }
                )
                .then(function(response) {
                    console.log('Success!');
                })
                .catch(function(err) {
                    console.log(err);
                });
        } catch (e) {
            console.log('exception');
        }
    }

    didClickAdd(newName) {
        const { t } = this.props;
        const { uuid, snippetUUID } = this.props.match.params;

        try {
            let self = this;
            axios
                .post(
                    `${config.baseURL}/organisations/${uuid}/medialibraries`,
                    {
                        name: newName
                    }
                )
                .then(function(response) {
                    // Add MediaLibrary to the snippet
                    return axios.post(
                        `${
                            config.baseURL
                        }/snippets/${snippetUUID}/medialibraries`,
                        {
                            mediaLibraryUUIDs: [response.data.medialibrary.uuid]
                        }
                    );
                })
                .then(function(response) {
                    self.setState({
                        error: {
                            hidden: false,
                            title: `${t('CreateMediaLibrary.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreateMediaLibrary.created_message'
                            )}`
                        }
                    });

                    self.loadMediaSnippetMediaLibrariesFromServer();
                })
                .catch(function(err) {
                    console.log(
                        `Error: ${JSON.stringify(err.response.data.message)}`
                    );
                    self.setState({
                        error: {
                            hidden: false,
                            title: `${t('CreateMediaLibrary.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                error: {
                    hidden: false,
                    title: `${t('CreateMediaLibrary.error')}:`,
                    style: 'danger',
                    message: `${t('CreateMediaLibrary.unknown_error')}`
                }
            });
        }
    }

    deleteMediaLibrary(medialibrary) {
        const { snippetUUID } = this.props.match.params;

        var self = this;
        axios
            .delete(
                `${config.baseURL}/snippets/${snippetUUID}/medialibraries/${
                    medialibrary.uuid
                }/${medialibrary.id}`
            )
            .then(function(response) {
                self.loadMediaSnippetMediaLibrariesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleChooseGlobalClicked() {
        this.setState({ showingModal: true });
    }

    hideModal() {
        this.setState({ showingModal: false });
    }

    didPickMediaLibraries(items) {
        const { t } = this.props;

        this.setState({ showingModal: false });
        const { uuid, snippetUUID } = this.props.match.params;

        console.log(`Selected Items: ${JSON.stringify(items)}`);

        let self = this;
        axios
            .post(`${config.baseURL}/snippets/${snippetUUID}/medialibraries`, {
                mediaLibraryUUIDs: items
            })
            .then(function(response) {
                self.setState({
                    error: {
                        hidden: false,
                        title: `${t('CreateMediaLibrary.success')}:`,
                        style: 'success',
                        message: `${t('CreateMediaLibrary.created_message')}`
                    }
                });

                self.loadMediaSnippetMediaLibrariesFromServer();
            })
            .catch(function(err) {
                console.log(
                    `Error: ${JSON.stringify(err.response.data.message)}`
                );
                self.setState({
                    error: {
                        hidden: false,
                        title: `${t('CreateMediaLibrary.error')}:`,
                        style: 'danger',
                        message: err.response.data.message
                    }
                });
            });
    }

    handleCreateClicked() {
        const { t } = this.props;

        this.dialog.show({
            body: t('OrganisationMediaLibrariesTable.medialibrary_name'),
            prompt: Dialog.TextPrompt({
                placeholder: t('OrganisationMediaLibrariesTable.name'),
                initialValue: ''
            }),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('MediaSnippet.add_medialibrary'),
                    dialog => this.didClickAdd(dialog.value),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadMediaSnippetFromServer() {
        var self = this;
        const { uuid, snippetUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}`, {})
            .then(function(response) {
                self.setState({
                    snippet: response.data.snippet
                });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {

            this.loadMediaSnippetMediaLibrariesFromServer();
        });
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'MediaSnippet.title'
        )}`;

        const user = this.state.user;
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        return (
            <div className="MediaSnippet">
                <div id="wrapper">
                    <Dialog
                        ref={el => {
                            this.dialog = el;
                        }}
                    />
                    <Navigation props={this.props} />
                    <MediaLibraryPickerModal
                        show={this.state.showingModal}
                        onHide={this.hideModal.bind(this)}
                        organisationUUID={uuid}
                        onChoose={this.didPickMediaLibraries.bind(this)}
                    />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {this.state.snippet.name}{' '}
                                    {rel === 'player' ? (
                                        <small>
                                            {this.state.player_name}{' '}
                                            <small
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {this.state.organisation_name}
                                            </small>
                                        </small>
                                    ) : (
                                        <small>
                                            {this.state.organisation_name}
                                        </small>
                                    )}
                                    {this.state.userIsAuthorized(user, [
                                        'staff_super_admin',
                                        'staff_admin',
                                        'organisation_admin',
                                        'organisation_user'
                                    ]) ? (
                                        <DropdownButton
                                            bsStyle={'primary'}
                                            title={
                                                <span>
                                                    <em className="fa fa-plus fa-fw" />{' '}
                                                    {t(
                                                        'MediaSnippet.add_medialibrary'
                                                    )}
                                                </span>
                                            }
                                        >
                                            <MenuItem
                                                eventKey="1"
                                                onClick={() =>
                                                    this.handleCreateClicked()
                                                }
                                            >
                                                {t('MediaSnippet.create_new')}
                                            </MenuItem>
                                            <MenuItem
                                                eventKey="2"
                                                onClick={() =>
                                                    this.handleChooseGlobalClicked()
                                                }
                                            >
                                                {t(
                                                    'MediaSnippet.choose_existing'
                                                )}
                                            </MenuItem>
                                        </DropdownButton>
                                    ) : null}
                                    {rel === 'player' ? (
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-primary"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fas fa-images" />{' '}
                                                {t('MediaSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fas fa-images" />{' '}
                                                {t('MediaSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}

                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fas fa-images" />{' '}
                                                {t('MediaSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>
                                <DismissableAlert
                                    title={this.state.error.title}
                                    message={this.state.error.message}
                                    hidden={this.state.error.hidden}
                                    style={this.state.error.style}
                                />
                                <PaginationPanel
                                    heading={
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <form
                                                    role="search"
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    <FormGroup controlId="search">
                                                        <InputGroup>
                                                            <FormControl
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .search
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                placeholder={t(
                                                                    'Search'
                                                                )}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    onClick={
                                                                        this
                                                                            .handleSubmit
                                                                    }
                                                                >
                                                                    <i className="glyphicon glyphicon-search" />
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </form>
                                            </Col>
                                        </Row>
                                    }
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'medialibraries pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <MediaSnippetMediaLibrariesTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        uuid={uuid}
                                        rel={rel}
                                        medialibraries={
                                            this.state.medialibraries
                                        }
                                        snippet={this.state.snippet}
                                        playerUUID={playerUUID}
                                        pageUUID={pageUUID}
                                        onSave={this.saveMediaLibrary.bind(
                                            this
                                        )}
                                        onDelete={this.deleteMediaLibrary.bind(
                                            this
                                        )}
                                        onDragEnd={this.reorderMediaLibraries.bind(
                                            this
                                        )}
                                    />
                                </PaginationPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(MediaSnippet);
