import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateMediaRuleForm from '../components/CreateMediaRuleForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';
import qs from 'qs';

import './CreateMediaRule.css';

class CreateMediaRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ruleTypes: [],
            attributes: [],
            rulegroup: { uuid: '' },
            media: { name: '', uuid: '' }
        };
    }

    componentDidMount() {
        this.loadMediaFromServer();
        this.loadRuleGroupFromServer();
        this.loadOrganisationAttributesFromServer();
    }

    loadMediaFromServer() {
        const { mediaUUID } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/media/${mediaUUID}`, {})
            .then(function(response) {
                self.setState({ media: response.data.media });
                self.loadMediaLibraryFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadMediaLibraryFromServer() {
        const {
            mediaLibraryUUID,
        } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/medialibraries/${mediaLibraryUUID}`, {})
            .then(function(response) {
                self.setState({ medialibrary: response.data.medialibrary });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadRuleGroupFromServer() {
        const { mediaUUID } = this.props.match.params;
        const { ruleGroupUUID } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/media/${mediaUUID}/rulegroups`, {})
            .then(function(response) {
                if (
                    response.data.rulegroups &&
                    response.data.rulegroups.length > 0
                ) {

                    const rulegroups = response.data.rulegroups;

                    const result = rulegroups.filter(rulegroup => rulegroup.uuid === ruleGroupUUID);

                    self.setState(
                        { rulegroup: result[0] },
                        () => {
                            self.loadRuleTypesFromServer();
                        }
                    );
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationAttributesFromServer() {
        const {
            uuid,
            mediaLibraryUUID,
            playerUUID,
            pageUUID,
            snippetUUID,
            mediaUUID
        } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}/attributes`, {
                params: {
                    all: true
                }
            })
            .then(function(response) {
                self.setState({
                    attributes: response.data.attributes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadRuleTypesFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/ruletypes`, {
                params: {
                    all: true
                }
            })
            .then(function(response) {
                self.setState({
                    ruleTypes: response.data.ruleTypes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreateMediaRule.title'
        )}`;

        const {
            uuid,
            mediaLibraryUUID,
            playerUUID,
            pageUUID,
            snippetUUID,
            mediaUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        return (
            <div className="CreateMediaRule">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreateMediaRule.title')}
                                    {' - '}
                                    {this.state.media.name}{' '}
                                    {rel === 'player' ? (
                                        <small>
                                            {this.state.player_name}{' '}
                                            <small
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {this.state.organisation_name}
                                            </small>
                                        </small>
                                    ) : (
                                        <small>
                                            {this.state.organisation_name}
                                        </small>
                                    )}
                                </h1>
                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}?rel=player`}
                                                >
                                                    {t('MediaSnippet.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}?rel=player`}
                                                >
                                                    {t(
                                                        'MediaSnippetMediaLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-clipboard-list" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${mediaUUID}/rules?rel=player`}
                                                >
                                                    {t('MediaRules.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMediaRule.title')}
                                            </li>{' '}
                                        </Fragment>
                                    ) : null}
                                    {rel === 'library' ? (
                                        <Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/medialibraries?rel=library`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalmedialibraries'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/medialibraries/${mediaLibraryUUID}?rel=library`}
                                                >
                                                    {t(
                                                        'MediaSnippetMediaLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-clipboard-list" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/medialibraries/${mediaLibraryUUID}/media/${mediaUUID}/rules?rel=library`}
                                                >
                                                    {t('MediaRules.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMediaRule.title')}
                                            </li>{' '}
                                        </Fragment>
                                    ) : null}

                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/mediasnippets/${snippetUUID}?rel=snippet`}
                                                >
                                                    {t('MediaSnippet.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}?rel=snippet`}
                                                >
                                                    {t(
                                                        'MediaSnippetMediaLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-clipboard-list" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${mediaUUID}/rules?rel=snippet`}
                                                >
                                                    {t('MediaRules.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMediaRule.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}

                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}?rel=page`}
                                                >
                                                    {t('MediaSnippet.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}?rel=page`}
                                                >
                                                    {t(
                                                        'MediaSnippetMediaLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-clipboard-list" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}/media/${mediaUUID}/rules?rel=page`}
                                                >
                                                    {t('MediaRules.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMediaRule.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>

                                <PlainPanel>
                                    <CreateMediaRuleForm
                                        uuid={uuid}
                                        ruleTypes={this.state.ruleTypes}
                                        attributes={this.state.attributes}
                                        ruleGroupUUID={
                                            this.state.rulegroup.uuid
                                        }
                                        mediaUUID={mediaUUID}
                                    />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateMediaRule);
