import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

class OTDTable extends Component {
    didConfirmDelete(otdFact) {
        this.props.onDelete(otdFact);
    }

    handleDeleteClicked(otdFact) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(otdFact),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t } = this.props;

        let otdRows = this.props.otds.map((otd, index) => {
            return (
                <tr key={index} height="50">
                    <td width="40">{otd.id}</td>
                    <td width="75">
                        <a
                            href={`https://images.cloudsignage.me/${
                                otd.image_url
                            }`}
                            className="thumbnail"
                            target="_blank"
                        >
                            <img
                                src={`https://images.cloudsignage.me/${
                                    otd.image_url
                                }`}
                                height="75"
                                width="75"
                            />
                        </a>
                    </td>
                    <td width="75">{otd.day}</td>
                    <td width="75">{otd.month}</td>
                    <td width="75">{Moment(otd.date).format('YYYY')}</td>
                    <td>{otd.description}</td>
                    <td align="center">
                        <TooltipLink
                            id="editButton"
                            className="btn btn-default"
                            tooltip={t('OTDTable.editotd')}
                            to={`/otd/${otd.id}/edit`}
                        >
                            <em className="fas fa-pencil-alt" />
                        </TooltipLink>
                        {this.props.userIsAuthorized(this.props.user, [
                            'staff_super_admin'
                        ]) ? (
                            <TooltipButton
                                id="deleteButton"
                                className="btn-danger pull-right"
                                tooltip={t('OTDTable.deleteotd')}
                                onClick={() => this.handleDeleteClicked(otd)}
                            >
                                <em className="fa fa-trash" />
                            </TooltipButton>
                        ) : null}
                    </td>
                </tr>
            );
        });

        return (
            <div id="otds">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('OTDTable.image')}</th>
                            <th>{t('OTDTable.day')}</th>
                            <th>{t('OTDTable.month')}</th>
                            <th>{t('OTDTable.date')}</th>
                            <th>{t('OTDTable.description')}</th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{otdRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(OTDTable);
