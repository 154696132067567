import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditOrganisationForm from '../components/EditOrganisationForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import './EditOrganisation.css';

class EditOrganisation extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = { organisation: null, uuid };
    }

    componentDidMount() {
        this.loadOrganisationFromServer();
    }

    loadOrganisationFromServer() {
        const { uuid } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({ organisation: response.data.organisation });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t('EditOrganisation.title')}`;

        return (
            <div className="EditOrganisation">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">{t('EditOrganisation.title')}</h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-briefcase" />{' '}
                                        <Link to="/organisations">{t('Organisations.title')}</Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-edit" /> {t('EditOrganisation.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <EditOrganisationForm organisation={this.state.organisation} />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditOrganisation);
