import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateQuestionTypeForm from '../components/CreateQuestionTypeForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import './CreateQuestionType.css';

class CreateQuestionType extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreateQuestionType.title'
        )}`;

        return (
            <div className="CreateQuestionType">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreateQuestionType.title')}
                                </h1>

                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-info" />{' '}
                                        <Link to={`/qanda`}>
                                            {t('QAndA.title')}
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-list" />{' '}
                                        <Link to={`/qanda/questiontypes`}>
                                            {t('QuestionTypes.title')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-plus" />{' '}
                                        {t('CreateQuestionType.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <CreateQuestionTypeForm />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateQuestionType);
