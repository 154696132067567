import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import { CompactPicker } from 'react-color';

import './CreateGlobalPageForm.css';

class CreateGlobalPageForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPage: null,
            isLoading: false,
            name: '',
            fontFamily: '',
            width: 1920,
            height: 1080,
            textColour: '#000000',
            backgroundColour: '#ffffff',
            organisationUUID: this.props.uuid,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.name.length > 0;
    }

    handleTextColourChangeComplete = color => {
        // update state
        this.setState({
            textColour: color.hex
        });
    };
    handleBackgroundColourChangeComplete = color => {
        this.setState({
            backgroundColour: color.hex
        });
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        let pageProperties = {
            name: this.state.name,
            width: this.state.width,
            height: this.state.height,
            backgroundColour: this.state.backgroundColour,
            textColour: this.state.textColour,
            fontFamily: this.state.fontFamily
        };

        try {
            let self = this;
            axios
                .post(
                    `${config.baseURL}/organisations/${
                        this.state.organisationUUID
                    }/pages`,
                    pageProperties
                )

                .then(function(response) {
                    self.setState({
                        newPage: response.data.page,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateGlobalPageForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreateGlobalPageForm.created_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    console.log(
                        `Error: ${JSON.stringify(err.response.data.message)}`
                    );
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateGlobalPageForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateGlobalPageForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateGlobalPageForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createPageForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateGlobalPageForm.name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateGlobalPageForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="width" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateGlobalPageForm.width')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fas fa-ruler-horizontal" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.width}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreateGlobalPageForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="height" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateGlobalPageForm.height')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fas fa-ruler-vertical" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.height}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreateGlobalPageForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="fontFamily" bsSize="sm">
                                <label>
                                    {t('CreateGlobalPageForm.fontFamily')}
                                </label>
                                <FormControl
                                    type="text"
                                    value={this.state.fontFamily}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateGlobalPageForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="backgroundColour" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateGlobalPageForm.backgroundColour')}
                                </ControlLabel>
                                <InputGroup>
                                    <CompactPicker
                                        color={this.state.backgroundColour}
                                        onChangeComplete={
                                            this
                                                .handleBackgroundColourChangeComplete
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="textColour" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateGlobalPageForm.textColour')}
                                </ControlLabel>
                                <InputGroup>
                                    <CompactPicker
                                        color={this.state.textColour}
                                        onChangeComplete={
                                            this.handleTextColourChangeComplete
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'CreateGlobalPageForm.add_page'
                                    )}`}
                                    loadingText={`${t(
                                        'CreateGlobalPageForm.adding_page'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreateGlobalPageForm">
                {this.state.newPage === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreateGlobalPageForm);
