import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import Moment from 'moment';
import { translate } from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';

import TooltipLink from './TooltipLink';
import TooltipButton from './TooltipButton';

import './OrganisationTable.css';

class OrganisationTable extends Component {
    constructor(props) {
        super(props);

        const childProps = this.props.childProps;

        this.state = {
            currentOrganisation: childProps.currentOrganisation,
            changeOrganisation: childProps.changeOrganisation
        };
    }

    didConfirmDeactivate(organisation) {
        this.props.onDeactivate(organisation);
    }

    handleDeactivateClicked(organisation) {
        const { t } = this.props;


        const listedOrganisation = (
			<li>
				<strong>{organisation.name}</strong>
			</li>
		);

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_deactivate')}
					{listedOrganisation}
				</div>
			),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.deactivate'),
                    () => this.didConfirmDeactivate(organisation),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    handleChangeOrganisation(organisation) {
        this.state.changeOrganisation(organisation);
        this.props.history.push(`/organisations/${organisation.uuid}`);
    }

    render() {
        const { t } = this.props;

        let organisationRows = this.props.organisations.map(
            (organisation, index) => {
                return (
                    <tr key={index}>
                        <td className="hidden-xs">
                            <span className="label label-success">
                                {t('OrganisationTable.active')}
                            </span>
                        </td>
                        <td>
                            <Link
                                to="#"
                                style={{
                                    alignItems: "center",
                                    textAlign: "left"
                                  }}
                                 className="label label-primary"
                                onClick={() =>
                                    this.handleChangeOrganisation(organisation)
                                }
                            >
                                {organisation.name}
                            </Link>
                        </td>
                        <td className="hidden-xs  hidden-sm">
                            {organisation.email}
                        </td>
                        <td className="hidden-xs  hidden-sm">
                            {organisation.phone}
                        </td>
                        <td className="hidden-xs">
                            {Moment(organisation.updatedAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}
                        </td>
                        <td>
                            {Moment(organisation.createdAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}
                        </td>
                        <td align="center">
                            <TooltipLink
                                id="editButton"
                                className="btn btn-default"
                                tooltip={t(
                                    'OrganisationTable.editorganisation'
                                )}
                                to={
                                    '/organisations/' +
                                    organisation.uuid +
                                    '/edit'
                                }
                            >
                                <em className="fas fa-pencil-alt" />
                            </TooltipLink>
                            <TooltipButton
                                id="deleteButton"
                                className="btn-danger pull-right"
                                tooltip={t('OrganisationTable.deactivateorganisation')}
                                onClick={() => this.handleDeactivateClicked(organisation)}
                            >
                                <em className="fa fa-trash" />
                            </TooltipButton>
                        </td>
                    </tr>
                );
            }
        );

        return (
            <div id="organisations">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th className="hidden-xs">
                                {t('OrganisationTable.status')}
                            </th>
                            <th>{t('OrganisationTable.name')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('OrganisationTable.email')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('OrganisationTable.phone')}
                            </th>
                            <th className="hidden-xs">
                                {t('OrganisationTable.updated')}
                            </th>
                            <th>{t('OrganisationTable.created')}</th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{organisationRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(withRouter(OrganisationTable));
