import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditOrganisationNotificationEmailRuleForm from '../components/EditOrganisationNotificationEmailRuleForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditOrganisationNotificationEmailRule.css';

class EditOrganisationNotificationEmailRule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rule: { description: '', type_id: 0 },
            rulegroup: { uuid: '' },
            ruleTypes: [],
            attributes: []
        };
    }

    componentDidMount() {
        this.loadNotificationRuleGroupsFromServer();
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadNotificationRuleGroupsFromServer() {
        const { textUUID, uuid, emailId } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}/emails/${emailId}/rulegroups`, {})
            .then(function(response) {
                if (
                    response.data.rulegroups &&
                    response.data.rulegroups.length > 0
                ) {
                    self.setState(
                        { rulegroup: response.data.rulegroups[0] },
                        () => {
                            self.loadRuleTypesFromServer();
                        }
                    );
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationAttributesFromServer() {
        const {
            uuid
        } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}/attributes`, {
                params: {
                    all: true,
                    emailAttribute: true
                }
            })
            .then(function(response) {
                self.setState({
                    attributes: response.data.attributes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadRuleTypesFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/ruletypes`, {
                params: {
                    all: true
                }
            })
            .then(function(response) {
                self.setState({
                    ruleTypes: response.data.ruleTypes
                });
                self.loadRuleFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadRuleFromServer() {
        const { ruleUUID, uuid, emailId } = this.props.match.params;

        var self = this;
        axios
            .get(
                `${config.baseURL}/organisations/${uuid}/emails/${emailId}/rulegroups/${
                    this.state.rulegroup.uuid
                }/rules/${ruleUUID}`,
                {}
            )

            .then(function(response) {
                self.setState({ rule: response.data.rule });
                self.loadOrganisationAttributesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const {
            uuid,
            emailId
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'EditOrganisationNotificationEmailRule.title'
        )}`;

        return (
            <div className="EditOrganisationNotificationEmailRule">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('EditOrganisationNotificationEmailRule.title')}
                                </h1>
                                <ol className="breadcrumb">

                                <React.Fragment>
                                       <li>
                                           <i className="fa fa-tachometer-alt" />{' '}
                                           <Link
                                               to={`/organisations/${uuid}`}
                                           >
                                               {t('Dashboard.title')}
                                           </Link>
                                       </li>
                                       <li>
                                           <i className="fa fa-cog" />{' '}
                                           <Link
                                               to={`/organisations/${uuid}/settings`}
                                           >
                                               {t(
                                                   'OrganisationSettings.title'
                                               )}
                                           </Link>
                                       </li>

                                       <li className="active">
                                           <i className="fas fa-clipboard-list" />{' '}
                                           <Link
                                               to={`/organisations/${uuid}/emails/${emailId}/rules`}
                                           >
                                               {t(
                                                   'OrganisationNotificationEmailRules.title'
                                               )}
                                           </Link>
                                       </li>{' '}
                                       <li className="active">
                                                <i className="fas fa-pencil-alt" />{' '}
                                                {t('EditOrganisationNotificationEmailRule.title')}
                                            </li>{' '}
                                   </React.Fragment>

                                </ol>

                                <PlainPanel>
                                    <EditOrganisationNotificationEmailRuleForm
                                        rule={this.state.rule}
                                        ruleTypes={this.state.ruleTypes}
                                        attributes={this.state.attributes}
                                        emailId={emailId}
                                        organisationUUID={uuid}
                                        ruleGroupUUID={
                                            this.state.rulegroup.uuid
                                        }
                                    />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditOrganisationNotificationEmailRule);