import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import { Link } from 'react-router-dom';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

Moment.locale('en');

class OrganisationSnippetsTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    didConfirmDelete(snippet) {
        this.props.onDelete(snippet);
    }

    handleDeleteClicked(snippet) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(snippet),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t, uuid, playerUUID, pageUUID } = this.props;
        let snippetRows = this.props.snippets.map((snippet, index) => {
            let iconName = 'fa fa-puzzle-piece';
            let snippetLink = `/organisations/${uuid}/snippets/${
                snippet.uuid
            }/edit?rel=snippet`;

            let rulesLink = `/organisations/${uuid}/snippets/${
                snippet.uuid
            }/rules?rel=snippet`;

            if (snippet.typeID == 1) {
                iconName = 'fas fa-images';
                snippetLink = `/organisations/${uuid}/mediasnippets/${
                    snippet.uuid
                }?rel=snippet`;
            } else if (snippet.typeID == 2) {
                iconName = 'far fa-calendar-alt';
            } else if (snippet.typeID == 3) {
                iconName = 'fa fa-info';
                snippetLink = `/organisations/${uuid}/factsnippets/${
                    snippet.uuid
                }?rel=snippet`;
            } else if (snippet.typeID == 4) {
                iconName = 'fa fa-code';
            } else if (snippet.typeID == 5) {
                iconName = 'fa fa-font';
                snippetLink = `/organisations/${uuid}/textsnippets/${
                    snippet.uuid
                }?rel=snippet`;
            } else if (snippet.typeID == 6) {
                iconName = 'fa fa-question';
                snippetLink = `/organisations/${uuid}/qandasnippets/${
                    snippet.uuid
                }?rel=snippet`;
            } else if (snippet.typeID == 7) {
                iconName = 'fa fa-rss';
            } else if (snippet.typeID == 8) {
                iconName = 'fa fa-sun';
            } else if (snippet.typeID == 9) {
                iconName = 'fa fa-calendar';
            } else if (snippet.typeID == 10) {
                iconName = 'fa fa-clock';
            } else if (snippet.typeID == 11) {
                iconName = 'fa fa-clock';
            } else if (snippet.typeID == 12) {
                iconName = 'fa fa-sun';
            } else if (snippet.typeID == 13) {
                iconName = 'fa fa-tv';
            } else if (snippet.typeID == 15) {
                iconName = 'fa fa-puzzle-piece';
                snippetLink = `/organisations/${uuid}/templatesnippets/${
                    snippet.uuid
                }?rel=snippet`;
            } else if (snippet.typeID == 16) {
                iconName = 'fa fa-calendar';
            } else if (snippet.typeID == 17) {
                iconName = 'fa fa-font';
                snippetLink = `/organisations/${uuid}/textlibrarysnippets/${
                    snippet.uuid
                }?rel=snippet`;
            }

            return (
                <tr key={index}>
                    <td width="250">
                        <Link
                           style={{
                            alignItems: "center",
                            textAlign: "left"
                          }}
                         className="label label-primary"
                        to={snippetLink}>
                            <i className={`${iconName}`} /> {snippet.name}
                        </Link>
                    </td>
                    <td width="80" className="hidden-xs hidden-sm">
                        {snippet.type}
                    </td>

                    <td width="80">
                        <span className="label label-default">
                            {snippet.width}
                        </span>
                    </td>
                    <td width="80">
                        <span className="label label-default">
                            {snippet.height}
                        </span>
                    </td>
                    <td width="150" className="hidden-xs hidden-sm">
                        {Moment(snippet.createdAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs hidden-sm">
                        {Moment(snippet.updatedAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td width="145" align="center">
                        <div className="btn-toolbar pull-right">
                            <TooltipLink
                                    id="rulesBtn"
                                    className={
                                        snippet.hasRules
                                            ? 'btn btn-primary'
                                            : 'btn btn-default'
                                    }
                                    tooltip={t(
                                        'OrganisationSnippetsTable.rules'
                                    )}
                                    to={rulesLink}
                                >
                                    <em className="fas fa-clipboard-list" />
                                </TooltipLink>
                            {this.props.userIsAuthorized(this.props.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin'
                            ]) ? (
                                <Fragment>
                                    <TooltipLink
                                        id="editBtn"
                                        className="btn btn-default"
                                        tooltip={t(
                                            'OrganisationSnippetsTable.editsnippet'
                                        )}
                                        to={`/organisations/${uuid}/snippets/${
                                            snippet.uuid
                                        }/edit?rel=snippet`}
                                    >
                                        <em className="fas fa-pencil-alt" />
                                    </TooltipLink>

                                    <TooltipButton
                                        id="deleteBtn"
                                        className="btn-danger"
                                        onClick={() =>
                                            this.handleDeleteClicked(snippet)
                                        }
                                        tooltip={t(
                                            'OrganisationSnippetsTable.deletesnippet'
                                        )}
                                    >
                                        <em className="fa fa-trash" />
                                    </TooltipButton>
                                </Fragment>
                            ) : null}
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div
                id="OrganisationSnippetsTable"
                className="OrganisationSnippetsTable"
            >
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th width="250">
                                {t('OrganisationSnippetsTable.name')}
                            </th>
                            <th width="80" className="hidden-xs hidden-sm">
                                {t('OrganisationSnippetsTable.type')}
                            </th>

                            <th width="80">
                                {t('OrganisationSnippetsTable.width')}
                            </th>
                            <th width="80">
                                {t('OrganisationSnippetsTable.height')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('OrganisationSnippetsTable.updated')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('OrganisationSnippetsTable.created')}
                            </th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{snippetRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(OrganisationSnippetsTable);
