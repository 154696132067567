import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateOTDForm from '../components/CreateOTDForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import './CreateOTD.css';

class CreateOTD extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t('CreateOTD.title')}`;

        return (
            <div className="CreateOTD">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">{t('CreateOTD.title')}</h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="far fa-calendar-alt" /> <Link to={`/otd`}>{t('OTD.title')}</Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-plus" /> {t('CreateOTD.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <CreateOTDForm />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateOTD);
