import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { translate } from 'react-i18next';
import Navigation from '../components/Navigation';
import QAndATable from '../components/QAndATable';
import PaginationPanel from '../components/PaginationPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';

import './QAndA.css';

class QAndA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1
        };
    }

    deleteQuestion(question) {
        var self = this;
        axios
            .delete(`${config.baseURL}/questions/${question.id}`)
            .then(function(response) {
                self.loadQAndAFromServer()();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.loadQAndAFromServer();
    }

    loadQAndAFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/questions`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    questions: response.data.questions,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {
            this.loadQAndAFromServer();
        });
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url);

        event.preventDefault();
        this.loadQAndAFromServer();
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'QAndA.title'
        )}`;

        return (
            <div className="QAndA">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('QAndA.title')}

                                    <div className="btn-toolbar pull-right">
                                        <Link
                                            type="submit"
                                            className="btn btn-primary"
                                            to="/qanda/questiontypes"
                                        >
                                            <em className="fa fa-list fa-fw" />{' '}
                                            {t('QAndA.question_types')}
                                        </Link>
                                        <Link
                                            type="submit"
                                            className="btn btn-primary"
                                            to="/qanda/create"
                                        >
                                            <em className="fa fa-plus fa-fw" />{' '}
                                            {t('QAndA.add_question')}
                                        </Link>
                                    </div>
                                </h1>

                                <ol className="breadcrumb">
                                    <li className="active">
                                        <i className="fa fa-question" />{' '}
                                        {t('QAndA.title')}
                                    </li>
                                </ol>

                                <PaginationPanel
                                    heading={
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <form
                                                    role="search"
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    <FormGroup controlId="search">
                                                        <InputGroup>
                                                            <FormControl
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .search
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                placeholder={t(
                                                                    'Search'
                                                                )}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    onClick={
                                                                        this
                                                                            .handleSubmit
                                                                    }
                                                                >
                                                                    <i className="glyphicon glyphicon-search" />
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </form>
                                            </Col>
                                        </Row>
                                    }
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={3}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <QAndATable
                                        questions={this.state.questions}
                                        onDelete={this.deleteQuestion.bind(
                                            this
                                        )}
                                    />
                                </PaginationPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default translate('translations')(QAndA);
