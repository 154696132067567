import React, { Component } from 'react';
import { Rnd } from 'react-rnd';

export class SnippetRichText extends Component {
	constructor(props) {
		super(props);

		this.state = {
			content: this.props.snippet.content,
			caretPos: this.props.caretPos
		};
	}

	/**
	 * @desc Adds click event to the canvas and assigns the handleClick method
	 */
	componentWillMount() {
		document.getElementById('template-snippet-canvas-wrapper').addEventListener('mousedown', this.handleClick, false);
	}

	/**
	 * @desc Applies focus to the Text Snippet, clears and replaces its content, then sets the caret position to the end of the content string
	 */
	componentDidMount() {
		const { content, caretPos } = this.state;

		this.textInput.focus();
		this.textInput.value = '';
		this.textInput.value = content;
		this.textInput.setSelectionRange(caretPos, caretPos);
	}

	/**
	 * @desc Removes event listener when component is unmounted
	 */
	componentWillUnmount() {
		document.getElementById('template-snippet-canvas-wrapper').removeEventListener('mousedown', this.handleClick, false);
	}

	/**
	 * @name handleClick
	 * @desc If target is a snippet, set snippet to 'Selected', otherwise clear the 'selected snippet'
	 */
	handleClick = (e, id) => {
		if (this.snippetRef.contains(e.target)) {
			this.props.selectSnippet(e, id);
			return;
		}

		this.props.deselectSnippet();
	};

	render() {
		const { snippet, zoom, contentChange, handleMove, handleDragStop, selectSnippet, currentSnippet, handleResize, gridValue, previewEnabled } = this.props;

		// Style for textbox snippet
		const dragItemStyle = {
			position: 'absolute',
			padding: 0,
			display: 'flex',
			flexDirection: 'row-reverse',
			zIndex: snippet.z,
			width: `${snippet.width}px`,
			height: `${snippet.height}px`,
			border: `2px ${previewEnabled ? 'transparent' : '#ccc'} ${currentSnippet == snippet.uuid ? 'solid' : 'dotted'}`
		};

		// Style for the movement handle
		const handleStyle = {
			zIndex: 999,
			position: 'absolute',
			right: '-15px',
			top: '-15px',
			borderRadius: '50%',
			width: '30px',
			height: '30px',
			display: previewEnabled ? 'none' : 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			background: '#0f385866'
		};

		// Style for the textbox textarea
		const textAreaStyle = {
			position: 'absolute',
			left: 0,
			border: 'none',
			padding: 0,
			background: 'transparent',
			height: '100%',
			width: '100%',
			resize: 'none',
			lineHeight: 1,
			fontSize: `${snippet.fontSize}px`,
			fontWeight: snippet.fontWeight,
			textDecoration: snippet.textDecoration,
			textAlign: snippet.textAlign,
			fontStyle: snippet.fontStyle,
			color: snippet.textColour
		};

		return (
			<div ref={node => (this.snippetRef = node)}>
				<Rnd
					size={{ width: snippet.width, height: snippet.height }}
					position={{ x: snippet.x, y: snippet.y }}
					onDrag={(e, d) => {
						handleMove(e, d, snippet.uuid);
					}}
					onDragStop={(e, d) => {
						handleDragStop(e, d, snippet.uuid);
					}}
					onResize={(e, direction, ref, delta, position, id) => handleResize(e, direction, ref, delta, position, snippet.uuid)}
					enableResizing={{
						top: false,
						right: false,
						bottom: false,
						left: false,
						topRight: false,
						bottomRight: true,
						bottomLeft: false,
						topLeft: false
					}}
					dragHandleClassName='handle'
					dragGrid={[gridValue, gridValue]}
					resizeGrid={[gridValue, gridValue]}
					scale={zoom}
					bounds='#template-snippet-canvas'
				>
					<div className='drag-item' style={dragItemStyle}>
						<div className='handle' style={handleStyle}>
							<i className='text-light fas fa-arrows-alt' style={{ color: '#fff' }} />
						</div>
						{previewEnabled ? (
							''
						) : (
								<svg width={10} height={10} style={{ position: 'absolute', bottom: 3, right: 3 }}>
									<path d='M5 0 L10 0 L10 10 L0 10 L0 5 L5 5 Z' fill='#aaa' />
								</svg>
							)}
						<form className='text-wrapper'>
							<textarea
								id={snippet.uuid}
								ref={input => {
									this.textInput = input;
								}}
								style={textAreaStyle}
								defaultValue={this.state.content}
								onChange={e => contentChange(e, snippet.uuid)}
								onClick={e => selectSnippet(e, snippet.uuid)}
							/>
						</form>
					</div>
				</Rnd>
			</div>
		);
	}
}

export default SnippetRichText;
