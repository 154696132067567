import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import config from '../config';
import { Link } from 'react-router-dom';

import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    ControlLabel,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';

import { translate } from 'react-i18next';

//import './GoogleAuthSuccess.css';

class GoogleAuthSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            organisation_name: '',
            userIsAuthorized: props.userIsAuthorized,
        };
    }

    componentDidMount() {
        this.loadOrganisationInformation();
        this.storeTokenForOrganisation();
    }

    loadOrganisationInformation() {
        var self = this;

        const uuid = this.querystring('state');

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    querystring(name, url = window.location.href) {
        name = name.replace(/[[]]/g, '\\$&');
    
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
        const results = regex.exec(url);
    
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
    
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    storeTokenForOrganisation() {
        
        this.setState({ isLoading: true });

        const authorizationCode = this.querystring('code');

        const uuid = this.querystring('state');
    
        const { t } = this.props;
        
        try {
            var self = this;
            axios
                .post(`${config.baseURL}/organisations/${uuid}/google/auth`, {
                    authorizationCode
                })
                .then(function(response) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateFactForm.success')}:`,
                            style: 'success',
                            message: `${t('CreateFactForm.created_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateFactForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateFactForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateFactForm.unknown_error')}`
                }
            });
        }
    }


    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();
        this.loadGoogleAuthSuccessFromServer();
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'GoogleAuthSuccess.title'
        )}`;

        const user = this.state.user;
        const uuid = this.querystring('state');

        return (
            <div className="GoogleAuthSuccess">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('GoogleAuthSuccess.title')}{' '}
                                    <small>
                                        {this.state.organisation_name}
                                    </small>
                                </h1>

                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>
                                            {t('Dashboard.title')}
                                        </Link>
                                    </li>

                                    <li className="active">
                                        <i className="fa fa-tags" />{' '}
                                        {t('SideNavigation.google_auth')}
                                    </li>
                                </ol>

                                <Panel id="heading-style">
                                    <Panel.Heading>
                                        <Panel.Title>
                                            {t(
                                                'GoogleAuthenticationForm.information'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                        <Panel.Body>
                                            <Row bsClass="form-row">
                                                <Col sm={4}>
                                                    <FormGroup
                                                        controlId="headingFontSize"
                                                        bsSize="sm"
                                                    >
                                                        Successfully Authenticated with Google!
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                        </Panel.Body>
                                </Panel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(GoogleAuthSuccess);
