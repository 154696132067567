import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './PrivacyPolicy.css';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;

        return (
            <div className="PrivacyPolicy">
                <h2>Privacy Policy</h2>
                <br/>
                <div>
  <p>
    We are committed to protecting your privacy. This privacy policy outlines
    how we collect, use, store, and share information in compliance with the
    Data Protection Act 1998, the EU Privacy and Communications Directive, and
    Google API Service: User Data Policy. By using our services, you agree to
    the terms of this policy.
  </p>
    <h3> 1. Information Collection and Use</h3>
  <div></div>
  <p>
    We may collect information from you voluntarily through forms or
    automatically as you visit our website. The information collected may
    include personal data and Google user data if you choose to authenticate
    using Google.
  </p>
  <p><h4>1.1 Google Authentication</h4></p>
  <p>
    If you choose to authenticate using Google, we will access and use your
    Google user data in accordance with this privacy policy and the Limited Use
    Policy.
  </p>
    <div></div>
  <p><h4>2.1 URL and Accessibility</h4></p>
  <p>
    Our privacy policy is hosted and accessible on our publicly accessible
    domain at the following URL:
    <a href="https://www.cloudsignage.me/">cloudsignage.me</a>. The privacy
    policy is accessible from the home page of our app.
  </p>
  <p><h4>2.2 Content</h4></p>
  <p>
    Our privacy policy clearly describes how our application accesses, uses,
    stores, and shares Google user data. It is linked to the OAuth Consent
    Screen on the Google API Console.
  </p>
  <p><h4>2.3 Compliance</h4></p>
  <p>
    We will use Google user data only in the ways described in this published
    privacy policy and in accordance with the Limited Use Policy.
  </p>
  
  <h3> 3. Limited Use Policy Compliance</h3>
    <div></div>
  <p>
    As our application requests access to sensitive scope(s) and to comply with
    the Limited Use Policy, we confirm that:
  </p>
  <p><h4>3.1 Disclosure in Privacy Policy</h4></p>
  <p>
    We have included the following disclosure in our Privacy Policy: MySign’s
    use and transfer of information received from Google APIs to any other app
    will adhere to Google API Services User Data Policy, including the Limited
    Use Requirements.
  </p>
  
  <h3>4. Third-Party Services</h3>
    <div></div>
  <p>
    We use various third-party services, including but not limited to Google
    Analytics, Google Remarketing, LinkedIn Follow button, Facebook Like Button,
    Twitter Follow button, Google +1 button, AddThis social bookmarking tool,
    YouTube videos, Olark LiveChat Services, Zopim LiveChat Services, Piwik
    Analytics, and Macromedia Flash, which may place cookies and collect
    anonymous information to improve the user experience.
  </p>
  
    <h3>5. Log Data</h3>
    <p>
    Like most websites, our server automatically logs data regarding each visit,
    including IP address, browser type, referring/exit pages, and operating
    system. We may use this information for server administration, to monitor
    visitor behavior, and to address any server errors.
  </p>
  
    <h3>6. Links to Other Websites</h3>
    <div></div>
  <p>
    This privacy policy applies only to our website and services. We are not
    responsible for the privacy practices of any external websites that we may
    link to.
  </p>
  
    <h3>7. Contact Us</h3>
    <div></div>
  <p>
    If you have any questions or concerns regarding your privacy or this privacy
    policy, you can contact us at: Mysign Limited, James House Unit 36 Waters
    Meeting Industrial Estate, Britannia Way, Bolton, BL2 2HH, United Kingdom.
  </p>
  <p>
    We appreciate your attention to this matter. If you have any further
    questions or need to confirm our compliance with the Limited Use Policy,
    please contact us directly.
  </p>
  <p>
    Note: This privacy policy is subject to change, and any updates will be
    posted on our website.
  </p>
</div>

            </div>
        );
    }
}

export default translate('translations')(PrivacyPolicy);
