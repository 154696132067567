import React, { Component } from 'react';
import { Row, Col, Form, FormControl } from 'react-bootstrap';

export class TemplateSnippetColorPicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			colorValue: this.props.textColor,
			inputValid: true
		};
	}

	/**
	 * @name getContrastYIQ
	 * @desc Calculates brightness of hexcolor and returns an opposing dark/light text color for colour picker. Found here: https://24ways.org/2010/calculating-color-contrast
	 * @param hexcolor Selected colour of snippet text
	 * @return A dark/light hex value, depending on yiq value
	 */
	getContrastYIQ = hexcolor => {
		hexcolor = hexcolor.replace('#', '');
		var r = parseInt(hexcolor.substr(0, 2), 16);
		var g = parseInt(hexcolor.substr(2, 2), 16);
		var b = parseInt(hexcolor.substr(4, 2), 16);
		var yiq = (r * 299 + g * 587 + b * 114) / 1000;
		return yiq >= 128 ? '#343a40' : '#fff';
	};

	/**
	 * @name handleChange
	 * @desc sets colorValue state to user selected state on input change
	 * @param {event} e event object for passing form value
	 */
	handleChange(e) {
		this.setState({
			colorValue: e.target.value
		});
	}

	/**
	 * @name handleSubmit
	 * @desc Tests the hex input and applies it if it's valid
	 * @param {event} e event object for passing form value
	 */
	handleSubmit(e) {
		var regex = new RegExp('^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
		var testedInput = regex.exec(e.target.value);

		if (!testedInput) {
			this.setState({
				inputValid: false
			});
		} else {
			this.setState({
				inputValid: true
			});
			this.props.setColor(testedInput[0]);
		}
	}

	/**
	 * @name handleColorSelect
	 * @desc Sets the text colour to the click target colour
	 * @param {HEX} color Selected target colour
	 */
	handleColorSelect(color) {
		this.setState({
			colorValue: color
		});

		this.props.setColor(color);
	}

	render() {
		const { setColor, textColor, t } = this.props;

		const formStyle = {
			background: textColor,
			color: this.getContrastYIQ(textColor),
			fontWeight: 700,
			textAlign: 'center',
			borderRadius: '5px',
			border: `2px solid ${this.state.inputValid ? 'transparent' : '#ff4444'}`
		};

		const colorArray = ['#343a40', '#ffffff', '#00B0CC', '#1f9e5a', '#fed766', '#b20e2c'];

		return (
			<Row className='ts-menu-row'>
				<Col className='ts-menu-col p-0' xs={12}>
					<h4>{t('TemplateSnippet.menu_colour')}</h4>
					<Row className='ts-menu-row'>
						<Col className='ts-menu-col p=0' xs={12}>
							<Form
								onSubmit={e => {
									e.preventDefault();
								}}
							>
								<FormControl
									id='color-picker-input'
									type='text'
									pattern='#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'
									title='Please input a valid HEX colour value. E.g: #FF0000'
									value={this.state.colorValue}
									onChange={e => this.handleChange(e)}
									onBlur={e => this.handleSubmit(e)}
									style={formStyle}
								/>
							</Form>
						</Col>
					</Row>
					<Row className='ts-menu-row'>
						{colorArray.map((key, index) => (
							<Col xs={2} className='ts-menu-col p-0' key={index}>
								<button className='color-picker-tile' style={{ background: colorArray[index] }} onClick={() => this.handleColorSelect(colorArray[index])}></button>
							</Col>
						))}
					</Row>
				</Col>
			</Row>
		);
	}
}

export default TemplateSnippetColorPicker;
