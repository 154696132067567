import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button, Modal } from 'react-bootstrap';
import './MediaLibraryPickerModal.css';
import MediaLibraryPickerTable from './MediaLibraryPickerTable.js';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';

class MediaLibraryPickerModal extends Component {
    constructor(props) {
        super(props);

        this.handleHide = this.handleHide.bind(this);

        this.state = {
            show: false,
            title: 'Hello World',
            medialibraries: [],
            page: 1,
            pageCount: 0,
            limit: 10,
            search: '',
            organisationUUID: '',
            excludeIDs: [],
            itemsSelected: []
        };
    }

    handlePageClick = data => {
        let selected = data.selected + 1;
 
        this.setState({ page: selected }, () => {
            this.loadOrganisationMediaLibrariesFromServer();
        });
    };

    handleHide() {
        this.props.onHide();
        this.setState({
            itemsSelected: []
        });
    }

    handleChooseClicked() {
        this.props.onChoose(this.state.itemsSelected);
        this.setState({
            itemsSelected: []
        });
    }

    componentDidMount() {}

    loadOrganisationMediaLibrariesFromServer() {
        var self = this;

        axios
            .get(
                `${config.baseURL}/organisations/${
                    this.state.organisationUUID
                }/medialibraries`,
                {
                    params: {
                        page: self.state.page,
                        limit: self.state.limit,
                        excludeAttribute: self.state.excludeAttribute,
                        search: self.state.search
                    }
                }
            )
            .then(function(response) {
                self.setState({
                    medialibraries: response.data.mediaLibraries,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    componentWillReceiveProps(newProps) {
        this.setState(
            {
                show: newProps.show,
                title: newProps.title,
                organisationUUID: newProps.organisationUUID,
                excludeIDs: newProps.excludeIDs
            },
            () => {
                this.loadOrganisationMediaLibrariesFromServer();
            }
        );
    }

    selectionChanged(medialibrary, checked) {
        let itemsSelected = this.state.itemsSelected;

        if (checked) {
            itemsSelected.push(medialibrary.uuid);
        } else {
            itemsSelected = itemsSelected.filter(
                selectedItem => selectedItem !== medialibrary.uuid
            );
        }

        var selectedSet = new Set(itemsSelected);

        this.setState({
            itemsSelected: [...selectedSet]
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="MediaLibraryPickerModal">
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    container={this}
                    aria-labelledby="contained-modal-title"
                    dialogClassName="MediaLibraryPickerModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">
                            {t('MediaLibraryPickerModal.title')}{' '}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '0px', padding: '0px' }}>
                        <MediaLibraryPickerTable
                            itemsSelected={this.state.itemsSelected}
                            medialibraries={this.state.medialibraries}
                            onDelete={() => {}}
                            onSelectionChanged={this.selectionChanged.bind(
                                this
                            )}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center">
                            <ReactPaginate
                                previousLabel={t('Pagination.previous')}
                                nextLabel={t('Pagination.next')}
                                breakLabel={<Link to="#">...</Link>}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="btn-toolbar pull-right">
                            <Button onClick={this.handleHide}>
                                {t('MediaLibraryPickerModal.close')}
                            </Button>
                            <Button
                                disabled={this.state.itemsSelected.length === 0}
                                className="btn-primary"
                                onClick={this.handleChooseClicked.bind(this)}
                            >
                                {t('MediaLibraryPickerModal.add_selected')}
                                {` (${this.state.itemsSelected.length})`}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default translate('translations')(MediaLibraryPickerModal);
