import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            currentOrganisation: this.props.currentOrganisation,
            organisation: {},
            statistics: {
                users: 0,
                players: 0,
                attributes: 0,
                pages: 0,
                snippets: 0,
                medialibraries: 0
            }
        };
    }

    render() {
        return (
            <Redirect
                to={`/organisations/${this.props.currentOrganisation.uuid}`}
            />
        );
    }
}

export default Home;
