import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditTextForm from '../components/EditTextForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditText.css';

class EditText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: {
                heading: '',
                detail: '',
                uuid: '1'
            }
        };
    }

    componentDidMount() {
        this.loadTextFromServer();
    }

    loadTextFromServer() {
        const { uuid, snippetUUID, textUUID } = this.props.match.params;

        var self = this;
        axios
            .get(
                `${config.baseURL}/snippets/${snippetUUID}/texts/${textUUID}`,
                {}
            )
            .then(function(response) {
                self.setState({ text: response.data.text });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'EditText.title'
        )}`;

        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID,
            textUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        return (
            <div className="EditText">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('EditText.title')}
                                </h1>
                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>{' '}
                                            <li>
                                                <i className="fa fa-font" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textsnippets/${snippetUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('TextSnippet.title')}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fas fa-pencil-alt" />{' '}
                                                {t('EditText.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            <li>
                                                <i className="fa fa-font" />
                                                <Link
                                                    to={`/organisations/${uuid}/textsnippets/${snippetUUID}?rel=snippet`}
                                                >
                                                    {' '}
                                                    {t('TextSnippet.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fas fa-pencil-alt" />{' '}
                                                {t('EditText.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-font" />
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}/textsnippets/${snippetUUID}?rel=page`}
                                                >
                                                    {' '}
                                                    {t('TextSnippet.title')}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fas fa-pencil-alt" />{' '}
                                                {t('EditText.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>

                                <PlainPanel>
                                    <EditTextForm
                                        snippetUUID={snippetUUID}
                                        text={this.state.text}
                                    />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditText);
