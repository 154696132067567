import React, { Component } from 'react';
import { Panel, Col, Row } from 'react-bootstrap';

import './PaginationPanel.css';

export default class PaginationPanel extends Component {
    render() {
        return (
            <Row className="PaginationPanel">
                <Col md={12}>
                    <Panel className="panel-default panel-table">
                        <Panel.Heading className="panel-heading">
                            {this.props.heading}
                        </Panel.Heading>
                        <Panel.Body>{this.props.children}</Panel.Body>
                        <Panel.Footer>
                            <div className="text-center">{this.props.pagination}</div>
                        </Panel.Footer>
                    </Panel>
                </Col>
            </Row>
        );
    }
}
