import React, { Component } from 'react';
import { Panel, Col, Row } from 'react-bootstrap';

import './PlainPanel.css';

export default class PlainPanel extends Component {
    render() {
        return (
            <Row className="PlainPanel">
                <Col md={12} mdOffset={0}>
                    <Panel className="panel-default panel-table">
                        <Panel.Body>{this.props.children}</Panel.Body>
                    </Panel>
                </Col>
            </Row>
        );
    }
}
