import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import Select from 'react-select';

import './AddPageSnippetForm.css';

class AddPageSnippetForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newSnippet: null,
            x: 0,
            y: 0,
            z: 0,
            typeID: '1',
            organisationUUID: this.props.uuid,
            pageUUID: this.props.pageUUID,
            snippetUUID: '',
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.snippetUUID.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSnippetChange = chosenItem => {
        console.log(`New Value: ${JSON.stringify(chosenItem.value)}`);
        this.setState({
            snippetUUID: chosenItem.value
        });
    };

    componentDidMount() {
        this.loadOrganisationSnippetsFromServer();
    }

    loadOrganisationSnippetsFromServer() {
        var self = this;

        axios
            .get(
                `${config.baseURL}/organisations/${this.props.uuid}/snippets`,
                {
                    params: {
                        page: 1,
                        limit: 1000
                    }
                }
            )
            .then(function(response) {
                let snippets = response.data.snippets;

                const options = [];

                for (const snippet of snippets) {
                    options.push({ value: snippet.uuid, label: snippet.name });
                }

                console.log(`Options: ${JSON.stringify(options)}`);

                self.setState({
                    globalSnippets: options
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            let self = this;
            axios
                .post(
                    `${config.baseURL}/pages/${self.state.pageUUID}/snippets`,
                    {
                        snippetUUID: self.state.snippetUUID,
                        x: self.state.x,
                        y: self.state.y,
                        z: self.state.z
                    }
                )
                .then(function(response) {
                    self.setState({
                        newSnippet: response.data.snippet,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('AddPageSnippetForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'AddPageSnippetForm.created_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    console.log(
                        `Error: ${JSON.stringify(err.response.data.message)}`
                    );
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('AddPageSnippetForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('AddPageSnippetForm.error')}:`,
                    style: 'danger',
                    message: `${t('AddPageSnippetForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createSnippetForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={4}>
                            <FormGroup controlId="x" bsSize="sm">
                                <ControlLabel>
                                    {t('AddPageSnippetForm.x')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-arrows-alt" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.x}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('AddPageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup controlId="y" bsSize="sm">
                                <ControlLabel>
                                    {t('AddPageSnippetForm.y')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-arrows-alt" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.y}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('AddPageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup controlId="z" bsSize="sm">
                                <ControlLabel>
                                    {t('AddPageSnippetForm.z')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-arrows-alt" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.z}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('AddPageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="snippetUUID" bsSize="sm">
                                <ControlLabel>
                                    {t('AddPageSnippetForm.snippet')}
                                </ControlLabel>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={this.state.snippetUUID}
                                    name="snippetUUID"
                                    isLoading={this.state.isLoading}
                                    onChange={this.handleSnippetChange}
                                    options={this.state.globalSnippets}
                                />
                                <small className="form-text text-muted">
                                    {t('AddPageSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'AddPageSnippetForm.add_snippet'
                                    )}`}
                                    loadingText={`${t(
                                        'AddPageSnippetForm.adding_snippet'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="AddPageSnippetForm">
                {this.state.newSnippet === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(AddPageSnippetForm);
