import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateGlobalPageForm from '../components/CreateGlobalPageForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './CreateGlobalPage.css';

class CreateGlobalPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreateGlobalPage.title'
        )}`;

        const { uuid, playerUUID, pageUUID } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        return (
            <div className="CreateGlobalPage">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreateGlobalPage.title')}
                                </h1>
                                <ol className="breadcrumb">
                                    <React.Fragment>
                                        <li>
                                            <i className="fa fa-tachometer-alt" />{' '}
                                            <Link to={`/organisations/${uuid}`}>
                                                {t('Dashboard.title')}
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-file" />{' '}
                                            <Link
                                                to={`/organisations/${uuid}/pages`}
                                            >
                                                {t(
                                                    'SideNavigation.globalpages'
                                                )}
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <i className="fa fa-edit" />{' '}
                                            {t('CreateGlobalPage.title')}
                                        </li>{' '}
                                    </React.Fragment>
                                </ol>

                                <PlainPanel>
                                    <CreateGlobalPageForm
                                        uuid={uuid}
                                        pageUUID={pageUUID}
                                    />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateGlobalPage);
