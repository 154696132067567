import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

import './EditTextLibraryTextForm.css';

class EditTextLibraryTextForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            snippetUUID: '1',
            text: {
                heading: '',
                detail: '',
                uuid: '1'
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.text !== this.state.text) {
            if (nextProps.text) {
                this.setState({ text: nextProps.text });
            }
        }

        if (nextProps.snippetUUID !== this.state.snippetUUID) {
            if (nextProps.snippetUUID) {
                this.setState({ snippetUUID: nextProps.snippetUUID });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return (
            this.state.text.heading.length > 0 &&
            this.state.text.detail.length > 0
        );
    }

    handleChange = event => {
        const text = this.state.text;
        text[event.target.id] = event.target.value;

        // update state
        this.setState({
            text
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const uuid = this.props.uuid;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(
                    `${config.baseURL}/snippets/${
                        this.state.snippetUUID
                    }/textlibrarytexts/${this.state.text.uuid}`,
                    {
                        heading: this.state.text.heading,
                        detail: this.state.text.detail,
                        position: this.state.text.position
                    }
                )
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditTextLibraryTextForm.success')}:`,
                            style: 'success',
                            message: `${t('EditTextLibraryTextForm.saved_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditTextLibraryTextForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditTextLibraryTextForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditTextLibraryTextForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditTextLibraryTextForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="heading" bsSize="sm">
                                <ControlLabel>
                                    {t('EditTextLibraryTextForm.heading')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.text.heading}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditTextLibraryTextForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="detail" bsSize="sm">
                                <ControlLabel>
                                    {t('EditTextLibraryTextForm.detail')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.text.detail}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditTextLibraryTextForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('EditTextLibraryTextForm.save_text')}`}
                                    loadingText={`${t(
                                        'EditTextLibraryTextForm.saving_text'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditTextLibraryTextForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditTextLibraryTextForm);
