import React from 'react';
import AccessDenied from '../containers/AccessDenied';

const Authorization = (user, allowedRoles) => (WrappedComponent) =>
    class WithAuthorization extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                user
            };
        }
        render() {
            const user = this.state.user;
            let roles = new Set();
            for (var key in user.organisationRoles) {
                const organisationRoles = user.organisationRoles[key];
                for (const role of organisationRoles) {
                    roles.add(role);
                }
            }

            roles = [...roles];
            if (roles.some((role) => allowedRoles.includes(role))) {
                return <WrappedComponent {...this.props} />;
            } else {
                return <AccessDenied />;
            }
        }
    };

export default Authorization;
