import React, { Component } from 'react';
import { translate } from 'react-i18next';
import config from '../config';

import { Table, Checkbox, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import TooltipButton from './TooltipButton';

import './MediaTable.css';

class MediaTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDragging: false,
			medialibraryuuid: this.props.medialibraryuuid,
			organisationuuid: this.props.organisationuuid,
			media: this.props.media,
			itemsSelected: this.props.itemsSelected
		};
	}

	didSaveChanges(media, newDuration) {
		newDuration = newDuration * 1000;
		this.props.onSave(media, newDuration);
	}

	mediaIsVideo(media) { return media.extension === 'mp4' || media.extension === 'MP4' || media.extension === 'webm' || media.extension === 'WEBM'; }

	handleCopyClicked(media) {
		this.props.onCopyClicked(media);
	}

	handleDurationClicked(media) {
		const { t } = this.props;

		this.dialog.show({
			body: `${t('MediaTable.duration')} (Default: ${parseFloat(media.default_duration / 1000).toFixed(3)})`,
			prompt: Dialog.TextPrompt({
				placeholder: '10',
				initialValue: parseFloat(media.duration / 1000).toFixed(3)
			}),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.save'), dialog => this.didSaveChanges(media, dialog.value), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	humanFileSize(bytes, si) {
		var thresh = si ? 1000 : 1024;
		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}
		var units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		var u = -1;
		do {
			bytes /= thresh;
			++u;
		} while (Math.abs(bytes) >= thresh && u < units.length - 1);
		return bytes.toFixed(1) + ' ' + units[u];
	}

	componentWillReceiveProps(newProps) {
		this.setState({
			medialibraryuuid: newProps.medialibraryuuid,
			organisationuuid: newProps.organisationuuid,
			media: newProps.media,
			itemsSelected: newProps.itemsSelected
		});
	}

	toggleSelected(media) {
		this.props.onSelectionChanged(media, !this.getCheckedState(media));
	}

	onBeforeDragStart = () => {
		this.setState({
			isDragging: true
		});
	};

	onDragEnd = result => {
		this.setState({
			isDragging: false
		});

		// dropped outside the list
		if (!result.destination || result.destination.index === result.source.index) {
			return;
		}

		// no movement
		if (result.destination.index === result.source.index) {
			return;
		}

		this.props.onDragEnd(result);
	};

	getCheckedState(media) {
		return this.state.itemsSelected.includes(media);
	}

	handleSwitchChange(checked, media) {
		console.log('click: ' + media.uuid);
		this.props.onEnabledChanged(media, checked);
	}

	handleMutedChange(checked, media) {
		console.log('click: ' + media.uuid);
		this.props.onMutedChanged(media, checked);
	}

	handleCheckBoxChange = event => {
		const target = event.target;

		const checked = target.checked;
		const uuid = target.name;

		const media = this.state.media.find(media => {
			return media.uuid === uuid;
		});

		this.props.onSelectionChanged(media, checked);
	};

	getDescription(rule) {
        const { t } = this.props;

        if (rule.type_id == 1) {
            return (
                <p>
                    <span className="label label-success">
                        {Moment(rule.start_date).format(
                            'MMMM Do YYYY, h:mm:ss a'
                        )} - {Moment(rule.end_date).format(
                            'MMMM Do YYYY, h:mm:ss a'
                        )}                
                    </span>{' '}
                </p>
            );
        } else if (rule.type_id == 2) {
            return (
                <p>
                    <span className="label label-success">
                        {rule.attribute_name}
                    </span>{' '}
                </p>
            );
        } else if (rule.type_id == 3) {
            return (
                <p>
                    <span className="label label-success">
                        {this.getValidDays(rule)}
                    </span>{' '}
                    <span className="label label-success">
                        {Moment(rule.start_time).format('h:mm a')}
                    </span>{' '}
                    {` `}
                    <span className="label label-success">
                        {Moment(rule.end_time).format('h:mm a')}
                    </span>{' '}
                </p>
            );
        }
    }

    getValidDays(rule) {
        var abbreviations = '';
        if (rule.monday == true) {
            abbreviations += 'M';
        } else {
            abbreviations += '-';
        }
        if (rule.tuesday == true) {
            abbreviations += 'T';
        } else {
            abbreviations += '-';
        }
        if (rule.wednesday == true) {
            abbreviations += 'W';
        } else {
            abbreviations += '-';
        }
        if (rule.thursday == true) {
            abbreviations += 'T';
        } else {
            abbreviations += '-';
        }
        if (rule.friday == true) {
            abbreviations += 'F';
        } else {
            abbreviations += '-';
        }
        if (rule.saturday == true) {
            abbreviations += 'S';
        } else {
            abbreviations += '-';
        }
        if (rule.sunday == true) {
            abbreviations += 'S';
        } else {
            abbreviations += '-';
        }

        return abbreviations;
    }

	render() {
		const noPropagation = e => e.stopPropagation();

		const { t, medialibraryuuid, organisationuuid } = this.props;

		const getItemStyle = (isDragging, draggableStyle) => ({
			// some basic styles to make the items look a bit nicer
			userSelect: 'none',
			background: isDragging ? '#dddddd' : '',
			display: isDragging ? 'table' : '',
			...draggableStyle
		});

		const getListStyle = isDraggingOver => ({
			borderCollapse: isDraggingOver ? 'separate' : 'collapse'
		});

		let mediaRows = this.state.media.map((media, index) => {
			return (
				<Draggable key={media.uuid} draggableId={media.uuid} index={index}>
					{(provided, snapshot) => (
						<tr
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							onClick={e => {
								e.stopPropagation();
								this.toggleSelected(media);
							}}
							style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
						>
							<td width='20' className="test">
								<span>{index + 1}</span>
							</td>
							<td width='20'>
								<Checkbox checked={this.getCheckedState(media)} name={media.uuid} onChange={this.handleCheckBoxChange} />
							</td>
							<td width='150'>
								{media.uploaded ? (
									<a onClick={noPropagation} href={`https://players.cloudsignage.me/mediaserver/media/${organisationuuid}/${medialibraryuuid}/${media.uuid}.${media.extension}`} className='thumbnail' target='_blank'>
										<img src={`https://players.cloudsignage.me/mediaserver/thumbnails/${organisationuuid}/${medialibraryuuid}/${media.uuid}.jpg`} width='150' />
									</a>
								) : (
										<img src={`https://images.cloudsignage.me/misc/processing_spinner.gif`} width='150' />
									)}
							</td>
							<td width='150' className='hidden-xs'>
								{media.name}
							</td>
							<td width='75' className='hidden-xs hidden-sm'>
								<span className='label label-default'>{media.extension}</span>
							</td>
							<td width='75' className='hidden-xs hidden-sm'>
								<span className='label label-default'>{this.humanFileSize(media.filesize, true)}</span>
							</td>
							<td width='150'>{media.uploaded ? <span className='label label-success'>{parseFloat(media.duration / 1000).toFixed(2)}</span> : <span className='label label-default'>{t('MediaTable.processing')}</span>}</td>

							<td width='150' className='hidden-xs hidden-sm'>
								{Moment(media.updatedAt).format('YYYY-MM-DD HH:mm')}
							</td>
							<td className='hidden-xs hidden-sm'>
								{Moment(media.createdAt).format('YYYY-MM-DD HH:mm')}
							</td>
							<td>
							{this.mediaIsVideo(media) &&
								<Switch
								onChange={(checked, e, id) => {
									e.stopPropagation();

									this.handleMutedChange(checked, media);
								}}
								checked={media.muted}
								onColor={'#5db85d'}
								id='normal-switch'
							/>
							}
							</td>
							<td>
								<Switch
									onChange={(checked, e, id) => {
										e.stopPropagation();

										this.handleSwitchChange(checked, media);
									}}
									checked={media.enabled}
									onColor={'#5db85d'}
									id='normal-switch'
								/>
							</td>
							
							<td align='center' width='230'>
								<div className='btn-toolbar pull-right'>
									<TooltipButton
										className='btn-default'
										disabled={this.state.isDragging || !media.uploaded ? true : false}
										onClick={e => {
											e.stopPropagation();
											this.handleCopyClicked(media);
										}}
										tooltip={t('MediaTable.copy')}
									>
										<em className='far fa-clone' />
									</TooltipButton>
									<TooltipButton
										disabled={this.state.isDragging || !media.uploaded ? true : false}
										onClick={e => {
											e.stopPropagation();
											this.handleDurationClicked(media);
										}}
										tooltip={t('MediaTable.duration_tip')}
									>
										<em className='fas fa-clock' />
									</TooltipButton>
									<TooltipButton
										disabled={this.state.isDragging || !media.uploaded ? true : false}
										className={media.hasRules ? 'btn-primary' : 'btn-default'}
										onClick={e => {
											e.stopPropagation();
											this.props.onRulesClicked(media);
										}}
										tooltip={t('MediaTable.rules')}
									>
										<em className='fas fa-clipboard-list' />
									</TooltipButton>
									<TooltipButton
										disabled={this.state.isDragging || !media.uploaded ? true : false}
										className={media.hasTexts ? 'btn-primary' : 'btn-default'}
										onClick={e => {
											e.stopPropagation();
											this.props.onTextsClicked(media);
										}}
										tooltip={t('MediaTable.texts')}
									>
										<em className='fas fa-text-width' />
									</TooltipButton>
									<TooltipButton
										disabled={this.state.isDragging || !media.uploaded ? true : false}
										className='btn-danger'
										onClick={e => {
											e.stopPropagation();
											this.props.onDeleteMedia(media);
										}}
										tooltip={t('Dialog.delete')}
									>
										<em className='fas fa-trash' />
									</TooltipButton>
								</div>
								<div className='pull-right rules'>

									{ media && Array.isArray(media.rules) ? (
										media.rules.map((rule) => {
											return (
												this.getDescription(rule)
											);
										})
		 							) : ' ' }
									
						
								</div>
							</td>
						</tr>
					)}
				</Draggable>
			);
		});

		return (
			<div className='MediaTable'>
				<Dialog
					ref={el => {
						this.dialog = el;
					}}
				/>
				<DragDropContext onBeforeDragStart={this.onBeforeDragStart} onDragEnd={this.onDragEnd}>
					<Droppable droppableId='droppable'>
						{(provided, snapshot) => (
							<table style={getListStyle(snapshot.isDraggingOver)} ref={provided.innerRef} className='table table-striped'>
								<thead>
									<tr>
										<th width='20' />
										<th width='20' />
										<th width='150'>{t('MediaTable.thumbnail')}</th>
										<th width='150' className='hidden-xs'>
											{t('MediaTable.filename')}
										</th>
										<th width='75' className='hidden-xs hidden-sm'>
											{t('MediaTable.extension')}
										</th>
										<th width='100' className='hidden-xs hidden-sm'>
											{t('MediaTable.filesize')}
										</th>
										<th width='150'>{t('MediaTable.duration')}</th>
										<th width='150' className='hidden-xs hidden-sm'>
											{t('MediaTable.updated')}
										</th>
										<th className='hidden-xs hidden-sm'>{t('MediaTable.uploaded')}</th>
										<th width='150'>{t('MediaTable.muted')}</th>
										<th width='150'>{t('MediaTable.published')}</th>
										<th>
											<em className='fa fa-cog' />
										</th>
									</tr>
								</thead>
								<tbody>{mediaRows}</tbody>
							</table>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		);
	}
}

export default translate('translations')(MediaTable);
