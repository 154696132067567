import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditPlayerForm from '../components/EditPlayerForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditPlayer.css';

class EditPlayer extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = { player: null, uuid };
    }

    componentDidMount() {
        this.loadPlayerFromServer();
    }

    loadPlayerFromServer() {
        const { uuid, playerUUID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({ player: response.data.player });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t('EditPlayer.title')}`;

        return (
            <div className="EditPlayer">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">{t('EditPlayer.title')}</h1>
                                <ol className="breadcrumb">
                                    {rel === 'staff_admin' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-briefcase" />{' '}
                                                <Link to="/players">{t('Players.title')}</Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-edit" /> {t('EditPlayer.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link to={`/organisations/${this.state.uuid}`}>
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link to={`/organisations/${this.state.uuid}/players`}>
                                                    {t('SideNavigation.players')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-edit" /> {t('EditPlayer.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    )}
                                </ol>

                                <PlainPanel>
                                    <EditPlayerForm player={this.state.player} />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditPlayer);
