import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';

import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import TooltipLink from './TooltipLink';
import TooltipButton from './TooltipButton';

class FactTypeTable extends Component {
    didConfirmDelete(facttype) {
        this.props.onDelete(facttype);
    }

    handleDeleteClicked(facttype) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(facttype),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t } = this.props;

        let facttypeRows = this.props.facttypes.map((facttype, index) => {
            return (
                <tr key={index}>
                    <td>{facttype.id}</td>
                    <td>
                        {facttype.enabled ? (
                            <span className="label label-success">
                                {t('FactTypeTable.enabled')}
                            </span>
                        ) : (
                            <span className="label label-danger">
                                {t('FactTypeTable.disabled')}
                            </span>
                        )}
                    </td>
                    <td>{facttype.description}</td>
                    <td align="center">
                        <TooltipLink
                            id="editButton"
                            className="btn btn-default"
                            tooltip={t('FactTypeTable.editfacttype')}
                            to={`/facts/facttypes/${facttype.id}/edit`}
                        >
                            <em className="fas fa-pencil-alt" />
                        </TooltipLink>
                        <TooltipButton
                            id="deleteButton"
                            className="btn-danger pull-right"
                            tooltip={t('FactTypeTable.deletefacttype')}
                            onClick={() => this.handleDeleteClicked(facttype)}
                        >
                            <em className="fa fa-trash" />
                        </TooltipButton>
                    </td>
                </tr>
            );
        });

        return (
            <div id="facttypetable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{t('FactTypeTable.id')}</th>
                            <th>{t('FactTypeTable.status')}</th>
                            <th>{t('FactTypeTable.description')}</th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{facttypeRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(FactTypeTable);
