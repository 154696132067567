import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditPageSnippetForm from '../components/EditPageSnippetForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditPageSnippet.css';

class EditPageSnippet extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = { snippet: { name: '', }, uuid };
    }

    componentDidMount() {
        this.loadSnippetFromServer();
    }

    loadSnippetFromServer() {
        const {
            uuid,
            playerUUID,
            snippetUUID,
            pageUUID
        } = this.props.match.params;

        var self = this;
        axios
            .get(
                `${config.baseURL}/pages/${pageUUID}/snippets/${snippetUUID}`,
                {}
            )
            .then(function(response) {
                self.setState({ snippet: response.data.snippet });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'EditPageSnippet.title'
        )}`;

        return (
            <div className="EditPageSnippet">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('EditPageSnippet.title')}

                                    {rel === 'player' ? (
                                        <Fragment>
                                            {' - '}
                                            {this.state.snippet.name}{' '}
                                            <small>
                                                {this.state.player_name}{' '}
                                                <small
                                                    style={{
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {
                                                        this.state
                                                            .organisation_name
                                                    }
                                                </small>
                                            </small>
                                        </Fragment>
                                    ) : (
                                        <small>
                                            {this.state.organisation_name}
                                        </small>
                                    )}
                                    {rel === 'player' ? (
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-primary"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                {t('EditPageSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                {t('EditPageSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>

                                <PlainPanel>
                                    <EditPageSnippetForm
                                        snippet={this.state.snippet}
                                        pageUUID={pageUUID}
                                        organisationUUID={uuid}
                                    />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditPageSnippet);
