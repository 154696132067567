import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Checkbox } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';

Moment.locale('en');

class MediaLibraryPickerTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            medialibraries: this.props.medialibraries
        };
    }

    getCheckedState(medialibrary) {
        const selected = this.state.itemsSelected.includes(medialibrary.uuid);
        console.log(`selected: ${selected}`);
        return selected;
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            medialibraries: newProps.medialibraries,
            itemsSelected: newProps.itemsSelected
        });
    }

    handleCheckBoxChange = event => {
        const target = event.target;

        const checked = target.checked;
        const uuid = target.name;

        const medialibrary = this.state.medialibraries.find(medialibrary => {
            return medialibrary.uuid === uuid;
        });

        this.props.onSelectionChanged(medialibrary, checked);
    };

    render() {
        const { t, uuid } = this.props;
        let medialibraryRows = this.state.medialibraries.map(
            (medialibrary, index) => {
                return (
                    <tr key={index}>
                        <td width="20">
                            <Checkbox
                                checked={this.getCheckedState(medialibrary)}
                                name={medialibrary.uuid}
                                onChange={this.handleCheckBoxChange}
                            />
                        </td>

                        <td>
                            <i className={'fa fa-images'} /> {medialibrary.name}
                        </td>

                        <td className="hidden-xs hidden-sm hidden-md">
                            {Moment(medialibrary.updatedAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}
                        </td>
                        <td className="hidden-xs">
                            {Moment(medialibrary.createdAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}
                        </td>
                    </tr>
                );
            }
        );

        return (
            <div id="medialibraries" className="medialibraryTable">
                <Table
                    striped
                    bordered
                    condensed
                    hover
                    style={{ margin: '0px' }}
                >
                    <thead>
                        <tr>
                            <th width="20" />
                            <th width="500">
                                {t('MediaSnippetMediaLibrariesTable.name')}
                            </th>

                            <th className="hidden-xs">
                                {t('MediaSnippetMediaLibrariesTable.updated')}
                            </th>
                            <th className="hidden-xs">
                                {t('MediaSnippetMediaLibrariesTable.created')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{medialibraryRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(MediaLibraryPickerTable);
