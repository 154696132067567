import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Checkbox } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';

Moment.locale('en');

class QuestionTypePickerTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            questiontypes: this.props.questiontypes
        };
    }

    getCheckedState(questiontype) {
        const selected = this.state.itemsSelected.includes(questiontype.id);
        return selected;
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            questiontypes: newProps.questiontypes,
            itemsSelected: newProps.itemsSelected
        });
    }

    handleCheckBoxChange = event => {
        const target = event.target;

        const checked = target.checked;
        const id = target.name;

        const questiontype = this.state.questiontypes.find(questiontype => {
            return questiontype.id == id;
        });

        this.props.onSelectionChanged(questiontype, checked);
    };

    render() {
        const { t, uuid } = this.props;
        let questiontypeRows = this.state.questiontypes.map(
            (questiontype, index) => {
                return (
                    <tr key={index}>
                        <td width="20">
                            <Checkbox
                                checked={this.getCheckedState(questiontype)}
                                name={questiontype.id}
                                onChange={this.handleCheckBoxChange}
                            />
                        </td>

                        <td>{questiontype.id}</td>
                        <td>{questiontype.description}</td>
                    </tr>
                );
            }
        );

        return (
            <div id="questiontypes" className="questiontypeTable">
                <Table
                    striped
                    bordered
                    condensed
                    hover
                    style={{ margin: '0px' }}
                >
                    <thead>
                        <tr>
                            <th width="20" />
                            <th width="20">
                                {t('QuestionTypePickerTable.id')}
                            </th>
                            <th>{t('QuestionTypePickerTable.description')}</th>
                        </tr>
                    </thead>
                    <tbody>{questiontypeRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(QuestionTypePickerTable);
