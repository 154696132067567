import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import './TextSnippetTextsTable.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';
import Switch from 'react-switch';

Moment.locale('en');

class TextSnippetTextsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDragging: false
        };
    }

    didConfirmDelete(text) {
        this.props.onDelete(text);
    }

    handleDeleteClicked(text) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(text),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    onBeforeDragStart = () => {
        this.setState({
            isDragging: true
        });
    };

    onDragEnd = result => {
        this.setState({
            isDragging: false
        });

        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }

        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }

        this.props.onDragEnd(result);
    };

    handleSwitchChange(checked, text) {
        console.log('click: ' + text.uuid);
        this.props.onEnabledChanged(text, checked);
    }

    componentDidMount() {}

    render() {
        const { t, uuid, pageUUID, playerUUID, texts, snippet } = this.props;

        const getItemStyle = (isDragging, draggableStyle) => ({
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            background: isDragging ? '#dddddd' : '',
            display: isDragging ? 'table' : '',
            ...draggableStyle
        });

        const getListStyle = isDraggingOver => ({
            borderCollapse: isDraggingOver ? 'separate' : 'collapse'
        });

        let textRows = texts.map((text, index) => {
            let editLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textsnippets/${
                snippet.uuid
            }/texts/${text.uuid}/edit?rel=player`;

            let rulesLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textsnippets/${
                snippet.uuid
            }/texts/${text.uuid}/rules?rel=player`;

            if (this.props.rel === 'snippet') {
                editLink = `/organisations/${uuid}/textsnippets/${
                    snippet.uuid
                }/texts/${text.uuid}/edit?rel=snippet`;

                rulesLink = `/organisations/${uuid}/textsnippets/${
                    snippet.uuid
                }/texts/${text.uuid}/rules?rel=snippet`;
            } else if (this.props.rel === 'page') {
                editLink = `/organisations/${uuid}/pages/${pageUUID}/textsnippets/${
                    snippet.uuid
                }/texts/${text.uuid}/edit?rel=page`;

                rulesLink = `/organisations/${uuid}/pages/${pageUUID}/textsnippets/${
                    snippet.uuid
                }/texts/${text.uuid}/rules?rel=page`;
            }

            return (
                <Draggable
                    key={text.uuid}
                    draggableId={text.uuid}
                    index={index}
                >
                    {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                        >
                            <td width="10">
                                <i
                                    className="fa fa-bars"
                                    aria-hidden="true"
                                    style={{ color: '#d0d0d0' }}
                                />
                            </td>
                            <td>{text.heading}</td>
                            <td>{text.detail}</td>
                            <td>
                                <Switch
                                    onChange={(checked, e, id) => {
                                        e.stopPropagation();

                                        this.handleSwitchChange(checked, text);
                                    }}
                                    checked={text.enabled}
                                    onColor={'#5db85d'}
                                    id="normal-switch"
                                />
                            </td>
                            <td align="center" width="160">
                                <div className="btn-toolbar ">
                                    <TooltipLink
                                        id="editButton"
                                        className="btn btn-default"
                                        tooltip={t(
                                            'TextSnippetTextsTable.edittext'
                                        )}
                                        to={editLink}
                                    >
                                        <em className="fas fa-pencil-alt" />
                                    </TooltipLink>
                                    <TooltipLink
                                        id="ruleButton"
                                        className={
                                            text.hasRules
                                                ? 'btn btn-primary'
                                                : 'btn btn-default'
                                        }
                                        tooltip={t(
                                            'TextSnippetTextsTable.rules'
                                        )}
                                        to={rulesLink}
                                    >
                                        <em className="fas fa-clipboard-list" />
                                    </TooltipLink>

                                    {this.props.userIsAuthorized(
                                        this.props.user,
                                        [
                                            'staff_super_admin',
                                            'staff_admin',
                                            'organisation_admin',
                                            'organisation_user'
                                        ]
                                    ) ? (
                                        <TooltipButton
                                            id="deleteButton"
                                            className="btn-danger"
                                            tooltip={t(
                                                'TextSnippetTextsTable.deletetext'
                                            )}
                                            onClick={() =>
                                                this.handleDeleteClicked(text)
                                            }
                                        >
                                            <em className="fa fa-trash" />
                                        </TooltipButton>
                                    ) : null}
                                </div>
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        });

        return (
            <div id="TextSnippetTextsTable" className="TextSnippetTextsTable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />

                <DragDropContext
                    onBeforeDragStart={this.onBeforeDragStart}
                    onDragEnd={this.onDragEnd}
                >
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <table
                                style={getListStyle(snapshot.isDraggingOver)}
                                ref={provided.innerRef}
                                className="table table-striped"
                            >
                                <thead>
                                    <tr>
                                        <th width="10"> </th>
                                        <th width="auto">
                                            {t('TextSnippetTextsTable.heading')}
                                        </th>
                                        <th>
                                            {t('TextSnippetTextsTable.detail')}
                                        </th>
                                        <th width="150">
                                            {t(
                                                'TextSnippetTextsTable.published'
                                            )}
                                        </th>
                                        <th width="60">
                                            <em className="fa fa-cog" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{textRows}</tbody>
                            </table>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

export default translate('translations')(TextSnippetTextsTable);
