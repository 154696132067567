import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditFactForm from '../components/EditFactForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditFact.css';

class EditFact extends Component {
    constructor(props) {
        super(props);

        this.state = { fact: { description: '', typeID: 0 }, factTypes: [] };
    }

    componentDidMount() {
        this.loadFactFromServer();
        this.loadFactTypesFromServer();
    }

    loadFactFromServer() {
        const { factID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/facts/${factID}`, {})
            .then(function(response) {
                self.setState({ fact: response.data.fact });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadFactTypesFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/facttypes`, {
                params: {
                    all: true
                }
            })
            .then(function(response) {
                self.setState({
                    factTypes: response.data.factTypes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t('EditFact.title')}`;

        return (
            <div className="EditFact">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">{t('EditFact.title')}</h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-info" /> <Link to={`/facts`}>{t('Facts.title')}</Link>
                                    </li>
                                    <li className="active">
                                        <i className="fas fa-pencil-alt" /> {t('EditFact.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <EditFactForm fact={this.state.fact} factTypes={this.state.factTypes} />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditFact);
