import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from './config';
import Routes from './Routes';

import axios from 'axios';

import './App.css';

class App extends Component {
    constructor(props) {
        super(props);

        let user = {
            isAuthenticated: false,
            isEngineer: false
        };

        let self = this;
        axios.interceptors.response.use(null, function(error) {
            if (error.response.status === 401) {
                self.userHasAuthenticated(false, { isAuthenticated: false, isEngineer: false });
                self.props.history.push('/login');
            }
            return Promise.reject(error);
        });

        let currentOrganisation = { name: '', uuid: '1' };

        try {
            let userJsonString = localStorage.getItem(config.localStorageKey);
            if (userJsonString) {
                user = JSON.parse(userJsonString);
                if (
                    Array.isArray(user.organisations) &&
                    user.organisations.length
                ) {
                    currentOrganisation = user.organisations[0];
                }
            }
        } catch (exception) {
            console.log('FAIL');
        }

        this.state = {
            user: user,
            currentOrganisation: currentOrganisation
        };
    }

    changeOrganisation = newOrganisation => {
        let updatedOrganisation = {
            uuid: newOrganisation.uuid,
            name: newOrganisation.name
        };

        this.setState({ currentOrganisation: updatedOrganisation });
    };

    userHasAuthenticated = (authenticated, user) => {
        let updatedOrganisation = { name: '', uuid: '1' };

        if (authenticated) {
            if (
                Array.isArray(user.organisations) &&
                user.organisations.length
            ) {
                localStorage.setItem(
                    config.localStorageKey,
                    JSON.stringify(user)
                );
                updatedOrganisation = user.organisations[0];
            }
        }

        this.setState({ user: user, currentOrganisation: updatedOrganisation });
    };

    userIsAuthorized = (user, allowedRoles) => {
        let roles = new Set();
        for (var key in user.organisationRoles) {
            const organisationRoles = user.organisationRoles[key];
            for (const role of organisationRoles) {
                roles.add(role);
            }
        }

        roles = [...roles];
        return roles.some(role => allowedRoles.includes(role));
    };

    render() {
        const childProps = {
            currentOrganisation: this.state.currentOrganisation,
            user: this.state.user,
            userHasAuthenticated: this.userHasAuthenticated,
            userIsAuthorized: this.userIsAuthorized,
            changeOrganisation: this.changeOrganisation
        };

        return (
            <div className="App">
                <Routes childProps={childProps} />
            </div>
        );
    }
}

const withRouterHOC = withRouter(App);
export default withRouterHOC;
