import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './EditOrganisationForm.css';

class EditOrganisationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            organisation: {
                name: '',
                phone: '',
                email: '',
                address: '',
                city: '',
                county: '',
                postcode: '',
                country: ''
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.organisation !== this.state.organisation) {
            if (nextProps.organisation) {
                this.setState({ organisation: nextProps.organisation });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return (
            this.state.organisation.name.length > 0 &&
            this.state.organisation.email.length > 0 &&
            this.state.organisation.address.length > 0 &&
            this.state.organisation.country.length > 0
        );
    }

    handleChange = event => {
        const organisation = this.state.organisation;
        organisation[event.target.id] = event.target.value;

        // update state
        this.setState({
            organisation
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const uuid = this.state.organisation.uuid;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(`${config.baseURL}/organisations/${uuid}`, {
                    name: this.state.organisation.name,
                    country: this.state.organisation.country,
                    address: this.state.organisation.address,
                    city: this.state.organisation.city,
                    county: this.state.organisation.county,
                    postcode: this.state.organisation.postcode,
                    email: this.state.organisation.email,
                    phone: this.state.organisation.phone
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOrganisationForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'EditOrganisationForm.saved_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOrganisationForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditOrganisationForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditOrganisationForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditOrganisationForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t(
                                        'EditOrganisationForm.organisation_name'
                                    )}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="name"
                                    value={this.state.organisation.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="phone" bsSize="sm">
                                <ControlLabel>
                                    {t('EditOrganisationForm.phone')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-phone" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.organisation.phone}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditOrganisationForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="email" bsSize="sm">
                                <ControlLabel>
                                    {t('EditOrganisationForm.email')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-envelope" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.organisation.email}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="address" bsSize="sm">
                                <label>
                                    {t('EditOrganisationForm.address')}
                                </label>
                                <FormControl
                                    type="address"
                                    value={this.state.organisation.address}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="city" bsSize="sm">
                                <label>{t('EditOrganisationForm.city')}</label>
                                <FormControl
                                    type="address"
                                    value={this.state.organisation.city}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOrganisationForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="county" bsSize="sm">
                                <label>
                                    {t('EditOrganisationForm.county')}
                                </label>
                                <FormControl
                                    type="county"
                                    value={this.state.organisation.county}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOrganisationForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="postcode" bsSize="sm">
                                <label>
                                    {t('EditOrganisationForm.post_code')}
                                </label>
                                <FormControl
                                    type="postcode"
                                    value={this.state.organisation.postcode}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOrganisationForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="country" bsSize="sm">
                                <label>
                                    {t('EditOrganisationForm.country')}
                                </label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.organisation.country}
                                    onChange={this.handleChange}
                                >
                                    <option value="Ireland">
                                        {t(
                                            'EditOrganisationForm.countries.ireland'
                                        )}
                                    </option>
                                    <option value="Spain">
                                        {t(
                                            'EditOrganisationForm.countries.spain'
                                        )}
                                    </option>
                                    <option value="United Kingdom" defaultValue>
                                        {t('EditOrganisationForm.countries.uk')}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('EditOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'EditOrganisationForm.save_organisation'
                                    )}`}
                                    loadingText={`${t(
                                        'EditOrganisationForm.saving_organisation'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditOrganisationForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditOrganisationForm);
