import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    Form,
    FormGroup,
    ControlLabel,
    ProgressBar
} from 'react-bootstrap';
import HeaderFooterPanel from '../components/HeaderFooterPanel';
import TemplateMediaPicker from '../components/TemplateMediaPicker';
import SpinnerButton from '../components/SpinnerButton';
import config from '../config';
import qs from 'qs';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

import './CreateMedia.css';

class CreateMedia extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = {
            modalShow: false,
            medialibrary: { name: '' },
            uuid,
            media: [],
            files: [],
            filePercentages: [],
            uploading: false,
            isCopyingMedia: false
        };
    }

    componentDidMount() {
        this.loadMediaLibraryFromServer();
    }

    loadMediaLibraryFromServer() {
        const { uuid, mediaLibraryUUID } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/medialibraries/${mediaLibraryUUID}`, {})
            .then(function(response) {
                self.setState({ medialibrary: response.data.medialibrary });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    onDrop(files) {
        const { uuid, mediaLibraryUUID } = this.props.match.params;

        let self = this;

        this.setState({
            files
        });

        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

        function uploadFile(file) {
            return new Promise(function(resolve, reject) {
                axios
                    .post(
                        `${
                            config.baseURL
                        }/medialibraries/${mediaLibraryUUID}/media`,
                        {
                            fileName: file.name,
                            fileSize: file.size
                        }
                    )
                    .then(function(result) {
                        let key = result.data.key;
                        let policy = result.data.policy;
                        let signature = result.data.signature;
                        let contentType = result.data.contentType;

                        var options = {
                            headers: {
                                'Content-Type': file.type
                            }
                        };

                        var formData = new FormData();

                        formData.append('key', key);
                        formData.append(
                            'AWSAccessKeyId',
                            'AKIAIOLWPARNSYK27RYA'
                        );
                        formData.append('acl', 'public-read');
                        formData.append('success_action_status', '201');
                        formData.append('policy', policy);
                        formData.append('signature', signature);
                        formData.append('Content-Type', contentType);
                        formData.append('file', file);

                        let filePercentages = self.state.filePercentages;
                        filePercentages[file.name] = 0;
                        self.setState({ uploading: true, filePercentages });

                        return axios({
                            url:
                                'https://mylook-player-content.s3-eu-west-1.amazonaws.com/',
                            method: 'POST',
                            data: formData,
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: progressEvent => {
                                let percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                );
                                let filePercentages =
                                    self.state.filePercentages;
                                filePercentages[file.name] = percentCompleted;
                                self.setState({ filePercentages });
                            }
                        });
                    })
                    .then(function(result) {
                        let filePercentages = self.state.filePercentages;
                        filePercentages[file.name] = 100;
                        self.setState({ uploading: true, filePercentages });
                        resolve();
                    })
                    .catch(function(err) {
                        console.log(
                            `Error uploading file: ${file} error: ${err}`
                        );
                        let filePercentages = self.state.filePercentages;
                        filePercentages[file.name] = 'error';
                        self.setState({ uploading: true, filePercentages });
                        reject('error');
                    });
            });
        }

        const promiseSerial = files =>
            files.reduce(function(promise, file) {
                return promise.then(function(result) {
                    return Promise.all([delay(500), uploadFile(file)]);
                });
            }, Promise.resolve());

        promiseSerial(files).then(() => {
            console.log('Uploaded all Files');
            this.props.history.goBack();
        });
    }

    handleModalOpen = (event) => {
        event.preventDefault();
        this.setState({
            modalShow: true
        });
    };

    handleModalSave = () => {
        this.setState(prevState => ({
            ...prevState,
            modalShow: false
        }));
    };

    handleModalClose = () => {
        this.setState({
            modalShow: false
        });
    };

    handleSelectBackground = (backgroundUUID, fileName) => {
        console.log('Selected backgroundUUID:' + backgroundUUID);

        const { mediaLibraryUUID } = this.props.match.params;

        this.setState({
            modalShow: false,
            isCopyingMedia: true
        });

		var self = this;
		axios
			.post(`${config.baseURL}/medialibraries/${mediaLibraryUUID}/media?sourceTemplateMediaUUID=${backgroundUUID}`, {})
			.then(function(response) {
				console.log('copied media!');
                self.setState({
                    isCopyingMedia: false
                });
                self.props.history.goBack();
			})
			.catch(function(error) {
				console.log(error);
			});
    };

    render() {
        const { t } = this.props;
        const {
            uuid,
            mediaLibraryUUID,
            playerUUID,
            pageUUID,
            snippetUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreateMedia.title'
        )}`;

        const uploadOptions = {
            server: 'http://localhost:4000'
        };
        const s3Url = 'https://mylook-player-content.s3.amazonaws.com';

        let dropzoneRef;

        return (
            <div className="CreateMedia">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreateMedia.title')}
                                    {' - '}
                                    {this.state.medialibrary.name}{' '}
                                    {rel === 'player' ? (
                                        <small>
                                            {this.state.player_name}{' '}
                                            <small
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {this.state.organisation_name}
                                            </small>
                                        </small>
                                    ) : null}
                                </h1>
                                <ol className="breadcrumb">
                                    {rel === 'template' ? (
                                        <Fragment>
        									<li>
        										<i className='fas fa-images' /> <Link to={`/templatelibraries`}>{t('Navigation.templatelibraries')}</Link>
        									</li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/templatelibraries/${mediaLibraryUUID}`}
                                                >
                                                    {t('TemplateMediaLibrary.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMedia.title')}
                                            </li>{' '}
                                        </Fragment>

                                    ) : null}
                                    {rel === 'player' ? (
                                        <Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}?rel=player`}
                                                >
                                                    {t('MediaSnippet.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}?rel=player`}
                                                >
                                                    {t(
                                                        'MediaSnippetMediaLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMedia.title')}
                                            </li>{' '}
                                        </Fragment>
                                    ) : null}
                                    {rel === 'library' ? (
                                        <Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/medialibraries?rel=library`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalmedialibraries'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/medialibraries/${mediaLibraryUUID}?rel=library`}
                                                >
                                                    {t(
                                                        'MediaSnippetMediaLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMedia.title')}
                                            </li>{' '}
                                        </Fragment>
                                    ) : null}
                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/mediasnippets/${snippetUUID}?rel=snippet`}
                                                >
                                                    {t('MediaSnippet.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}?rel=snippet`}
                                                >
                                                    {t(
                                                        'MediaSnippetMediaLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMedia.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}?rel=page`}
                                                >
                                                    {t('MediaSnippet.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-images" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${snippetUUID}/medialibraries/${mediaLibraryUUID}?rel=page`}
                                                >
                                                    {t(
                                                        'MediaSnippetMediaLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateMedia.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>

                                <HeaderFooterPanel title={'Media'}>
                                    <div id="EditPlayerForm">
                                        <form onSubmit={this.handleSubmit}>
                                            <Row bsClass="form-row">
                                                <Col sm={12}>
                                                    <FormGroup bsSize="sm">
                                                        <h5>
                                                            {t(
                                                                'MediaLibraryUpload.choose_from_library'
                                                            )}
                                                        </h5>

                                                        <SpinnerButton
                                                            block
                                                            bsStyle="success"
                                                            type="submit"
                                                            isLoading={this.state.isCopyingMedia}
                                                            text={`${t(
                                                                'MediaLibraryUpload.choose_from_library'
                                                            )}`}
                                                            loadingText={`${t(
                                                                'MediaLibraryUpload.copying_from_library'
                                                            )}`}
                                                            onClick={(e) => {
                                                                this.handleModalOpen(e);
                                                            }}
                                                        />

                                                        <h5>
                                                            {t(
                                                                'MediaLibraryUpload.select_from_computer'
                                                            )}
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-block"
                                                            onClick={() => {
                                                                dropzoneRef.open();
                                                            }}
                                                        >
                                                            {t(
                                                                'MediaLibraryUpload.browse_for_media'
                                                            )}
                                                        </button>
                                                        <h5>
                                                            {t(
                                                                'MediaLibraryUpload.drag_message'
                                                            )}
                                                        </h5>
                                                        <Dropzone
                                                            ref={node => {
                                                                dropzoneRef = node;
                                                            }}
                                                            className="upload-drop-zone"
                                                            onDrop={this.onDrop.bind(
                                                                this
                                                            )}
                                                        >
                                                            <p>
                                                                {t(
                                                                    'MediaLibraryUpload.dropmediahere'
                                                                )}
                                                            </p>
                                                        </Dropzone>

                                                        {this.state.files
                                                            .length ? (
                                                            <div>
                                                                <h3>
                                                                    {t(
                                                                        'MediaLibraryUpload.file_status'
                                                                    )}
                                                                </h3>
                                                                {this.state.files.map(
                                                                    f => {
                                                                        if (
                                                                            this
                                                                                .state
                                                                                .filePercentages[
                                                                                f
                                                                                    .name
                                                                            ] ===
                                                                            'error'
                                                                        ) {
                                                                            return (
                                                                                <div
                                                                                >
                                                                                    <ControlLabel
                                                                                    >{`${t(
                                                                                        'MediaLibraryUpload.error'
                                                                                    )}:  ${t(
                                                                                        'MediaLibraryUpload.error_reason'
                                                                                    )} (${
                                                                                        f.name
                                                                                    })`}</ControlLabel>

                                                                                    <ProgressBar
                                                                                        bsStyle="danger"
                                                                                        key={
                                                                                            f.name
                                                                                        }
                                                                                        now={
                                                                                            100
                                                                                        }
                                                                                        label={t(
                                                                                            'MediaLibraryUpload.error_uploading'
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        }
                                                                        return (
                                                                            <div
                                                                            >
                                                                                <ControlLabel
                                                                                >{`${t(
                                                                                    'MediaLibraryUpload.uploading'
                                                                                )} ${
                                                                                    f.name
                                                                                } - ${this
                                                                                    .state
                                                                                    .filePercentages[
                                                                                    f
                                                                                        .name
                                                                                ] ||
                                                                                    '0'}%`}</ControlLabel>

                                                                                <ProgressBar
                                                                                    bsStyle="success"
                                                                                    key={
                                                                                        f.name
                                                                                    }
                                                                                    now={
                                                                                        this
                                                                                            .state
                                                                                            .filePercentages[
                                                                                            f
                                                                                                .name
                                                                                        ] ||
                                                                                        0
                                                                                    }
                                                                                    label={`${this
                                                                                        .state
                                                                                        .filePercentages[
                                                                                        f
                                                                                            .name
                                                                                    ] ||
                                                                                        '0'}%`}
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </HeaderFooterPanel>
                                <TemplateMediaPicker
                                    modalShow={this.state.modalShow}
                                    handleModalClose={this.handleModalClose}
                                    handleModalSave={this.handleModalSave}
                                    handleSelectBackground={this.handleSelectBackground}
                                    organisationUUID={this.state.organisationUUID}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateMedia);
