import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

import './CreateAttributeForm.css';

class CreateAttributeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newAttribute: null,
            isLoading: false,
            attribute_name: '',
            emailAttribute: this.props.emailAttribute || false,
            default: 'true',
            organisationUUID: this.props.uuid,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.attribute_name.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(
                    `${config.baseURL}/organisations/${
                        this.state.organisationUUID
                    }/attributes`,
                    {
                        name: this.state.attribute_name,
                        default: this.state.default == 'true',
                        emailAttribute: this.state.emailAttribute
                    }
                )
                .then(function(response) {
                    self.setState({
                        newAttribute: response.data.attribute,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateAttributeForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreateAttributeForm.created_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateAttributeForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateAttributeForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateAttributeForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createAttributeForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="attribute_name" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateAttributeForm.attribute_name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.attribute_name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateAttributeForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="default" bsSize="sm">
                                <label>
                                    {t('CreateAttributeForm.default')}
                                </label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.default}
                                    onChange={this.handleChange}
                                >
                                    <option value="true">
                                        {t('CreateAttributeForm.true')}
                                    </option>
                                    <option value="false">
                                        {t('CreateAttributeForm.false')}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t(
                                        'CreateAttributeForm.default_option_explanation'
                                    )}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'CreateAttributeForm.add_attribute'
                                    )}`}
                                    loadingText={`${t(
                                        'CreateAttributeForm.adding_attribute'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreateAttributeForm">
                {this.state.newAttribute === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreateAttributeForm);
