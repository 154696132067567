import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateFactForm from '../components/CreateFactForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import './CreateFact.css';

class CreateFact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            factTypes: []
        };
    }

    componentDidMount() {
        this.loadFactTypesFromServer();
    }

    loadFactTypesFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/facttypes`, {
                params: {
                    all: true
                }
            })
            .then(function(response) {
                self.setState({
                    factTypes: response.data.factTypes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t('CreateFact.title')}`;

        const { uuid } = this.props.match.params;

        return (
            <div className="CreateFact">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">{t('CreateFact.title')}</h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-info" /> <Link to={`/facts`}>{t('Facts.title')}</Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-plus" /> {t('CreateFact.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <CreateFactForm uuid={uuid} factTypes={this.state.factTypes} />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateFact);
