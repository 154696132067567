import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import config from '../config';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

Moment.locale('en');

class PlayerPagesTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    handleCopyClicked(page) {
		this.props.onPageCopyClicked(page);
	}

    render() {
        const { t, uuid, playerUUID } = this.props;
        let pageRows = this.props.pages.map((page, index) => {
            return (
                <tr key={index}>
                    <td align="center" width={80}>
                        <OverlayTrigger
                            placement="top"
                            delayShow="300"
                            delayHide="150"
                            overlay={
                                <Tooltip id="tooltip">
                                    <strong>
                                        {t('PlayerPagesTable.previewpage')}
                                    </strong>
                                </Tooltip>
                            }
                        >
                            <a
                                id="previewButton"
                                className="btn btn-primary"
                                target="_blank"
                                href={`${
                                    config.playersBaseURL
                                }/mediaserver/unit.php?pageUUID=${page.uuid}`}
                            >
                                <em className="fa fa-eye" />
                            </a>
                        </OverlayTrigger>
                    </td>
                    <td width="250">
                        
                        <Link
                        style={{
                           alignItems: "center",
                           textAlign: "left"
                         }}
                        className="label label-primary"
                            to={`/organisations/${uuid}/players/${playerUUID}/pages/${
                                page.uuid
                            }?rel=player`}
                        >
                            {page.global ? (
                            <span className="label label-success">
                                <i className={'fa fa-globe'} />{' '}
                                {t('MediaSnippetMediaLibrariesTable.global')}
                            </span>
                        ) : null}{' '}
                            <i className="fa fa-file" /> {page.name}
                        </Link>
                    </td>
                    <td width="80">
                        <span className="label label-default">
                            {page.width}
                        </span>
                    </td>
                    <td width="80">
                        <span className="label label-default">
                            {page.height}
                        </span>
                    </td>
                    <td width="200">{page.backgroundColour}</td>
                    <td width="150">{page.textColour}</td>
                    <td width="150">{page.fontFamily}</td>
                    <td width="" className="hidden-xs">
                        {Moment(page.updatedAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs">
                        {Moment(page.createdAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td align="center">
                    {this.props.userIsAuthorized(this.props.user, [
                            'staff_super_admin'
                        ]) ? (
                            <TooltipButton
                            className='btn-default'
                            onClick={e => {
                                e.stopPropagation();
                                this.handleCopyClicked(page);
                            }}
                                tooltip={t('PlayerPagesTable.copy')}
                            >
                                <em className='far fa-clone' />
                            </TooltipButton>
                        ) : null}
                        {this.props.userIsAuthorized(this.props.user, [
                            'staff_super_admin',
                            'staff_admin',
                            'organisation_admin',
                            'organisation_user'
                        ]) ? (
                            <TooltipLink
                                id="editButton"
                                tooltip={t('PlayerPagesTable.editpage')}
                                className="btn btn-default"
                                to={`/organisations/${uuid}/players/${playerUUID}/pages/${
                                    page.uuid
                                }/edit?rel=player`}
                            >
                                <em className="fas fa-pencil-alt" />
                            </TooltipLink>
                        ) : null}
                    </td>
                </tr>
            );
        });

        return (
            <div id="PlayerPagesTable" className="PlayerPagesTable">
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th width="80">{t('PlayerPagesTable.preview')}</th>
                            <th>{t('PlayerPagesTable.name')}</th>
                            <th>{t('PlayerPagesTable.width')}</th>
                            <th>{t('PlayerPagesTable.height')}</th>
                            <th>{t('PlayerPagesTable.backgroundColour')}</th>
                            <th>{t('PlayerPagesTable.textColour')}</th>
                            <th>{t('PlayerPagesTable.fontFamily')}</th>

                            <th className="hidden-xs">
                                {t('PlayerPagesTable.updated')}
                            </th>
                            <th className="hidden-xs">
                                {t('PlayerPagesTable.created')}
                            </th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{pageRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(PlayerPagesTable);
