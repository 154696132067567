import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';
import { Link } from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import TooltipButton from './TooltipButton';

Moment.locale('en');

class AttributePlayersTable extends Component {
    didConfirmDelete(player) {
        this.props.onDelete(player);
    }

    handleDeleteClicked(player) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: (
				<div>
					{t('Dialog.sure_deletion_attributeplayer')}<br/>
					<ul><li><strong>{player.name}</strong></li></ul>
				</div>
			),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(player),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t, uuid } = this.props;
        let playerRows = this.props.players.map((player, index) => {
            return (
                <tr key={index}>
                    <td align="center" width={80}>
                        <OverlayTrigger
                            placement="top"
                            delayShow="300"
                            delayHide="150"
                            overlay={
                                <Tooltip id="tooltip">
                                    <strong>
                                        {t(
                                            'AttributePlayersTable.previewplayer'
                                        )}
                                    </strong>
                                </Tooltip>
                            }
                        >
                            <a
                                id="previewButton"
                                className="btn btn-primary"
                                target="_blank"
                                href={`${
                                    config.playersBaseURL
                                }/mediaserver/unit.php?playerUUID=${
                                    player.uuid
                                }&preview=1`}
                            >
                                <em className="fa fa-eye" />
                            </a>
                        </OverlayTrigger>
                    </td>
                    <td className="hidden-xs hidden-sm">
                        {player.online ? (
                            <span className="label label-success">
                                {t('AttributePlayersTable.online')}
                            </span>
                        ) : (
                            <span className="label label-danger">
                                {t('AttributePlayersTable.offline')}
                            </span>
                        )}
                    </td>

                    <td>
                        <Link
                           style={{
                            alignItems: "center",
                            textAlign: "left"
                          }}
                         className="label label-primary"
                            to={`/organisations/${uuid}/players/${player.uuid}`}
                        >
                            {player.name}
                        </Link>
                    </td>
                    <td className="hidden-xs hidden-sm">{player.location}</td>
                    <td className="hidden-xs hidden-sm">{player.macAddress}</td>
                    <td className="hidden-xs hidden-sm">
                        {player.serialNumber}
                    </td>
                    <td className="hidden-xs hidden-sm hidden-md">
                        {Moment(player.installDate).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs">
                        {player.lastSeen
                            ? Moment(player.lastSeen).fromNow()
                            : t('AttributePlayersTable.never')}
                    </td>

                    <td align="center">
                        {this.props.userIsAuthorized(this.props.user, [
                            'staff_super_admin',
                            'staff_admin',
                            'organisation_admin',
                            'organisation_user'
                        ]) ? (
                            <TooltipButton
                                id="deleteButton"
                                style={{ 'margin-left': '10px' }}
                                className="btn-danger"
                                tooltip={t(
                                    'AttributePlayersTable.deleteplayer'
                                )}
                                onClick={() => this.handleDeleteClicked(player)}
                            >
                                <em className="fa fa-trash" />{' '}
                                {t('AttributePlayersTable.remove')}
                            </TooltipButton>
                        ) : null}
                    </td>
                </tr>
            );
        });

        return (
            <div id="players" className="playerTable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{t('AttributePlayersTable.preview')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('AttributePlayersTable.status')}
                            </th>
                            <th>{t('AttributePlayersTable.name')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('AttributePlayersTable.location')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('AttributePlayersTable.mac_address')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('AttributePlayersTable.serial_number')}
                            </th>
                            <th className="hidden-xs hidden-sm hidden-md">
                                {t('AttributePlayersTable.install_date')}
                            </th>
                            <th className="hidden-xs">
                                {t('AttributePlayersTable.last_seen')}
                            </th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{playerRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(AttributePlayersTable);
