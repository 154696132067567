import React, { Component, Fragment } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

export class TemplateSnippetPositionMenu extends Component {
	render() {
		const { t, alignItemMiddle, alignItemCenter } = this.props;

		return (
			<Fragment>
				<h4>{t('TemplateSnippet.menu_pos')}</h4>
				<Row className='ts-menu-row'>
					<Col className='ts-menu-col p-0' xs={6}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_alignItemCenter')}</strong>
									</Tooltip>
								}
							>
								<button className='btn btn-dark btn-block' onClick={alignItemCenter}>
									<i className='fas fa-arrows-alt-h fa-lg' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
					<Col className='ts-menu-col p-0' xs={6}>
						<Col className='ts-menu-col' xs={12}>
							<OverlayTrigger
								placement='top'
								delayShow={300}
								delayHide={150}
								overlay={
									<Tooltip id='tooltip'>
										<strong>{t('TemplateSnippet.tooltip_alignItemMiddle')}</strong>
									</Tooltip>
								}
							>
								<button className='btn btn-dark btn-block' onClick={alignItemMiddle}>
									<i className='fas fa-arrows-alt-v fa-lg' />
								</button>
							</OverlayTrigger>
						</Col>
					</Col>
				</Row>
			</Fragment>
		);
	}
}
