import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './PageSnippetsTable.css';
import { Link } from 'react-router-dom';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

Moment.locale('en');

class PageSnippetsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDragging: false
        };
    }

    didConfirmDelete(snippet) {
        this.props.onDelete(snippet);
    }

    handleDeleteClicked(snippet) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(snippet),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    componentDidMount() {}

    onBeforeDragStart = () => {
        this.setState({
            isDragging: true
        });
    };

    onDragEnd = result => {
        this.setState({
            isDragging: false
        });

        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }

        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }

        this.props.onDragEnd(result);
    };

    render() {
        const { t, uuid, pageUUID, playerUUID, rel } = this.props;

        const getItemStyle = (isDragging, draggableStyle) => ({
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            background: isDragging ? '#dddddd' : '',
            display: isDragging ? 'table' : '',
            ...draggableStyle
        });

        const getListStyle = isDraggingOver => ({
            borderCollapse: isDraggingOver ? 'separate' : 'collapse'
        });

        let snippetRows = this.props.snippets.map((snippet, index) => {
            let iconName = 'fa fa-puzzle-piece';
            let snippetLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/snippets/${
                snippet.uuid
            }/edit?rel=player`;

            if (rel === 'page') {
                snippetLink = `/organisations/${uuid}/pages/${pageUUID}/snippets/${
                    snippet.uuid
                }/edit?rel=page`;
            }

            if (snippet.typeID == 1) {
                iconName = 'fas fa-images';
                snippetLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${
                    snippet.uuid
                }?rel=player`;

                if (rel === 'page') {
                    snippetLink = `/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${
                        snippet.uuid
                    }?rel=page`;
                }

            } else if (snippet.typeID == 2) {

                iconName = 'far fa-calendar-alt';

            } else if (snippet.typeID == 3) {
                iconName = 'fa fa-info';
                snippetLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/factsnippets/${
                    snippet.uuid
                }?rel=player`;

                if (rel === 'page') {
                    snippetLink = `/organisations/${uuid}/pages/${pageUUID}/factsnippets/${
                        snippet.uuid
                    }?rel=page`;
                }
            } else if (snippet.typeID == 4) {

                iconName = 'fa fa-code';

            } else if (snippet.typeID == 5) {

                iconName = 'fa fa-font';
                snippetLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textsnippets/${
                    snippet.uuid
                }?rel=player`;

                if (rel === 'page') {
                    snippetLink = `/organisations/${uuid}/pages/${pageUUID}/textsnippets/${
                        snippet.uuid
                    }?rel=page`;
                }

            } else if (snippet.typeID == 6) {
                iconName = 'fa fa-question';
                snippetLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/qandasnippets/${
                    snippet.uuid
                }?rel=player`;

                if (rel === 'page') {
                    snippetLink = `/organisations/${uuid}/pages/${pageUUID}/qandasnippets/${
                        snippet.uuid
                    }?rel=page`;
                }
            } else if (snippet.typeID == 7) {
                iconName = 'fa fa-rss';
            } else if (snippet.typeID == 8) {
                iconName = 'fa fa-sun';
            } else if (snippet.typeID == 9) {
                iconName = 'fa fa-calendar';
            } else if (snippet.typeID == 10) {
                iconName = 'fa fa-clock';
            } else if (snippet.typeID == 11) {
                iconName = 'fa fa-clock';
            } else if (snippet.typeID == 12) {
                iconName = 'fa fa-sun';
            } else if (snippet.typeID == 13) {
                iconName = 'fa fa-tv';
            } else if (snippet.typeID == 15) {
                iconName = 'fa fa-puzzle-piece';
                snippetLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/templatesnippets/${
                    snippet.uuid
                }?rel=player`;

                if (rel === 'page') {
                    snippetLink = `/organisations/${uuid}/pages/${pageUUID}/templatesnippets/${
                        snippet.uuid
                    }?rel=page`;
                }
                
            } else if (snippet.typeID == 16) {
                iconName = 'fa fa-calendar';

            } else if (snippet.typeID == 17) {

                iconName = 'fa fa-font';
                snippetLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textlibrarysnippets/${
                    snippet.uuid
                }?rel=player`;

                if (rel === 'page') {
                    snippetLink = `/organisations/${uuid}/pages/${pageUUID}/textlibrarysnippets/${
                        snippet.uuid
                    }?rel=page`;
                }

            }

            let editSnippetLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/snippets/${
                snippet.uuid
            }/edit?rel=player`;

            let rulesLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/snippets/${
                    snippet.uuid
                }/rules?rel=player`;

            if (rel === 'page') {
                editSnippetLink = `/organisations/${uuid}/pages/${pageUUID}/snippets/${
                    snippet.uuid
                }/edit?rel=page`;

                rulesLink = `/organisations/${uuid}/pages/${pageUUID}/snippets/${
                       snippet.uuid
                   }/rules?rel=page`;
            }

            return (
                <Draggable
                    key={snippet.uuid}
                    draggableId={snippet.uuid}
                    index={index}
                >
                    {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                        >
                            <td width="10">
                                <i
                                    className="fa fa-bars"
                                    aria-hidden="true"
                                    style={{ color: '#d0d0d0' }}
                                />
                            </td>
                            <td width="250">
                                {snippet.global ? (
                                    <span className="label label-success">
                                        <i className={'fa fa-globe'} />{' '}
                                        {t('PageSnippetsTable.global')}
                                    </span>
                                ) : null}{' '}
                                <Link 
                                id="snippetButton"
                                style={{
                                   alignItems: "center",
                                   textAlign: "left"
                                 }}
                                className="label label-primary"
                                to={snippetLink}>
                                    <i className={`${iconName}`} />{' '}
                                    {snippet.name}
                                </Link>
                            </td>
                            <td width="80" className="hidden-xs hidden-sm">
                                {snippet.type}
                            </td>
                            <td width="80">
                                <span className="label label-default">
                                    {snippet.x}
                                </span>
                            </td>
                            <td width="80">
                                <span className="label label-default">
                                    {snippet.y}
                                </span>
                            </td>
                            <td width="80">
                                <span className="label label-default">
                                    {snippet.width}
                                </span>
                            </td>
                            <td width="80">
                                <span className="label label-default">
                                    {snippet.height}
                                </span>
                            </td>
                            <td width="150" className="hidden-xs hidden-sm">
                                {Moment(snippet.createdAt).format(
                                    'YYYY-MM-DD HH:mm'
                                )}
                            </td>
                            <td className="hidden-xs hidden-sm">
                                {Moment(snippet.updatedAt).format(
                                    'YYYY-MM-DD HH:mm'
                                )}
                            </td>
                            <td align="center" width="145">
                                <div className="btn-toolbar pull-right">
                                <TooltipLink
                                        id="ruleButton"
                                        className={
                                            snippet.hasRules
                                                ? 'btn btn-primary'
                                                : 'btn btn-default'
                                        }
                                        tooltip={t(
                                            'PageSnippetsTable.rules'
                                        )}
                                        to={rulesLink}
                                    >
                                        <em className="fas fa-clipboard-list" />
                                    </TooltipLink>
                                {this.props.userIsAuthorized(this.props.user, [
                                    'staff_super_admin',
                                    'staff_admin',
                                    'organisation_admin'
                                ]) ? (
                                    <Fragment>
                                        <TooltipLink
                                            id="editBtn"
                                            className="btn btn-default pull-left"
                                            tooltip={t(
                                                'PageSnippetsTable.editsnippet'
                                            )}
                                            to={editSnippetLink}
                                        >
                                            <em className="fas fa-pencil-alt" />
                                        </TooltipLink>

                                        <TooltipButton
                                            id="deleteBtn"
                                            className="btn-danger pull-right"
                                            tooltip={t(
                                                'PageSnippetsTable.deletesnippet'
                                            )}
                                            onClick={() =>
                                                this.handleDeleteClicked(
                                                    snippet
                                                )
                                            }
                                        >
                                            <em className="fa fa-trash" />
                                        </TooltipButton>
                                    </Fragment>
                                ) : null}
                            </div>
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        });

        return (
            <div id="PageSnippetsTable" className="PageSnippetsTable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <DragDropContext
                    onBeforeDragStart={this.onBeforeDragStart}
                    onDragEnd={this.onDragEnd}
                >
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <table
                                style={getListStyle(snapshot.isDraggingOver)}
                                ref={provided.innerRef}
                                className="table table-striped"
                            >
                                <thead>
                                    <tr>
                                        <th width="10"> </th>
                                        <th width="250">
                                            {t('PageSnippetsTable.name')}
                                        </th>
                                        <th
                                            width="80"
                                            className="hidden-xs hidden-sm"
                                        >
                                            {t('PageSnippetsTable.type')}
                                        </th>
                                        <th width="80">
                                            {t('PageSnippetsTable.x')}
                                        </th>
                                        <th width="80">
                                            {t('PageSnippetsTable.y')}
                                        </th>
                                        <th width="80">
                                            {t('PageSnippetsTable.width')}
                                        </th>
                                        <th width="80">
                                            {t('PageSnippetsTable.height')}
                                        </th>
                                        <th className="hidden-xs hidden-sm">
                                            {t('PageSnippetsTable.updated')}
                                        </th>
                                        <th className="hidden-xs hidden-sm">
                                            {t('PageSnippetsTable.created')}
                                        </th>
                                        <th>
                                            <em className="fa fa-cog" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{snippetRows}</tbody>
                            </table>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

export default translate('translations')(PageSnippetsTable);
