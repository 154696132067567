import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

import './CreateFactForm.css';

class CreateFactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newFact: null,
            isLoading: false,
            description: '',
            typeID: 1,
            organisationUUID: this.props.uuid,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            },
            factTypes: this.props.factTypes
        };
    }

    componentDidMount() {}

    componentWillReceiveProps(newProps) {
        this.setState({
            factTypes: newProps.factTypes
        });
    }

    validateForm() {
        return this.state.description.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/facts`, {
                    description: this.state.description,
                    typeID: this.state.typeID
                })
                .then(function(response) {
                    self.setState({
                        newFact: response.data.fact,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateFactForm.success')}:`,
                            style: 'success',
                            message: `${t('CreateFactForm.created_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateFactForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateFactForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateFactForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createAttributeForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="typeID" bsSize="sm">
                                <label>{t('CreateFactForm.type')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.typeID}
                                    onChange={this.handleChange}
                                >
                                    {this.state.factTypes.map((type, key) => {
                                        console.log(type);
                                        return (
                                            <option key={key} value={type.id}>
                                                {type.description}
                                            </option>
                                        );
                                    })}
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('CreateFactForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateFactForm.description')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateFactForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('CreateFactForm.add_fact')}`}
                                    loadingText={`${t(
                                        'CreateFactForm.adding_fact'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreateFactForm">
                {this.state.newFact === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreateFactForm);
