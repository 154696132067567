import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './EditFactForm.css';

class EditFactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            factTypes: this.props.factTypes,
            fact: {
                description: '',
                typeID: 1
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.fact !== this.state.fact) {
            if (nextProps.fact) {
                this.setState({ fact: nextProps.fact });
            }
        }

        if (nextProps.factTypes !== this.state.factTypes) {
            this.setState({ factTypes: nextProps.factTypes });
        }
    }

    componentDidMount() {}

    validateForm() {
        return this.state.fact.description.length > 0;
    }

    handleChange = event => {
        const fact = this.state.fact;
        fact[event.target.id] = event.target.value;

        // update state
        this.setState({
            fact
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const id = this.state.fact.id;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(`${config.baseURL}/facts/${id}`, {
                    typeID: this.state.fact.typeID,
                    description: this.state.fact.description
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditFactForm.success')}:`,
                            style: 'success',
                            message: `${t('EditFactForm.saved_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditFactForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditFactForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditFactForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditFactForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="typeID" bsSize="sm">
                                <label>{t('EditFactForm.type')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.fact.typeID}
                                    onChange={this.handleChange}
                                >
                                    {this.state.factTypes.map((type, key) => {
                                        console.log(type);
                                        return (
                                            <option key={key} value={type.id}>
                                                {type.description}
                                            </option>
                                        );
                                    })}
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('EditFactForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>
                                    {t('EditFactForm.description')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.fact.description}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditFactForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('EditFactForm.save_fact')}`}
                                    loadingText={`${t(
                                        'EditFactForm.saving_fact'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditFactForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditFactForm);
