import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import OrganisationUserTable from '../components/OrganisationUserTable';
import PaginationPanel from '../components/PaginationPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import axios from 'axios';
import {
	Panel,
	Col,
	Row,
	Button,
	FormControl,
	FormGroup,
	InputGroup
} from 'react-bootstrap';

import { translate } from 'react-i18next';

import './OrganisationUsers.css';

class OrganisationUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: props.user,
			userIsAuthorized: props.userIsAuthorized,
			user: props.user,
			users: [],
			page: props.page,
			pageCount: 0,
			limit: 40,
			search: '',
			selected: props.selected - 1
		};
	}

	componentDidMount() {
		this.loadOrganisationInformation();
		this.loadOrganisationUsersFromServer();
	}

	loadOrganisationInformation() {
		var self = this;

		const { uuid } = this.props.match.params;

		axios
			.get(`${config.baseURL}/organisations/${uuid}`, {})
			.then(function (response) {
				self.setState({
					organisation_name: response.data.organisation.name
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	loadOrganisationUsersFromServer() {
		var self = this;
		const { uuid } = this.props.match.params;

		axios
			.get(`${config.baseURL}/organisations/${uuid}/users`, {
				params: {
					page: self.state.page,
					limit: self.state.limit,
					search: self.state.search
				}
			})
			.then(function (response) {
				self.setState({
					users: response.data.users,
					pageCount: response.data.pageCount
				});

				console.log(`Users: ${JSON.stringify(response.data.users)}`);

			})
			.catch(function (error) {
				console.log(error);
			});
	}

	handlePageClick = data => {
		let selected = data.selected + 1;

		const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {
			this.loadOrganisationUsersFromServer();
		});
	};

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	handleSubmit = event => {
		const { t } = this.props;

		const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url);

		event.preventDefault();
		this.loadOrganisationUsersFromServer();
	};

	render() {
		const { t } = this.props;
		document.title = `MySign Digital Screen Solutions | ${t(
			'Users.title'
		)}`;

		const user = this.state.user;
		const { uuid } = this.props.match.params;

		return (
			<div className="OrganisationUsers">
				<div id="wrapper">
					<Navigation props={this.props} />
					<div id="content-wrapper">
						<Row>
							<Col lg={12}>
								<h1 className="page-header">
									{t('Users.title')}{' '}
									<small>
										{this.state.organisation_name}
									</small>
									{this.state.userIsAuthorized(user, [
										'staff_super_admin',
										'staff_admin',
										'organisation_admin'
									]) ? (
											<Link
												type="submit"
												className="btn btn-primary"
												to={`/organisations/${uuid}/users/invite`}
											>
												<em className="fa fa-plus fa-fw" />{' '}
												{t('OrganisationUsers.invite_user')}
											</Link>
										) : null}
								</h1>

								<ol className="breadcrumb">
									<li>
										<i className="fa fa-tachometer-alt" />{' '}
										<Link to={`/organisations/${uuid}`}>
											{t('Dashboard.title')}
										</Link>
									</li>

									<li className="active">
										<i className="fas fa-users" />{' '}
										{t('SideNavigation.users')}
									</li>
								</ol>

								<PaginationPanel
									heading={
										<Row>
											<Col xs={12} className="text-right">
												<form
													role="search"
													onSubmit={this.handleSubmit}
												>
													<FormGroup controlId="search">
														<InputGroup>
															<FormControl
																type="text"
																value={
																	this.state
																		.search
																}
																onChange={
																	this
																		.handleChange
																}
																placeholder={t(
																	'Search'
																)}
															/>
															<InputGroup.Button>
																<Button
																	onClick={
																		this
																			.handleSubmit
																	}
																>
																	<i className="glyphicon glyphicon-search" />
																</Button>
															</InputGroup.Button>
														</InputGroup>
													</FormGroup>
												</form>
											</Col>
										</Row>
									}
									pagination={
										<ReactPaginate
											previousLabel={t(
												'Pagination.previous'
											)}
											nextLabel={t('Pagination.next')}
											breakLabel={<Link to="#">...</Link>}
											breakClassName={'break-me'}
											pageCount={this.state.pageCount}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={this.handlePageClick}
											containerClassName={'pagination'}
											subContainerClassName={
												'pages pagination'
											}
											activeClassName={'active'}
											forcePage={this.state.selected}
										/>
									}
								>
									<OrganisationUserTable
										userIsAuthorized={this.state.userIsAuthorized}
										user={this.state.user}
										users={this.state.users}
										uuid={uuid}
									/>
								</PaginationPanel>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('translations')(OrganisationUsers);
