import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './EditQuestionTypeForm.css';

class EditQuestionTypeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            questiontypeTypes: this.props.questiontypeTypes,
            questiontype: {
                description: '',
                enabled: 'false'
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.questiontype !== this.state.questiontype) {
            if (nextProps.questiontype) {
                this.setState({ questiontype: nextProps.questiontype });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return this.state.questiontype.description.length > 0;
    }

    handleChange = event => {
        const questiontype = this.state.questiontype;
        questiontype[event.target.id] = event.target.value;

        // update state
        this.setState({
            questiontype
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const id = this.state.questiontype.id;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(`${config.baseURL}/questiontypes/${id}`, {
                    enabled: this.state.questiontype.enabled,
                    description: this.state.questiontype.description
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditQuestionTypeForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'EditQuestionTypeForm.saved_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditQuestionTypeForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditQuestionTypeForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditQuestionTypeForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="enabled" bsSize="sm">
                                <label>
                                    {t('EditQuestionTypeForm.enabled')}
                                </label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.questiontype.enabled}
                                    onChange={this.handleChange}
                                >
                                    <option value="true">
                                        {t('EditQuestionTypeForm.true')}
                                    </option>
                                    <option value="false">
                                        {t('EditQuestionTypeForm.false')}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('EditQuestionTypeForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>
                                    {t('EditQuestionTypeForm.description')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.questiontype.description}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditQuestionTypeForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'EditQuestionTypeForm.save_questiontype'
                                    )}`}
                                    loadingText={`${t(
                                        'EditQuestionTypeForm.saving_questiontype'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditQuestionTypeForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditQuestionTypeForm);
