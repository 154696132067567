import React, { Component } from 'react';
import { translate } from 'react-i18next';
import DismissableAlert from '../components/DismissableAlert';
import {
    FormGroup,
    Button,
    FormControl,
    ControlLabel,
    Panel,
    Col,
    Row
} from 'react-bootstrap';
import logo from '../logo.png';

import axios from 'axios';
import qs from 'qs';
import config from '../config';

import './AcceptInvite.css';

class AcceptInvite extends Component {
    constructor(props) {
        super(props);

        const parsed = qs.parse(this.props.location.search.substring(1));
        this.state = {
            inviteCode: parsed.code,
            isLoading: false,
            accepted: false,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {
        const { t } = this.props;

        this.setState({ isLoading: true });

        if (this.state.inviteCode && this.state.inviteCode.length > 5) {
            try {
                var self = this;
                axios
                    .get(
                        `${config.baseURL}/invites/${
                            this.state.inviteCode
                        }/redeem`
                    )
                    .then(function(response) {
                        self.setState({
                            isLoading: false,
                            accepted: true,
                            error: {
                                hidden: false,
                                title: `${t('AcceptInvite.success')}:`,
                                message: `${t(
                                    'AcceptInvite.redeemed_message'
                                )}`,
                                style: 'success'
                            }
                        });
                    })
                    .catch(function(error) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        if (error.response.status === 400) {
                            self.setState({
                                isLoading: false,
                                error: {
                                    hidden: false,
                                    title: `${t('AcceptInvite.error')}:`,
                                    style: 'danger',
                                    message: `${t(
                                        'AcceptInvite.invite_used_or_invalid'
                                    )}`
                                }
                            });
                        } else {
                            self.setState({
                                isLoading: false,
                                error: {
                                    hidden: false,
                                    title: `${t('AcceptInvite.error')}:`,
                                    style: 'danger',
                                    message: `${t(
                                        'AcceptInvite.unknown_error'
                                    )}`
                                }
                            });
                        }
                    });
            } catch (e) {
                this.setState({
                    isLoading: false,
                    error: {
                        hidden: false,
                        title: `${t('AcceptInvite.error')}:`,
                        message: `${t('AcceptInvite.unknown_error')}`,
                        style: 'danger'
                    }
                });
            }
        }
    }

    render() {
        const { t } = this.props;

        let button;
        if (this.state.accepted) {
            button = (
                <Button
                    block
                    bsStyle="primary"
                    bsSize="large"
                    href="/login"
                    type="submit"
                >
                    {t('AcceptInvite.proceed_to_login')}
                </Button>
            );
        } else {
            button = null;
        }

        return (
            <div className="AcceptInvite">
                <Row>
                    <Col
                        xs={10}
                        sm={6}
                        md={6}
                        lg={4}
                        xsOffset={1}
                        smOffset={3}
                        mdOffset={3}
                        lgOffset={4}
                    >
                        <Panel>
                            <Panel.Body>
                                <form onSubmit={this.handleSubmit}>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        className="center-block img-responsive"
                                        style={{ width: 168, height: 81 }}
                                    />

                                    <DismissableAlert
                                        title={this.state.error.title}
                                        message={this.state.error.message}
                                        hidden={this.state.error.hidden}
                                        style={this.state.error.style}
                                    />
                                    {button}
                                </form>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate('translations')(AcceptInvite);
