import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateOrganisationNotificationEmailRuleForm from '../components/CreateOrganisationNotificationEmailRuleForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';
import qs from 'qs';

import './CreateOrganisationNotificationEmailRule.css';

class CreateOrganisationNotificationEmailRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ruleTypes: [],
            attributes: [],
            preferences: { uuid: '' },
            rulegroup: { uuid: ''}
        };
    }

    componentDidMount() {
        this.loadSettingsFromServer();
        this.loadSettingsRuleGroupsFromServer();
        this.loadOrganisationAttributesFromServer();
    }

    loadSettingsFromServer() {
        const { emailId, uuid } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}/notificationpreferences/${emailId}`, {})
            .then(function(response) {
                self.setState({ preferences: response.data.preferences });
            })
            .catch(function(error) {
                console.log(error);
            });
    }


    loadSettingsRuleGroupsFromServer() {
        const { uuid, emailId } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}/emails/${emailId}/rulegroups`, {})
            .then(function(response) {
                if (
                    response.data.rulegroups &&
                    response.data.rulegroups.length > 0
                ) {
                    self.setState(
                        { rulegroup: response.data.rulegroups[0] },
                        () => {
                            self.loadRuleTypesFromServer();
                        }
                    );
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationAttributesFromServer() {
        const {
            uuid,
            emailId
        } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}/attributes`, {
                params: {
                    all: true,
                    emailAttribute: true
                }
            })
            .then(function(response) {
                self.setState({
                    attributes: response.data.attributes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadRuleTypesFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/ruletypes`, {
                params: {
                    all: true
                }
            })
            .then(function(response) {
                self.setState({
                    ruleTypes: response.data.ruleTypes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreateOrganisationNotificationEmailRule.title'
        )}`;

        const {
            uuid,
            emailId
        } = this.props.match.params;

        return (
            <div className="CreateOrganisationNotificationEmailRule">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreateOrganisationNotificationEmailRule.title')}
                                </h1>
                                <ol className="breadcrumb">


                                   <React.Fragment>
                                       <li>
                                           <i className="fa fa-tachometer-alt" />{' '}
                                           <Link
                                               to={`/organisations/${uuid}`}
                                           >
                                               {t('Dashboard.title')}
                                           </Link>
                                       </li>
                                       <li>
                                           <i className="fa fa-cog" />{' '}
                                           <Link
                                               to={`/organisations/${uuid}/settings`}
                                           >
                                               {t(
                                                   'OrganisationSettings.title'
                                               )}
                                           </Link>
                                       </li>

                                       <li className="active">
                                           <i className="fas fa-clipboard-list" />{' '}
                                           <Link
                                               to={`/organisations/${uuid}/emails/${emailId}/rules`}
                                           >
                                               {t(
                                                   'OrganisationNotificationEmailRules.title'
                                               )}
                                           </Link>
                                       </li>{' '}
                                       <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateOrganisationNotificationEmailRule.title')}
                                            </li>{' '}
                                   </React.Fragment>

                           </ol>


                                <PlainPanel>
                                    <CreateOrganisationNotificationEmailRuleForm
                                        uuid={uuid}
                                        ruleTypes={this.state.ruleTypes}
                                        attributes={this.state.attributes}
                                        emailId={emailId}
                                        ruleGroupUUID={
                                            this.state.rulegroup.uuid
                                        }
                                    />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateOrganisationNotificationEmailRule);