import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { SnippetRichText } from './TemplateSnippetComponents/SnippetRichText';

export class TemplateSnippetCanvas extends Component {
	/**
	 * @desc Calculate the scroll position before an update
	 * @param {props} prevProps
	 * @param {state} prevState
	 * @return {number} scrollPos
	 */
	getSnapshotBeforeUpdate(prevProps, prevState) {
		const canvas = this.refs.canvas;
		const scrollPos = {
			x: canvas.scrollWidth - canvas.scrollLeft,
			y: canvas.scrollHeight - canvas.scrollTop
		};
		return scrollPos;
	}

	/**
	 * @desc Sets the canvas scroll position to value before update
	 *
	 * @param {props} prevProps
	 * @param {state} prevState
	 * @param {snapshot} snapshot
	 */
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot !== null) {
			const canvas = this.refs.canvas;
			canvas.scrollLeft = canvas.scrollWidth - snapshot.x;
			canvas.scrollTop = canvas.scrollHeight - snapshot.y;
		}
	}

	render() {
		const { snippets, zoom, handleMove, handleDragStop, contentChange, caretPos, isSelected, selectSnippet, deselectSnippet, currentSnippet, handleResize, gridValue, selectedBackground, previewEnabled, canvasHeight, canvasWidth } = this.props;

		let snippetsToDisplay = [];
		if (snippets) {
			snippetsToDisplay = snippets;
		}

		const canvasStyle = {
			transform: `scale(${zoom})`,
			transformOrigin: 'left top',
			width: `${canvasWidth}px`,
			height: `${canvasHeight}px`
		};

		const imageStyle = {
			width: `${canvasWidth}px`,
			height: `${canvasHeight}px`,
			position: 'absolute',
			left: 0,
			top: 0
		}

		return (
			<Col xs={10}>
				<Col xs={12} id='template-snippet-canvas-wrapper'>
					<div id='template-snippet-canvas' style={canvasStyle} ref='canvas'>
						<img src={selectedBackground} style={imageStyle} />

						{snippetsToDisplay.map((key, index) => (
							<SnippetRichText
								key={index}
								snippet={snippetsToDisplay[index]}
								zoom={zoom}
								caretPos={caretPos}
								handleDragStop={handleDragStop}
								handleMove={handleMove}
								contentChange={contentChange}
								isSelected={isSelected}
								selectSnippet={selectSnippet}
								deselectSnippet={deselectSnippet}
								currentSnippet={currentSnippet}
								handleResize={handleResize}
								gridValue={gridValue}
								previewEnabled={previewEnabled}
							/>
						))}
					</div>
				</Col>
			</Col>
		);
	}
}

export default TemplateSnippetCanvas;
