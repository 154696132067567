import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form, FormGroup } from 'react-bootstrap';
import HeaderFooterPanel from '../components/HeaderFooterPanel';
import config from '../config';
import qs from 'qs';
import Dropzone from 'react-dropzone';
import TextLibraryTextTable from '../components/TextLibraryTextTable';
import reorder from '../helpers/reorder';
import { Link } from 'react-router-dom';
import Dialog, { displayName } from 'react-bootstrap-dialog';

import './TextLibrarySnippetTextLibrary.css';

class TextLibrarySnippetTextLibrary extends Component {
	constructor(props) {
		super(props);
		const { uuid } = this.props.match.params;

		this.state = {
			textlibrary: { name: '' },
			uuid,
			texts: [],
			files: [],
			filePercentages: [],
			itemsSelected: [],
		};
	}

	selectionChanged(text, checked) {
		let itemsSelected = this.state.itemsSelected;

		if (checked) {
			itemsSelected.push(text);
		} else {
			itemsSelected = itemsSelected.filter(selectedItem => selectedItem.uuid !== text.uuid);
		}

		var selectedSet = new Set(itemsSelected);

		this.setState({
			itemsSelected: [...selectedSet]
		});
	}

	enabledChanged(text, enabled) {
		try {
			var self = this;
			axios
				.put(`${config.baseURL}/textlibrarytext/${text.uuid}/enabled `, {
					enabled: enabled
				})
				.then(function(response) {
					console.log('Success!');
					self.loadTextLibrarySnippetTextLibraryTextFromServer();
				})
				.catch(function(err) {
					console.log(err);
				});
		} catch (e) {
			console.log('exception');
		}
	}

	handleDurationClicked() {
		const { t } = this.props;

		this.dialog.show({
			body: `${t('TextLibraryTextTable.duration')}`,
			prompt: Dialog.TextPrompt({
				placeholder: '10.000',
				initialValue: parseFloat(10000 / 1000).toFixed(3)
			}),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.save'), dialog => this.changeDurationOfText(dialog.value * 1000), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	handlePublishedClicked() {
		const { t } = this.props;

		const listedText = this.state.itemsSelected.map(d => (
			<li key={d.uuid}>
				<strong>{d.heading}</strong> {d.detail}
			</li>
		));

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_publish_items')}
					{listedText}
				</div>
			),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.publish'), () => this.changePublishedStateOfText(), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	deleteSelected(text) {
		const { t } = this.props;

		let listedText;

		if (text) {
			listedText = text.name;
		} else {
			listedText = this.state.itemsSelected.map(d => (
				<li key={d.uuid}>
					<strong>{d.name}</strong>
				</li>
			));
		}

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_deletion_items')}
					{listedText}
				</div>
			),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.delete'), () => this.didConfirmDelete(), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

onDeleteText(text) {
		const { t } = this.props;

		const listedText = (
			<li>
				<strong>{text.name}</strong>
			</li>
		);

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_deletion_items')}
					{listedText}
				</div>
			),
			actions: [Dialog.Action(t('Dialog.cancel'), () => 'btn-default'), Dialog.Action(t('Dialog.delete'), () => this.didConfirmDelete(text), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	changeDurationOfText(newDuration) {
		const { textLibraryUUID } = this.props.match.params;

		const textToReorder = [];
		for (var i = 0; i < this.state.itemsSelected.length; i++) {
			const text = this.state.itemsSelected[i];
			textToReorder[i] = text.uuid;
		}

		try {
			var self = this;
			axios
				.post(`${config.baseURL}/textlibraries/${textLibraryUUID}/text/duration `, {
					text: textToReorder,
					duration: newDuration
				})
				.then(function(response) {
					console.log('Success!');
					self.loadTextLibrarySnippetTextLibraryTextFromServer();
					self.setState({ itemsSelected: [] });
				})
				.catch(function(err) {
					console.log(err);
					self.setState({ itemsSelected: [] });
				});
		} catch (e) {
			console.log('exception');
		}
	}

	changePublishedStateOfText() {
		const { textLibraryUUID } = this.props.match.params;

		const textToPublish = [];
		for (var i = 0; i < this.state.itemsSelected.length; i++) {
			const text = this.state.itemsSelected[i];
			textToPublish[i] = text.uuid;
		}

		try {
			var self = this;
			axios
				.post(`${config.baseURL}/textlibraries/${textLibraryUUID}/text/enabled `, {
					text: textToPublish,
					enabled: true
				})
				.then(function(response) {
					console.log('Success!');
					self.loadTextLibrarySnippetTextLibraryTextFromServer();
					self.setState({ itemsSelected: [] });
				})
				.catch(function(err) {
					console.log(err);
					self.setState({ itemsSelected: [] });
				});
		} catch (e) {
			console.log('exception');
		}
	}

	textRulesClicked(text) {
		const { uuid, textLibraryUUID, snippetUUID, playerUUID, pageUUID } = this.props.match.params;

		const parsed = qs.parse(this.props.location.search.substring(1));
		const rel = parsed.rel;

		let link = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}/textlibraries/${textLibraryUUID}/texts/${text.uuid}/rules?rel=player`;
		if (rel === 'library') {
			link = `/organisations/${uuid}/textlibraries/${textLibraryUUID}/texts/${text.uuid}/rules?rel=library`;
		} else if (rel === 'snippet') {
			link = `/organisations/${uuid}/textlibrarysnippets/${snippetUUID}/textlibraries/${textLibraryUUID}/texts/${text.uuid}/rules?rel=snippet`;
		} else if (rel === 'page') {
			link = `/organisations/${uuid}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}/textlibraries/${textLibraryUUID}/texts/${text.uuid}/rules?rel=page`;
		}

		this.props.history.push(link);
	}

	reorderText(result) {
		const { textLibraryUUID } = this.props.match.params;
		const reorderedTexts = reorder(this.state.texts, result.source.index, result.destination.index);

		this.setState({
			texts: reorderedTexts
		});

		const ordering = {};
		for (var i = 0; i < reorderedTexts.length; i++) {
			const text = reorderedTexts[i];
			ordering[text.uuid] = i;
		}

		try {
			var self = this;
			axios
				.post(`${config.baseURL}/textlibraries/${textLibraryUUID}/text/reorder `, { ordering })
				.then(function(response) {
					console.log('Success!');
				})
				.catch(function(err) {
					console.log(err);
				});
		} catch (e) {
			console.log('exception');
		}
	}

	copyText(text) {
		const { textLibraryUUID } = this.props.match.params;

		var self = this;
		axios
			.post(`${config.baseURL}/textlibraries/${textLibraryUUID}/texts?sourceUUID=${text.uuid}`, {})
			.then(function(response) {
				self.loadTextLibrarySnippetTextLibraryTextFromServer();
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	saveText(text, newDuration) {
		var self = this;
		axios
			.put(`${config.baseURL}/textlibrarytext/${text.uuid}/duration`, {
				duration: newDuration
			})
			.then(function(response) {
				self.loadTextLibrarySnippetTextLibraryTextFromServer();
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	selectAll() {
		this.setState({ itemsSelected: this.state.texts });
	}

	selectNone() {
		this.setState({ itemsSelected: [] });
	}

	deleteText(text) {
		return new Promise(function(resolve, reject) {
			var self = this;
			axios
				.delete(`${config.baseURL}/textlibrarytext/${text.uuid}`)
				.then(function(result) {
					console.log('deleted text');
					resolve();
				})
				.catch(function(err) {
					console.log(`Error deleting file: ${text} error: ${err}`);
					reject('error');
				});
		});
	}
	componentDidMount() {
		this.loadTextLibrarySnippetTextLibraryFromServer();
		this.loadTextLibrarySnippetTextLibraryTextFromServer();
	}

	loadTextLibrarySnippetTextLibraryFromServer() {
		const { uuid, textLibraryUUID, snippetUUID, playerUUID, pageUUID } = this.props.match.params;

		let self = this;
		axios
			.get(`${config.baseURL}/textlibraries/${textLibraryUUID}`, {})
			.then(function(response) {
				self.setState({ textlibrary: response.data.textlibrary });

				self.loadOrganisationInformation();
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	loadOrganisationInformation() {
		var self = this;

		const { uuid } = this.props.match.params;

		axios
			.get(`${config.baseURL}/organisations/${uuid}`, {})
			.then(function(response) {
				self.setState({
					organisation_name: response.data.organisation.name
				});

				const parsed = qs.parse(self.props.location.search.substring(1));
				const rel = parsed.rel;
				if (rel === 'player') {
					self.loadPlayerFromServer();
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	loadPlayerFromServer() {
		var self = this;
		const { playerUUID } = this.props.match.params;

		axios
			.get(`${config.baseURL}/players/${playerUUID}`, {})
			.then(function(response) {
				self.setState({
					player_name: response.data.player.name
				});
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	didConfirmDelete(text) {
		let self = this;

		const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

		const promiseSerial = files =>
			files.reduce(function(promise, text) {
				return promise.then(function(result) {
					return Promise.all([delay(250), self.deleteText(text)]);
				});
			}, Promise.resolve());

		if (text) {
			self.deleteText(text).then(function() {
				self.loadTextLibrarySnippetTextLibraryTextFromServer();
			});
		} else {
			promiseSerial(this.state.itemsSelected).then(function() {
				console.log('Deleted text');
				self.setState({ itemsSelected: [] });
				self.loadTextLibrarySnippetTextLibraryTextFromServer();
			});
		}
	}

	loadTextLibrarySnippetTextLibraryTextFromServer() {
		const { uuid, textLibraryUUID } = this.props.match.params;

		let self = this;
		axios
			.get(`${config.baseURL}/textlibraries/${textLibraryUUID}/texts`, {})
			.then(function(response) {
				let texts = response.data.texts;
				console.log(`Texts: ${JSON.stringify(texts)}`);
				self.setState({ texts, itemsSelected: [] });
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	render() {
		const { t } = this.props;
		const { uuid, textLibraryUUID, playerUUID, pageUUID, snippetUUID } = this.props.match.params;

        console.log(`UUID: ${uuid}`);

		const parsed = qs.parse(this.props.location.search.substring(1));
		const rel = parsed.rel;
		document.title = `MySign Digital Screen Solutions | ${t('TextLibrarySnippetTextLibrary.title')}`;

		let createTextURL = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}/textlibraries/${textLibraryUUID}/text/create`;
		if (rel === 'player') {
			createTextURL = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}/textlibraries/${textLibraryUUID}/text/create?rel=player`;
		} else if (rel === 'library') {
			createTextURL = `/organisations/${uuid}/textlibraries/${textLibraryUUID}/text/create?rel=library`;
		} else if (rel === 'snippet') {
			createTextURL = `/organisations/${uuid}/textlibrarysnippets/${snippetUUID}/textlibraries/${textLibraryUUID}/text/create?rel=snippet`;
		} else if (rel === 'page') {
			createTextURL = `/organisations/${uuid}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}/textlibraries/${textLibraryUUID}/text/create?rel=page`;
		}

		return (
			<div className='TextLibrarySnippetTextLibrary'>
				<Dialog
					ref={el => {
						this.dialog = el;
					}}
				/>
				<div id='wrapper'>
					<Navigation props={this.props} />
					<div id='content-wrapper'>
						<Row>
							<Col lg={12}>
								<h1 className='page-header'>
									{this.state.textlibrary.name}{' '}
									{rel === 'player' ? (
										<small>
											{this.state.player_name}{' '}
											<small
												style={{
													whiteSpace: 'nowrap'
												}}
											>
												{this.state.organisation_name}
											</small>
										</small>
									) : null}
									{rel === 'library' ? <small>{this.state.organisation_name}</small> : null}
									<Link type='submit' className='btn btn-primary' to={createTextURL}>
										<em className='fa fa-plus fa-fw' /> {t('TextLibrarySnippetTextLibrary.add_text')}
									</Link>
									{rel === 'player' ? (
										<a id='previewButton' type='submit' className='btn btn-primary' target='_blank' href={`${config.playersBaseURL}/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}>
											<em className='fa fa-eye fa-fw' /> {t('Player.preview_player')}
										</a>
									) : null}
								</h1>

								<ol className='breadcrumb'>
									{rel === 'player' ? (
										<Fragment>
											<li>
												<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
											</li>
											<li>
												<i className='fa fa-tv' /> <Link to={`/organisations/${uuid}/players`}>{t('SideNavigation.players')}</Link>
											</li>
											<li>
												<i className='fa fa-tv' /> <Link to={`/organisations/${uuid}/players/${playerUUID}`}>{t('OrganisationPlayer.title')}</Link>
											</li>
											<li>
												<i className='fa fa-file' /> <Link to={`/organisations/${uuid}/players/${playerUUID}/pages/`}>{t('SideNavigation.pages')}</Link>
											</li>
											<li>
												<i className='fa fa-puzzle-piece' />
												<Link to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}> {t('Page.title')}</Link>
											</li>
											<li>
												<i className='fas fa-font' /> <Link to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}?rel=player`}>{t('TextLibrarySnippet.title')}</Link>
											</li>
											<li className='active'>
												<i className='fas fa-font' /> {t('TextLibrarySnippetTextLibrary.title')}
											</li>{' '}
										</Fragment>
									) : null}
									{rel === 'library' ? (
										<Fragment>
											<li>
												<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
											</li>
											<li>
												<i className='fas fa-font' /> <Link to={`/organisations/${uuid}/textlibraries?rel=library`}>{t('SideNavigation.globaltextlibraries')}</Link>
											</li>
											<li className='active'>
												<i className='fas fa-font' /> {t('TextLibrarySnippetTextLibrary.title')}
											</li>{' '}
										</Fragment>
									) : null}

									{rel === 'snippet' ? (
										<React.Fragment>
											<li>
												<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
											</li>
											<li>
												<i className='fa fa-puzzle-piece' />
												<Link to={`/organisations/${uuid}/snippets`}> {t('SideNavigation.globalsnippets')}</Link>
											</li>{' '}
											<li>
												<i className='fas fa-font' /> <Link to={`/organisations/${uuid}/textlibrarysnippets/${snippetUUID}?rel=snippet`}>{t('TextLibrarySnippet.title')}</Link>
											</li>
											<li className='active'>
												<i className='fas fa-font' /> {t('TextLibrarySnippetTextLibrary.title')}
											</li>{' '}
										</React.Fragment>
									) : null}

									{rel === 'page' ? (
										<React.Fragment>
											<li>
												<i className='fa fa-tachometer-alt' /> <Link to={`/organisations/${uuid}`}>{t('Dashboard.title')}</Link>
											</li>
											<li>
												<i className='fa fa-file' /> <Link to={`/organisations/${uuid}/pages`}>{t('SideNavigation.globalpages')}</Link>
											</li>
											<li>
												<i className='fa fa-puzzle-piece' /> <Link to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}>{t('Page.title')}</Link>
											</li>
											<li>
												<i className='fas fa-font' /> <Link to={`/organisations/${uuid}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}?rel=page`}>{t('TextLibrarySnippet.title')}</Link>
											</li>
											<li className='active'>
												<i className='fas fa-font' /> {t('TextLibrarySnippetTextLibrary.title')}
											</li>{' '}
										</React.Fragment>
									) : null}
								</ol>

								<HeaderFooterPanel
									title={t('TextLibrarySnippetTextLibrary.text')}
									headerContent={
										<div className='btn-toolbar pull-right'>
											<Button className='btn-default btn-sm' disabled={false} onClick={() => this.selectAll()}>
												<em className='far fa-check-square' /> {t('TextLibrarySnippetTextLibrary.select_all')}
											</Button>
											<Button className='btn-default btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.selectNone()}>
												<em className='far fa-square' /> {t('TextLibrarySnippetTextLibrary.select_none')}
											</Button>
											<Button className='btn-primary btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.handlePublishedClicked()}>
												<em className='far fa-check-square' /> {t('TextLibrarySnippetTextLibrary.mark_published')}
											</Button>
											<Button className='btn-primary btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.handleDurationClicked()}>
												<em className='fa fa-clock' /> {t('TextLibrarySnippetTextLibrary.modify_duration')}
											</Button>

											<Button className='btn btn-danger btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.deleteSelected()}>
												<em className='fa fa-trash' /> {t('TextLibrarySnippetTextLibrary.delete_selected')}
											</Button>
										</div>
									}
								>
									 <TextLibraryTextTable
                                        texts={this.state.texts}
                                        rel={rel}
                                        textlibraryuuid={textLibraryUUID}
                                        organisationuuid={uuid}
                                        onRulesClicked={this.textRulesClicked.bind(this)}
                                        onSave={this.saveText.bind(this)}
                                        onDragEnd={this.reorderText.bind(this)}
                                        onCopyClicked={this.copyText.bind(this)}
                                        onSelectionChanged={this.selectionChanged.bind(this)}
                                        onEnabledChanged={this.enabledChanged.bind(this)}
                                        itemsSelected={this.state.itemsSelected}
                                        onDeleteText={text => this.onDeleteText(text)}
                                    />
								</HeaderFooterPanel>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('translations')(TextLibrarySnippetTextLibrary);
