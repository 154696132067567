import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditGlobalSnippetForm from '../components/EditGlobalSnippetForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditGlobalSnippet.css';

class EditGlobalSnippet extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = { snippet: null, uuid };
    }

    componentDidMount() {
        this.loadSnippetFromServer();
    }

    loadSnippetFromServer() {
        const {
            uuid,
            playerUUID,
            snippetUUID,
            pageUUID
        } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}`, {})
            .then(function(response) {
                self.setState({ snippet: response.data.snippet });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'EditGlobalSnippet.title'
        )}`;

        return (
            <div className="EditGlobalSnippet">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('EditGlobalSnippet.title')}
                                    {rel === 'player' ? (
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-primary"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    <React.Fragment>
                                        <li>
                                            <i className="fa fa-tachometer-alt" />{' '}
                                            <Link to={`/organisations/${uuid}`}>
                                                {t('Dashboard.title')}
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-puzzle-piece" />{' '}
                                            <Link
                                                to={`/organisations/${uuid}/snippets`}
                                            >
                                                {t(
                                                    'SideNavigation.globalsnippets'
                                                )}
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <i className="fa fa-edit" />{' '}
                                            {t('EditGlobalSnippet.title')}
                                        </li>{' '}
                                    </React.Fragment>
                                </ol>

                                <PlainPanel>
                                    <EditGlobalSnippetForm
                                        snippet={this.state.snippet}
                                        pageUUID={pageUUID}
                                        organisationUUID={uuid}
                                    />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditGlobalSnippet);
