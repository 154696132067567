import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import './DismissableAlert.css';

export default class DismissableAlert extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleDismiss = this.handleDismiss.bind(this);

        this.state = {
            hidden: this.props.hidden,
            title: this.props.title,
            message: this.props.message,
            style: this.props.style
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            hidden: newProps.hidden,
            title: newProps.title,
            message: newProps.message,
            style: newProps.style
        });
    }

    handleDismiss() {
        this.setState({ hidden: true });
    }

    render() {
        if (!this.state.hidden) {
            return (
                <Alert
                    className="DismissableAlert"
                    bsStyle={this.state.style}
                    onDismiss={this.handleDismiss}
                >
                    <strong>{this.state.title}</strong> {this.state.message}
                </Alert>
            );
        }

        return null;
    }
}
