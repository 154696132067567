import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './EditQuestionForm.css';

class EditQuestionForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            questionTypes: this.props.questionTypes,
            question: {
                description: '',
                typeID: 1
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.question !== this.state.question) {
            if (nextProps.question) {
                this.setState({ question: nextProps.question });
            }
        }

        if (nextProps.questionTypes !== this.state.questionTypes) {
            if (nextProps.questionTypes) {
                this.setState({ questionTypes: nextProps.questionTypes });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return this.state.question.description.length > 0;
    }

    handleChange = event => {
        const question = this.state.question;
        question[event.target.id] = event.target.value;

        // update state
        this.setState({
            question
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const id = this.state.question.id;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(`${config.baseURL}/questions/${id}`, {
                    typeID: this.state.question.type_id,
                    answer: this.state.question.answer,
                    description: this.state.question.description
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditQuestionForm.success')}:`,
                            style: 'success',
                            message: `${t('EditQuestionForm.saved_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditQuestionForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditQuestionForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditQuestionForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditQuestionForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="typeID" bsSize="sm">
                                <label>{t('EditQuestionForm.type')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.question.typeID}
                                    onChange={this.handleChange}
                                >
                                    {this.state.questionTypes.map(
                                        (type, key) => {
                                            console.log(type);
                                            return (
                                                <option
                                                    key={key}
                                                    value={type.id}
                                                >
                                                    {type.description}
                                                </option>
                                            );
                                        }
                                    )}
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('EditQuestionForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>
                                    {t('EditQuestionForm.description')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.question.description}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditQuestionForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="answer" bsSize="sm">
                                <ControlLabel>
                                    {t('EditQuestionForm.answer')}
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.question.answer}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditQuestionForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'EditQuestionForm.save_question'
                                    )}`}
                                    loadingText={`${t(
                                        'EditQuestionForm.saving_question'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditQuestionForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditQuestionForm);
