import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';

import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

class FactTable extends Component {
    didConfirmDelete(fact) {
        this.props.onDelete(fact);
    }

    handleDeleteClicked(fact) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(fact),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t } = this.props;

        let factRows = this.props.facts.map((fact, index) => {
            return (
                <tr key={index}>
                    <td>{fact.id}</td>
                    <td>{fact.type}</td>
                    <td>{fact.description}</td>
                    <td align="center">
                        <TooltipLink
                            id="editButton"
                            className="btn btn-default"
                            tooltip={t('FactTable.editfact')}
                            to={`/facts/${fact.id}/edit`}
                        >
                            <em className="fas fa-pencil-alt" />
                        </TooltipLink>

                        <TooltipButton
                            id="deleteButton"
                            className="btn-danger"
                            tooltip={t('FactTable.deletefact')}
                            onClick={() => this.handleDeleteClicked(fact)}
                        >
                            <em className="fa fa-trash" />
                        </TooltipButton>
                    </td>
                </tr>
            );
        });

        return (
            <div id="facttable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('FactTable.type')}</th>
                            <th>{t('FactTable.description')}</th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{factRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(FactTable);
