import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './CreateFactTypeForm.css';

class CreateFactTypeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newFactType: null,
            isLoading: false,
            description: '',
            enabled: false,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.description.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/facttypes`, {
                    description: this.state.description,
                    enabled: this.state.enabled
                })
                .then(function(response) {
                    self.setState({
                        newFactType: response.data.factType,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateFactTypeForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreateFactTypeForm.created_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateFactTypeForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateFactTypeForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateFactTypeForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="CreateFactTypeForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="enabled" bsSize="sm">
                                <label>{t('CreateFactTypeForm.enabled')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.enabled}
                                    onChange={this.handleChange}
                                >
                                    <option value="true">
                                        {t('CreateFactTypeForm.true')}
                                    </option>
                                    <option value="false">
                                        {t('CreateFactTypeForm.false')}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('CreateFactTypeForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateFactTypeForm.description')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateFactTypeForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'CreateFactTypeForm.add_facttype'
                                    )}`}
                                    loadingText={`${t(
                                        'CreateFactTypeForm.adding_facttype'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreateFactTypeForm">
                {this.state.newFactType === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreateFactTypeForm);
