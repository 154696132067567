import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import { Link } from 'react-router-dom';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

Moment.locale('en');

class TemplateMediaLibrariesTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    didConfirmDelete(medialibrary) {
        this.props.onDelete(medialibrary);
    }

    didSaveChanges(medialibrary, newName) {
        this.props.onSave(medialibrary, newName);
    }

    handleDeleteClicked(medialibrary) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(medialibrary),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    handleEditClicked(medialibrary) {
        const { t } = this.props;

        this.dialog.show({
            body: t('TemplateMediaLibrariesTable.medialibrary_name'),
            prompt: Dialog.TextPrompt({
                placeholder: t('TemplateMediaLibrariesTable.name'),
                initialValue: medialibrary.name
            }),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.save'),
                    dialog => this.didSaveChanges(medialibrary, dialog.value),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t, uuid } = this.props;
        let medialibraryRows = this.props.medialibraries.map(
            (medialibrary, index) => {
                return (
                    <tr key={index}>
                        <td>
                            <Link
                               style={{
                                alignItems: "center",
                                textAlign: "left"
                              }}
                             className="label label-primary"
                                to={`/templatelibraries/${
                                    medialibrary.uuid
                                }?rel=library`}
                            >
                                <i className={'fa fa-images'} />{' '}
                                {medialibrary.name}{' '}
                            </Link>{' '}
                        </td>{' '}
                        <td className="hidden-xs">
                            {' '}
                            {Moment(medialibrary.updatedAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}{' '}
                        </td>{' '}
                        <td className="hidden-xs">
                            {' '}
                            {Moment(medialibrary.createdAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}{' '}
                        </td>{' '}
                        <td align="center">
                            <TooltipButton
                                id="editButton"
                                className="btn-default pull-left"
                                onClick={() =>
                                    this.handleEditClicked(medialibrary)
                                }
                                tooltip={t(
                                    'TemplateMediaLibrariesTable.editmedialibrary'
                                )}
                            >
                                <em className="fas fa-pencil-alt" />
                            </TooltipButton>{' '}
                            {this.props.userIsAuthorized(this.props.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin',
                                'organisation_user'
                            ]) ? (
                                <TooltipButton
                                    id="deleteButton"
                                    className="btn-danger pull-right"
                                    tooltip={t(
                                        'TemplateMediaLibrariesTable.deletemedialibrary'
                                    )}
                                    onClick={() =>
                                        this.handleDeleteClicked(medialibrary)
                                    }
                                >
                                    <em className="fa fa-trash" />
                                </TooltipButton>
                            ) : null}
                        </td>{' '}
                    </tr>
                );
            }
        );

        return (
            <div
                id="TemplateMediaLibrariesTable"
                className="TemplateMediaLibrariesTable"
            >
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />{' '}
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>
                                {' '}
                                {t('TemplateMediaLibrariesTable.name')}{' '}
                            </th>{' '}
                            <th className="hidden-xs">
                                {' '}
                                {t(
                                    'TemplateMediaLibrariesTable.updated'
                                )}{' '}
                            </th>{' '}
                            <th className="hidden-xs">
                                {' '}
                                {t(
                                    'TemplateMediaLibrariesTable.created'
                                )}{' '}
                            </th>{' '}
                            <th>
                                <em className="fa fa-cog" />
                            </th>{' '}
                        </tr>{' '}
                    </thead>{' '}
                    <tbody> {medialibraryRows} </tbody>{' '}
                </Table>{' '}
            </div>
        );
    }
}

export default translate('translations')(TemplateMediaLibrariesTable);
