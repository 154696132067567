import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditOTDFactForm from '../components/EditOTDFactForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditOTDFact.css';

class EditOTDFact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otdFact: {
                description: '',
                detailed_description: '',
                month: 1,
                image_url: '',
                day: 1,
                date: new Date()
            }
        };
    }

    componentDidMount() {
        this.loadOTDFactFromServer();
    }

    loadOTDFactFromServer() {
        const { otdID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/onthisday/${otdID}`, {})
            .then(function(response) {
                self.setState({ otdFact: response.data.onthisday });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t('EditOTDFact.title')}`;

        return (
            <div className="EditOTDFact">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">{t('EditOTDFact.title')}</h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="far fa-calendar-alt" /> <Link to={`/otd`}>{t('OTD.title')}</Link>
                                    </li>
                                    <li className="active">
                                        <i className="fas fa-pencil-alt" /> {t('EditOTDFact.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <EditOTDFactForm otdFact={this.state.otdFact} />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditOTDFact);
