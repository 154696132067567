import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import InviteUserForm from '../components/InviteUserForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import './InviteUser.css';

class InviteUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized
        };
    }

    componentDidMount() {}

    render() {
        const { t } = this.props;
        const { uuid } = this.props.match.params;

        document.title = `MySign Digital Screen Solutions | ${t(
            'InviteUser.title'
        )}`;

        return (
            <div className="InviteUser">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('InviteUser.title')}
                                </h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>
                                            {t('Dashboard.title')}
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fas fa-users" />{' '}
                                        <Link
                                            to={`/organisations/${uuid}/users`}
                                        >
                                            {t('Users.title')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-edit" />{' '}
                                        {t('InviteUser.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <InviteUserForm
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        uuid={uuid}
                                    />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(InviteUser);
