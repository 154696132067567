import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './TooltipLabel.css';

export default ({
    className = '',
    tooltip = '',
    label = '',
    disabled = false,
    placement = 'top',
    delayShow = 300,
    delayHide = 150,
    ...props
}) => (
    <OverlayTrigger
        placement={placement}
        delayShow={delayShow}
        delayHide={delayHide}
        overlay={
            <Tooltip id="tooltip">
                <strong>{tooltip}</strong>
            </Tooltip>
        }
    >
            <span className={`TooltipLabel label ${className}`}>
                {label}
            </span>
    </OverlayTrigger>
);
