import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

import './EditAttributeForm.css';

class EditAttributeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            attribute: {
                name: '',
                default: false,
                uuid: '1'
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.attribute !== this.state.attribute) {
            if (nextProps.attribute) {
                this.setState({ attribute: nextProps.attribute });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return this.state.attribute.name.length > 0;
    }

    handleChange = event => {
        const attribute = this.state.attribute;
        attribute[event.target.id] = event.target.value;

        // update state
        this.setState({
            attribute
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const uuid = this.props.uuid;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(
                    `${config.baseURL}/organisations/${uuid}/attributes/${
                        this.state.attribute.uuid
                    }`,
                    {
                        name: this.state.attribute.name,
                        default: this.state.attribute.default
                    }
                )
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditAttributeForm.success')}:`,
                            style: 'success',
                            message: `${t('EditAttributeForm.saved_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditAttributeForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditAttributeForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditAttributeForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditAttributeForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t('EditAttributeForm.attribute_name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.attribute.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditAttributeForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="default" bsSize="sm">
                                <label>{t('EditAttributeForm.default')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.attribute.default}
                                    onChange={this.handleChange}
                                >
                                    <option value="true">
                                        {t('EditAttributeForm.true')}
                                    </option>
                                    <option value="false">
                                        {t('EditAttributeForm.false')}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t(
                                        'EditAttributeForm.default_option_explanation'
                                    )}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'EditAttributeForm.save_attribute'
                                    )}`}
                                    loadingText={`${t(
                                        'EditAttributeForm.saving_attribute'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditAttributeForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditAttributeForm);
