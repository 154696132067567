import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import { Link } from 'react-router-dom';

import './EditOrganisationNotificationSettingsForm.css';

class EditOrganisationNotificationSettingsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            organisation: {
                uuid: ''
            },
            preferences: {
                id: -1,
                emails: []
                // enabled: false,
                // interval: 'DAILY'
            },
            interval: "",
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.organisation !== this.state.organisation) {
            if (nextProps.organisation) {
                this.setState({ organisation: nextProps.organisation });
            }
        }

        if (nextProps.preferences !== this.state.preferences) {
            if(nextProps.preferences) {
                console.log(`Setting preferences: ${JSON.stringify(nextProps.preferences)}`);
                this.setState({ preferences: nextProps.preferences });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return (true);
    }

    handleChange = event => {
        const preferences = this.state.preferences;
        preferences[event.target.id] = event.target.value;

        // update state
        this.setState({
            preferences
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const uuid = this.state.organisation.uuid;
        const preferencesID = this.state.preferences.id;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .patch(`${config.baseURL}/organisations/${uuid}/notificationpreferences/${preferencesID}`, {
                    enabled: this.state.preferences.enabled,
                    interval: this.state.preferences.interval
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOrganisationNotificationSettingsForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'EditOrganisationNotificationSettingsForm.saved_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOrganisationNotificationSettingsForm.error')}:`,
                            style: 'danger',
                            message: 'Error occurred'
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditOrganisationNotificationSettingsForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditOrganisationNotificationSettingsForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        const uuid = this.state.organisation.uuid;
        const preferencesID = this.state.preferences.id;

        return (
            <div id="EditOrganisationNotificationSettingsForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="enabled" bsSize="sm">
                                <label>{t('EditOrganisationNotificationSettingsForm.enabled')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.preferences.enabled}
                                    onChange={this.handleChange}
                                >
                                    <option value="true">
                                        {t('EditOrganisationNotificationSettingsForm.true')}
                                    </option>
                                    <option value="false">
                                        {t('EditOrganisationNotificationSettingsForm.false')}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('EditOrganisationNotificationSettingsForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup
                                controlId="interval"
                                bsSize="sm"
                            >
                                <label>
                                    {t(
                                        'EditOrganisationNotificationSettingsForm.interval'
                                    )}
                                </label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.preferences.interval}
                                    onChange={this.handleChange}
                                >
                                    <option value="HOURLY">
                                        {t(
                                            'EditOrganisationNotificationSettingsForm.intervals.hourly'
                                        )}
                                    </option>
                                    <option value="DAILY">
                                        {t(
                                            'EditOrganisationNotificationSettingsForm.intervals.daily'
                                        )}
                                    </option>
                                    <option value="WEEKLY">
                                        {t(
                                            'EditOrganisationNotificationSettingsForm.intervals.weekly'
                                        )}
                                    </option>
                                    <option value="MONTHLY">
                                        {t(
                                            'EditOrganisationNotificationSettingsForm.intervals.monthly'
                                        )}
                                    </option>
                            
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t(
                                        'EditOrganisationNotificationSettingsForm.required'
                                    )}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={3}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'EditOrganisationNotificationSettingsForm.save_settings'
                                    )}`}
                                    loadingText={`${t(
                                        'EditOrganisationNotificationSettingsForm.saving_settings'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                            <a
                                        id="settingsButton"
                                        type="submit"
                                        className="btn btn-success"
                                        href={`/organisations/${this.state.organisation.uuid}/notificationsettings/${preferencesID}/rules`}
                                    >
                                        <em className="fas fa-clipboard-list" />{' '}
                                        {t('EditOrganisationNotificationSettingsForm.notification_rules')}
                                    </a>
                    </Row>
                </form>
                {/* <form onSubmit={this.handleSubmit}>
                <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="enabled" bsSize="sm">
                                <label>{t('EditOrganisationNotificationSettingsForm.enabled')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.enabled}
                                    onChange={this.handleChange}
                                >
                                    <option value="true">
                                        {t('EditOrganisationNotificationSettingsForm.true')}
                                    </option>
                                    <option value="false">
                                        {t('EditOrganisationNotificationSettingsForm.false')}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('EditOrganisationNotificationSettingsForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                </form> */}
            </div>
        );
    }

    render() {
        return <div className="EditOrganisationNotificationSettingsForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditOrganisationNotificationSettingsForm);
