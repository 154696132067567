import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox,
    Panel
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import { CompactPicker } from 'react-color';

import Select from 'react-select';

import './EditGlobalSnippetForm.css';

class EditGlobalSnippetForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            calendars: [],
            snippet: {
                name: '',
                typeID: '',
                width: 1920,
                height: 1080,
                animationType: 'fade',
                fadeType: 'sequence',
                animationSpeed: 'normal',
                backgroundColour: '',
                duration: '10000',
                fontSize: '',
                global: false,
                dialColour: '#000000',
                secondHandColour: '#ff0000',
                minuteHandColour: '#000000',
                hourHandColour: '#000000',
                showNumerals: true,
                headingFontSize: '',
                headingFontWeight: '',
                headingTextColour: '',
                headingFontFamily: '',
                detailFontSize: '',
                detailFontWeight: '',
                detailTextColour: '',
                detailFontFamily: '',
                latitude: '',
                longitude: '',
                lowTemperatureColour: '#bababa',
                highTemperatureColour: '#878787',
                muted: false,
                gridColumns: 3,
                gridRows: 3,
                gridDuration: 10000,
                eventCount: 10,
                calendarId: '',
                oddRowColour: '#E4F2F8',
                evenRowColour: '#FFFFFF',
                pageSize: 10

            },
            selectedCalendar: {value: '', label: ''},
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.snippet !== this.state.snippet) {
            if (nextProps.snippet) {
                this.setState({ snippet: nextProps.snippet });
            }
        }
    }

    handleCalendarChanged = chosenItem => {
        const snippet = this.state.snippet;
        snippet.calendarId = chosenItem.value;
        // update state
        this.setState({
            snippet,
            selectedCalendar: chosenItem
        });
    };

    loadOrganisationCalendarsFromServer() {
        var self = this;

        axios
            .get(
                `${config.baseURL}/organisations/${this.props.organisationUUID}/google/calendars`
            )
            .then(function(response) {
                let calendars = response.data.calendars;

                const options = [];

                for (const calendar of calendars) {
                    options.push({ value: calendar.id, label: calendar.name });
                }

                let selectedCalendar = self.state.selectedCalendar;
                for (const option of options) {
                    if(option.value === self.state.snippet.calendarId) {
                        selectedCalendar = option;
                    }
                }

                self.setState({
                    calendars: options,
                    selectedCalendar
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.loadOrganisationCalendarsFromServer();
    }

    validateForm() {
        return this.state.snippet.name.length > 0;
    }

    handleChange = event => {
        const snippet = this.state.snippet;
        snippet[event.target.id] = event.target.value;

        // update state
        this.setState({
            snippet
        });
    };

    handleMutedChanged = event => {
        const snippet = this.state.snippet;
        snippet.muted = event.target.checked;
        // update state
        this.setState({
            snippet
        });
    };

    handleShowNumeralsChanged = event => {
        const snippet = this.state.snippet;
        snippet.showNumerals = event.target.checked;
        // update state
        this.setState({
            snippet
        });
    };

    handleSubmit = event => {
        const { t, pageUUID } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const uuid = this.state.snippet.uuid;

        let snippetProperties = {
            name: this.state.snippet.name,
            width: this.state.snippet.width,
            height: this.state.snippet.height,
            typeID: this.state.snippet.typeID,
            style: ''
        };

        if (this.state.snippet.typeID == '1') {
            snippetProperties.animationSpeed = this.state.snippet.animationSpeed;
            snippetProperties.animationType = this.state.snippet.animationType;
            snippetProperties.fadeType = this.state.snippet.fadeType;
            snippetProperties.backgroundColour = this.state.snippet.backgroundColour;
            snippetProperties.gridColumns = this.state.snippet.gridColumns;
            snippetProperties.gridRows = this.state.snippet.gridRows;
            snippetProperties.gridDuration = this.state.snippet.gridDuration;
        }

        if (
            this.state.snippet.typeID == '2' ||
            this.state.snippet.typeID == '3' ||
            this.state.snippet.typeID == '5' ||
            this.state.snippet.typeID == '17' ||
            this.state.snippet.typeID == '6'
        ) {
            snippetProperties.duration = this.state.snippet.duration;
            snippetProperties.backgroundColour = this.state.snippet.backgroundColour;
        }

        if (
            this.state.snippet.typeID == '3' ||
            this.state.snippet.typeID == '6' ||
            this.state.snippet.typeID == '7' ||
            this.state.snippet.typeID == '8' ||
            this.state.snippet.typeID == '9' ||
            this.state.snippet.typeID == '10' ||
            this.state.snippet.typeID == '12'
        ) {
            snippetProperties.textColour = this.state.snippet.textColour;
            snippetProperties.fontFamily = this.state.snippet.fontFamily;
            snippetProperties.fontSize = this.state.snippet.fontSize;
        }

        if (this.state.snippet.typeID == '4') {
            snippetProperties.html = this.state.snippet.html;
        }

        if (this.state.snippet.typeID == '5' || this.state.snippet.typeID == '17') {
            snippetProperties.headingStyle = this.state.snippet.headingStyle;
            snippetProperties.detailStyle = this.state.snippet.detailStyle;
            snippetProperties.headingFontFamily = this.state.snippet.headingFontFamily;
            snippetProperties.headingFontSize = this.state.snippet.headingFontSize;
            snippetProperties.headingFontWeight = this.state.snippet.headingFontWeight;
            snippetProperties.headingTextColour = this.state.snippet.headingTextColour;
            snippetProperties.detailFontFamily = this.state.snippet.detailFontFamily;
            snippetProperties.detailFontSize = this.state.snippet.detailFontSize;
            snippetProperties.detailFontWeight = this.state.snippet.detailFontWeight;
            snippetProperties.detailTextColour = this.state.snippet.detailTextColour;
        }

        if (this.state.snippet.typeID == '7') {
            snippetProperties.rss_feed = this.state.snippet.rss_feed;
        }

        if (
            this.state.snippet.typeID == '8' ||
            this.state.snippet.typeID == '12'
        ) {
            snippetProperties.latitude = this.state.snippet.latitude;
            snippetProperties.longitude = this.state.snippet.longitude;
        }

        if (
            this.state.snippet.typeID == '9' ||
            this.state.snippet.typeID == '10'
        ) {
            snippetProperties.format = this.state.snippet.format;
            snippetProperties.textAlign = this.state.snippet.textAlign;
        }

        if (this.state.snippet.typeID == '11') {
            snippetProperties.backgroundColour = this.state.snippet.backgroundColour;
            snippetProperties.dialColour = this.state.snippet.dialColour;
            snippetProperties.secondHandColour = this.state.snippet.secondHandColour;
            snippetProperties.minuteHandColour = this.state.snippet.minuteHandColour;
            snippetProperties.hourHandColour = this.state.snippet.hourHandColour;
            snippetProperties.fontFamily = this.state.snippet.fontFamily;
            snippetProperties.textColour = this.state.snippet.textColour;
            snippetProperties.showNumerals = this.state.snippet.showNumerals;
            snippetProperties.height = this.state.snippet.width;
        }

        if (this.state.snippet.typeID == '12') {
            snippetProperties.lowTemperatureColour = this.state.snippet.lowTemperatureColour;
            snippetProperties.highTemperatureColour = this.state.snippet.highTemperatureColour;
        }

        if (this.state.snippet.typeID == '13') {
            snippetProperties.muted = this.state.snippet.muted;
        }

        if (this.state.snippet.typeID == '16') {
            snippetProperties.calendarId = this.state.snippet.calendarId;
            snippetProperties.eventCount = this.state.snippet.eventCount;
            snippetProperties.attributes = this.state.snippet.attributes;
            snippetProperties.pageSize = this.state.snippet.pageSize;
            snippetProperties.duration = this.state.snippet.duration;
            snippetProperties.oddRowColour = this.state.snippet.oddRowColour;
            snippetProperties.evenRowColour = this.state.snippet.evenRowColour;

        }

        try {
            var self = this;
            axios
                .put(`${config.baseURL}/snippets/${uuid}`, snippetProperties)
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditGlobalSnippetForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'EditGlobalSnippetForm.saved_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditGlobalSnippetForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditGlobalSnippetForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditGlobalSnippetForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditGlobalSnippetForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t('EditGlobalSnippetForm.name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.snippet.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditGlobalSnippetForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    {this.state.snippet.typeID != '11' ? (
                        <Row bsClass="form-row">
                            <Col sm={6}>
                                <FormGroup controlId="width" bsSize="sm">
                                    <ControlLabel>
                                        {t('EditGlobalSnippetForm.width')}
                                    </ControlLabel>
                                    <InputGroup>
                                        <span className="input-group-addon">
                                            <i className="fas fa-ruler-horizontal" />
                                        </span>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.width}
                                            onChange={this.handleChange}
                                        />
                                    </InputGroup>
                                    <small className="form-text text-muted">
                                        {t('EditGlobalSnippetForm.required')}
                                    </small>
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup controlId="height" bsSize="sm">
                                    <ControlLabel>
                                        {t('EditGlobalSnippetForm.height')}
                                    </ControlLabel>
                                    <InputGroup>
                                        <span className="input-group-addon">
                                            <i className="fas fa-ruler-vertical" />
                                        </span>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.height}
                                            onChange={this.handleChange}
                                        />
                                    </InputGroup>
                                    <small className="form-text text-muted">
                                        {t('EditGlobalSnippetForm.required')}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                    ) : null}

                    {this.state.snippet.typeID == '8' ||
                    this.state.snippet.typeID == '12' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="latitude" bsSize="sm">
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.latitude'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.latitude}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="longitude"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.longitude'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.longitude}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}
                    {this.state.snippet.typeID == '1' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="animationType"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t(
                                                'EditGlobalSnippetForm.animationType'
                                            )}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={
                                                this.state.snippet.animationType
                                            }
                                            onChange={this.handleChange}
                                        >
                                            <option value="fade">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.fade'
                                                )}
                                            </option>
                                            <option value="fadeEmpty">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.fadeEmpty'
                                                )}
                                            </option>
                                            <option value="slideOver">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.slideOver'
                                                )}
                                            </option>
                                            <option value="slideRight">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.slideRight'
                                                )}
                                            </option>
                                            <option value="slideUp">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.slideUp'
                                                )}
                                            </option>
                                            <option value="slideDown">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.slideDown'
                                                )}
                                            </option>
                                            <option value="slideEmpty">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.slideEmpty'
                                                )}
                                            </option>
                                            <option value="displayAll">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.displayAll'
                                                )}
                                            </option>
                                            <option value="displayGrid">
                                                {t(
                                                    'EditGlobalSnippetForm.animationTypes.displayGrid'
                                                )}
                                            </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="fadeType"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t(
                                                'EditGlobalSnippetForm.fadeType'
                                            )}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={
                                                this.state.snippet.fadeType
                                            }
                                            onChange={this.handleChange}
                                        >
                                            <option value="sequence">
                                                {t(
                                                    'EditGlobalSnippetForm.fadeTypes.sequence'
                                                )}
                                            </option>
                                            <option value="random">
                                                {t(
                                                    'EditGlobalSnippetForm.fadeTypes.random'
                                                )}
                                            </option>
                                           
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="animationSpeed"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t(
                                                'EditGlobalSnippetForm.animationSpeed'
                                            )}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={
                                                this.state.snippet
                                                    .animationSpeed
                                            }
                                            onChange={this.handleChange}
                                        >
                                            <option value="slow">
                                                {t(
                                                    'EditGlobalSnippetForm.animationSpeeds.slow'
                                                )}
                                            </option>
                                            <option value="normal">
                                                {t(
                                                    'EditGlobalSnippetForm.animationSpeeds.normal'
                                                )}
                                            </option>
                                            <option value="fast">
                                                {t(
                                                    'EditGlobalSnippetForm.animationSpeeds.fast'
                                                )}
                                            </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '1' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="backgroundColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.backgroundColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .backgroundColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '2' ||
                    this.state.snippet.typeID == '3' ||
                    this.state.snippet.typeID == '5' ||
                    this.state.snippet.typeID == '17' ||
                    this.state.snippet.typeID == '6' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                            {this.state.snippet.typeID != '17' ? (
                                <Col sm={6}>
                                    <FormGroup controlId="duration" bsSize="sm">
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.duration'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.duration}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>  ) : null }
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="backgroundColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.backgroundColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .backgroundColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

{this.state.snippet.animationType == 'displayGrid' ? (
                                <Fragment> <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="gridRows" bsSize="sm">
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.gridRows')}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.gridRows}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t('EditGlobalSnippetForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="gridColumns"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.gridColumns'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .gridColumns
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t('EditGlobalSnippetForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                </Row>
                                <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="gridDuration"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.gridDuration'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .gridDuration
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t('EditGlobalSnippetForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                </Row>
                                </Fragment>
                                ) : null }

                    {this.state.snippet.typeID == '3' ||
                    this.state.snippet.typeID == '6' ||
                    this.state.snippet.typeID == '7' ||
                    this.state.snippet.typeID == '8' ||
                    this.state.snippet.typeID == '9' ||
                    this.state.snippet.typeID == '10' ||
                    this.state.snippet.typeID == '12' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="textColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.textColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet.textColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="fontFamily"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.fontFamily'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet.fontFamily
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup controlId="fontSize" bsSize="sm">
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.fontSize'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.fontSize}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '4' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup controlId="html" bsSize="sm">
                                        <label>
                                            {t('EditGlobalSnippetForm.html')}
                                        </label>
                                        <FormControl
                                            componentClass="textarea"
                                            placeholder={t(
                                                'EditGlobalSnippetForm.html'
                                            )}
                                            value={this.state.snippet.html}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '5' || this.state.snippet.typeID == '17' ? (
                        <Fragment>
                            <Col sm={12}>
                                <Panel id="heading-style">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            {t(
                                                'EditGlobalSnippetForm.headingStyling'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            <Row bsClass="form-row">
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="headingFontSize"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'EditGlobalSnippetForm.headingFontSize'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .headingFontSize
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />

                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="headingFontFamily"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'EditGlobalSnippetForm.headingFontFamily'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .headingFontFamily
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row bsClass="form-row">
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="headingTextColour"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'EditGlobalSnippetForm.headingTextColour'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .headingTextColour
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />

                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="headingFontWeight"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'EditGlobalSnippetForm.headingFontWeight'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            componentClass="select"
                                                            placeholder="Choose..."
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .headingFontWeight
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        >
                                                            <option value="normal">
                                                                {t(
                                                                    'EditGlobalSnippetForm.fontweight.normal'
                                                                )}
                                                            </option>
                                                            <option value="bold">
                                                                {t(
                                                                    'EditGlobalSnippetForm.fontweight.bold'
                                                                )}
                                                            </option>
                                                        </FormControl>
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row bsClass="form-row">
                                                <Col sm={12}>
                                                    <FormGroup
                                                        controlId="headingStyle"
                                                        bsSize="sm"
                                                    >
                                                        <label>
                                                            {t(
                                                                'EditGlobalSnippetForm.headingStyle'
                                                            )}
                                                        </label>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            placeholder={t(
                                                                'EditGlobalSnippetForm.css_prompt'
                                                            )}
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .headingStyle
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            </Col>
                            <Col sm={12}>
                                <Panel id="detail-style">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            {t(
                                                'EditGlobalSnippetForm.detailStyling'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            <Row bsClass="form-row">
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="detailFontSize"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'EditGlobalSnippetForm.detailFontSize'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .detailFontSize
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />

                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="detailFontFamily"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'EditGlobalSnippetForm.detailFontFamily'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .detailFontFamily
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row bsClass="form-row">
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="detailTextColour"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'EditGlobalSnippetForm.detailTextColour'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .detailTextColour
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />

                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup
                                                        controlId="detailFontWeight"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'EditGlobalSnippetForm.detailFontWeight'
                                                            )}
                                                        </ControlLabel>
                                                        <FormControl
                                                            componentClass="select"
                                                            placeholder="Choose..."
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .detailFontWeight
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        >
                                                            <option value="normal">
                                                                {t(
                                                                    'EditGlobalSnippetForm.fontweight.normal'
                                                                )}
                                                            </option>
                                                            <option value="bold">
                                                                {t(
                                                                    'EditGlobalSnippetForm.fontweight.bold'
                                                                )}
                                                            </option>
                                                        </FormControl>
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row bsClass="form-row">
                                                <Col sm={12}>
                                                    <FormGroup
                                                        controlId="detailStyle"
                                                        bsSize="sm"
                                                    >
                                                        <label>
                                                            {t(
                                                                'EditGlobalSnippetForm.detailStyle'
                                                            )}
                                                        </label>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            placeholder={t(
                                                                'EditGlobalSnippetForm.css_prompt'
                                                            )}
                                                            value={
                                                                this.state
                                                                    .snippet
                                                                    .detailStyle
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <small className="form-text text-muted">
                                                            {t(
                                                                'EditGlobalSnippetForm.optional'
                                                            )}
                                                        </small>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            </Col>
                        </Fragment>
                    ) : null}
                    {this.state.snippet.typeID == '16' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="eventCount"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.eventCount')}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.eventCount}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t('EditGlobalSnippetForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                <FormGroup
                                        controlId="pageSize"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.pageSize'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet.pageSize
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            

                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="evenRowColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.evenRowColour')}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.snippet.evenRowColour}
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t('EditGlobalSnippetForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                <FormGroup
                                        controlId="oddRowColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.oddRowColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet.oddRowColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                            <Col sm={6}>
                                    <FormGroup
                                        controlId="duration"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.duration'
                                            )} (ms)
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet.duration
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            
                                <Col sm={6}>
                                    <FormGroup controlId="calendarId" bsSize="sm">
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.calendarId')}
                                        </ControlLabel>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={this.state.selectedCalendar}

                                            isLoading={this.state.isLoading}
                                            onChange={this.handleCalendarChanged}
                                            options={this.state.calendars}
                                        />
                                        <small className="form-text">
                                            {t('EditGlobalSnippetForm.required')}. Click <Link to={`/organisations/${this.props.organisationUUID}/google/auth`}>here</Link> to authenticate with Google.
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                <table {...{
                                        style: {
                                            'border-spacing': '10px',
                                            'border-collapse': 'separate'
                                        },
                                    }}>
                                    <tr  {...{
                                        style: {
                                            'font-weight': 'bold',
                                            'font-size': '13pt'
                                        },
                                    }}>
                                    
                                        <th>Attribute Type</th>
                                        <th>Title Override</th>
                                        <th>Font Size</th>
                                        <th>Text Colour</th>
                                        <th>Font Family</th>
                                        <th>Text Align</th>
                                        <th>Font Style</th>
                                        <th>Font Weight</th>
                                        <th>Text Decoration</th>
                                        <th>Width</th>
                                        <th>Enabled</th>
                                    </tr>
                                        <tr>
                                        <td>
                                                <ControlLabel>All<br/>Attributes</ControlLabel>
                                              </td>
                                              <td>
                                                <FormGroup controlId="titleOverride" bsSize="sm">
                                                
                                                </FormGroup>
                                              </td>
                                              <td>
                                                <FormGroup controlId="fontSize" bsSize="sm">
                                                  <FormControl
                                                    type="text"
                                                    onChange={(event) => this.handleAllAttributeChange(event)}
                                                  />
                                                </FormGroup>
                                              </td>
                                              <td>
                                                <FormGroup controlId="textColour" bsSize="sm">
                                                  <FormControl
                                                    type="text"
                                                    onChange={(event) => this.handleAllAttributeChange(event)}
                                                  />
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="fontFamily" bsSize="sm">
                                                <FormControl
                                                  type="text"
                                                  onChange={(event) => this.handleAllAttributeChange(event)}
                                                />
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="textAlign" bsSize="sm">

                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="Choose..."
                                                  onChange={(event) => this.handleAllAttributeChange(event)}
                                                >
                                                  <option value="left">{t("EditGlobalSnippetForm.align.left")}</option>
                                                  <option value="center">{t("EditGlobalSnippetForm.align.center")}</option>
                                                  <option value="right">{t("EditGlobalSnippetForm.align.right")}</option>
                                                </FormControl>
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="fontStyle" bsSize="sm">
                                                <FormControl
                                                  type="text"
                                                  onChange={(event) => this.handleAllAttributeChange(event)}
                                                />
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="fontWeight" bsSize="sm">
                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="Choose..."
                                                  onChange={(event) => this.handleAllAttributeChange(event)}
                                                >
                                                  <option value="normal">
                                                    {t("EditGlobalSnippetForm.fontweight.normal")}
                                                  </option>
                                                  <option value="bold">{t("EditGlobalSnippetForm.fontweight.bold")}</option>
                                                </FormControl>
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="textDecoration" bsSize="sm">
                                                <FormControl
                                                  type="text"
                                                  onChange={(event) => this.handleAllAttributeChange(event)}
                                                />
                                                 </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="width" bsSize="sm">
                                                <FormControl
                                                  type="text"
                                                  onChange={(event) => this.handleAllAttributeChange(event)}
                                                />
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="enabled" bsSize="sm">
                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="Choose..."
                                                  onChange={(event) => this.handleAllAttributeChange(event)}
                                                >
                                                  <option value="true">{t("EditGlobalSnippetForm.true")}</option>
                                                  <option value="false">{t("EditGlobalSnippetForm.false")}</option>
                                                </FormControl>
                                                </FormGroup>
                                              </td>

                                        </tr>
                                        <tr {...{
                                        style: {
                                            'border-bottom': '11px solid black'
                                        },
                                    }}>
                                      </tr>
                                        {this.state.snippet.attributes.map((attribute, key) => {
                                        return (
                                            <tr key={key} value={attribute.attribute_id}>
                                              <td>
                                                <ControlLabel>{attribute.name}</ControlLabel>
                                              </td>
                                              <td>
                                                <FormGroup controlId="titleOverride" bsSize="sm">
                                                  <FormControl
                                                    type="text"
                                                    value={attribute.titleOverride}
                                                    onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                  />
                                                </FormGroup>
                                              </td>
                                              <td>
                                                <FormGroup controlId="fontSize" bsSize="sm">
                                                  <FormControl
                                                    type="text"
                                                    value={attribute.fontSize}
                                                    onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                  />
                                                </FormGroup>
                                              </td>
                                              <td>
                                                <FormGroup controlId="textColour" bsSize="sm">
                                                  <FormControl
                                                    type="text"
                                                    value={attribute.textColour}
                                                    onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                  />
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="fontFamily" bsSize="sm">
                                                <FormControl
                                                  type="text"
                                                  value={attribute.fontFamily}
                                                  onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                />
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="textAlign" bsSize="sm">

                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="Choose..."
                                                  value={attribute.textAlign}
                                                  onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                >
                                                  <option value="left">{t("EditGlobalSnippetForm.align.left")}</option>
                                                  <option value="center">{t("EditGlobalSnippetForm.align.center")}</option>
                                                  <option value="right">{t("EditGlobalSnippetForm.align.right")}</option>
                                                </FormControl>
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="fontStyle" bsSize="sm">
                                                <FormControl
                                                  type="text"
                                                  value={attribute.fontStyle}
                                                  onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                />
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="fontWeight" bsSize="sm">
                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="Choose..."
                                                  value={attribute.fontWeight}
                                                  onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                >
                                                  <option value="normal">
                                                    {t("EditGlobalSnippetForm.fontweight.normal")}
                                                  </option>
                                                  <option value="bold">{t("EditGlobalSnippetForm.fontweight.bold")}</option>
                                                </FormControl>
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="textDecoration" bsSize="sm">
                                                <FormControl
                                                  type="text"
                                                  value={attribute.textDecoration}
                                                  onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                />
                                                 </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="width" bsSize="sm">
                                                <FormControl
                                                  type="text"
                                                  value={attribute.width}
                                                  onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                />
                                                </FormGroup>
                                              </td>
                                              <td>
                                              <FormGroup controlId="enabled" bsSize="sm">
                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="Choose..."
                                                  value={attribute.enabled}
                                                  onChange={(event) => this.handleAttributeChange(attribute.attribute_id, event)}
                                                >
                                                  <option value="true">{t("EditGlobalSnippetForm.true")}</option>
                                                  <option value="false">{t("EditGlobalSnippetForm.false")}</option>
                                                </FormControl>
                                                </FormGroup>
                                              </td>
                                            </tr>
                                          );                                          
                                    })}
                                    </table>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '7' ? (
                        <Row bsClass="form-row">
                            <Col sm={12}>
                                <FormGroup controlId="rss_feed" bsSize="sm">
                                    <label>
                                        {t('EditGlobalSnippetForm.rss_feed')}
                                    </label>
                                    <FormControl
                                        componentClass="textarea"
                                        placeholder={t(
                                            'EditGlobalSnippetForm.rss_prompt'
                                        )}
                                        value={this.state.snippet.rss_feed}
                                        onChange={this.handleChange}
                                    />
                                    <small className="form-text text-muted">
                                        {t('EditGlobalSnippetForm.required')}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                    ) : null}

                    {this.state.snippet.typeID == '9' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="format" bsSize="sm">
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.format')}
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.snippet.format}
                                            onChange={this.handleChange}
                                        >
                                            <option value="dddd D MMMM">
                                                Monday 1 January
                                            </option>
                                            <option value="dddd[<br/>]D[<br/>]MMMM">
                                                Monday&#9166; 1&#9166; January
                                            </option>
                                            <option value="dddd[<br/>]D MMMM">
                                                Monday&#9166; 1 January
                                            </option>
                                            <option value="ddd[<br/>]D MMM">
                                                Mon&#9166; 1 Jan
                                            </option>
                                            <option value="dddd Do MMMM">
                                                Monday 1st January
                                            </option>
                                            <option value="ddd D MMM">
                                                Mon 1 Jan
                                            </option>
                                            <option value="ddd[<br/>]D[<br/>]MMM">
                                                Mon&#9166; 1&#9166; Jan
                                            </option>
                                            <option value="D MMMM">
                                                1 January
                                            </option>
                                            <option value="Do MMMM">
                                                1st January
                                            </option>
                                            <option value="D MMM">1 Jan</option>
                                            <option value="dddd">Monday</option>
                                            <option value="ddd">Mon</option>
                                            <option value="D">1</option>
                                            <option value="MMMM">
                                                January
                                            </option>
                                            <option value="MMM">Jan</option>
                                            <option value="YYYY">2019</option>
                                            <option value="YY">19</option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="textAlign"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.textAlign'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.snippet.textAlign}
                                            onChange={this.handleChange}
                                        >
                                            <option value="left">
                                                {t(
                                                    'EditGlobalSnippetForm.align.left'
                                                )}
                                            </option>
                                            <option value="center">
                                                {t(
                                                    'EditGlobalSnippetForm.align.center'
                                                )}
                                            </option>
                                            <option value="right">
                                                {t(
                                                    'EditGlobalSnippetForm.align.right'
                                                )}
                                            </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '10' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="format" bsSize="sm">
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.format')}
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.snippet.format}
                                            onChange={this.handleChange}
                                        >
                                            <option value="hh:mma">
                                                03:30pm
                                            </option>
                                            <option value="hh:mmA">
                                                03:30PM
                                            </option>
                                            <option value="h:mma">
                                                3:30pm
                                            </option>
                                            <option value="h:mmA">
                                                3:30PM
                                            </option>
                                            <option value="hh:mm">03:30</option>
                                            <option value="HH:mm">15:30</option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="textAlign"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.textAlign'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={this.state.snippet.textAlign}
                                            onChange={this.handleChange}
                                        >
                                            <option value="left">
                                                {t(
                                                    'EditGlobalSnippetForm.align.left'
                                                )}
                                            </option>
                                            <option value="center">
                                                {t(
                                                    'EditGlobalSnippetForm.align.center'
                                                )}
                                            </option>
                                            <option value="right">
                                                {t(
                                                    'EditGlobalSnippetForm.align.right'
                                                )}
                                            </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '11' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup controlId="width" bsSize="sm">
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.width')}
                                        </ControlLabel>
                                        <InputGroup>
                                            <span className="input-group-addon">
                                                <i className="fas fa-ruler-horizontal" />
                                            </span>
                                            <FormControl
                                                type="text"
                                                value={this.state.snippet.width}
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup controlId="height" bsSize="sm">
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.height')}
                                        </ControlLabel>
                                        <InputGroup>
                                            <span className="input-group-addon">
                                                <i className="fas fa-ruler-vertical" />
                                            </span>
                                            <FormControl
                                                type="text"
                                                disabled
                                                value={this.state.snippet.width}
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.required'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="backgroundColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.backgroundColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .backgroundColour
                                            }
                                            onChange={this.handleChange}
                                        />

                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="dialColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.dialColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet.dialColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="secondHandColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.secondHandColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .secondHandColour
                                            }
                                            onChange={this.handleChange}
                                        />

                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="minuteHandColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.minuteHandColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .minuteHandColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="hourHandColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.hourHandColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .hourHandColour
                                            }
                                            onChange={this.handleChange}
                                        />

                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="fontFamily"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.fontFamily'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet.fontFamily
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="textColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.textColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet.textColour
                                            }
                                            onChange={this.handleChange}
                                        />

                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="showNumerals"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.showNumerals'
                                            )}
                                        </ControlLabel>
                                        <Checkbox
                                            controlId="showNumerals"
                                            checked={
                                                this.state.snippet.showNumerals
                                            }
                                            onChange={
                                                this.handleShowNumeralsChanged
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '12' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="lowTemperatureColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.lowTemperatureColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .lowTemperatureColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="highTemperatureColour"
                                        bsSize="sm"
                                    >
                                        <ControlLabel>
                                            {t(
                                                'EditGlobalSnippetForm.highTemperatureColour'
                                            )}
                                        </ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={
                                                this.state.snippet
                                                    .highTemperatureColour
                                            }
                                            onChange={this.handleChange}
                                        />
                                        <small className="form-text text-muted">
                                            {t(
                                                'EditGlobalSnippetForm.optional'
                                            )}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.snippet.typeID == '13' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup controlId="muted" bsSize="sm">
                                        <ControlLabel>
                                            {t('EditGlobalSnippetForm.muted')}
                                        </ControlLabel>
                                        <Checkbox
                                            controlId="muted"
                                            checked={this.state.snippet.muted}
                                            onChange={this.handleMutedChanged}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'EditGlobalSnippetForm.save_snippet'
                                    )}`}
                                    loadingText={`${t(
                                        'EditGlobalSnippetForm.saving_snippet'
                                    )}`}
                                />
                                {this.state.snippet.typeID == '8' ||
                                this.state.snippet.typeID == '12' ? (
                                    <small className="text-muted">
                                        <a href="https://developer.apple.com/weatherkit/">
                                            {t(
                                                'EditGlobalSnippetForm.appleweather_attribution'
                                            )}
                                        </a>
                                    </small>
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
    //                {this.state.saved ? this.renderForm() : this.renderSuccessMessage()}

    render() {
        return <div className="EditGlobalSnippetForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditGlobalSnippetForm);
