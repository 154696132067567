import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateTextLibraryTextForm from '../components/CreateTextLibraryTextForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './CreateTextLibraryText.css';

class CreateTextLibraryText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            library: { name: '' }
        };
    }

    componentDidMount() {
        this.loadLibraryFromServer();
    }

    loadLibraryFromServer() {
        const { textLibraryUUID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/textlibraries/${textLibraryUUID}`, {})
            .then(function(response) {
                self.setState({ library: response.data.textlibrary });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }
    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreateTextLibraryText.title'
        )}`;

        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID,
            textLibraryUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        return (
            <div className="CreateTextLibraryText">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreateTextLibraryText.title')}
                                    {' - '}
                                    {this.state.library.name}{' '}
                                    {rel === 'player' ? (
                                        <small>
                                            {this.state.player_name}{' '}
                                            <small
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {this.state.organisation_name}
                                            </small>
                                        </small>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>{' '}
                                            <li>
                                                <i className="fa fa-font" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('TextLibrarySnippet.title')}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateTextLibraryText.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}

{rel === 'library' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-font" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/textlibraries?rel=library`}
                                                >
                                                    {t(
                                                        'SideNavigation.globaltextlibraries'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fas fa-font" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/textlibraries/${textLibraryUUID}?rel=library`}
                                                >
                                                    {t(
                                                        'TextLibrarySnippetTextLibrary.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateTextLibraryText.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}

                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            <li>
                                                <i className="fa fa-font" />
                                                <Link
                                                    to={`/organisations/${uuid}/textlibrarysnippets/${snippetUUID}?rel=snippet`}
                                                >
                                                    {' '}
                                                    {t('TextLibrarySnippet.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateTextLibraryText.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-font" />
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}/textlibrarysnippets/${snippetUUID}?rel=page`}
                                                >
                                                    {' '}
                                                    {t('TextLibrarySnippet.title')}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreateTextLibraryText.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>

                                <PlainPanel>
                                    <CreateTextLibraryTextForm textLibraryUUID={textLibraryUUID} />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateTextLibraryText);
