import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import { Link } from 'react-router-dom';
import config from '../config';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

Moment.locale('en');

class OrganisationPagesTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    didConfirmDelete(page) {
        this.props.onDelete(page);
    }

    handleDeleteClicked(page) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(page),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t, uuid, playerUUID } = this.props;
        let pageRows = this.props.pages.map((page, index) => {
            return (
                <tr key={index}>
                    <td align="center" width={80}>
                        <OverlayTrigger
                            placement="top"
                            delayShow="300"
                            delayHide="150"
                            overlay={
                                <Tooltip id="tooltip">
                                    <strong>
                                        {t(
                                            'OrganisationPagesTable.previewpage'
                                        )}
                                    </strong>
                                </Tooltip>
                            }
                        >
                            <a
                                id="previewButton"
                                className="btn btn-primary"
                                target="_blank"
                                href={`${
                                    config.playersBaseURL
                                }/mediaserver/unit.php?pageUUID=${page.uuid}`}
                            >
                                <em className="fa fa-eye" />
                            </a>
                        </OverlayTrigger>
                    </td>
                    <td width="250">
                        <Link
                         style={{
                            alignItems: "center",
                            textAlign: "left"
                          }}
                         className="label label-primary"
                            to={`/organisations/${uuid}/pages/${
                                page.uuid
                            }?rel=page`}
                        >
                            <i className="fa fa-file" /> {page.name}
                        </Link>
                    </td>
                    <td width="80" className="hidden-xs">
                        <span className="label label-default">
                            {page.width}
                        </span>
                    </td>
                    <td width="80" className="hidden-xs">
                        <span className="label label-default">
                            {page.height}
                        </span>
                    </td>
                    <td width="150" className="hidden-xs hidden-sm">
                        {page.backgroundColour}
                    </td>
                    <td width="150" className="hidden-xs">
                        {page.textColour}
                    </td>
                    <td width="150" className="hidden-xs">
                        {page.fontFamily}
                    </td>
                    <td width="" className="hidden-xs hidden-sm hidden-md">
                        {Moment(page.updatedAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs hidden-sm hidden-md">
                        {Moment(page.createdAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td width="195" align="center">
                        <div className="btn-toolbar pull-right">
                            <TooltipLink
                                id=""
                                className="btn btn-default"
                                tooltip={t(
                                    'OrganisationPagesTable.pageplayers'
                                )}
                                to={`/organisations/${uuid}/pages/${
                                    page.uuid
                                }/players`}
                            >
                                <em className="fa fa-tv" />{' '}
                                {t('OrganisationPagesTable.players')}
                            </TooltipLink>

                            {this.props.userIsAuthorized(this.props.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin',
                                'organisation_user'
                            ]) ? (
                                <Fragment>
                                    <TooltipLink
                                        id=""
                                        className="btn btn-default"
                                        tooltip={t(
                                            'OrganisationPagesTable.editpage'
                                        )}
                                        to={`/organisations/${uuid}/pages/${
                                            page.uuid
                                        }/edit?rel=page`}
                                    >
                                        <em className="fas fa-pencil-alt" />
                                    </TooltipLink>
                                    <TooltipButton
                                        id="deleteButton"
                                        className="btn-danger pull-right"
                                        tooltip={t(
                                            'OrganisationPagesTable.deletepage'
                                        )}
                                        onClick={() =>
                                            this.handleDeleteClicked(page)
                                        }
                                    >
                                        <em className="fa fa-trash" />
                                    </TooltipButton>
                                </Fragment>
                            ) : null}
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div id="OrganisationPagesTable" className="OrganisationPagesTable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th width="80">
                                {t('OrganisationPagesTable.preview')}
                            </th>
                            <th>{t('OrganisationPagesTable.name')}</th>
                            <th className="hidden-xs">
                                {t('OrganisationPagesTable.width')}
                            </th>
                            <th className="hidden-xs">
                                {t('OrganisationPagesTable.height')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('OrganisationPagesTable.backgroundColour')}
                            </th>
                            <th className="hidden-xs">
                                {t('OrganisationPagesTable.textColour')}
                            </th>
                            <th className="hidden-xs">
                                {t('OrganisationPagesTable.fontFamily')}
                            </th>

                            <th className="hidden-xs hidden-sm hidden-md">
                                {t('OrganisationPagesTable.updated')}
                            </th>
                            <th className="hidden-xs hidden-sm hidden-md">
                                {t('OrganisationPagesTable.created')}
                            </th>
                            <th width="195">
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{pageRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(OrganisationPagesTable);
