import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreateQuestionForm from '../components/CreateQuestionForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import './CreateQuestion.css';

class CreateQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionTypes: []
        };
    }

    componentDidMount() {
        this.loadQuestionTypesFromServer();
    }

    loadQuestionTypesFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/questiontypes`, {
                params: {
                    all: true
                }
            })
            .then(function(response) {
                self.setState({
                    questionTypes: response.data.questionTypes
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreateQuestion.title'
        )}`;

        const { uuid } = this.props.match.params;

        return (
            <div className="CreateQuestion">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreateQuestion.title')}
                                </h1>
                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-question" />{' '}
                                        <Link to={`/qanda`}>
                                            {t('QAndA.title')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-plus" />{' '}
                                        {t('CreateQuestion.title')}
                                    </li>
                                </ol>

                                <PlainPanel>
                                    <CreateQuestionForm
                                        uuid={uuid}
                                        questionTypes={this.state.questionTypes}
                                    />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreateQuestion);
