import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';
Moment.locale('en');

class EngineerPlayerTable extends Component {

    render() {
        const { t } = this.props;
        let playerRows = this.props.players.map((player, index) => {
            return (
                <tr key={index}>
                    <td align="center" style={{ width: '5%'}}>
                        <OverlayTrigger
                            placement="top"
                            delayShow="300"
                            delayHide="150"
                            overlay={
                                <Tooltip id="tooltip">
                                    <strong>
                                        {t('PlayersTable.previewplayer')}
                                    </strong>
                                </Tooltip>
                            }
                        >
                            <a
                                id="previewButton"
                                className="btn btn-primary"
                                target="_blank"
                                href={`${
                                    config.playersBaseURL
                                }/mediaserver/unit.php?playerUUID=${
                                    player.uuid
                                }&preview=1`}
                            >
                                <em className="fa fa-eye" />
                            </a>
                        </OverlayTrigger>
                    </td>
                    <td className="hidden-xs hidden-sm"  style={{ width: '5%'}}>
                        {player.online ? (
                            <span className="label label-success">
                                {t('PlayersTable.online')}
                            </span>
                        ) : (
                            <span className="label label-danger">
                                {t('PlayersTable.offline')}
                            </span>
                        )}
                    </td>
                    <td style={{ width: '20%'}}>
                        <strong>{player.name}</strong>
                    </td>
                    <td className="hidden-xs hidden-sm" style={{ width: '20%'}}>{player.location}</td>
                    <td className="hidden-xs hidden-sm" style={{ width: '10%'}}>
                        {player.macAddress}
                    </td>
                    <td className="hidden-xs hidden-sm" style={{ width: '10%'}}>
                        {player.serialNumber}
                    </td>
                    <td className="hidden-sm hidden-xs hidden-md" style={{ width: '11%'}}>
                        {Moment(player.installDate).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs" style={{ width: '15%'}}>
                        {player.lastSeen
                            ? Moment(player.lastSeen).fromNow()
                            : t('PlayersTable.never')}
                    </td>
                </tr>
            );
        });

        return (
            <div id="players" className="EngineerPlayerTable">
            
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th >{t('PlayersTable.preview')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayersTable.status')}
                            </th>
                            <th>{t('PlayersTable.name')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayersTable.location')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayersTable.mac_address')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('PlayersTable.serial_number')}
                            </th>
                            <th className="hidden-xs hidden-sm hidden-md">
                                {t('PlayersTable.install_date')}
                            </th>
                            <th className="hidden-xs">
                                {t('PlayersTable.last_seen')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{playerRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(EngineerPlayerTable);
