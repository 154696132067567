import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

export default ({ component: Component, props: componentProps, otherProps: otherProps, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {

                console.log(`User: ${JSON.stringify(componentProps.user)}`);
                
                if (componentProps.user.isAuthenticated) {

                    if (componentProps.user.isEngineer === true && props.location.pathname !== '/engineers') {
                        return (<Redirect
                            to={`/engineers`}
                        />)
                    } else {
                        return <Component {...props} {...componentProps} {...otherProps} />
                    }
                } else {
                    return (<Redirect
                        to={`/login?redirect=${props.location.pathname}${
                            props.location.search
                        }`}
                    />)
                } 
            }}
        />
    );
};
