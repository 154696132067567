import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import DateTime from 'react-datetime';
import TimePicker from 'rc-time-picker';

import 'react-datetime/css/react-datetime.css';
import './EditOrganisationNotificationRuleForm.css';
import Moment from 'moment';
import 'rc-time-picker/assets/index.css';

import './EditOrganisationNotificationRuleForm.css';

class EditOrganisationNotificationRuleForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            ruleTypes: this.props.ruleTypes,
            rule: {
                name: '',
                description: '',
                type_id: 1,
                start_time: new Moment(),
                end_time: new Moment(),
                end_date: new Moment(),
                start_date: new Moment(),
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
                exclude: false
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    componentWillReceiveProps(newProps) {
        if (newProps.rule) {
            newProps.rule.start_time = new Moment(newProps.rule.start_time);
            newProps.rule.end_time = new Moment(newProps.rule.end_time);

            newProps.rule.start_date = new Moment(newProps.rule.start_date);
            newProps.rule.end_date = new Moment(newProps.rule.end_date);

            this.setState({
                organisationUUID: newProps.organisationUUID,
                ruleTypes: newProps.ruleTypes,
                attributes: newProps.attributes,
                ruleGroupUUID: newProps.ruleGroupUUID,
                rule: newProps.rule
            });
        }
    }

    validateForm() {
        if (this.state.rule && this.state.rule.name) {
            return this.state.rule.name.length > 0;
        }
        return false;
    }

    handleStartDateChange = date => {
        // update state
        const rule = this.state.rule;
        rule.start_date = date;

        // update state
        this.setState({
            rule
        });
    };

    handleEndDateChange = date => {
        // update state
        const rule = this.state.rule;
        rule.end_date = date;

        // update state
        this.setState({
            rule
        });
    };

    handleStartTimeChange = time => {
        // update state
        const rule = this.state.rule;
        rule.start_time = time;

        // update state
        this.setState({
            rule
        });
    };

    handleEndTimeChange = time => {
        // update state
        const rule = this.state.rule;
        rule.end_time = time;

        // update state
        this.setState({
            rule
        });
    };

    handleDayChanged = event => {
        const rule = this.state.rule;
        rule[event.target.id] = event.target.checked;

        // update state
        this.setState({
            rule
        });
    };

    handleChange = event => {
        const rule = this.state.rule;

        let updatedValue = event.target.value;

        if (updatedValue === "true" || updatedValue === "false") {
            updatedValue = JSON.parse(updatedValue);
        }
    
        rule[event.target.id] = updatedValue;

        // update state
        this.setState({
            rule
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        let ruleProperties = {
            name: this.state.rule.name,
            description: '_',
            typeID: this.state.rule.type_id
        };

        if (this.state.rule.type_id == '1') {
            ruleProperties.start_date = this.state.rule.start_date;
            ruleProperties.end_date = this.state.rule.end_date;
        }

        if (this.state.rule.type_id == '2') {
            ruleProperties.attributeUUID = this.state.rule.attribute_uuid;
            ruleProperties.exclude = this.state.rule.exclude;
        }

        if (this.state.rule.type_id == '3') {
            ruleProperties.start_time = this.state.rule.start_time;
            ruleProperties.end_time = this.state.rule.end_time;
            ruleProperties.monday = this.state.rule.monday;
            ruleProperties.tuesday = this.state.rule.tuesday;
            ruleProperties.wednesday = this.state.rule.wednesday;
            ruleProperties.thursday = this.state.rule.thursday;
            ruleProperties.friday = this.state.rule.friday;
            ruleProperties.saturday = this.state.rule.saturday;
            ruleProperties.sunday = this.state.rule.sunday;
        }

        try {


            let self = this;
            axios
                .put(
                    `${config.baseURL}/organisations/${this.state.organisationUUID}/notifications/rulegroups/${
                        this.state.ruleGroupUUID
                    }/rules/${this.state.rule.uuid}`,
                    ruleProperties
                )

                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOrganisationNotificationRuleForm.success')}:`,
                            style: 'success',
                            message: `${t('EditOrganisationNotificationRuleForm.saved_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOrganisationNotificationRuleForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            console.log(`${e}`);
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditOrganisationNotificationRuleForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditOrganisationNotificationRuleForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditOrganisationNotificationRuleForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t('EditOrganisationNotificationRuleForm.name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.rule.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOrganisationNotificationRuleForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="type_id" bsSize="sm">
                                <label>{t('EditOrganisationNotificationRuleForm.type')}</label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.rule.type_id}
                                    disabled={true}
                                    onChange={this.handleChange}
                                >
                                    {this.state.ruleTypes.map((type, key) => {
                                        return (
                                            <option key={key} value={type.id}>
                                                {type.description}
                                            </option>
                                        );
                                    })}
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('EditOrganisationNotificationRuleForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    {this.state.rule.type_id == '1' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="start_date"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t('EditOrganisationNotificationRuleForm.start_date')}
                                        </label>
                                        <DateTime
                                            dateFormat="MMMM Do YYYY"
                                            utc={false}
                                            onChange={
                                                this.handleStartDateChange
                                            }
                                            value={this.state.rule.start_date}
                                        />
                                        <small className="form-text text-muted">
                                            {t('EditOrganisationNotificationRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup controlId="end_date" bsSize="sm">
                                        <label>
                                            {t('EditOrganisationNotificationRuleForm.end_date')}
                                        </label>
                                        <DateTime
                                            dateFormat="MMMM Do YYYY"
                                            utc={false}
                                            onChange={this.handleEndDateChange}
                                            value={this.state.rule.end_date}
                                        />
                                        <small className="form-text text-muted">
                                            {t('EditOrganisationNotificationRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.rule.type_id == '2' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="attribute_uuid"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t('EditOrganisationNotificationRuleForm.attribute')}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={
                                                this.state.rule.attribute_uuid
                                            }
                                            onChange={this.handleChange}
                                        >
                                            {this.state.attributes.map(
                                                (attribute, key) => {
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={
                                                                attribute.uuid
                                                            }
                                                        >
                                                            {attribute.name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t('EditOrganisationNotificationRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="exclude"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t('EditTextRuleForm.exclude_or_include')}
                                        </label>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={
                                                this.state.rule.exclude ? "true" : "false"
                                            }
                                            onChange={this.handleChange}
                                        >
                                             <option
                                                            key={1}
                                                            value="false"
                                                        >
                                                            {"Include"}
                                                        </option>
                                                        <option
                                                            key={2}
                                                            value="true"
                                                        >
                                                            {"Exclude"}
                                                        </option>
                                        </FormControl>
                                        <small className="form-text text-muted">
                                            {t('EditTextRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}

                    {this.state.rule.type_id == '3' ? (
                        <Fragment>
                            <Row bsClass="form-row">
                                <Col sm={6}>
                                    <FormGroup
                                        controlId="start_time"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t('EditOrganisationNotificationRuleForm.start_time')}
                                        </label>
                                        <TimePicker
                                            style={{ display: 'block' }}
                                            defaultValue={Moment()}
                                            onChange={
                                                this.handleStartTimeChange
                                            }
                                            value={this.state.rule.start_time}
                                        />{' '}
                                        <small className="form-text text-muted">
                                            {t('EditOrganisationNotificationRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup controlId="end_time" bsSize="sm">
                                        <label>
                                            {t('EditOrganisationNotificationRuleForm.end_time')}
                                        </label>
                                        <TimePicker
                                            style={{ display: 'block' }}
                                            defaultValue={Moment()}
                                            onChange={this.handleEndTimeChange}
                                            value={this.state.rule.end_time}
                                        />
                                        <small className="form-text text-muted">
                                            {t('EditOrganisationNotificationRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row bsClass="form-row">
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="start_time"
                                        bsSize="sm"
                                    >
                                        <label>
                                            {t(
                                                'EditOrganisationNotificationRuleForm.valid_week_days'
                                            )}
                                        </label>
                                        <FormGroup>
                                            <Checkbox
                                                id="monday"
                                                checked={this.state.rule.monday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('EditOrganisationNotificationRuleForm.monday')}
                                            </Checkbox>
                                            <Checkbox
                                                id="tuesday"
                                                checked={
                                                    this.state.rule.tuesday
                                                }
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('EditOrganisationNotificationRuleForm.tuesday')}
                                            </Checkbox>
                                            <Checkbox
                                                id="wednesday"
                                                checked={
                                                    this.state.rule.wednesday
                                                }
                                                onChange={this.handleDayChanged}
                                            >
                                                {t(
                                                    'EditOrganisationNotificationRuleForm.wednesday'
                                                )}
                                            </Checkbox>
                                            <Checkbox
                                                id="thursday"
                                                checked={
                                                    this.state.rule.thursday
                                                }
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('EditOrganisationNotificationRuleForm.thursday')}
                                            </Checkbox>
                                            <Checkbox
                                                id="friday"
                                                checked={this.state.rule.friday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('EditOrganisationNotificationRuleForm.friday')}
                                            </Checkbox>
                                            <Checkbox
                                                id="saturday"
                                                checked={
                                                    this.state.rule.saturday
                                                }
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('EditOrganisationNotificationRuleForm.saturday')}
                                            </Checkbox>
                                            <Checkbox
                                                id="sunday"
                                                checked={this.state.rule.sunday}
                                                onChange={this.handleDayChanged}
                                            >
                                                {t('EditOrganisationNotificationRuleForm.sunday')}
                                            </Checkbox>
                                        </FormGroup>
                                        <small className="form-text text-muted">
                                            {t('EditOrganisationNotificationRuleForm.required')}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : null}
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('EditOrganisationNotificationRuleForm.save_rule')}`}
                                    loadingText={`${t(
                                        'EditOrganisationNotificationRuleForm.saving_rule'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditOrganisationNotificationRuleForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditOrganisationNotificationRuleForm);
