import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import config from '../config';
import { Link } from 'react-router-dom';
import GoogleButton from 'react-google-button';

import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    ControlLabel,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';

import { translate } from 'react-i18next';

//import './OrganisationGoogleAuth.css';

class OrganisationGoogleAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            sign_in_url: ''
        };
    }

    componentDidMount() {
        this.loadOrganisationInformation();
        this.loadOrganisationGoogleAuthFromServer();
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationGoogleAuthFromServer() {
        var self = this;
        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}/google/auth_url`, {})
            .then(function(response) {
                console.log(
                    `Attributes: ${JSON.stringify(response.data.attributes)}`
                );
                self.setState({
                    sign_in_url: response.data.url
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }


    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();
        this.loadOrganisationGoogleAuthFromServer();
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'OrganisationGoogleAuth.title'
        )}`;

        const user = this.state.user;
        const { uuid } = this.props.match.params;

        return (
            <div className="OrganisationGoogleAuth">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('OrganisationGoogleAuth.title')}{' '}
                                    <small>
                                        {this.state.organisation_name}
                                    </small>
                                </h1>

                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>
                                            {t('Dashboard.title')}
                                        </Link>
                                    </li>

                                    <li className="active">
                                        <i className="fa fa-tags" />{' '}
                                        {t('SideNavigation.google_auth')}
                                    </li>
                                </ol>

                                <Panel id="heading-style">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            {t(
                                                'GoogleAuthenticationForm.information'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                        <Panel.Body>
                                            <Row bsClass="form-row">
                                                <Col sm={4}>
                                                    <FormGroup
                                                        controlId="headingFontSize"
                                                        bsSize="sm"
                                                    >
                                                        <ControlLabel>
                                                            {t(
                                                                'GoogleAuthenticationForm.click_to_authenticate'
                                                            )}
                                                        </ControlLabel>
                                                        <br/>
                                            
                                                        <GoogleButton
                                                            disabled={false}
                                                            className="btn-primary"
                                                            href={this.state.sign_in_url}
                                                            onClick={() => { window.location.assign(this.state.sign_in_url); }}
                                                        >
                                                        </GoogleButton>

                                                        
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                        </Panel.Body>
                                </Panel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(OrganisationGoogleAuth);
