import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { translate } from 'react-i18next';
import Navigation from '../components/Navigation';
import PlayerTable from '../components/PlayerTable';
import PaginationPanel from '../components/PaginationPanel';
import config from '../config';
import { Link } from 'react-router-dom';

import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';

import './Players.css';

class Players extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            players: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1
        };
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {
            this.loadPlayersFromServer();
        });
    };

    deletePlayer(player) {
        const { uuid } = this.props.match.params;

        var self = this;
        axios
            .delete(`${config.baseURL}/players/${player.uuid}`)
            .then(function(response) {
                self.loadPlayersFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.loadPlayersFromServer();
    }

    loadPlayersFromServer() {
        var self = this;
        axios
            .get(`${config.baseURL}/players`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    players: response.data.players,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url);

        event.preventDefault();
        this.loadPlayersFromServer();
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'Players.title'
        )}`;

        return (
            <div className="Players">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('Players.title')}
                                </h1>
                                <ol className="breadcrumb">
                                    <li className="active">
                                        <i className="fa fa-desktop" />{' '}
                                        {t('Players.title')}
                                    </li>
                                </ol>
                            </Col>
                        </Row>

                        <PaginationPanel
                            heading={
                                <Row>
                                    <Col xs={12} className="text-right">
                                        <form
                                            role="search"
                                            onSubmit={this.handleSubmit}
                                        >
                                            <FormGroup controlId="search">
                                                <InputGroup>
                                                    <FormControl
                                                        type="text"
                                                        value={
                                                            this.state.search
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        placeholder={t(
                                                            'Search'
                                                        )}
                                                    />
                                                    <InputGroup.Button>
                                                        <Button
                                                            onClick={
                                                                this
                                                                    .handleSubmit
                                                            }
                                                        >
                                                            <i className="glyphicon glyphicon-search" />
                                                        </Button>
                                                    </InputGroup.Button>
                                                </InputGroup>
                                            </FormGroup>
                                        </form>
                                    </Col>
                                </Row>
                            }
                            pagination={
                                <ReactPaginate
                                    previousLabel={t('Pagination.previous')}
                                    nextLabel={t('Pagination.next')}
                                    breakLabel={<Link to="#">...</Link>}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    forcePage={this.state.selected}
                                />
                            }
                        >
                            <PlayerTable
                                userIsAuthorized={this.state.userIsAuthorized}
                                user={this.state.user}
                                players={this.state.players}
                                onDelete={this.deletePlayer.bind(this)}
                            />
                        </PaginationPanel>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(Players);
