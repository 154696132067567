import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';

import { Table, Button } from 'react-bootstrap';
import TooltipButton from './TooltipButton';

import Moment from 'moment';

class TextLibraryTextRulesTable extends Component {

    didConfirmDelete(rulegroup, rule) {
        this.props.onDelete(rulegroup, rule);
    }

    handleDeleteClicked(rulegroup, rule) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(rulegroup, rule),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    getDescription(rule) {
        const { t } = this.props;

        if (rule.type_id == 1) {
            return (
                <p>
                    {`${t('TextLibraryTextRulesTable.the_date_is_between')} `}
                    <span className="label label-success">
                        {Moment(rule.start_date).format(
                            'MMMM Do YYYY, h:mm:ss a'
                        )}
                    </span>{' '}
                    {`${t('TextLibraryTextRulesTable.and')} `}
                    <span className="label label-success">
                        {Moment(rule.end_date).format(
                            'MMMM Do YYYY, h:mm:ss a'
                        )}
                    </span>{' '}
                </p>
            );
        } else if (rule.type_id == 2) {
            return (
                <p>
                    {rule.exclude ? `${t('TextLibraryTextRulesTable.the_player_must_not_have_the')} ` : `${t('TextLibraryTextRulesTable.the_player_must_have_the')} `}
                    <span className="label label-success">
                        {rule.attribute_name}
                    </span>{' '}
                    {`${t('TextLibraryTextRulesTable.attribute')}`}
                </p>
            );
        } else if (rule.type_id == 3) {
            return (
                <p>
                    {`${t('TextLibraryTextRulesTable.every')} `}
                    <span className="label label-success">
                        {this.getValidDays(rule)}
                    </span>{' '}
                    {`${t('TextLibraryTextRulesTable.the_time_is_between')} `}
                    <span className="label label-success">
                        {Moment(rule.start_time).format('h:mm a')}
                    </span>{' '}
                    {`${t('TextLibraryTextRulesTable.and')} `}
                    <span className="label label-success">
                        {Moment(rule.end_time).format('h:mm a')}
                    </span>{' '}
                </p>
            );
        }
    }

    getValidDays(rule) {
        var abbreviations = '';
        if (rule.monday == true) {
            abbreviations += 'M';
        } else {
            abbreviations += '-';
        }
        if (rule.tuesday == true) {
            abbreviations += 'T';
        } else {
            abbreviations += '-';
        }
        if (rule.wednesday == true) {
            abbreviations += 'W';
        } else {
            abbreviations += '-';
        }
        if (rule.thursday == true) {
            abbreviations += 'T';
        } else {
            abbreviations += '-';
        }
        if (rule.friday == true) {
            abbreviations += 'F';
        } else {
            abbreviations += '-';
        }
        if (rule.saturday == true) {
            abbreviations += 'S';
        } else {
            abbreviations += '-';
        }
        if (rule.sunday == true) {
            abbreviations += 'S';
        } else {
            abbreviations += '-';
        }

        return abbreviations;
    }

    render() {
        const { t } = this.props;

        let ruleRows = null;
        if (this.props.rules) {
            ruleRows = this.props.rules.map((rule, index) => {
            console.log(`Rule: ${JSON.stringify(rule)}`);
            return (
                <tr key={index}>
                    <td>{rule.name}</td>
                    <td width={150}>
                        {' '}
                        <span className="label label-default">
                            {rule.type_description}
                        </span>
                    </td>
                    <td>{this.getDescription(rule)}</td>

                    <td align="center">
                        {this.props.userIsAuthorized(this.props.user, [
                            'staff_super_admin',
                            'staff_admin',
                            'organisation_admin',
                            'organisation_user'
                        ]) ? (
                            <Fragment>
                                <TooltipButton
                                    id=""
                                    className="btn-default"
                                    tooltip={t('TextLibraryTextRulesTable.editrule')}
                                    onClick={() =>
                                        this.props.handleEditClicked(this.props.rulegroup, rule)
                                    }
                                >
                                    <em className="fas fa-pencil-alt" />
                                </TooltipButton>
                                <TooltipButton
                                    id="deleteButton"
                                    className="btn-danger pull-right"
                                    tooltip={t('TextLibraryTextRulesTable.deleterule')}
                                    onClick={() =>
                                        this.handleDeleteClicked(this.props.rulegroup, rule)
                                    }
                                >
                                    <em className="fa fa-trash" />
                                </TooltipButton>
                            </Fragment>
                        ) : null}
                    </td>
                </tr>
            );
        });
    }

        return (
            <div id="TextLibraryTextRulesTable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th width={250}>{t('TextLibraryTextRulesTable.name')}</th>
                            <th width={150}>{t('TextLibraryTextRulesTable.type')}</th>
                            <th>{t('TextLibraryTextRulesTable.description')}</th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{ruleRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(TextLibraryTextRulesTable);
