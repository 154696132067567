import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import DateTime from 'react-datetime';
import Moment from 'moment';

import 'react-datetime/css/react-datetime.css';

import './CreatePlayerForm.css';

class CreatePlayerForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPlayer: null,
            isLoading: false,
            player_name: '',
            location: '',
            macAddress: '',
            serialNumber: '',
            installDate: '',
            organisationUUID: this.props.uuid,
            timezone: 'Europe/London',
            addToExistingUsers: true,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return (
            this.state.player_name.length > 0 &&
            this.state.macAddress.length > 0 &&
            this.state.organisationUUID.length > 5
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleAddToExistingUsersChanged = event => {
        this.setState({
            addToExistingUsers: event.target.checked
        });
    };

    handleTimeZoneChange = timezone => {
        this.setState({
            timezone
        });
    };

    handleDateChange = installDate => {
        this.setState({
            installDate
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/players`, {
                    name: this.state.player_name,
                    location: this.state.location,
                    macAddress: this.state.macAddress,
                    organisationUUID: this.state.organisationUUID,
                    timezone: this.state.timezone,
                    installDate: this.state.installDate,
                    serialNumber: this.state.serialNumber,
                    addToExistingUsers: this.state.addToExistingUsers
                })
                .then(function(response) {
                    self.setState({
                        newPlayer: response.data.player,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreatePlayerForm.success')}:`,
                            style: 'success',
                            message: `${t('CreatePlayerForm.created_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreatePlayerForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreatePlayerForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreatePlayerForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createPlayerForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="player_name" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePlayerForm.player_name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.player_name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreatePlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="location" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePlayerForm.location')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-location-arrow" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.location}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreatePlayerForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="macAddress" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePlayerForm.mac_address')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-microchip" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.macAddress}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreatePlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="installDate" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePlayerForm.install_date')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="far fa-calendar-alt" />
                                    </span>
                                    <DateTime
                                        dateFormat="MMMM Do YYYY"
                                        timeFormat={false}
                                        utc={true}
                                        onChange={this.handleDateChange}
                                        value={
                                            new Moment(this.state.installDate)
                                        }
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreatePlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="serialNumber" bsSize="sm">
                                <ControlLabel>
                                    {t('CreatePlayerForm.serial_number')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-qrcode" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.serialNumber}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreatePlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="timezone" bsSize="sm">
                                <label>{t('CreatePlayerForm.timezone')}</label>
                                <TimezonePicker
                                    absolute={true}
                                    overflow={'none'}
                                    style={{ width: '100%' }}
                                    defaultValue="Europe/London"
                                    placeholder={t(
                                        'CreatePlayerForm.choose_timezone'
                                    )}
                                    onChange={this.handleTimeZoneChange}
                                />

                                <small className="form-text text-muted">
                                    {t('CreatePlayerForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="checkbox" bsSize="sm">
                                <Checkbox
                                    checked={this.state.addToExistingUsers}
                                    onChange={
                                        this.handleAddToExistingUsersChanged
                                    }
                                >
                                    {t(
                                        'CreatePlayerForm.addToExistingUsers'
                                    )}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('CreatePlayerForm.add_player')}`}
                                    loadingText={`${t(
                                        'CreatePlayerForm.adding_player'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreatePlayerForm">
                {this.state.newPlayer === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreatePlayerForm);
