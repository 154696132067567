import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form, FormGroup } from 'react-bootstrap';
import HeaderFooterPanel from '../components/HeaderFooterPanel';
import config from '../config';
import qs from 'qs';
import Dropzone from 'react-dropzone';
import TemplateMediaTable from '../components/TemplateMediaTable';
import reorder from '../helpers/reorder';
import { Link } from 'react-router-dom';
import Dialog, { displayName } from 'react-bootstrap-dialog';

import './TemplateMediaLibrary.css';

class TemplateMediaLibrary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			medialibrary: { name: '' },
			media: [],
			files: [],
			itemsSelected: []
		};
	}

	selectionChanged(media, checked) {
		let itemsSelected = this.state.itemsSelected;

		if (checked) {
			itemsSelected.push(media);
		} else {
			itemsSelected = itemsSelected.filter(selectedItem => selectedItem.uuid !== media.uuid);
		}

		var selectedSet = new Set(itemsSelected);

		this.setState({
			itemsSelected: [...selectedSet]
		});
	}

	deleteSelected(media) {
		const { t } = this.props;

		let listedMedia;

		if (media) {
			listedMedia = media.name;
		} else {
			listedMedia = this.state.itemsSelected.map(d => (
				<li key={d.uuid}>
					<strong>{d.name}</strong>
				</li>
			));
		}

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_deletion_items')}
					{listedMedia}
				</div>
			),
			actions: [Dialog.Action(t('Dialog.cancel'), () => console.log('Cancel!'), 'btn-default'), Dialog.Action(t('Dialog.delete'), () => this.didConfirmDelete(), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	onDeleteMedia(media) {
		const { t } = this.props;

		const listedMedia = (
			<li>
				<strong>{media.name}</strong>
			</li>
		);

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_deletion_items')}
					{listedMedia}
				</div>
			),
			actions: [Dialog.Action(t('Dialog.cancel'), () => 'btn-default'), Dialog.Action(t('Dialog.delete'), () => this.didConfirmDelete(media), 'btn-danger')],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	reorderMedia(result) {
		const { mediaLibraryUUID } = this.props.match.params;
		const reorderedMedia = reorder(this.state.media, result.source.index, result.destination.index);

		this.setState({
			media: reorderedMedia
		});

		const ordering = {};
		for (var i = 0; i < reorderedMedia.length; i++) {
			const media = reorderedMedia[i];
			ordering[media.uuid] = i;
		}

		try {
			var self = this;
			axios
				.post(`${config.baseURL}/medialibraries/${mediaLibraryUUID}/media/reorder `, { ordering })
				.then(function(response) {
					console.log('Success!');
				})
				.catch(function(err) {
					console.log(err);
				});
		} catch (e) {
			console.log('exception');
		}
	}

	selectAll() {
		this.setState({ itemsSelected: this.state.media });
	}

	selectNone() {
		this.setState({ itemsSelected: [] });
	}

	deleteMedia(media) {
		return new Promise(function(resolve, reject) {
			var self = this;
			axios
				.delete(`${config.baseURL}/media/${media.uuid}`)
				.then(function(result) {
					resolve();
				})
				.catch(function(err) {
					console.log(`Error deleting file: ${media} error: ${err}`);
					reject('error');
				});
		});
	}
	componentDidMount() {
		this.loadTemplateMediaLibraryFromServer();
		this.loadTemplateMediaLibraryMediaFromServer();
	}

	loadTemplateMediaLibraryFromServer() {
		const { mediaLibraryUUID } = this.props.match.params;

		let self = this;
		axios
			.get(`${config.baseURL}/medialibraries/${mediaLibraryUUID}`, {})
			.then(function(response) {
				self.setState({ medialibrary: response.data.medialibrary });

			})
			.catch(function(error) {
				console.log(error);
			});
	}

	didConfirmDelete(media) {
		let self = this;

		const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

		const promiseSerial = files =>
			files.reduce(function(promise, media) {
				return promise.then(function(result) {
					return Promise.all([delay(250), self.deleteMedia(media)]);
				});
			}, Promise.resolve());

		if (media) {
			self.deleteMedia(media).then(function() {
				self.loadTemplateMediaLibraryMediaFromServer();
			});
		} else {
			promiseSerial(this.state.itemsSelected).then(function() {
				console.log('Deleted media');
				self.setState({ itemsSelected: [] });
				self.loadTemplateMediaLibraryMediaFromServer();
			});
		}
	}

	loadTemplateMediaLibraryMediaFromServer() {
		const { mediaLibraryUUID } = this.props.match.params;

		let self = this;
		axios
			.get(`${config.baseURL}/medialibraries/${mediaLibraryUUID}/media`, {})
			.then(function(response) {
				let media = response.data.media || [];

				self.setState({ media, itemsSelected: [] });

				let setTimer = false;
				for (const mediaItem of media) {
					if (!mediaItem.uploaded) {
						setTimer = true;
						break;
					}
				}

				if (setTimer) {
					setTimeout(
						function() {
							self.loadTemplateMediaLibraryMediaFromServer();
						}.bind(this),
						10000
					);
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	render() {
		const { t } = this.props;
		const { uuid, mediaLibraryUUID } = this.props.match.params;

		const parsed = qs.parse(this.props.location.search.substring(1));
		const rel = parsed.rel;
		document.title = `MySign Digital Screen Solutions | ${t('TemplateMediaLibrary.title')}`;

		let createMediaURL = `/templatelibraries/${mediaLibraryUUID}/media/create`;


		return (
			<div className='TemplateMediaLibrary'>
				<Dialog
					ref={el => {
						this.dialog = el;
					}}
				/>
				<div id='wrapper'>
					<Navigation props={this.props} />
					<div id='content-wrapper'>
						<Row>
							<Col lg={12}>
								<h1 className='page-header'>
									{this.state.medialibrary.name}{' '}
									<Link type='submit' className='btn btn-primary' to={`/templatelibraries/${mediaLibraryUUID}/media/create?rel=template`}>
										<em className='fa fa-plus fa-fw' /> {t('TemplateMediaLibrary.add_media')}
									</Link>
								</h1>

								<ol className='breadcrumb'>
									<li>
										<i className='fas fa-images' /> <Link to={`/templatelibraries`}>{t('Navigation.templatelibraries')}</Link>
									</li>
									<li className='active'>
										<i className='fas fa-images' /> {t('TemplateMediaLibrary.title')}
									</li>{' '}
								</ol>

								<HeaderFooterPanel
									title={t('TemplateMediaLibrary.media')}
									headerContent={
										<div className='btn-toolbar pull-right'>
											<Button className='btn-default btn-sm' disabled={false} onClick={() => this.selectAll()}>
												<em className='far fa-check-square' /> {t('TemplateMediaLibrary.select_all')}
											</Button>
											<Button className='btn-default btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.selectNone()}>
												<em className='far fa-square' /> {t('TemplateMediaLibrary.select_none')}
											</Button>
											<Button className='btn btn-danger btn-sm' disabled={this.state.itemsSelected.length == 0} onClick={() => this.deleteSelected()}>
												<em className='fa fa-trash' /> {t('TemplateMediaLibrary.delete_selected')}
											</Button>
										</div>
									}
								>
									<TemplateMediaTable
										media={this.state.media}
										rel={rel}
										medialibraryuuid={mediaLibraryUUID}
										organisationuuid={this.state.medialibrary.organisationUUID}
										onSave={null}
										onDragEnd={this.reorderMedia.bind(this)}
										onCopyClicked={null}
										onTextsClicked={null}
										onSelectionChanged={this.selectionChanged.bind(this)}
										onEnabledChanged={null}
										itemsSelected={this.state.itemsSelected}
										onDeleteMedia={media => this.onDeleteMedia(media)}
									/>
								</HeaderFooterPanel>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('translations')(TemplateMediaLibrary);
