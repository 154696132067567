import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';

Moment.locale('en');

class OrganisationAttributesTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    didConfirmDelete(attribute) {
        this.props.onDelete(attribute);
    }

    handleDeleteClicked(attribute) {
        const { t } = this.props;

        let listedAttribute;

		if (attribute) {
			listedAttribute = attribute.name;
		}

        this.dialog.show({
            title: t('Dialog.warning'),
            body: (
				<div>
					{t('Dialog.sure_deletion_attribute')}<br/>
					<ul><li><strong>{listedAttribute}</strong></li></ul>
				</div>
			),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(attribute),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t, uuid } = this.props;
        
        const attributepath = this.props.emailAttribute ? "emailattributes" : "attributes";

        let attributeRows = this.props.attributes.map((attribute, index) => {
            return (
                <tr key={index}>
                    <td width="250">{attribute.name}</td>
                    <td width="80">
                        {attribute.default ? (
                            <span className="label label-success">
                                {t('OrganisationAttributesTable.true')}
                            </span>
                        ) : (
                            <span className="label label-danger">
                                {t('OrganisationAttributesTable.false')}
                            </span>
                        )}
                    </td>
                    <td width="150" className="hidden-xs">
                        {Moment(attribute.updatedAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td width="150" className="hidden-xs">
                        {Moment(attribute.createdAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td align="center">
                        <div className="btn-toolbar pull-right">
                            {this.props.userIsAuthorized(this.props.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin'
                            ]) ? (
                                <TooltipLink
                                    id="editButton"
                                    className="btn btn-default"
                                    tooltip={t(
                                        'OrganisationAttributesTable.viewplayers'
                                    )}
                                    to={`/organisations/${uuid}/${attributepath}/${
                                        attribute.uuid
                                    }/players`}
                                >
                                    <em className="fa fa-tv" />{' '}
                                    {t('OrganisationAttributesTable.players')}
                                </TooltipLink>
                            ) : null}
                            {this.props.userIsAuthorized(this.props.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin',
                                'organisation_user'
                            ]) ? (
                                <Fragment>
                                    <TooltipLink
                                        className="btn btn-default"
                                        tooltip={t(
                                            'OrganisationAttributesTable.editattribute'
                                        )}
                                        to={`/organisations/${uuid}/${attributepath}/${
                                            attribute.uuid
                                        }/edit`}
                                    >
                                        <em className="fas fa-pencil-alt" />
                                    </TooltipLink>

                                    <TooltipButton
                                        tooltip={t(
                                            'OrganisationAttributesTable.deleteattribute'
                                        )}
                                        className="btn-danger "
                                        onClick={() =>
                                            this.handleDeleteClicked(attribute)
                                        }
                                    >
                                        <em className="fa fa-trash" />
                                    </TooltipButton>
                                </Fragment>
                            ) : null}
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div
                id="OrganisationAttributesTable"
                className="OrganisationAttributesTable"
            >
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{t('OrganisationAttributesTable.name')}</th>
                            <th>{t('OrganisationAttributesTable.default')}</th>
                            <th className="hidden-xs">
                                {t('OrganisationAttributesTable.updated')}
                            </th>
                            <th className="hidden-xs">
                                {t('OrganisationAttributesTable.created')}
                            </th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{attributeRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(OrganisationAttributesTable);
