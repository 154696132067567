import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import DateTime from 'react-datetime';
import Moment from 'moment';

import './EditOTDFactForm.css';

import 'react-datetime/css/react-datetime.css';

class EditOTDFactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            otdFact: {
                description: '',
                detailed_description: '',
                month: 1,
                image_url: '',
                day: 1,
                date: ''
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.otdFact !== this.state.otdFact) {
            if (nextProps.otdFact) {
                this.setState({ otdFact: nextProps.otdFact });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return this.state.otdFact.description.length > 0;
    }

    handleChange = event => {
        const otdFact = this.state.otdFact;
        otdFact[event.target.id] = event.target.value;

        // update state
        this.setState({
            otdFact
        });
    };

    handleDateChange = date => {
        const otdFact = this.state.otdFact;

        let month = date.month() + 1;
        let day = date.date();

        otdFact.day = day;
        otdFact.date = date;
        otdFact.month = month;

        // update state
        this.setState({
            otdFact
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const id = this.state.otdFact.id;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(`${config.baseURL}/onthisday/${id}`, {
                    month: this.state.otdFact.month,
                    day: this.state.otdFact.day,
                    description: this.state.otdFact.description,
                    detailed_description: this.state.otdFact
                        .detailed_description,
                    date: this.state.otdFact.date,
                    image_url: this.state.otdFact.image_url
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOTDFactForm.success')}:`,
                            style: 'success',
                            message: `${t('EditOTDFactForm.saved_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOTDFactForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditOTDFactForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditOTDFactForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditOTDFactForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="image_url" bsSize="sm">
                                <ControlLabel>
                                    {t('EditOTDFactForm.image_path')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    placeholder="e.g. otd_hd/fireworks.jpg"
                                    value={this.state.otdFact.image_url}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOTDFactForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>
                                    {t('EditOTDFactForm.date')}
                                </ControlLabel>
                                <DateTime
                                    dateFormat="MMMM Do YYYY"
                                    timeFormat={false}
                                    utc={true}
                                    onChange={this.handleDateChange}
                                    value={new Moment(this.state.otdFact.date)}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOTDFactForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="description" bsSize="sm">
                                <ControlLabel>
                                    {t('EditOTDFactForm.description')}
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="e.g. January 1st: New Year's Day"
                                    value={this.state.otdFact.description}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOTDFactForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup
                                controlId="detailed_description"
                                bsSize="sm"
                            >
                                <ControlLabel>
                                    {t('EditOTDFactForm.detailed_description')}
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="e.g. This is the world's only global holiday, often celebrated with fireworks and a party to welcome in the New Year."
                                    value={
                                        this.state.otdFact.detailed_description
                                    }
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditOTDFactForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.otdFact.isLoading}
                                    text={`${t(
                                        'EditOTDFactForm.save_otdFact'
                                    )}`}
                                    loadingText={`${t(
                                        'EditOTDFactForm.saving_otdFact'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditOTDFactForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditOTDFactForm);
