const dev = {
  localStorageKey: "MyLookLocalStorage",
  baseURL: "http://localhost:3001",
  playersBaseURL: "http://localhost:3002"
};

const prod = {
  localStorageKey: "MyLookLocalStorageProduction",
  baseURL: "https://api.cloudsignage.me",
  playersBaseURL: "https://players.cloudsignage.me"
};

const config = process.env.REACT_APP_STAGE === 'development' ? dev : prod;

export default {
  ...config
};
