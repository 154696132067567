import React, { Component } from 'react';
import { translate } from 'react-i18next';
import axios from 'axios';
import config from '../config';

import './EditOrganisationNotificationEmailSettingsForm.css';

class EditOrganisationNotificationEmailSettingsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            organisation: {
                uuid: ''
            },
            preferences: {
                emails: []
                // enabled: false,
                // interval: 'DAILY'
            },
            interval: "",
            errorMessage: "",
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            },
            value: "",
            items: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.organisation !== this.state.organisation) {
            if (nextProps.organisation) {
                this.setState({ organisation: nextProps.organisation });
            }
        }

        if (nextProps.preferences !== this.state.preferences) {
            if(nextProps.preferences) {
                console.log(`Setting preferences: ${JSON.stringify(nextProps.preferences)}`);
                this.setState({ preferences: nextProps.preferences });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return (true);
    }

    handleKeyDown = evt => {
        const { t } = this.props;

        if (["Enter", "Tab", ","].includes(evt.key)) {
          evt.preventDefault();

            var value = this.state.value.trim();
            console.log(value);
        
              if (value && this.isValid(value)) {
    
                this.setState({ isLoading: true });
    
                const uuid = this.state.organisation.uuid;
                const preferencesID = this.state.preferences.id;
    
                // TODO: Validate that it's a valid UUID.
                
                try {
                    var self = this;
                    axios
                        .post(`${config.baseURL}/organisations/${uuid}/notificationpreferences/${preferencesID}/emails`, {
                            email: value
                        })
                        .then(function(response) {
                            self.setState({
                                saved: true,
                                isLoading: false,
                                error: {
                                    hidden: false,
                                    title: `${t('EditOrganisationNotificationEmailSettingsForm.success')}:`,
                                    style: 'success',
                                    message: `${t(
                                        'EditOrganisationNotificationEmailSettingsForm.saved_message'
                                    )}`
                                }
                            });
                            self.props.onRefreshRequired();
                        })
                        .catch(function(err) {
                            self.setState({
                                isLoading: false,
                                error: {
                                    hidden: false,
                                    title: `${t('EditOrganisationNotificationEmailSettingsForm.error')}:`,
                                    style: 'danger',
                                    message: 'Error occurred'
                                }
                            });
                        });
                } catch (e) {
                    this.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOrganisationNotificationEmailSettingsForm.error')}:`,
                            style: 'danger',
                            message: `${t('EditOrganisationNotificationEmailSettingsForm.unknown_error')}`
                        }
                    });
                }
            }

            this.setState({
              value: ""
            });
          }
        };
    
    
      handleChange = evt => {
        this.setState({
          value: evt.target.value,
          errorMessage: null
        });
      };
    
      handleDelete = item => {

        const { t } = this.props;

        const uuid = this.state.organisation.uuid;
                const preferencesID = this.state.preferences.id;
    
                // TODO: Validate that it's a valid UUID.
                
                try {
                    var self = this;
                    axios
                        .delete(`${config.baseURL}/organisations/${uuid}/notificationpreferences/${preferencesID}/emails/${item}`, {})
                        .then(function(response) {
                            self.setState({
                                saved: true,
                                isLoading: false,
                                error: {
                                    hidden: false,
                                    title: `${t('EditOrganisationNotificationEmailSettingsForm.success')}:`,
                                    style: 'success',
                                    message: `${t(
                                        'EditOrganisationNotificationEmailSettingsForm.saved_message'
                                    )}`
                                }
                            });
                            self.props.onRefreshRequired();
                        })
                        .catch(function(err) {
                            self.setState({
                                isLoading: false,
                                error: {
                                    hidden: false,
                                    title: `${t('EditOrganisationNotificationEmailSettingsForm.error')}:`,
                                    style: 'danger',
                                    message: 'Error occurred'
                                }
                            });
                        });
                } catch (e) {
                    this.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditOrganisationNotificationEmailSettingsForm.error')}:`,
                            style: 'danger',
                            message: `${t('EditOrganisationNotificationEmailSettingsForm.unknown_error')}`
                        }
                    });
                }
        
      };
    
      isValid(email) {

        let errorMessage = null;
    
        if (this.isInList(email)) {
            errorMessage = `${email} has already been added.`;
        }
    
        if (!this.isEmail(email)) {
            errorMessage = `${email} is not a valid email address.`;
        }
    
        if (errorMessage) {
          this.setState({ errorMessage });
    
          return false;
        }
    
        return true;
      }
    
      isInList(email) {

        return  this.state.preferences.emails.filter(e => e.email.toUpperCase() === email.toUpperCase()).length > 0;
    }
    
      isEmail(email) {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
      }
    

    render() {
        const { t } = this.props;
        return (
          <>
            {this.state.preferences.emails.map(email => (
              <div className="tag-item" key={email.id}>
                {email.email}

                <button
                  type="button"
                  className={"button" + (email.hasRules ? "_active" : "")}

                  onClick={() => this.props.onRulesPressed(email.id)}
                >
                {' '} <em className="fas fa-clipboard-list" />{' '}
                </button>
                <button
                  type="button"
                  className="button"
                  onClick={() => this.handleDelete(email.id)}
                >
                  &times;
                </button>
              </div>
            ))}
    
            <input
              className={"input " + (this.state.error && " has-error")}
              value={this.state.value}
              placeholder="Type an email address and press `Enter`..."
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
                          />
    
            {this.state.errorMessage && <p className="error">{this.state.errorMessage}</p>}
          </>
        );
      }
}

export default translate('translations')(EditOrganisationNotificationEmailSettingsForm);
