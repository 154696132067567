import i18n from 'i18next';
import Translations_EN from './locales/en/translations';
import Translations_DE from './locales/de/translations';

import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

i18n.use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        fallbackLng: 'en',
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        resources: {
            en: {
                translations: Translations_EN
            },
            de: {
                translations: Translations_DE
            }
        },

        debug: false,

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        react: {
            wait: false
        }
    });

export default i18n;
