import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditAttributeForm from '../components/EditAttributeForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './EditAttribute.css';

class EditAttribute extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = { attribute: null, uuid };
    }

    componentDidMount() {
        this.loadAttributeFromServer();
    }

    loadAttributeFromServer() {
        const { uuid, attributeUUID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/organisations/${uuid}/attributes/${attributeUUID}`, {})
            .then(function(response) {
                self.setState({ attribute: response.data.attribute });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        const attributepath = this.props.emailAttribute ? "emailattributes" : "attributes";
        
        document.title = `MySign Digital Screen Solutions | ${t('EditAttribute.title')}`;

        return (
            <div className="EditAttribute">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">{t('EditAttribute.title')}</h1>
                                <ol className="breadcrumb">
                                    {rel === 'staff_admin' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-briefcase" />{' '}
                                                <Link to="/attributes">{t('Attributes.title')}</Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-edit" /> {t('EditAttribute.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link to={`/organisations/${this.state.uuid}`}>
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tags" />{' '}
                                                <Link to={`/organisations/${this.state.uuid}/${attributepath}`}>
                                                {this.props.emailAttribute ? (

                                                t('SideNavigation.emailattributes')

                                                ) :  t('SideNavigation.attributes')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-edit" /> {t('EditAttribute.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    )}
                                </ol>

                                <PlainPanel>
                                    <EditAttributeForm attribute={this.state.attribute} uuid={this.state.uuid} />
                                </PlainPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(EditAttribute);
